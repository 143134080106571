import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { UserRoutes } from "../../components/Navigation";
import Bookmarks from "./Bookmarks";
import Notices from "./Notices";
import { forFade } from "../../navigation";
import { useTheme } from "../../components";

const UserStack = createStackNavigator<UserRoutes>();

const UserNavigator = () => {
  const theme = useTheme();
  return (
    <UserStack.Navigator
      screenOptions={{
        gestureEnabled: false,
        cardStyleInterpolator: forFade,
        cardStyle: {
          backgroundColor: theme.colors.mainBackground,
        },
      }}
    >
      <UserStack.Screen name="Bookmarks" component={Bookmarks} />
      <UserStack.Screen name="Notices" component={Notices} />
    </UserStack.Navigator>
  );
};

export default UserNavigator;
