import React from "react";
import { RenderTokenProps, Token } from "./types";
import DefaultRenderer from "./DefaultRenderer";
import { generateUniqueId } from "../../utils/helpers";

const RenderTokens: React.FC<RenderTokenProps> = ({
  tokens,
  H1,
  H2,
  H3,
  Link,
  Bold,
  Red,
  Standard,
}: RenderTokenProps) =>
  tokens.map((token) => {
    if (token.type === "Header") {
      return <H1 key={generateUniqueId()}>{token.content.toString()}</H1>;
    }
    if (token.type === "Text") {
      return (
        <Standard key={generateUniqueId()}>{token.content.toString()}</Standard>
      );
    }

    return (
      <DefaultRenderer
        markdown={token.content}
        key={generateUniqueId()}
        Link={Link}
        Bold={Bold}
        Red={Red}
        Standard={Standard}
      />
    );
  });

export default RenderTokens;
