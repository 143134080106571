import React from "react";
import QRWrapper from "./QRWrapper";
import t from "../../../Texts/TextImport";

import { Box, Button, LinkBox, Text } from "../../components";
import { generateUniqueId, buildHomeLink } from "../../utils/helpers";

import { GuidelineNavigationProps } from "../../components/Navigation";
import useGuideline, {
  useIsGuidelineUnlocked,
} from "../../hooks/queries/useGuideline";
import Markdown from "../../components/Markdown";
import { getTitle } from "../../hooks/useLanguage";

function QRSuccess({
  navigation,
  route,
}: GuidelineNavigationProps<"FullText">) {
  const isUnlocked = useIsGuidelineUnlocked(route.params.guidelineId);
  const { data: guideline } = useGuideline(route.params.guidelineId);

  return (
    <QRWrapper>
      <Box
        width={"100%"}
        height={"100%"}
        justifyContent="space-between"
        marginVertical="s"
        flexDirection="column"
      >
        <Box></Box>
        {isUnlocked ? (
          <Markdown>{t("QRSuccAct", [getTitle(guideline)])}</Markdown>
        ) : (
          <Text>Da ist was schief gelaufen</Text>
        )}

        <Box marginHorizontal="xxl">
          <LinkBox showLoading={false} to={buildHomeLink()}>
            <Button
              variant="border"
              color={"primary"}
              mainColor={"primary"}
              label={t("GenClose")}
              key={generateUniqueId()}
              onPress={() => {}}
            />
          </LinkBox>
        </Box>

        <Box></Box>
      </Box>
    </QRWrapper>
  );
}

export default QRSuccess;
