import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import axios from "axios";
import { HORIZONTAL, MET, URL, SECTOR, LANGUAGE } from "./config";
import { HorizontalType } from "../../types";
import { useEffect } from "react";
import { writeToStorage } from "../../utils";
import { DEBUGISON, HOOKSDEBUGGING } from "../../config";
import { getDownloadedData } from "./useInitialize";

let writeEnabled = false;

export const getHorizontalById = async (context: QueryFunctionContext) => {
  DEBUGISON && HOOKSDEBUGGING && console.log("Horizontal:getHorizontalById");
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: HORIZONTAL,
    query: `GUIDELINE_UUID=${context.queryKey[1]}`,
    distinct: "TERM"
  };
  const { data } = await axios.get(URL, { params });
  writeEnabled = true;
  return data;
};

export default function useHorizontal(
  guidelineId: string,
  onSuccess: (data: string[]) => void,
  options?: QueryObserverOptions<string[]>
) {
  DEBUGISON &&
    HOOKSDEBUGGING &&
    options?.enabled &&
    console.log("useHorizontal:useHorizontal: " + guidelineId);

  const downloaded = getDownloadedData(guidelineId, [
    "horizontal",
    guidelineId
  ]);

  const res = useQuery<string[]>(
    ["horizontal", guidelineId],
    getHorizontalById,
    {
      ...options
    }
  );
  res.data && options?.enabled && onSuccess(res.data);
}
