import { Platform } from "react-native";

export const APPBRANCH = "PRO"; // possible values: CARE | PRO | ERKNET
export const DEBUGISON = false;
export const TEXTDEBUG = false;
export const ORIGINDEBUG = true;
export const FEEDBACKDEBUG = false;
export const BACKENDTRACING = false;
export const HTMLDEBUGGING = false;
export const HTMLACTIONDEBUG = false;
export const LANGUAGEDEBUGGING = false;
export const NAVDEBUGGING = false;
export const TABLEDEBUG = false;
export const LAYOUTDEBUG = false;
export const STORAGEDEBUG = false;
export const IMAGEDEBUG = false;
export const HOOKSDEBUGGING = true;
export const SEARCHDEBUG = true;
export const SCOREDEBUG = false;
export const USERDEBUG = false;
export const UTILDEBUGGING = false;
export const FEATUREDEBUGGING = false;
export const PATHWAYDEBUGGING = false;

export const backends: { [key: string]: string } = {
  // CARE: "https://leila.leila.care/",
  CARE: "https://prelive.leila.de/",
  PRO: "https://leila.leila.care/",
  ERKNET: "https://leila.leila.care/",
};
export const Language: { [key: string]: string } = {
  CARE: "DE",
  PRO: "DE",
  ERKNET: "EN",
};

export const TextSelectable: { [key: string]: boolean } = {
  CARE: false,
  PRO: true,
  ERKNET: true,
};
export const isTextSelectable = TextSelectable[APPBRANCH];

const webLinks: { [key: string]: string } = {
  CARE: "https://leila.leila.care",
  PRO: "https://medic.leila.de",
  ERKNET: "https://leila.leila.care",
};

export const Sector: { [key: string]: string } = {
  CARE: "CARE",
  PRO: "Medical",
  ERKNET: "ERKnet",
};

export const ROOT = backends[APPBRANCH];
export const WebLink = webLinks[APPBRANCH];

export const API = ROOT + "/?met=WEB_API";
export const FEEDBACK = ROOT + "/?met=LEILA_LEILI__GET_FEEDBACK";
export const TRACKER = ROOT + "/?met=TRACKING";

export const BREAKPOINTS = {
  xxs: 200,
  xs: 320,
  s: 815,
  m: 1023,
  l: 1650,
  xl: 2000,
};

export const FLEX_LAYOUT = {
  left: 0.5,
  middle: 1,
};

export const isWeb = Platform.OS === "web";
export const PLATFORM = isWeb ? "web" : "phone";

export const AutoDownload = Platform.OS === "web" ? false : true;
// 1n8ajxqcuf
// pBm5lJzf0A
// C9MrI4O1a9

// 4D
// Benutzer: Jan Dominik Franz
// Passwort: p1Zz09#
// URL: 93.92.134.180
// 4D Handbuch:
// https://doc.4d.com/4Dv18/4D/18.4/4D-Programmiersprache.100-5232397.de.htm

// 6021;

export const isPro = APPBRANCH === "PRO";
export const isCare = APPBRANCH === "CARE";
export const isErknet = APPBRANCH === "ERKNET";

export const setupMode: "test" | "production" | "development" =
  process.env.NODE_ENV;
export const isInTest = setupMode === "test";
export const isInDevelopment = setupMode === "development";
export const isInProduction = setupMode === "production";
