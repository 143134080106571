import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import axios from "axios";
import { MET, SCORE, URL, SECTOR, LANGUAGE } from "./config";
import { ScoreType } from "../../types";
import { useEffect } from "react";
import { writeToStorage } from "../../utils";
import { LANGUAGE_SUFFIX } from "../useLanguage";
import { DEBUGISON, HOOKSDEBUGGING } from "../../config";

export const getScoreById = async (context: QueryFunctionContext) => {
  DEBUGISON && HOOKSDEBUGGING && console.log("useScore:getScoreById: ");
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: SCORE,
    get: context.queryKey[1],
    attributes: "*,RESULTS.*,QUESTIONS.*,QUESTIONS.ANSWERS.* , "
  };
  const { data } = await axios.get(URL, { params });

  DEBUGISON &&
    HOOKSDEBUGGING &&
    console.log(
      "useScore:getScoreById  \nparams" +
        JSON.stringify(params) +
        "\n\n return" +
        JSON.stringify(data)
    );

  return data;
};

export const getAllScores = async (_: any) => {
  DEBUGISON && HOOKSDEBUGGING && console.log("useScore:getAllScores: ");
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: SCORE,
    query: "STATUS=VERÖFFENTLICHT",
    attributes: `UUID,TITLE${LANGUAGE_SUFFIX},TITLE`
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export default function useScore(
  scoreId: string,
  onSuccess: (data: ScoreType) => void,
  options?: QueryObserverOptions<ScoreType>
) {
  const res = useQuery<ScoreType>(["score", scoreId], getScoreById, {
    ...options
  });
  res.data && options?.enabled && onSuccess(res.data);
}
export function useAllScores() {
  DEBUGISON && HOOKSDEBUGGING && console.log("useScore:useAllScores: ");
  return useQuery<ScoreType[]>(["allScores"], getAllScores);
}
