import { BoxProps, color } from "@shopify/restyle";
import React from "react";
import { DEBUGISON, ORIGINDEBUG } from "../config";
import { LANGUAGE_SUFFIX, getContent, getTitle } from "../hooks/useLanguage";
import { ChapterType, ModeType } from "../types";
import HTML from "./HTML";
import Icon from "./Icon";
import LinkBox from "./LinkBox";
import { Box, Text, Theme, useTheme } from "./Theme";
import t from "../../Texts/TextImport";
import { LinearGradient } from "expo-linear-gradient";
import { View } from "react-native";

interface ChapterCardProps extends BoxProps<Theme> {
  chapter: ChapterType;
  mode: ModeType;
  hits: number;
  term: string;
  withGuidelineTitle?: boolean;
  maxHeight?: number;
}

const ChapterCard = ({
  mode,
  chapter,
  hits,
  term,
  withGuidelineTitle = false,
  maxHeight,
  ...props
}: ChapterCardProps) => {
  const theme = useTheme();
  const reg = new RegExp(term, "ig");
  const index = reg.exec(getContent(chapter))?.index || 0;
  const content =
    getContent(chapter).slice(0, index) +
    "<term>" +
    getContent(chapter).slice(index, index + term.length) +
    "</term>" +
    getContent(chapter).slice(index + term.length, -1);
  return (
    <Box paddingTop="m" {...props}>
      <LinkBox
        to={`/Guideline/FullText?id=${chapter.UUID}&guidelineId=${chapter.GUIDELINE_UUID}&term=${term}`}
      >
        <Box
          borderBottomWidth={1}
          borderBottomColor="information"
          borderLeftWidth={5}
          borderLeftColor={mode}
          paddingBottom="m"
          paddingHorizontal="m"
          paddingTop="xs"
        >
          {DEBUGISON && ORIGINDEBUG && <Text> ChapterCard</Text>}
          {withGuidelineTitle && (
            <Box flexDirection="row" alignItems="center" paddingBottom="s">
              <Icon name={mode} color={mode} />
              <Box flex={1}>
                <Text variant="description" paddingLeft="s" numberOfLines={1}>
                  {getTitle(chapter.GUIDELINE)}
                </Text>
              </Box>
            </Box>
          )}
          <Box>
            <Text variant="subtitle2" numberOfLines={2}>
              {getTitle(chapter)}
            </Text>
            <Box
              overflow={"hidden"}
              maxHeight={maxHeight ? maxHeight : undefined}
            >
              <HTML html={`<p>${content}</p>`} mode={mode} />
            </Box>

            <Text variant="description" color="information" paddingTop="xs">
              {hits} {t("CChits")}
              {ORIGINDEBUG && DEBUGISON && "ChapterCard"}
            </Text>
          </Box>
        </Box>
      </LinkBox>
    </Box>
  );
};

export default ChapterCard;
