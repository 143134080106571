import React from "react";
import { Linking, TouchableOpacity } from "react-native";
import { Box, Icon, Text } from "../../components";
import t from "../../../Texts/TextImport";

const EmailButton = () => {
  const openMail = () =>
    Linking.openURL(
      `mailto:leila@leila.de?subject=Fragen zu Leila&body=${t("EBConsent")}`
    );
  return (
    <TouchableOpacity onPress={openMail}>
      <Box flexDirection='row' alignItems='center'>
        <Icon name='circledFeedback' color='primary' />
        <Text color='primary'>{t("EBDe")}</Text>
      </Box>
    </TouchableOpacity>
  );
};

export default EmailButton;
