import { Platform } from "react-native";
import { createStore, combineReducers, applyMiddleware } from "redux";
import {
	useSelector as useReduxSelector,
	TypedUseSelectorHook,
} from "react-redux";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import promise from "redux-promise-middleware";
import guidelinesReducer from "./guidelines/reducers";
import globalReducer from "./global/reducers";
import userReducer from "./user/reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import AsyncStorage from "@react-native-async-storage/async-storage";

// // eslint-disable-next-line
// const webstore = require("redux-persist/lib/storage/session").default;
// eslint-disable-next-line
const isWeb = Platform.OS === "web";
const storage = AsyncStorage;

const rootPersistConfig = {
	key: "root",
	storage,
};

const userPersistConfig = {
	key: "user",
	storage,
	blacklist: [],
};

const guidelinesPersistConfig = {
	key: "guidelines",
	storage,
	blacklist: ["loadedGuidelines"],
};

const globalPersistConfig = {
	key: "global",
	storage,
	// not working properly
	blacklist: ["popup"],
};

const rootReducer = combineReducers({
	guidelines: persistReducer(guidelinesPersistConfig, guidelinesReducer),
	global: persistReducer(globalPersistConfig, globalReducer),
	user: persistReducer(userPersistConfig, userReducer),
});

export type RootState = ReturnType<typeof rootReducer>;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

const rootPersistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = createStore(
	rootPersistedReducer,
	{},
	composeWithDevTools(applyMiddleware(promise))
);
export const persistor = persistStore(store);
