import React, { Dispatch, SetStateAction } from "react";
import t from "../../../../Texts/TextImport";
import { ButtonGroup, Box, Text } from "../../../components";
import { AreaType } from "../../../types";
import { GuidelineType } from "../../../types/guideline";

import Button from "./Button";
import { APPBRANCH, BACKENDTRACING, DEBUGISON } from "../../../config";
import { LANGUAGE_SUFFIX } from "../../../hooks/useLanguage";

export type SortProps = {
  key: keyof GuidelineType;
  order: "asc" | "desc";
};

interface FilterProps {
  areaData: AreaType[] | undefined;
  guidelinesData: GuidelineType[] | undefined;
  selectedArea: AreaType[];
  setSelectedArea: Dispatch<SetStateAction<AreaType[]>>;
  sortBy: SortProps;
  setSortBy: Dispatch<SetStateAction<SortProps>>;
}

const Filter = ({
  areaData,
  selectedArea,
  setSelectedArea,
  guidelinesData,
  setSortBy
}: FilterProps) => {
  return (
    <>
      <Box flexDirection="row" flexWrap="wrap">
        {areaData?.map((area) => {
          DEBUGISON && BACKENDTRACING && console.log("area:");
          DEBUGISON && BACKENDTRACING && console.log(area);
          DEBUGISON && BACKENDTRACING && console.log("guidelines:");
          DEBUGISON && BACKENDTRACING && console.log(guidelinesData);
          let found = false;
          guidelinesData?.map((el, i) => {
            if (el.UUID == area.UUID) {
              found = true;
            }
          });

          DEBUGISON &&
            BACKENDTRACING &&
            console.log(area.ICON && `We have an Icon: ${area.UUID}`);
          const isDisabled = guidelinesData?.find(
            (i) => i.AREA.UUID === area.UUID
          )
            ? false
            : true;
          const isActive = selectedArea.find((i) => i.UUID === area.UUID)
            ? true
            : false;
          const onPress = () => {
            if (isActive) {
              setSelectedArea((areas) => areas.filter((i) => i !== area));
            } else {
              setSelectedArea((areas) => [...areas, area]);
            }
          };
          return (
            <React.Fragment key={area.UUID}>
              {!isDisabled && (
                <Button
                  label={area.SHORT_NAME}
                  icon={area.ICON ? area.ICON : "ICONNOTFOUND"}
                  isActive={isActive}
                  isDisabled={isDisabled}
                  onPress={onPress}
                />
              )}
            </React.Fragment>
          );
        })}
      </Box>

      {APPBRANCH !== "ERKNET" && (
        <ButtonGroup multiSelect={false} disableEmpty={true} defaultState={[0]}>
          <ButtonGroup.Button
            index={0}
            onPress={() => setSortBy({ key: "ORDER", order: "asc" })}
          >
            {t("AFFreq")}
          </ButtonGroup.Button>
          <ButtonGroup.Button
            index={1}
            onPress={() =>
              setSortBy({ key: "TITLE" + LANGUAGE_SUFFIX, order: "asc" })
            }
          >
            {t("AFAlph")}
          </ButtonGroup.Button>
          <ButtonGroup.Button
            index={2}
            onPress={() => setSortBy({ key: "CLASSIFICATION", order: "desc" })}
          >
            {t("AFClass")}
          </ButtonGroup.Button>
        </ButtonGroup>
      )}
    </>
  );
};

export default Filter;
