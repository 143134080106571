import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { GUIDELINE, MET, URL, SECTOR, LANGUAGE } from "./config";
import { GuidelineType } from "../../types/guideline";
import { useEffect, useState } from "react";
import { writeToStorage } from "../../utils";
import { LANGUAGE_SUFFIX } from "../useLanguage";
import { DEBUGISON, HOOKSDEBUGGING, isCare } from "../../config";
import constructURL from "./utils";
import { useAuth } from "../useAuth";
import useUser, { useActivatedGuidelines } from "./useUser";
import {
  GuidelineNavigationProps,
  GuidelineRoutes,
} from "../../components/Navigation";
import { StackNavigationProp } from "@react-navigation/stack";
import { devLog } from "../../utils/logging";
const attributes = `UUID,TITLE${LANGUAGE_SUFFIX},TITLE_SHORT,TITLE_SHORT,AUTHOR,FIRST_AUTHOR.UUID,FIRST_AUTHOR.NAME,FIRST_AUTHOR.NAME_LAST,CLASSIFICATION,SOCIETY.TITLE,SOCIETY.ACRONYM,AREA.*,ORDER,EXPIRATION_DATE,BASIC_ENTRY_POINTS.*,GUIDELINE_EXPORT.HASH_KEY`;
const CareAttributes = attributes + `,LIST`;

export const getGuidelineById = async (context: QueryFunctionContext) => {
  const params = {
    data: undefined,
    met: MET,
    sector: SECTOR,
    language: LANGUAGE,
    entity: GUIDELINE,
    get: context.queryKey[1],
    attributes: isCare ? CareAttributes : attributes,
    brew: `CONTENT${LANGUAGE_SUFFIX}`,
    brewguideline: context.queryKey[2],
  };

  devLog(constructURL(URL, params), HOOKSDEBUGGING);
  const { data } = await axios.get(URL, { params, data: undefined });

  return data;
};
export const getGuidelines = async (_: any) => {
  const params = {
    met: MET,
    sector: SECTOR,
    language: LANGUAGE,
    entity: GUIDELINE,
    query: "STATUS=VERÖFFENTLICHT",
    attributes: isCare ? CareAttributes : attributes,
    brew: `CONTENT${LANGUAGE_SUFFIX}`,
  };
  const { data } = await axios.get(URL, { params });

  devLog(constructURL(URL, params), HOOKSDEBUGGING);

  return data;
};
const getNewestGuideline = async (_: any) => {
  devLog("useGuideline:getNewestGuideline");
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: GUIDELINE,
    query: "STATUS=VERÖFFENTLICHT",
    orderBy: "CHANGE_DATE desc CHANGE_Time desc",
    slice: "1",
    attributes: `UUID,TITLE${LANGUAGE_SUFFIX}, TITLE,CHANGE_DATE,LIST`,
    brew: `CONTENT${LANGUAGE_SUFFIX}`,
  };
  const { data } = await axios.get(URL, { params });

  DEBUGISON &&
    HOOKSDEBUGGING &&
    console.log(
      "useGuideline:getNewestGuideline params:  " +
        JSON.stringify(params) +
        "\n\n return" +
        JSON.stringify(data).substring(0, 100)
    );
  return data;
};
export default function useGuideline(
  guidelineId: string,
  options?: QueryObserverOptions<GuidelineType>
) {
  DEBUGISON && HOOKSDEBUGGING && console.log("useGuideline:useGuideline");

  const queried = useQuery<GuidelineType>(
    ["guideline", guidelineId],
    getGuidelineById,
    {
      ...options,
    }
  );
  return queried;
}
export function useGuidelines(options?: QueryObserverOptions<GuidelineType[]>) {
  DEBUGISON && HOOKSDEBUGGING && console.log("useGuideline:useGuidelines");
  return useQuery<GuidelineType[]>(["guidelines"], getGuidelines, {
    ...options,
    onSuccess: (data) => {
      options?.onSuccess && options.onSuccess(data);
      writeToStorage(["guidelines"], data);
    },
  });
}
export function useNewestGuideline(
  options?: QueryObserverOptions<GuidelineType[]>
) {
  return useQuery<GuidelineType[]>(
    ["newestGuideline"],
    getNewestGuideline,
    options
  );
}
export function ensureGuideline(guidelineId: string) {
  DEBUGISON && HOOKSDEBUGGING && console.log("useGuideline:ensureGuideline: ");
  const queryClient = useQueryClient();
  return queryClient.ensureQueryData<GuidelineType>({
    queryKey: ["chapter", guidelineId],
    queryFn: getGuidelineById,
  });
}

export function useIsGuidelineUnlocked(GUIDELINE_UUID: string): boolean {
  const { username } = useAuth();
  const { data: user } = useUser(username);
  const { data: normalUserGuidelines } = useActivatedGuidelines(user?.ID || "");
  const { data: MainUserGuidelines } = useActivatedGuidelines(
    user?.MAIN_USER_ID || ""
  );
  const { data: guideline } = useGuideline(GUIDELINE_UUID);
  console.log("useGuideline:isGuidelineUnlocked: ");
  if (!isCare) return true;

  if (guideline?.LIST === "FREE") {
    DEBUGISON && console.log("isUnlocked=free");
    return true;
  }

  if (
    normalUserGuidelines?.some((element) => {
      console.log(
        "useIsGuidelineUnlocked:",
        element.USER_ID.toLowerCase(),
        user?.ID.toLowerCase()
      );
      return (
        element.GUIDELINE_UUID === GUIDELINE_UUID &&
        element.USER_ID.toLowerCase() === user?.ID.toLowerCase()
      );
    })
  ) {
    DEBUGISON && console.log("isUnlocked=true");
    return true;
  }

  if (
    MainUserGuidelines?.some((element) => {
      console.log(
        "useIsGuidelineUnlocked:",
        element.USER_ID.toLowerCase(),
        user?.MAIN_USER_ID.toLowerCase()
      );
      return (
        element.GUIDELINE_UUID === GUIDELINE_UUID &&
        element.USER_ID.toLowerCase() === user?.MAIN_USER_ID.toLowerCase()
      );
    })
  ) {
    DEBUGISON && console.log("isUnlocked=true");
    return true;
  }

  DEBUGISON && console.log("isUnlocked=false");
  return false;
}

export function useForwardIfNotUnlocked(
  GUIDELINE_ID: string,
  navigation: StackNavigationProp<GuidelineRoutes, "FullText">
) {
  !useIsGuidelineUnlocked(GUIDELINE_ID) &&
    navigation.navigate("Guideline", {
      screen: "WantToUnlock",
      params: { guidelineId: GUIDELINE_ID },
    });
}
