import React, { ReactChild, ReactNode } from "react";
import { View, TouchableOpacity, ViewStyle } from "react-native";
import { Hoverable as RNWebHoverable } from "react-native-web-hooks";

interface HoverableProps {
  children: ReactNode;
  hoverStyle?: ViewStyle;
}

const Hoverable = ({ children, hoverStyle }: HoverableProps) => {
  return (
    <RNWebHoverable>
      {(isHovered) => {
        const style = [
          // @ts-ignore
          children?.props.style,
          isHovered && (hoverStyle || { opacity: 0.5 }),
        ];
        return (
          // @ts-ignore
          React.cloneElement(children, { style })
        );
      }}
    </RNWebHoverable>
  );
};

export default Hoverable;
