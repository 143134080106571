import {
  guidelinesState,
  GuidelineActionTypes,
  ADD_LOCAL_GUIDELINES,
  RESTORE_LOCAL_GUIDELINE,
  ADD_LOADED_GUIDELINES,
  RESET_LOADED_GUIDELINES,
} from "./types";

const initialState: guidelinesState = {
  localGuidelines: [],
  loadedGuidelines: [],
  isRestoring: false,
};

export default (state = initialState, action: GuidelineActionTypes) => {
  switch (action.type) {
    case ADD_LOCAL_GUIDELINES: {
      return {
        ...state,
        localGuidelines: [
          // update local guideline if exists (e.g. if HASH_KEY has changed)
          ...state.localGuidelines.filter(
            (i) => i.GUIDELINE_UUID !== action.payload.GUIDELINE_UUID
          ),
          action.payload,
        ],
      };
    }
    case RESTORE_LOCAL_GUIDELINE: {
      return {
        ...state,
        isRestoring: action.payload,
      };
    }
    case ADD_LOADED_GUIDELINES: {
      return {
        ...state,
        loadedGuidelines: [...state.loadedGuidelines, action.payload],
      };
    }
    case RESET_LOADED_GUIDELINES: {
      return {
        ...state,
        loadedGuidelines: [],
      };
    }
    default: {
      return state;
    }
  }
};
