import React, { useEffect, useState } from "react";
import { Platform } from "react-native";
import {
  useAuth,
  useBookmarkCreate,
  useAllAbout,
  useAllChapters,
  useChapter,
  useAllPathways
} from "../../hooks";
import { BookmarkCard, BookmarkList } from "../Bookmark";
import { Box } from "../Theme";
import PopupFooter from "./PopupFooter";
import moment from "moment";
import {
  pathToChapter,
  randomString,
  translateScreenToMode
} from "../../utils";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigationState } from "@react-navigation/native";
import { BookmarkType, ModeType } from "../../types";
import { useDispatch } from "react-redux";
import { togglePopup } from "../../redux/global";
import { addLocalBookmark } from "../../redux/user/actions";
import { PopupButton } from ".";
import { useSelector } from "../../redux";
import t from "../../../Texts/TextImport";
import { DEBUGISON, ORIGINDEBUG } from "../../config";

interface PopupBookmarkProps {
  chapterId: string;
  guidelineId: string;
  mode: ModeType;
}

const PopupBookmark = ({
  chapterId,
  guidelineId,
  mode
}: PopupBookmarkProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data: chapterData } = useChapter(chapterId, guidelineId);
  const allChapter = useAllChapters(guidelineId);
  const pathways = useAllPathways(guidelineId);
  const { username } = useAuth();

  const special = !chapterData; //&& pathways.data?.find((i) => i.UUID === chapterId)?.UUID;
  const breadcrumb =
    allChapter.data && pathToChapter(allChapter.data, chapterId);

  const bookmark: BookmarkType = {
    ID: randomString(),
    USER_ID: username,
    CREATION_DATE: moment().format("YYYY-MM-DD"),
    CREATION_TIME: moment().format(),
    PLATFORM: Platform.OS,
    BREADCRUMB: breadcrumb || "",
    GUIDELINE_UUID: guidelineId,
    CONTENT_UUID: chapterId,
    MODE: mode,
    CONTENT: chapterData || pathways?.data?.find((i) => i.UUID === chapterId),
    CONTENT_EN: chapterData || pathways?.data?.find((i) => i.UUID === chapterId)
  };

  const { mutate } = useBookmarkCreate({
    onSuccess: () => {
      queryClient.refetchQueries(["user", username]);
    },
    onError: () => {
      dispatch(addLocalBookmark(bookmark));
    }
  });

  return (
    <>
      <Box margin="m" marginTop="s" minHeight={100}>
        <BookmarkCard {...{ bookmark }} withButton={false} isDisabled={true} />
      </Box>
      <PopupFooter
        singleButton={false}
        onSuccess={() => {
          mutate({ ...bookmark });
          dispatch(togglePopup({ isVisible: false }));
        }}
        successText={t("GenSave")}
      />
    </>
  );
};

interface PopupBookmarkListProps {
  orderBy?: keyof BookmarkType;
}

const PopupBookmarkList = () => {
  DEBUGISON && ORIGINDEBUG && console.log("PopupBookmark:PopupBookmarkList: ");
  const [orderBy, setOrderBy] = useState<keyof BookmarkType>("CREATION_DATE");
  const dispatch = useDispatch();
  const reduxPopup = useSelector((state) => state.global.popup);
  const navigationStateRoutes = useNavigationState((state) => state.routes);
  const currentRoute = navigationStateRoutes[navigationStateRoutes.length - 1];
  // @ts-expect-error
  const id = currentRoute.params?.id;
  // @ts-expect-error
  const guidelineId = currentRoute.params?.guidelineId;

  const onCreateBookmark = () => {
    DEBUGISON && ORIGINDEBUG && console.log("PopupBookmark:onCreateBookmark: ");
    dispatch(
      togglePopup({
        isVisible: true,
        title: t("Bookmarknew"),
        content: (
          <PopupBookmark
            mode={translateScreenToMode(currentRoute.name)}
            chapterId={id}
            guidelineId={guidelineId}
          />
        )
      })
    );
  };

  useEffect(() => {
    dispatch(
      togglePopup({
        ...reduxPopup,
        header: (
          <PopupButton
            iconName="shuffle"
            text={orderBy === "CREATION_DATE" ? t("GenDate") : t("GenGui")}
            onPress={() =>
              setOrderBy(
                orderBy === "CREATION_DATE" ? "GUIDELINE_UUID" : "CREATION_DATE"
              )
            }
          />
        )
      })
    );
  }, [orderBy]);

  const enabled =
    // "StepByStep" removed
    ["FullText", "Recommendations", "Information", "StepByStep"].includes(
      currentRoute.name
    ) && id
      ? true
      : false;

  return (
    <>
      <Box margin="m" marginTop="s" minHeight={100}>
        <Box paddingBottom="m" flexDirection="row" opacity={enabled ? 1 : 0.5}>
          <PopupButton
            iconName="plus"
            text={t("Bookmarknew")}
            onPress={onCreateBookmark}
            disabled={!enabled}
          />
        </Box>
        <BookmarkList orderBy={orderBy} order="desc" />
      </Box>
      <PopupFooter />
    </>
  );
};

export default PopupBookmark;
export { PopupBookmarkList };
