import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { ModeType } from "../../types";
import FloatingButton from "./FloatingButton";

interface FloatingButtonGroupContextProps {
  currentIndex: number;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
  mode: ModeType;
}

const FloatingButtonGroupContext = createContext<FloatingButtonGroupContextProps>(
  {
    currentIndex: -1,
    setCurrentIndex: () => undefined,
    mode: "default",
  }
);

export const useFloatingButtonGroupContext = () => {
  const context = useContext(FloatingButtonGroupContext);
  if (!context) {
    throw new Error(
      "FloatingButtonGroup Compount Components should be rendered inside FloatingButtonGroup"
    );
  }
  return context;
};

interface FloatingButtonGroupProps {
  buttonGroupWidth: number;
  children: ReactNode;
  mode: ModeType;
}

const FloatingButtonGroup = ({
  buttonGroupWidth,
  children,
  mode,
}: FloatingButtonGroupProps) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const value = { currentIndex, setCurrentIndex, mode };
  return (
    <FloatingButtonGroupContext.Provider value={value}>
      {children}
    </FloatingButtonGroupContext.Provider>
  );
};

FloatingButtonGroup.Button = FloatingButton;

export default FloatingButtonGroup;
