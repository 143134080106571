import { BoxProps } from "@shopify/restyle";
import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { View } from "react-native";
import { BorderlessButton, RectButton } from "react-native-gesture-handler";
import { Box, Text, Theme, useTheme } from "./Theme";

interface StylesProps {
  backgroundColor?: keyof Theme["colors"];
  color?: keyof Theme["colors"];
  borderColor?: keyof Theme["colors"];
  activeBackgroundColor?: keyof Theme["colors"];
  activeColor?: keyof Theme["colors"];
  activeBorderColor?: keyof Theme["colors"];
}

interface ButtonGroupContextProps {
  selectedIndexes: number[];
  onPress: (index: number) => void;
  styles: StylesProps;
}
const ButtonGroupContext = createContext<ButtonGroupContextProps>({
  selectedIndexes: [],
  onPress: () => null,
  styles: {},
});

const useButtonGroupContext = () => {
  const context = useContext(ButtonGroupContext);
  if (!context) {
    throw new Error(
      `ButtonGroup compound components cannot be rendered outside the ButtonGroup component`
    );
  }
  return context;
};

interface ButtonGroupProps extends StylesProps {
  children: ReactNode;
  onStateChange?: (selectedIndexes: number[]) => void;
  multiSelect: boolean;
  disableEmpty: boolean;
  defaultState?: number[];
}

const ButtonGroup = ({
  children,
  onStateChange,
  multiSelect,
  defaultState,
  disableEmpty,
  ...styles
}: ButtonGroupProps) => {
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>(
    defaultState || []
  );
  const onPress = (index: number) => {
    if (multiSelect) {
      selectedIndexes.includes(index)
        ? setSelectedIndexes([...selectedIndexes.filter((i) => i !== index)])
        : setSelectedIndexes([...selectedIndexes, index]);
    } else {
      selectedIndexes.includes(index)
        ? disableEmpty
          ? null
          : setSelectedIndexes([])
        : setSelectedIndexes([index]);
    }
  };
  useEffect(() => {
    onStateChange && onStateChange(selectedIndexes);
  }, [selectedIndexes]);
  const value = { selectedIndexes, onPress, styles: { ...styles } };
  return (
    <ButtonGroupContext.Provider {...{ value }}>
      <Box flexDirection="row" paddingVertical="m" flexWrap="wrap">
        {children}
      </Box>
    </ButtonGroupContext.Provider>
  );
};

interface ButtonProps extends BoxProps<Theme> {
  children: string;
  index: number;
  onPress?: () => void;
}

const Button = ({
  children,
  index,
  onPress: onDefaultPress,
  ...props
}: ButtonProps) => {
  const {
    selectedIndexes,
    onPress,
    styles: {
      activeBackgroundColor,
      activeColor,
      activeBorderColor,
      backgroundColor,
      color,
      borderColor,
    },
  } = useButtonGroupContext();
  const theme = useTheme();
  const isSelected = selectedIndexes.includes(index);
  const onButtonPress = () => {
    onPress(index);
    onDefaultPress && onDefaultPress();
  };
  return (
    <Box
      marginRight="s"
      marginBottom="s"
      backgroundColor={isSelected ? activeBackgroundColor : backgroundColor}
      borderWidth={1}
      borderRadius={theme.spacing.xs}
      borderColor={isSelected ? activeBorderColor : borderColor}
      {...props}
    >
      <RectButton style={{ alignItems: "center" }} onPress={onButtonPress}>
        <Text
          variant="button"
          fontSize={16}
          color={isSelected ? activeColor : color}
          padding="xs"
        >
          {children}
        </Text>
      </RectButton>
    </Box>
  );
};

ButtonGroup.Button = Button;

ButtonGroup.defaultProps = {
  backgroundColor: "mainBackground",
  color: "mainForeground",
  borderColor: "mainForeground",
  activeBackgroundColor: "primary",
  activeColor: "mainBackground",
  activeBorderColor: "primary",
  multiSelect: false,
  disableEmpty: false,
};

export default ButtonGroup;
