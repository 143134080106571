import React, { useRef, useState } from "react";
import { Box, useTheme, Text } from "../../../components";
import {
  BaseButton,
  BorderlessButton,
  TouchableOpacity,
} from "react-native-gesture-handler";
import { useHover } from "react-native-web-hooks";
import { Pressable } from "react-native";

interface ButtonProps {
  icon: string;
  label: string;
  onPress: () => void;
  isActive: boolean;
  isDisabled: boolean;
}

const Button = ({
  icon,
  label,
  onPress,
  isActive,
  isDisabled,
}: ButtonProps) => {
  const ref = useRef();
  const theme = useTheme();
  const isHovered = useHover(ref);
  const [isPressed, setIsPressed] = useState(false);

  const onHandlerStateChange = () => setIsPressed((prev) => !prev);
  const isStateActive = !isDisabled && (isHovered || isPressed);

  const color = isStateActive || isActive ? "mainBackground" : "mainForeground";

  const backgroundColor =
    isStateActive || isActive ? "secondary" : "mainBackground";

  return (
    <BaseButton
      {...{ onPress }}
      enabled={!isDisabled}
      onActiveStateChange={onHandlerStateChange}
      style={{
        marginBottom: theme.spacing.m,
        marginRight: theme.spacing.m,
      }}
    >
      <Box
        borderRadius={theme.spacing.xs}
        borderWidth={1}
        borderColor="secondary"
        opacity={!isDisabled ? 1 : 0.5}
        width={55}
        height={55}
        justifyContent="center"
        alignItems="center"
        {...{ backgroundColor, ref }}
      >
        <Text variant="symbols" fontSize={30} {...{ color }}>
          {String.fromCharCode(parseInt(icon, 16))}
        </Text>
        <Text variant="tiny" fontSize={10} paddingBottom="xs" {...{ color }}>
          {label}
        </Text>
      </Box>
    </BaseButton>
  );
};

export default Button;
