import React from "react";
import { Box } from "../../../components";

interface DotProps {
  idx?: number;
  size: number;
}
const Dot = ({ idx, size }: DotProps) => {
  return (
    <>
      {idx === 0 ? (
        <Box
          key={idx}
          height={4}
          width={size}
          backgroundColor="mainForeground"
        />
      ) : (
        <Box
          key={idx}
          height={size}
          width={size}
          backgroundColor="mainForeground"
          borderRadius={size / 2}
        />
      )}
    </>
  );
};

export default Dot;
