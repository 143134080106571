import React from "react";
import { Token, tokenContent, InsetObjType, InsetsRenderProps } from "./types";
import { convertToJsonObject } from "./utils";
import { Text } from "../Theme";
import { generateUniqueId } from "../../utils/helpers";

const extractInsets = (text: string): Token[] => {
  const pattern = /(!?)\[(.*?)\]\((.*?)\)/g;
  let match;
  const parts: Token[] = [];
  let lastIndex = 0;

  // eslint-disable-next-line no-cond-assign
  while ((match = pattern.exec(text)) !== null) {
    // Text before the link or imsjge
    if (match.index > lastIndex) {
      parts.push({
        type: "Text",
        content: text.substring(lastIndex, match.index),
      });
    }

    // Markdown link
    parts.push({
      type: "Inset",
      content: `${match[2]}{${match[3].replace(/\{|\}/g, "")}}`,
    });

    lastIndex = pattern.lastIndex;
  }

  // Text after the last link or image
  if (lastIndex < text?.length) {
    parts.push({ type: "Text", content: text.substring(lastIndex) });
  }

  return parts;
};

const extractBold = (tokens: Token[]): Token[] => {
  const result: Token[] = [];

  tokens.forEach((token) => {
    if (token.type === "Text") {
      // Split the text into bold and normal parts
      const boldParts = token.content.split(/\*\*(.*?)\*\*/g);

      // Add the parts to the result
      boldParts.forEach((part, index) => {
        if (index % 2 === 0) {
          // Even indices are normal text
          result.push({ type: "Text", content: part });
        } else {
          // Odd indices are bold parts
          result.push({ type: "Bold", content: part });
        }
      });
    } else {
      // For non-Text types, just push the token as is
      result.push(token);
    }
  });

  return result;
};

const RenderInset: React.FC<tokenContent> = ({ content }: tokenContent) => {
  function convertInsetStringToObject(inputString: string): InsetObjType {
    const FirstPart = inputString.split("{")[0];
    const ParamPart = `{${inputString.split("{")[1]}`;

    const Type = FirstPart.split(":")[1] ? FirstPart.split(":")[0] : "Link";
    const InsetText = FirstPart.split(":")[1]
      ? FirstPart.split(":")[1]
      : FirstPart.split(":")[0];

    const params = convertToJsonObject(ParamPart);

    const result: InsetObjType = {
      text: InsetText,
      insetType: Type,
      params,
    };

    return result;
  }

  const InsetObj = convertInsetStringToObject(content);

  return <Text variant="body">Invalid Inset: {JSON.stringify(InsetObj)}</Text>;
};

const RenderTextWithInsets: React.FC<InsetsRenderProps> = ({
  markdown,
  Link,
  Bold,
  Red,
  Standard,
}: InsetsRenderProps) => {
  let renderList = extractInsets(markdown);
  renderList = extractBold(renderList);

  return (
    <Text>
      {renderList.map((element, key) => {
        if (element.type === "Text") {
          return (
            <Standard key={generateUniqueId()}>
              {element.content.toString()}
            </Standard>
          );
        }

        if (element.type === "Bold") {
          return (
            <Bold key={generateUniqueId()}>{element.content.toString()}</Bold>
          );
        }

        if (element.type === "Inset") {
          return (
            <RenderInset key={generateUniqueId()} content={element.content} />
          );
        }
        return "";
      })}
    </Text>
  );
};

const DefaultRenderer: React.FC<InsetsRenderProps> = ({
  markdown,
  Link,
  Bold,
  Red,
  Standard,
}: InsetsRenderProps) => {
  return (
    <RenderTextWithInsets
      markdown={markdown}
      key={generateUniqueId()}
      Link={Link}
      Bold={Bold}
      Red={Red}
      Standard={Standard}
    />
  );
};

export default DefaultRenderer;
