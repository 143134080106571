import React, { useCallback, useState } from "react";
import { Clipboard } from "react-native";
import { useDispatch } from "react-redux";
import Constants from "expo-constants";
import {
  Box,
  Icon,
  Text,
  BorderlessTap,
  SwitchBar,
  SubmitInput,
} from "../../../components";
import { useToast } from "../../../components/Toast";
import { useAuth, useUserMerge } from "../../../hooks";
import { useSelector } from "../../../redux";
import { toggleAutoUpdate } from "../../../redux/global";
import { textData } from "./config";
import t from "../../../../Texts/TextImport";
import { APPBRANCH, DEBUGISON } from "../../../config";
import { useQueryClient } from "@tanstack/react-query";

export const Identifier = () => {
  const { username } = useAuth();
  const { show } = useToast();

  const onClipboard = useCallback(() => {
    Clipboard.setString(username);
    show("In Zwischenablage gespeichert");
  }, [username]);
  return (
    <Box>
      <Text>{t("CId")}</Text>
      <Text fontFamily="bliss-pro-medium" paddingVertical="xs">
        {username}
      </Text>
      <Text paddingBottom="m">{textData.id}</Text>
      <Box alignItems="center">
        <BorderlessTap onPress={onClipboard}>
          <Box flexDirection="row" padding="s" alignItems="center">
            <Icon name="copy" color="mainForeground" />
            <Text paddingLeft="s" variant="subtitle1">
              {t("Clipboard")}
            </Text>
          </Box>
        </BorderlessTap>
      </Box>
    </Box>
  );
};

export const Synchronisation = () => {
  const [text, setText] = useState("");
  const { username } = useAuth();
  const { show } = useToast();

  const { mutate } = useUserMerge({
    onSuccess: () => {
      show(t("SetContSynch"));
      setText("");
    },
    onError: () => {
      show(t("SetContId"));
    },
  });
  const onMerge = useCallback(() => {
    mutate({
      ID: text,
      MAIN_USER_ID: username,
    });
  }, [username, text]);
  return (
    <Box>
      <Text paddingBottom="m">{textData.sync}</Text>
      <SubmitInput
        label={t("COConnect")}
        onSubmit={onMerge}
        value={text}
        onChangeText={setText}
        returnKeySubmit={true}
        placeholder={t("SNumber")}
      />
    </Box>
  );
};

export const LogOut = () => {
  const { signOut } = useAuth();

  return (
    <Box>
      <Text marginBottom="m">{textData.logout}</Text>
      <Box alignItems="center">
        <BorderlessTap onPress={() => signOut()}>
          <Box flexDirection="row" padding="s" alignItems="center">
            <Icon name="circledMark" color="success" />
            <Text paddingLeft="s" variant="subtitle2">
              {t("COut")}
            </Text>
          </Box>
        </BorderlessTap>
      </Box>
    </Box>
  );
};

export const Updates = () => {
  const dispatch = useDispatch();
  const autoUpdate = useSelector((state) => state.global.autoUpdate);
  return (
    <Box>
      <Text paddingBottom="m">{textData.update}</Text>
      <SwitchBar
        title="Automatische Updates"
        value={autoUpdate}
        onValueChange={() => dispatch(toggleAutoUpdate())}
      />
    </Box>
  );
};

export const Version = () => {
  return (
    <Box>
      <Text>
        {t("SetConfAppVers")}: {APPBRANCH} {Constants?.expoConfig?.version}
      </Text>
    </Box>
  );
};

export const ClearCache = () => {
  const client = useQueryClient();
  client.invalidateQueries();
  client.invalidateQueries({ queryKey: ["guideline"] });
  client.invalidateQueries({ queryKey: ["guidelines"] });
  client.invalidateQueries({ queryKey: ["newestGuideline"] });
  client.removeQueries();
  return (
    <Box>
      <Text>
        {t("Success")}

        <Icon name="circledMark" color="success" />
      </Text>
    </Box>
  );
};
