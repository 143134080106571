import React from "react";
import { Platform } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import { Authentification } from "../screens/Authentification";
import { AuthRoutes } from "../components/Navigation";
import NewAuthentification from "../screens/Authentification/newAuthentification";

const Stack = createStackNavigator<AuthRoutes>();

const AuthStack = () => {
	const isWeb = Platform.OS === "web";

	return (
		<Stack.Navigator
			screenOptions={{
				cardStyle: {
					height: isWeb ? "100vh" : "100%",
				},
			}}
			headerMode="none"
		>
			<Stack.Screen name="Authentification" component={NewAuthentification} />
		</Stack.Navigator>
	);
};

export default AuthStack;
