import React, { useEffect, useState } from "react";
import { useLinkBuilder, CommonActions } from "@react-navigation/native";
import { Box, Text, useTheme, LinkBox, Hoverable } from "../../components";
import { ChapterType, ModeType, PathwayType } from "../../types";
import { RectButton } from "react-native-gesture-handler";
import { GuidelineRoutes } from "../../components/Navigation";
import { translateScreenToMode } from "../../utils";
import { DEBUGISON, ORIGINDEBUG } from "../../config";
import { LANGUAGE } from "../../hooks/queries/config";
import { TouchableOpacity } from "react-native";
import { generateUniqueId } from "../../utils/helpers";
import DevText from "../../utils/DevText";
import { useChapter } from "../../hooks";
import { getPathwayName } from "../../hooks/useLanguage";

interface ChapterTreeProps {
  chapters: ChapterType[];
  activeChapter: string;
  level: number;
  screen: keyof GuidelineRoutes;
  activePath?: any[];
}

const ChapterTree = ({
  chapters,
  level,
  activeChapter,
  screen,
  activePath,
}: ChapterTreeProps) => {
  const [selectedId, setSelectedId] = useState<string>();
  const theme = useTheme();
  const buildLink = useLinkBuilder();
  const currentMode: ModeType = translateScreenToMode(screen);
  const isRecommendation = screen === "Recommendations";

  useEffect(() => {
    if (activePath && activePath.length > 0) {
      const index = activePath[2 * level];
      if (chapters.length >= index && chapters[index]) {
        setSelectedId(chapters[index].UUID);
      }
    }
  }, [activeChapter, activePath]);

  return (
    <Box
      paddingLeft={level === 0 ? "none" : "l"}
      paddingBottom={level === 1 ? "s" : "none"}
    >
      {chapters.map(
        (
          {
            UUID,
            TITLE,
            CHILDREN,
            GUIDELINE_UUID,
            PATHWAYS,
            TITLE_EN,
            RECOMMENDATIONS,
          },
          index
        ) => {
          if (
            (isRecommendation && RECOMMENDATIONS.length === 0) ||
            (!isRecommendation && CHILDREN && CHILDREN?.length > 0) ||
            (!isRecommendation && PATHWAYS && PATHWAYS.length > 0)
          ) {
            return (
              <React.Fragment key={UUID}>
                <TouchableOpacity
                  onPress={() => setSelectedId(selectedId === UUID ? "" : UUID)}
                  underlayColor={theme.colors.information}
                >
                  <Hoverable
                    hoverStyle={{
                      backgroundColor: theme.colors.hoverBackground,
                    }}
                  >
                    <Box
                      key={UUID + generateUniqueId()}
                      padding="s"
                      borderLeftWidth={6}
                      borderLeftColor={currentMode}
                      borderBottomWidth={1}
                      borderBottomColor="smoke"
                      nativeID={
                        "DEBUGINFO PathwayTree: \n" +
                        "UUID: " +
                        UUID +
                        "\nGUIDELINE_UUID: " +
                        GUIDELINE_UUID +
                        "\nTitle: " +
                        TITLE +
                        "\nTitle_EN: " +
                        TITLE_EN
                      }
                    >
                      <Text variant="body" fontFamily="bliss-pro-light-italic">
                        {LANGUAGE === "EN" ? TITLE_EN : TITLE}
                      </Text>
                    </Box>
                  </Hoverable>
                </TouchableOpacity>
                {selectedId === UUID &&
                  ((screen === "StepByStep" && PATHWAYS) || CHILDREN) && (
                    <>
                      {CHILDREN && (
                        <ChapterTree
                          key={UUID + generateUniqueId()}
                          chapters={CHILDREN || []}
                          activeChapter={activeChapter}
                          level={level + 1}
                          screen={screen}
                          activePath={activePath}
                        />
                      )}
                      {screen === "StepByStep" && PATHWAYS && (
                        <Box
                          key={UUID + generateUniqueId()}
                          paddingLeft="l"
                          nativeID={
                            "DEBUGINFO PathwayTree: \n" +
                            "UUID: " +
                            UUID +
                            "\nGUIDELINE_UUID: " +
                            GUIDELINE_UUID +
                            "\nTitle: " +
                            TITLE +
                            "\nTitleEN" +
                            TITLE_EN
                          }
                        >
                          <PathwayChapters
                            pathways={PATHWAYS}
                            activeChapter={activeChapter}
                          />
                        </Box>
                      )}
                    </>
                  )}
              </React.Fragment>
            );
          }
          return (
            <Box
              key={UUID + generateUniqueId()}
              marginBottom="s"
              nativeID={
                "DEBUGINFO PathwayTree: \n" +
                "UUID: " +
                UUID +
                "\nGUIDELINE_UUID: " +
                GUIDELINE_UUID +
                "\nTitle: " +
                TITLE +
                "\nTitleEN: " +
                TITLE_EN
              }
            >
              <LinkBox
                to={
                  buildLink(
                    `/Guideline/${screen}?id=${UUID}&guidelineId=${GUIDELINE_UUID}`
                  ) || "404"
                }
                action={CommonActions.navigate(screen, {
                  id: UUID,
                  guidelineId: GUIDELINE_UUID,
                })}
              >
                <DevText>
                  Title: {TITLE} TITLE_EN: {TITLE_EN}
                </DevText>
                <Chapter
                  mode={currentMode}
                  isActive={activeChapter === UUID}
                  title={LANGUAGE === "EN" ? TITLE_EN : TITLE}
                />

                <DevText conditions={ORIGINDEBUG}>ChapterTree</DevText>
              </LinkBox>
            </Box>
          );
        }
      )}
    </Box>
  );
};

ChapterTree.defaultProps = {
  level: 0,
};

export default ChapterTree;

interface ChapterProps {
  mode: ModeType;
  isActive?: boolean;
  title: string;
}

export const Chapter = ({ mode, isActive, title }: ChapterProps) => {
  return (
    <Box
      padding="s"
      borderLeftWidth={6}
      borderLeftColor={mode}
      borderBottomWidth={1}
      borderBottomColor="smoke"
      key={generateUniqueId()}
    >
      <Text
        variant="body"
        color={isActive ? mode : "mainForeground"}
        fontFamily="bliss-pro-light-italic"
      >
        {title}
      </Text>
    </Box>
  );
};

interface PathwayChaptersProps {
  pathways: PathwayType[];
  activeChapter: string;
}

const PathwayChapters = ({ pathways, activeChapter }: PathwayChaptersProps) => {
  const buildLink = useLinkBuilder();
  return (
    <>
      {pathways.map(({ UUID, GUIDELINE_UUID, ...rest }) => (
        <Box
          marginBottom="s"
          key={UUID + generateUniqueId()}
          nativeID={
            "DEBUGINFO ChapterTree:" +
            "\nUUID" +
            UUID +
            "\nguidelineId" +
            GUIDELINE_UUID +
            "\nPathway name" +
            getPathwayName(rest)
          }
        >
          <LinkBox
            to={
              buildLink(
                `/Guideline/StepByStep?id=${UUID}&guidelineId=${GUIDELINE_UUID}`
              ) || "404"
            }
            action={CommonActions.navigate("StepByStep", {
              id: UUID,
              guidelineId: GUIDELINE_UUID,
            })}
          >
            <Chapter
              mode={"stepbystep"}
              isActive={activeChapter === UUID}
              title={getPathwayName(rest)}
            />
            <DevText conditions={ORIGINDEBUG}>
              Pathway name: {getPathwayName(rest)}
            </DevText>
          </LinkBox>
        </Box>
      ))}
    </>
  );
};
