import React, { useEffect, useMemo, useState } from "react";
import { ScrollView } from "react-native";
import { DrawerContentComponentProps } from "@react-navigation/drawer";
import { BookmarkThumbnail, Box, Date, LinkBox, Text } from "../../components";
import {
  useAllAbout,
  useAuth,
  useBookmarks,
  useIsLargeScreen,
  useUser,
} from "../../hooks";
import ChapterTree, { Chapter } from "./ChapterTree";
import { AboutType, ChapterType, ModeType } from "../../types";
import {
  createAboutTree,
  createChapterTree,
  filterChapterTree,
  filterInfoTree,
  filterRecommendationsTree,
  translateScreen,
  compareValues,
  findPathById,
  translateScreenToMode,
  filterStepByStepTree,
  addPathwayToChapter,
} from "../../utils";
import { GeneralRoutes, GuidelineRoutes } from "../../components/Navigation";
import {
  CommonActions,
  StackActions,
  useLinkBuilder,
} from "@react-navigation/native";
import ModeTabs from "./ModeTabs";
import { InformationButton } from "./Chapters";
import { MobileHomeFooter } from "../../components/Footer";
import t from "../../../Texts/TextImport";
import { DEBUGISON, NAVDEBUGGING, ORIGINDEBUG } from "../../config";
import { LANGUAGE } from "../../hooks/queries/config";
import { getTitle } from "../../hooks/useLanguage";
import { useQueryClient } from "@tanstack/react-query";
import useChapter, {
  getAllChaptersById,
  useAllChapters,
} from "../../hooks/queries/useChapter";
import useGuideline, {
  getGuidelineById,
  useNewestGuideline,
} from "../../hooks/queries/useGuideline";
import { GuidelineType } from "../../types/guideline";
import usePathway, {
  getAllPathways,
  useAllPathways,
} from "../../hooks/queries/usePathway";
import { visualizeRecommendations } from "../../utils/chapter";
import DevText from "../../utils/DevText";
import { devLog } from "../../utils/logging";

type DrawerBodyProps = DrawerContentComponentProps;

// const DrawerBody = (props: DrawerBodyProps) => {
// 	return <DebuggingDrawer />;
// };
// const er = (props: DrawerBodyProps) => {
const DrawerBody = (props: DrawerBodyProps) => {
  let actualRoute: any = props.state.routes[props.state.index]; // TODO typescript
  DEBUGISON &&
    NAVDEBUGGING &&
    console.log("DrawerBody:actualRoute" + JSON.stringify(actualRoute));

  useEffect(() => {
    DEBUGISON &&
      NAVDEBUGGING &&
      console.log("DrawerBody:actualRoute.name" + actualRoute.name);
    DEBUGISON &&
      NAVDEBUGGING &&
      console.log(
        "actualRoute: " + JSON.stringify(actualRoute)?.substring(0, 100)
      );
  }, [actualRoute.name]);

  while (actualRoute?.state) {
    if (actualRoute.state.index) {
      actualRoute = actualRoute.state.routes[actualRoute.state.index];
    } else {
      actualRoute =
        actualRoute.state.routes[actualRoute.state.routes.length] ||
        actualRoute.state.routes[actualRoute.state.routes.length - 1];
    }
  }

  const guidelineId: string =
    actualRoute?.params?.guidelineId ||
    actualRoute?.params?.params?.guidelineId;
  const chapterId: string =
    actualRoute?.params?.id || actualRoute?.params?.params?.id;

  DEBUGISON &&
    NAVDEBUGGING &&
    console.log("DrawerBody:guidelineId: " + guidelineId);
  DEBUGISON && NAVDEBUGGING && console.log("chapterId: " + chapterId);

  const guidelineNames: (keyof GuidelineRoutes)[] = [
    "FullText",
    "Recommendations",
    "StepByStep",
    "Information",
    "Search",
    "ModeSelection",
  ];

  const homeNames = ["AppStack", "Areal", "List", "Home"];
  const authNames = ["Authentification"];

  const authScreen = authNames.find((n) => [actualRoute?.name].includes(n));

  const generalNames: (keyof GeneralRoutes)[] = ["Informations", "Settings"];

  const guidelineScreen = guidelineNames.find((n) =>
    [actualRoute?.name, actualRoute?.params?.screen].includes(n)
  );
  const homeScreen = homeNames.find((n) =>
    [actualRoute?.name, actualRoute?.params?.screen].includes(n)
  );
  devLog([actualRoute?.name, actualRoute?.params?.screen]);

  const generalScreen = generalNames.find((n) =>
    [actualRoute?.name, actualRoute?.params?.screen].includes(n)
  );

  const isLargeScreen = useIsLargeScreen();
  // return <DebuggingDrawer />;
  return (
    <Box flex={1}>
      {ORIGINDEBUG && DEBUGISON && <Text>DrawerBody</Text>}
      {!isLargeScreen && guidelineScreen && (
        <ModeTabs guidelineId={guidelineId} screen={guidelineScreen} />
      )}
      <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1 }}>
        <Box flex={1}>
          {guidelineScreen && (
            <>
              <DevText>GuidelineDrawer</DevText>
              <GuidelineDrawer
                screen={guidelineScreen}
                {...{ guidelineId, chapterId }}
              />
            </>
          )}
          {homeScreen && (
            <>
              <DevText>HomeDrawer</DevText>
              <HomeDrawer {...{}} />
            </>
          )}
          {generalScreen && (
            <>
              {ORIGINDEBUG && DEBUGISON && <Text>GeneralDrawer</Text>}

              <HomeDrawer {...{}} />
            </>
          )}
          {authScreen && (
            <>
              {ORIGINDEBUG && DEBUGISON && <Text>AuthDrawer</Text>}

              <AuthDrawer {...{}} />
            </>
          )}
        </Box>
        {!isLargeScreen &&
          guidelineScreen &&
          guidelineScreen !== "Information" &&
          guidelineScreen !== "ModeSelection" && (
            <>
              {ORIGINDEBUG && DEBUGISON && <Text>InformationButton</Text>}

              <InformationButton guidelineId={guidelineId} />
            </>
          )}
      </ScrollView>
      {!isLargeScreen && <MobileHomeFooter />}
    </Box>
  );
};

export default DrawerBody;

interface GuidelineDrawerProps {
  guidelineId: string;
  chapterId: string;
  screen: keyof GuidelineRoutes;
}
const GuidelineDrawer = ({
  guidelineId,
  chapterId,
  screen,
}: GuidelineDrawerProps) => {
  const queryClient = useQueryClient();
  const isLargeScreen = useIsLargeScreen();
  const allPathways = useAllPathways(guidelineId);
  const allChapters = useAllChapters(guidelineId);
  const guideline = useGuideline(guidelineId);
  useEffect(() => {
    DEBUGISON &&
      NAVDEBUGGING &&
      console.log(
        "chapter: " +
          chapterId +
          "\nscreen: " +
          screen +
          "\nguideline: " +
          guidelineId
      );
  }, [guidelineId, chapterId, screen]);

  if (allChapters.isLoading) {
    return;
  }

  DEBUGISON &&
    NAVDEBUGGING &&
    console.log(JSON.stringify(allChapters.data)?.substring(0, 100));
  const tree =
    screen === "FullText"
      ? filterChapterTree(createChapterTree(allChapters.data))
      : screen === "Recommendations"
      ? filterRecommendationsTree(createChapterTree(allChapters.data))
      : screen === "Information"
      ? filterInfoTree(createChapterTree(allChapters.data))
      : screen === "ModeSelection"
      ? filterInfoTree(createChapterTree(allChapters.data))
      : screen === "StepByStep" && allPathways.data
      ? filterStepByStepTree(
          createChapterTree(
            addPathwayToChapter(allChapters.data, allPathways.data)
          )
        )
      : [];
  screen === "Recommendations" && visualizeRecommendations(tree);
  const newPath = findPathById(chapterId, tree);
  DEBUGISON && NAVDEBUGGING && console.log("chapterId: " + chapterId);
  DEBUGISON && NAVDEBUGGING && console.log("guidelineId: " + guidelineId);
  DEBUGISON &&
    NAVDEBUGGING &&
    console.log("tree: " + JSON.stringify(tree)?.substring(0, 100));
  DEBUGISON && NAVDEBUGGING && console.log("path: " + newPath);
  const activePath = newPath || null;

  const color: ModeType = translateScreenToMode(screen);

  // useEffect(() => {
  //   queryClient.invalidateQueries(["allChapter", guidelineId]);
  // }, []);

  return (
    <Box paddingVertical="m">
      {ORIGINDEBUG && DEBUGISON && (
        <>
          <Text>GuidelineDrawer Screen: {screen}</Text>
          <Text>GuidelineID: {guidelineId}</Text>
        </>
      )}
      {guideline.data && tree && screen !== "ModeSelection" && (
        <>
          {isLargeScreen && (
            <Box marginHorizontal="m" paddingBottom="l">
              <Text variant="tiny">{t("DBGuideline")}</Text>
              <Text variant="description" fontFamily="bliss-pro-italic">
                {getTitle(guideline.data)}
              </Text>
            </Box>
          )}
          {screen !== "Search" && (
            <>
              <Box marginHorizontal="m" paddingVertical="m">
                <Text
                  color={color}
                  variant="tiny"
                  fontFamily="bliss-pro"
                  textTransform="uppercase"
                >
                  {`${translateScreen(screen) + t("DBSelection")}
                  `}
                </Text>
              </Box>
              <ChapterTree
                chapters={tree}
                activeChapter={chapterId}
                screen={screen}
                activePath={activePath}
              />
            </>
          )}
        </>
      )}
      {tree && screen === "ModeSelection" && (
        <Box paddingVertical="m">
          <ChapterTree
            chapters={tree}
            activeChapter={chapterId}
            screen={"Information"}
            activePath={activePath}
          />
        </Box>
      )}
    </Box>
  );
};

const HomeDrawer = () => {
  const { username } = useAuth();
  const { data: userData } = useUser(username);
  const { data: newsData } = useNewestGuideline();
  const bookmarks = useBookmarks();
  return (
    <Box margin="m">
      <DevText>HomeDrawer</DevText>
      <DevText obj={userData}> Userdata: </DevText>
      <DevText array={bookmarks}> bookmarks: </DevText>
      <DevText array={newsData}> newsData: </DevText>

      {userData && bookmarks && bookmarks.length > 0 && (
        <Box paddingBottom="m">
          <Text variant="description" fontFamily="bliss-pro" paddingBottom="s">
            {t("DBBookmarks")}
          </Text>
          {bookmarks
            .sort(compareValues("CREATION_DATE", "desc"))
            .slice(0, 3)
            .map((bookmark) => (
              <BookmarkThumbnail key={bookmark.ID} {...{ bookmark }} />
            ))}
        </Box>
      )}

      {userData && newsData && newsData.length > 0 && (
        <Box>
          <Text variant="description" fontFamily="bliss-pro" paddingBottom="s">
            {t("DBUpdated")}
          </Text>
          <Text variant="description" color="information">
            <Date
              variant="description"
              color="information"
              date={newsData[0].CHANGE_DATE}
            />
            {` | ${t("DBContents")}`}
          </Text>
          <Text variant="description">
            {LANGUAGE == "EN" ? newsData[0].TITLE_EN : newsData[0].TITLE}
          </Text>
        </Box>
      )}
    </Box>
  );
};

interface GeneralDrawerProps {
  screen: keyof GeneralRoutes;
  id: string;
}

const GeneralDrawer = ({ screen, id }: GeneralDrawerProps) => {
  const [tree, setTree] = useState<AboutType[]>();
  const { data: aboutData } = useAllAbout();
  const buildLink = useLinkBuilder();
  useEffect(() => {
    if (aboutData) {
      setTree(createAboutTree(aboutData));
    }
  }, [aboutData]);
  if (screen === "Informations") {
    return (
      <Box marginVertical="m">
        {ORIGINDEBUG && DEBUGISON && <Text>GeneralDrawer</Text>}
        <Text
          color="default"
          marginHorizontal="m"
          paddingVertical="m"
          variant="tiny"
          fontFamily="bliss-pro"
          textTransform="uppercase"
        >
          {t("MHFLeila")}
        </Text>
        {aboutData
          ?.sort(compareValues("ORDER"))
          .map(({ UUID, TITLE, TITLE_EN }) => (
            <Box key={UUID} marginBottom="s">
              <LinkBox
                to={buildLink(`/General/${screen}?id=${UUID}`) || "404"}
                action={StackActions.push("General", {
                  screen: "Informations",
                  params: { id: UUID },
                })}
              >
                <Chapter
                  mode="default"
                  title={LANGUAGE == "EN" ? TITLE_EN : TITLE}
                  isActive={id === UUID}
                />
              </LinkBox>
            </Box>
          ))}
      </Box>
    );
  }
  return <></>;
};

const AuthDrawer = () => {
  return (
    <Box paddingHorizontal="l">
      <Text fontWeight="bold" paddingVertical="m">
        {t("AuthYet")}
      </Text>
      <Text>
        <Text color="fulltext" fontWeight="bold">
          1.
        </Text>
        <Text fontWeight="bold">{t("AuthDownloadBold")}</Text>
        {t("AuthDownloadNormal")}
      </Text>
      <Text paddingTop="m">
        <Text color="fulltext" fontWeight="bold">
          2.
        </Text>
        <Text fontWeight="bold">{t("AuthWhereIdBold")}</Text>
        {t("AuthWhereIdNormal")}
      </Text>
      <Text paddingTop="m">
        <Text color="fulltext" fontWeight="bold">
          3.
        </Text>
        <Text fontWeight="bold">{t("AuthSignInBold")}</Text>
        {t("AuthSignInNormal")}
      </Text>
    </Box>
  );
};
