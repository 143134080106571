import React from "react";
import { Platform } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
import {
  AppNavigationProps,
  GuidelineRoutes,
} from "../../components/Navigation";
import Fulltext from "./Fulltext";
import Recommendations from "./Recommendations";
import StepByStep from "./StepByStep";
import Information from "./Information";
import Search from "./Search";
import { forFade, forHeaderFade } from "../../navigation";
import {
  Box,
  DeepLinkCopy,
  RoundedIconButton,
  useTheme,
} from "../../components";
import { useIsLargeScreen } from "../../hooks";
import {
  DrawerActions,
  StackActions,
  CommonActions,
} from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import HeaderTitle from "../../navigation/components/HeaderTitle";
import ModeSelection from "./ModeSelection";
import { useDispatch } from "react-redux";
import { resetHistory } from "../../redux/global";
import { APPBRANCH, DEBUGISON, LAYOUTDEBUG, NAVDEBUGGING } from "../../config";
import t from "../../../Texts/TextImport";
import CouldNotAccessCam from "../QRCode/CouldNotAccessCam";
import CouldNotBeRead from "../QRCode/CouldNotBeRead";
import ForOtherUser from "../QRCode/ForOtherUser";
import QRSuccess from "../QRCode/QRSuccess";
import ScanQR from "../QRCode/ScanQR";
import WantToUnlock from "../QRCode/WantToUnlock";
import ForOtherGuideline from "../QRCode/ForOtherGuideline";
import EnterQR from "../QRCode/EnterQR";

const GuidelineStack = createStackNavigator<GuidelineRoutes>();

const GuidelineNavigator = ({
  navigation,
  route,
}: AppNavigationProps<"Guideline">) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  const isWeb = Platform.OS === "web";
  const insets = useSafeAreaInsets();
  return (
    <GuidelineStack.Navigator
      screenOptions={{
        headerTitleAlign: "left",
        gestureEnabled: false,

        headerStyleInterpolator: forHeaderFade,
        headerTitleContainerStyle: {
          flexGrow: 1,
        },
        cardStyle: {
          backgroundColor: theme.colors.mainBackground,
          height: isWeb ? "100vh" : "100%",
        },
        headerLeft: () => null,
        headerRight: () => {
          return !isLargeScreen ? (
            <Box
              paddingHorizontal="s"
              justifyContent="center"
              flexDirection="row"
            >
              <RoundedIconButton
                size={36}
                iconRatio={0.7}
                name="hamburger"
                color="mainBackground"
                onPress={() =>
                  navigation.dispatch(DrawerActions.toggleDrawer())
                }
              />
            </Box>
          ) : null;
        },
        headerTitle: (props) => (
          <HeaderTitle
            {...props}
            color="mainBackground"
            onPress={() => {
              DEBUGISON &&
                NAVDEBUGGING &&
                console.log("GuidelineNavigator navigate to mode selection");
              console.log(route);
              dispatch(resetHistory());
              navigation.dispatch(
                CommonActions.navigate("Guideline", {
                  screen: "ModeSelection",
                  params: { guidelineId: route?.params?.params?.guidelineId },
                })
              );
            }}
          />
        ),
      }}
    >
      <GuidelineStack.Screen
        name="FullText"
        component={Fulltext}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : theme.colors.fulltext,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: t("GenFu"),
        }}
      />
      <GuidelineStack.Screen
        name="Recommendations"
        component={Recommendations}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : APPBRANCH == "ERKNET"
              ? theme.colors.informations
              : theme.colors.recommendations,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },

          title: t("GenRecom"),
        }}
      />
      <GuidelineStack.Screen
        name="Search"
        component={Search}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : theme.colors.search,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: t("GenSearch"),
        }}
      />
      <GuidelineStack.Screen
        name="StepByStep"
        component={StepByStep}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : theme.colors.stepbystep,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: t("GenSbs"),
        }}
      />
      <GuidelineStack.Screen
        name="Information"
        component={Information}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : theme.colors.information,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: t("GenNotes"),
        }}
      />
      <GuidelineStack.Screen
        name="ModeSelection"
        component={ModeSelection}
        options={{
          headerStyle: {
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: "Modusauswahl",
          headerRight: () => {
            return !isLargeScreen ? (
              <Box paddingHorizontal="s">
                {!isWeb && (
                  <RoundedIconButton
                    size={36}
                    iconRatio={0.7}
                    name="hamburger"
                    color="mainForeground"
                    onPress={() =>
                      navigation.dispatch(DrawerActions.toggleDrawer())
                    }
                  />
                )}
              </Box>
            ) : null;
          },
          headerTitle: (props) => (
            <HeaderTitle {...props} color="mainForeground" />
          ),
        }}
      />

      <GuidelineStack.Screen
        name="CouldNotAccessCam"
        component={CouldNotAccessCam}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : theme.colors.information,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: t("GenNotes"),
        }}
      />

      <GuidelineStack.Screen
        name="CouldNotBeRead"
        component={CouldNotBeRead}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : theme.colors.information,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: t("GenNotes"),
        }}
      />

      <GuidelineStack.Screen
        name="ForOtherUser"
        component={ForOtherUser}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : theme.colors.information,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: t("GenNotes"),
        }}
      />
      <GuidelineStack.Screen
        name="ForOtherGuideline"
        component={ForOtherGuideline}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : theme.colors.information,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: t("GenNotes"),
        }}
      />

      <GuidelineStack.Screen
        name="QRSuccess"
        component={QRSuccess}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : theme.colors.information,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: t("GenNotes"),
        }}
      />

      <GuidelineStack.Screen
        name="ScanQR"
        component={ScanQR}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : theme.colors.information,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: t("GenNotes"),
        }}
      />

      <GuidelineStack.Screen
        name="WantToUnlock"
        component={WantToUnlock}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : theme.colors.information,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: t("GenNotes"),
        }}
      />

      <GuidelineStack.Screen
        name="EnterQR"
        component={EnterQR}
        options={{
          headerStyle: {
            backgroundColor: isLargeScreen
              ? theme.colors.mainBackground
              : theme.colors.information,
            borderBottomWidth: 0,
            shadowColor: "transparent",
            height: 60 + insets.top,
          },
          title: t("GenNotes"),
        }}
      />
    </GuidelineStack.Navigator>
  );
};

export default GuidelineNavigator;
