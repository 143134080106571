import React, { ReactNode } from "react";

interface TheaterProps {
  children: ReactNode;
}
const Theater = ({ children }: TheaterProps) => {
  return <>{children}</>;
};

export default Theater;
