import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { GeneralRoutes } from "../../components/Navigation";
import Settings from "./Settings";
import Informations from "./Informations";
import { forFade } from "../../navigation";
import t from "../../../Texts/TextImport";

const GeneralStack = createStackNavigator<GeneralRoutes>();

const GeneralNavigator = () => {
  return (
    <GeneralStack.Navigator
      screenOptions={{
        gestureEnabled: false,
        headerLeft: () => null
        //cardStyleInterpolator: forFade,
      }}
    >
      <GeneralStack.Screen
        name="Settings"
        component={Settings}
        options={{ title: t("GenSett") }}
      />
      <GeneralStack.Screen
        name="Informations"
        component={Informations}
        options={{ title: t("ASI") }}
      />
    </GeneralStack.Navigator>
  );
};

export default GeneralNavigator;
