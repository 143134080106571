import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
  useLayoutEffect,
  useCallback,
  useRef,
} from "react";
import { LayoutChangeEvent, View } from "react-native";
import { BorderlessButton } from "react-native-gesture-handler";
import Animated from "react-native-reanimated";
import { Box } from "./Theme";
import { DEBUGISON, NAVDEBUGGING } from "../config";
import { devLog } from "../utils/logging";

const AnimatedBox = Animated.createAnimatedComponent(Box);

interface AccordionContextProps {
  activeEventKey: any;
  onChange: Dispatch<SetStateAction<any>>;
}

const AccordionContext = createContext<AccordionContextProps>({
  activeEventKey: null,
  onChange: () => null,
});

const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error(
      "Accordion components are compound component. Must be inside Accordion"
    );
  }
  return context;
};

const useAccordionClick = (
  eventKey: any,
  onClick: undefined | ((event: any) => void)
) => {
  const { activeEventKey, onChange } = useAccordionContext();
  console.log("accordioncontext was used");
  return (event: any) => {
    onChange(eventKey === activeEventKey ? null : eventKey);
    if (onClick) {
      onClick(event);
    }
  };
};

const useEventKey = (
  eventKey: any,
  onChange: undefined | ((event: any) => void)
) => {
  const [activeEventKey, setActiveEventKey] = useState(eventKey);
  useLayoutEffect(() => {
    setActiveEventKey(eventKey);
  }, [eventKey, onChange]);
  return [activeEventKey, setActiveEventKey];
};

interface AccordionProps {
  children: ReactNode;
  activeEventKey?: any;
  onChange?: Dispatch<SetStateAction<any>>;
}

const Accordion = ({ children, activeEventKey, onChange }: AccordionProps) => {
  const [eventKey, setEventKey] = useEventKey(activeEventKey, onChange);
  const handleChange = useCallback(
    (eventKey) => {
      if (activeEventKey !== undefined && onChange !== undefined) {
        onChange(eventKey);
        return;
      }
      setEventKey(eventKey);
    },
    [activeEventKey, onChange, setEventKey]
  );

  const value = useMemo(() => {
    return {
      activeEventKey: eventKey,
      onChange: handleChange,
    };
  }, [eventKey, handleChange]);
  return (
    <AccordionContext.Provider value={value}>
      {children}
    </AccordionContext.Provider>
  );
};

interface CollapsProps {
  eventKey: any;
  children: ReactNode;
}
const Collapse = ({ children, eventKey }: CollapsProps) => {
  const { activeEventKey } = useAccordionContext();
  // const animatedValue = useRef(new Animated.Value(0)).current;
  const onLayout = (event: LayoutChangeEvent) => {
    DEBUGISON && NAVDEBUGGING && console.log(event.nativeEvent.layout);
  };
  return (
    <AnimatedBox onLayout={onLayout}>
      {activeEventKey === eventKey ? children : null}
    </AnimatedBox>
  );
};

interface ToggleProps {
  children: ReactNode;
  eventKey: any;
  onClick?: undefined | ((event: any) => void);
}
const Toggle = ({ children, eventKey, onClick }: ToggleProps) => {
  const accordionClick = useAccordionClick(eventKey, onClick);
  return (
    <BorderlessButton onPress={accordionClick}>{children}</BorderlessButton>
  );
};

Accordion.Collapse = Collapse;
Accordion.Toggle = Toggle;

export default Accordion;
