import React from "react";
import QRWrapper from "./QRWrapper";
import t from "../../../Texts/TextImport";

import { Button, Text } from "../../components";
import { generateUniqueId } from "../../utils/helpers";
import { GuidelineNavigationProps } from "../../components/Navigation";

function ForOtherUser({
  navigation,
  route,
}: GuidelineNavigationProps<"FullText">) {
  return (
    <QRWrapper>
      <Text variant="body">{t("QROtherUser")}</Text>

      <Button
        variant="border"
        color={"primary"}
        mainColor={"primary"}
        label={t("GenClose")}
        key={generateUniqueId()}
        onPress={() => {}}
      />
    </QRWrapper>
  );
}

export default ForOtherUser;
