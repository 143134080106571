import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import axios from "axios";
import { ARS, MET, URL, SECTOR, LANGUAGE } from "./config";
import { ArsType } from "../../types";
import { writeToStorage } from "../../utils";
import { useEffect } from "react";

export const getArsByName = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: ARS,
    query: `ANTIBIOTIKUM="${context.queryKey[1]}"`,
    attributes:
      "ERREGER,R_PROZ,I_PROZ,S_PROZ,TOTAL_N,REGION,ZEITRAUM,VERSORGUNGSBEREICH,CREATION_DATE"
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export default function useArs(
  name: string,
  options?: QueryObserverOptions<ArsType[]>
) {
  return useQuery<ArsType[]>(["ars", name], getArsByName, {
    ...options,
    onSuccess: (data) => {
      options?.onSuccess && options.onSuccess(data);
      writeToStorage(["ars", name], data);
    }
  });
}
