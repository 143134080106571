import React from "react";
import { CommonActions, StackActions } from "@react-navigation/native";
import { Box, LinkBox, Text } from "../../../components";
import t from "../../../../Texts/TextImport";
import { DEBUGISON, ORIGINDEBUG } from "../../../config";

interface InformationButton {
  guidelineId: string;
}

const InformationButton = ({ guidelineId }: InformationButton) => {
  return (
    <Box paddingVertical='m'>
      <LinkBox
        to={`/Guideline/Information?guidelineId=${guidelineId}`}
        action={StackActions.push("Guideline", {
          screen: "Information",
          params: { guidelineId },
        })}
      >
        <Box
          padding='s'
          borderLeftWidth={6}
          borderLeftColor='information'
          borderBottomWidth={1}
          borderBottomColor='smoke'
        >
          <Text variant='body' fontFamily='bliss-pro-light-italic'>
            {t("IBInformation")}
            {ORIGINDEBUG && DEBUGISON && "InformationButton"}
          </Text>
        </Box>
      </LinkBox>
    </Box>
  );
};

export default InformationButton;
