import React from "react";
import t from "../../../../Texts/TextImport";
import { Box, Button, Text, useTheme } from "../../../components";
import { Linking } from "react-native";

const Footer = () => {
	const openExternalLink = async (url: string) => {
		try {
			await Linking.openURL(url);
		} catch (error) {
			console.error("Failed to open external link:", error);
		}
	};
	const theme = useTheme();
	const handlePrivacy = () => {
		openExternalLink("https://www.leila.de/de/datenschutz/");
	};

	const handleImprint = () => {
		openExternalLink("https://www.leila.de/de/impressum/");
	};
	return (
		<Box
			backgroundColor="mainForeground"
			paddingHorizontal="l"
			paddingVertical="m"
		>
			<Box
				width={800}
				flexDirection="row"
				justifyContent="flex-start"
				paddingLeft="m"
			>
				<Button
					onPress={() => {
						handleImprint();
					}}
					label={t("FImprint")}
					mainColor="settings"
					color="mainBackground"
					variant="default"
				></Button>
				<Button
					label={t("FPrivacy")}
					mainColor="settings"
					color="mainBackground"
					variant="default"
					onPress={() => {
						handlePrivacy();
					}}
				></Button>
			</Box>
		</Box>
	);
};

export default Footer;
