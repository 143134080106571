import React, { useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { Date } from ".";
import t from "../../Texts/TextImport";
import { ArsType } from "../types";
import ButtonGroup from "./ButtonGroup";
import { Box, palette, Text } from "./Theme";
import { DEBUGISON, ORIGINDEBUG } from "../config";

const CELL_WIDTH = 65;

interface ArsTableProps {
	data: ArsType[];
}

const ArsTable = ({ data }: ArsTableProps) => {
	// @ts-expect-error
	const allRegions = [...new Set(data.map((item) => item.REGION))];
	// @ts-expect-error
	const allFields = [...new Set(data.map((item) => item.VERSORGUNGSBEREICH))];
	const [region, setRegion] = useState([0]);
	const [field, setField] = useState([0]);

	return (
		<>
			{data && data.length > 0 ? (
				<Box>
					<Text>
						{t("ATStation")}

						{DEBUGISON && ORIGINDEBUG && "ArsTable"}
					</Text>
					<ButtonGroup
						onStateChange={(selectedIndexes) => setField(selectedIndexes)}
						defaultState={[0]}
					>
						{allFields.map((field, index) => (
							<ButtonGroup.Button key={index} index={index} minWidth={150}>
								{field}
							</ButtonGroup.Button>
						))}
					</ButtonGroup>
					<Divider />
					<Text>{t("ATRegion")}</Text>
					<ButtonGroup
						onStateChange={(selectedIndexes) => setRegion(selectedIndexes)}
						defaultState={[0]}
					>
						{allRegions.map((region, index) => (
							<ButtonGroup.Button key={index} index={index} minWidth={150}>
								{region}
							</ButtonGroup.Button>
						))}
					</ButtonGroup>
					<ScrollView
						contentContainerStyle={{ flexDirection: "column" }}
						style={{ flex: 1 }}
						horizontal
					>
						<TableHeader />
						{data
							.filter(
								(i) =>
									i.REGION === allRegions[region[0]] &&
									i.VERSORGUNGSBEREICH === allFields[field[0]]
							)
							.map((i) => (
								<TableRow key={i.ERREGER} pathogen={i} />
							))}
					</ScrollView>
					<TableFooter
						periode={data[0].ZEITRAUM}
						date={data[0].CREATION_DATE}
					/>
				</Box>
			) : null}
		</>
	);
};

const TableHeader = () => (
	<Box style={{ flexDirection: "row" }}>
		<Box width={CELL_WIDTH * 3} style={styles.cell}>
			<Text fontFamily="bliss-pro-medium">{t("ATPathogen")}</Text>
		</Box>
		<TableHeaderDouble title={"R"} backgroundColor="tableBackground" />
		<TableHeaderDouble title={"I"} />
		<TableHeaderDouble title={"S"} backgroundColor="tableBackground" />
		<Box>
			<Box style={styles.cell} minWidth={CELL_WIDTH}>
				<Text fontFamily="bliss-pro-medium">{t("ATTotal")}</Text>
			</Box>
			<Box style={styles.cell} minWidth={CELL_WIDTH}>
				<Text>{t("ATN")}</Text>
			</Box>
		</Box>
	</Box>
);

interface TableHeaderDoubleProps {
	title: string;
	backgroundColor?: "tableBackground";
}
const TableHeaderDouble = ({
	title,
	backgroundColor,
}: TableHeaderDoubleProps) => (
	<Box>
		<Box style={styles.cell} minWidth={CELL_WIDTH}>
			<Text fontFamily="bliss-pro-medium">{title}</Text>
		</Box>
		<Box flexDirection="row">
			<Box
				style={styles.cell}
				minWidth={CELL_WIDTH}
				backgroundColor={backgroundColor}
			>
				<Text>{t("ATPercent")}</Text>
			</Box>
		</Box>
	</Box>
);
interface TableRowProps {
	pathogen: ArsType;
}
const TableRow = ({ pathogen }: TableRowProps) => {
	return (
		<Box flexDirection="row" flex={1}>
			<Box
				width={CELL_WIDTH * 3}
				style={{
					borderLeftWidth: 1,
					borderBottomWidth: 1,
					borderColor: palette.tableBorder,
				}}
			>
				<Text padding="xs">{pathogen.ERREGER}</Text>
			</Box>
			<Box
				minWidth={CELL_WIDTH}
				backgroundColor="tableBackground"
				style={styles.cell}
			>
				<Text>{pathogen.R_PROZ}</Text>
			</Box>
			<Box minWidth={CELL_WIDTH} style={styles.cell}>
				<Text>{pathogen.I_PROZ}</Text>
			</Box>
			<Box
				minWidth={CELL_WIDTH}
				backgroundColor="tableBackground"
				style={styles.cell}
			>
				<Text>{pathogen.S_PROZ}</Text>
			</Box>
			<Box minWidth={CELL_WIDTH} style={styles.cell}>
				<Text>{pathogen.TOTAL_N}</Text>
			</Box>
		</Box>
	);
};

interface TableFooterProps {
	date: string;
	periode: string;
}
const TableFooter = ({ date, periode }: TableFooterProps) => {
	return (
		<Box paddingTop="xs">
			<Text variant="description">{t("ATOnly")}</Text>
			<Text variant="description">{t("ATDisplayed")}</Text>
			<Text variant="description">
				{t("ATPeriod")} {periode}
			</Text>
			<Text variant="description">{t("ATDe")}</Text>
			<Text variant="description">
				{t("ATStatus")} <Date date={date} variant="description" />
			</Text>
		</Box>
	);
};

const Divider = () => (
	<Box height={1} backgroundColor="mainForeground" marginVertical="s" />
);

export default ArsTable;

const styles = StyleSheet.create({
	cell: {
		alignItems: "center",
		borderBottomWidth: 1,
		borderColor: palette.tableBorder,
		borderLeftWidth: 1,
		justifyContent: "center",
	},
});
