import React from "react";
import t from "../../../Texts/TextImport";
import { useNavigationState } from "@react-navigation/native";
import { useWindowDimensions } from "react-native";
import { TabBarButtonProps, Box, TabBarButton } from "../../components";
import { useGuideline } from "../../hooks";
import { APPBRANCH, DEBUGISON, NAVDEBUGGING, ORIGINDEBUG } from "../../config";
import { Text } from "../../components";
import { GuidelineNavigationProps } from "../../components/Navigation";
const maxWidth = 800;

const TabBar = () => {
  const windowWidth = useWindowDimensions().width;
  const isDeviceSmall = maxWidth > windowWidth;
  const routes = useNavigationState<any>((state) => state.routes);
  DEBUGISON && NAVDEBUGGING && console.log(routes);
  const currentRouteName = routes[0]?.name ? routes[0].name : "noName";

  const guidelineId = routes[0]?.params?.guidelineId;
  DEBUGISON && NAVDEBUGGING && console.log(guidelineId);

  const { data: guidelineData, isLoading } = useGuideline(guidelineId);
  DEBUGISON &&
    NAVDEBUGGING &&
    console.log(
      "EntryPoints" +
        JSON.stringify(guidelineData?.BASIC_ENTRY_POINTS)?.substring(0, 100)
    );

  if (isLoading) return <></>;
  const tabs: TabBarButtonProps[] = [
    {
      name: t("GenFu"),
      to: `/AppStack/Guideline/FullText?guidelineId=${guidelineId}&id=${
        guidelineData?.BASIC_ENTRY_POINTS?.find((i) => i.MODE === "fulltext")
          ?.CONTENT_UUID
      }`,
      mode: "fulltext",
      withIcon: true
    },
    {
      name: t("GenRecom"),
      to: `/AppStack/Guideline/Recommendations?guidelineId=${guidelineId}&id=${
        guidelineData?.BASIC_ENTRY_POINTS?.find(
          (i) => i.MODE === "recommendations"
        )?.CONTENT_UUID
      }`,
      mode: "recommendations",
      withIcon: true,
      isDisabled:
        guidelineData?.BASIC_ENTRY_POINTS?.find(
          (i) => i.MODE === "recommendations"
        )?.CONTENT_UUID !== ""
          ? false
          : true
    },
    {
      name: t("GenSbs"),
      to: `/AppStack/Guideline/StepByStep?guidelineId=${guidelineId}&id=${
        guidelineData?.BASIC_ENTRY_POINTS?.find((i) => i.MODE === "stepbystep")
          ?.CONTENT_UUID
      }`,
      mode: "stepbystep",
      withIcon: true,
      isDisabled:
        guidelineData?.BASIC_ENTRY_POINTS?.find((i) => i.MODE === "stepbystep")
          ?.CONTENT_UUID !== ""
          ? false
          : true
    },
    {
      name: t("GenSearch"),
      to: `/AppStack/Guideline/Search?guidelineId=${guidelineId}`,
      mode: "search",
      withIcon: true
    },
    {
      name: isDeviceSmall ? t("GenInstructions") : t("GenNotes"),
      to: `/AppStack/Guideline/Information?guidelineId=${guidelineId}&id=${
        guidelineData?.BASIC_ENTRY_POINTS?.find((i) => i.MODE === "information")
          ?.CONTENT_UUID
      }`,
      mode: "information",
      withIcon: false
    }
  ];

  return (
    <>
      <Box flexDirection="row">
        {DEBUGISON && ORIGINDEBUG && <Text> Tapbar</Text>}
        {tabs.map((tab, index) => {
          const isLast = tabs.length - 1 === index;
          const isActive = tab.mode == currentRouteName.toLowerCase();
          return (
            <TabBarButton
              key={tab.to}
              marginRight={!isLast ? "l" : "none"}
              //flexGrow={1}
              isActive={isActive}
              {...tab}
            />
          );
        })}
      </Box>
      {DEBUGISON && ORIGINDEBUG && (
        <>
          <Text>
            Basic Entry Points:{" "}
            {JSON.stringify(guidelineData?.BASIC_ENTRY_POINTS)}
          </Text>

          <Text>
            currentRouteName:
            {JSON.stringify(currentRouteName)}
          </Text>
        </>
      )}
    </>
  );
};

export default TabBar;
