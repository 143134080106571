import { DEBUGISON, isInDevelopment, isInTest } from "../config";
import { Text } from "../components";

function getStackTrace(): string {
  try {
    throw new Error();
  } catch (err) {
    const stackLines = (err.stack || "").split("\n");
    // Extracting the 3rd and 4th elements (indices 2 and 3)
    const relevantLines = stackLines.slice(3, 10);

    return relevantLines
      .map((line) => {
        // Remove the leading 'at ', the 'Error' keyword, and the URL
        return line.replace(/^\s*at\s+/, "").split(" (")[0];
      })
      .join(", ");
  }
}
function getCallingFileName(): string | undefined {
  try {
    throw new Error();
  } catch (err) {
    const stackLines = (err.stack || "").split("\n");
    // Extracting the 4th element (index 3) which contains calling file information
    const callingFileLine = stackLines[4];

    if (callingFileLine) {
      const match = callingFileLine.match(/\((.*):(\d+):(\d+)\)/);
      if (match) {
        const [, fileName] = match;
        return fileName;
      }
    }

    return undefined;
  }
}

/**
 * Logs a development message to the console.
 * @param {any} messages - The messages to log.
 * @param {boolean} debugCondition - Condition to check before logging.
 */
export function devLog(messages: unknown, debugCondition = true): void {
  if ((isInDevelopment && DEBUGISON && debugCondition) || isInTest) {
    const stack = getStackTrace();

    const callingFileName = getCallingFileName();

    console.log(
      `${messages} %c${stack} %c(${callingFileName})`,
      "color: gray; font-size: 4;",
      "color: blue; font-size: 4;"
    );
  }
}

/**
 * Logs a development error to the console.
 * @param {any} messages - The error messages to log.
 * @param {boolean} debugCondition - Condition to check before logging.
 */
export function devError(messages: unknown, debugCondition = true): void {
  if (DEBUGISON && debugCondition) {
    const stack = getStackTrace();

    const callingFileName = getCallingFileName();

    console.error(
      `${messages} %c${stack} %c(${callingFileName})`,
      "color: gray; font-size: 4;",
      "color: blue; font-size: 4;"
    );
  }
}

/**
 * Logs a development warning to the console.
 * @param {any} messages - The warning messages to log.
 * @param {boolean} debugCondition - Condition to check before logging.
 */
export function devWarn(messages: unknown, debugCondition = true): void {
  if (DEBUGISON && debugCondition) {
    const stack = getStackTrace();

    const callingFileName = getCallingFileName();

    console.warn(
      `${messages} %c${stack} %c(${callingFileName})`,
      "color: gray; font-size: 4;",
      "color: blue; font-size: 4;"
    );
  }
}
