import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";

import { getGuidelines } from "../../hooks/queries/useGuideline";
const DummyGuidelines = () => {
  const [guidelinesData, setGuidelinesData] = useState(null);

  useEffect(() => {
    // Use an async function within useEffect to call the asynchronous function
    const fetchData = async () => {
      try {
        const data = await getGuidelines(null);
        setGuidelinesData(data);
      } catch (error) {
        console.error(JSON.stringify(error));
      }
    };

    fetchData();
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  return (
    <View>
      <Text style={{ fontSize: 20, fontWeight: "bold", marginBottom: 10 }}>
        Dummy Guidelines Component
      </Text>
      {guidelinesData ? (
        <View>
          <Text style={{ fontSize: 18, marginBottom: 5 }}>
            Substring of Data:
          </Text>
          <Text>{JSON.stringify(guidelinesData).substring(0, 100)}</Text>
        </View>
      ) : (
        <Text>Loading...</Text>
      )}
    </View>
  );
};

export default DummyGuidelines;
