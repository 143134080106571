import { ROOT } from "../../config";

export default function constructURL(baseURL: string = ROOT, params: object) {
  const queryString = Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  return `${baseURL}?${queryString}`;
}
