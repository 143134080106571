import React from "react";
import { TouchableOpacity } from "react-native";
import { Box, Text, useTheme, Theme } from "./Theme";

export interface ButtonProps {
  variant: "default" | "border";
  label: string;
  mainColor: keyof Theme["colors"];
  color: keyof Theme["colors"];
  onPress: () => void;
}
export interface ButtonTabProps extends ButtonProps {
  textVariant: keyof Theme["textVariants"];
}

const Button = ({ variant, label, mainColor, color, onPress }: ButtonProps) => {
  const theme = useTheme();
  const backgroundColor = variant === "default" ? mainColor : "mainBackground";
  return (
    <TouchableOpacity {...{ onPress }}>
      <Box
        borderRadius={theme.spacing.xs}
        paddingVertical="s"
        paddingHorizontal="l"
        backgroundColor={backgroundColor}
        borderWidth={1}
        borderColor={mainColor}
        alignItems="center"
      >
        <Text color={color} variant="button">
          {label}
        </Text>
      </Box>
    </TouchableOpacity>
  );
};

const ButtonSmall = ({
  variant,
  label,
  mainColor,
  color,
  onPress
}: ButtonProps) => {
  const theme = useTheme();
  const backgroundColor = variant === "default" ? mainColor : "mainBackground";
  return (
    <TouchableOpacity {...{ onPress }}>
      <Box
        borderRadius={theme.spacing.xs}
        paddingVertical="xs"
        paddingHorizontal="s"
        backgroundColor={backgroundColor}
        borderWidth={1}
        borderColor={mainColor}
        alignItems="center"
      >
        <Text color={color} variant="button" fontSize={14}>
          {label}
        </Text>
      </Box>
    </TouchableOpacity>
  );
};

const ButtonTab = ({
  label,
  mainColor,
  color,
  onPress,
  textVariant
}: ButtonTabProps) => {
  const theme = useTheme();
  return (
    <TouchableOpacity {...{ onPress }}>
      <Box
        paddingHorizontal="s"
        marginBottom="s"
        marginHorizontal="s"
        backgroundColor="mainBackground"
        borderBottomWidth={2}
        borderColor={mainColor}
        alignItems="center"
      >
        <Text color={color} variant={textVariant} fontSize={20}>
          {label}
        </Text>
      </Box>
    </TouchableOpacity>
  );
};

Button.defaultProps = {
  variant: "default"
};

ButtonSmall.defaultProps = {
  variant: "default"
};

export { ButtonSmall, ButtonTab };

export default Button;
