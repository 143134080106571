import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { MET, PATHWAY, URL, SECTOR, LANGUAGE } from "./config";
import { PathwayType } from "../../types";
import { useEffect } from "react";
import { writeToStorage } from "../../utils";
import { LANGUAGE_SUFFIX } from "../useLanguage";
import { BACKENDTRACING, DEBUGISON, HOOKSDEBUGGING } from "../../config";
import { getDownloadedData } from "./useInitialize";
import { devLog, devWarn } from "../../utils/logging";
import DevText from "../../utils/DevText";
import constructURL from "./utils";

export const getPathwayById = async (
  // _: any,
  // pathwayId: string,
  // guidelineId: string
  context: QueryFunctionContext
) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: PATHWAY,
    query: `START_UUID=${context.queryKey[1]} or UUID=${context.queryKey[1]}`,
    brew: `CONTENT${LANGUAGE_SUFFIX}`,
    brewGuideline: context.queryKey[2],
  };
  devLog(constructURL(URL, params), HOOKSDEBUGGING);
  const { data } = await axios.get(URL, { params });
  return data;
};

export const getAllPathways = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: PATHWAY,
    query: `GUIDELINE_UUID=${context.queryKey[1]} and START_TREE=true and STATUS=VERÖFFENTLICHT`,
    attributes: `*,GUIDELINE.TITLE${LANGUAGE_SUFFIX},GUIDELINE.TITLE`,
  };

  devLog(constructURL(URL, params), HOOKSDEBUGGING);
  const { data } = await axios.get(URL, { params });

  return data;
};

export default function usePathway(
  pathwayId: string,
  guidelineId: string,
  options?: QueryObserverOptions<PathwayType[]>
) {
  DEBUGISON &&
    BACKENDTRACING &&
    console.log(
      "usePathway:  " +
        "\npathwayId" +
        pathwayId +
        "\nguidelineId" +
        guidelineId
    );

  const downloaded = getDownloadedData(guidelineId, [
    "pathway",
    pathwayId,
    guidelineId,
  ]);
  const queried = useQuery<PathwayType[]>(
    ["pathway", pathwayId, guidelineId],
    getPathwayById,
    options
  );

  if (downloaded) {
    return { data: downloaded };
  } else return queried;
}

export function useAllPathways(
  guidelineId: string,
  options?: QueryObserverOptions<PathwayType[]>
) {
  DEBUGISON &&
    BACKENDTRACING &&
    console.log("useAllPathways:  " + "\nguidelineId" + guidelineId);

  const downloaded = getDownloadedData(guidelineId, ["pathway", guidelineId]);
  const queried = useQuery<PathwayType[]>(
    ["pathways", guidelineId],
    getAllPathways,
    {
      ...options,
    }
  );

  if (downloaded) {
    return { data: downloaded };
  } else return queried;
}
