import React from "react";
import { TouchableOpacity } from "react-native";
import { Box, useTheme, Text } from "../Theme";
import Icon from "../Icon";

interface CheckboxProps {
  label: string;
  checked: boolean;
  onPress: () => void;
}

const CheckBox = ({ label, checked, onPress }: CheckboxProps) => {
  return (
    <TouchableOpacity {...{ onPress }}>
      <Box flexDirection="row" alignItems="center">
        <Box
          marginRight="s"
          height={20}
          width={20}
          justifyContent="center"
          alignItems="center"
          borderWidth={1}
          borderColor="mainForeground"
          borderRadius={2}
          backgroundColor="mainBackground"
        >
          {checked && <Icon name="buttonSave" color="mainForeground" />}
        </Box>
        <Text>{label}</Text>
      </Box>
    </TouchableOpacity>
  );
};

export default CheckBox;
