import {
  QueryFunctionContext,
  UseMutationOptions,
  hashQueryKey,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { GUIDELINE, MET, URL, SECTOR, LANGUAGE, QR } from "./config";
import { writeToStorage } from "../../utils";
import { LANGUAGE_SUFFIX } from "../useLanguage";
import { DEBUGISON, HOOKSDEBUGGING } from "../../config";
import { createURLWithParams } from "../../utils/helpers";
import useUser from "./useUser";

interface QRCodeType {
  UUID: string;
  QR_IMAGE: string;
  GUIDELINE_UUID: string;
  USER_ID: string;
  EXPORT_DATE: string;
  EXPORT_TIME: number;
  USE_DATE: string;
  USE_TIME: number;
  USE_MONTH: number;
  USE_YEAR: number;
  GUIDELINE_NAME: string;
  QR_CODE_SHOW: string;
  CHARGE: string;
}

export const postQRCode = async (
  guidelineId: string,
  code: string,
  user: string
) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: QR,
    request: "POST",
    UUID: code,
    GUIDELINE_UUID: guidelineId,
    USER_ID: user,
    get: code,
  };
  DEBUGISON &&
    HOOKSDEBUGGING &&
    console.log("post:postQRCode\nurl" + createURLWithParams(params));
  const { data } = await axios.get(URL, { params });

  DEBUGISON &&
    HOOKSDEBUGGING &&
    console.log(
      "useQRCode:postQRCode\nparams" +
        JSON.stringify(params) +
        "\n\nreturn" +
        JSON.stringify(data)
    );
  return data;
};

export type QROutcometype =
  | "success"
  | "error"
  | "otherUser"
  | "otherGuideline";

export function useMutateQRCodes(
  guidelineId: string,
  user: string,
  onError: (msg: QROutcometype) => void,
  onSuccess: (msg: QROutcometype) => void
) {
  DEBUGISON && HOOKSDEBUGGING && console.log("useQRCode: useQRCodes");
  console.log("guidelineId:", guidelineId);
  console.log("user:", user);

  const client = useQueryClient();

  return useMutation<QRCodeType[], unknown, string, unknown>(
    (code: string) => postQRCode(guidelineId, code, user),
    {
      onSuccess: (data) => {
        //@ts-expect-error
        if (guidelineId === data?.GUIDELINE_UUID && user === data?.USER_ID) {
          onSuccess("success");
          client.invalidateQueries({ queryKey: "user" });
        } else if ("USED" === data?.GUIDELINE_UUID) {
          onSuccess("otherGuideline");
        } else if (user !== data?.USER_ID) {
          onSuccess("otherUser");
        } else {
          onSuccess("error");
        }
      },
      onError: (error) => {
        onError("error");
        console.error("Mutation error:", error);
      },
    }
  );
}
