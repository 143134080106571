import React, { ReactNode } from "react";
import {
  ClearCache,
  Identifier,
  Synchronisation,
  Updates,
  Version,
} from "./Content";
import t from "../../../../Texts/TextImport";
import { DEBUGISON, isInDevelopment } from "../../../config";

export const textData = {
  id: t("SetConfId"),
  sync: t("SetConfSync"),
  logout: t("SetConfLogout"),
  update: t("SetConfUpdate"),
};

export interface SettingsPageType {
  id: string;
  title?: string;
  content?: ReactNode;
}

const basePages: SettingsPageType[] = [
  {
    id: "1",
    title: t("SetConfUpdateTitle"),
    content: <Updates />,
  },
  {
    id: "2",
    title: t("SetConfInputId"),
    content: <Identifier />,
  },
  {
    id: "3",
    title: t("SetConfSync"),
    content: <Synchronisation />,
  },
  {
    id: "4",
    title: t("SetConfAppVers"),
    content: <Version />,
  },
];

export const pages = isInDevelopment
  ? [
      ...basePages,

      {
        id: "5",
        title: t("ClearCache"),
        content: <ClearCache />,
      },
    ]
  : basePages;
