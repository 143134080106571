import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import axios from "axios";
import { MET, REFERENCE, URL, SECTOR, LANGUAGE } from "./config";
import { ReferenceType } from "../../types";
import { writeToStorage } from "../../utils";
import { BACKENDTRACING, DEBUGISON } from "../../config";
import { getDownloadedData } from "./useInitialize";

export const getReferenceById = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: REFERENCE,
    get: context.queryKey[1]
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export const getReferencesByGuideline = async (
  context: QueryFunctionContext
) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: REFERENCE,
    query: `GUIDELINE_UUID=${context.queryKey[1]}`,
    attributes: `ID,CONTENT,DOI,ORDER`,
    // HIER kann kein ENGLISH weil QUellen sind einsprachig attributes: `ID,CONTENT,DOI,ORDER`,
    orderBy: "ORDER"
  };
  const { data } = await axios.get(URL, { params });

  DEBUGISON && BACKENDTRACING && console.log(data);
  return data;
};

export default function useReference(
  referenceId: string,
  guidelineId: string,
  onSuccess: (data: ReferenceType) => void,
  options?: QueryObserverOptions<ReferenceType>
) {
  const downloaded = getDownloadedData(guidelineId, [
    "chapter",
    referenceId,
    guidelineId
  ]);
  const res = useQuery<ReferenceType>(
    ["reference", referenceId],
    getReferenceById,
    {
      enabled: true,
      ...options
    }
  );
  res.data && options?.enabled && onSuccess(res.data);
  if (downloaded) {
    return { data: downloaded };
  } else return res;
}

export function useReferences(
  guidelineId: string,
  options?: QueryObserverOptions<ReferenceType[]>
) {
  const downloaded = getDownloadedData(guidelineId, ["reference", guidelineId]);
  const data = useQuery<ReferenceType[]>(
    ["references", guidelineId],
    getReferencesByGuideline,
    {
      ...options,
      onSuccess: (data) => {
        options?.onSuccess && options.onSuccess(data);
        writeToStorage(["references", guidelineId], data);
      }
    }
  );
  DEBUGISON && BACKENDTRACING && console.log(data);
  if (downloaded) {
    return { data: downloaded };
  } else return data;
}
