import React from "react";
import { View, TouchableOpacity, TouchableOpacityProps } from "react-native";
import Icon from "../Icon";
import { Icons } from "../../styles";
import { Box, Text, Theme } from "../Theme";

interface PopupButtonProps extends TouchableOpacityProps {
  onPress: () => void;
  iconName: keyof typeof Icons;
  iconColor?: keyof Theme["colors"];
  text: string;
}

const PopupButton = ({
  iconName,
  text,
  iconColor,
  ...props
}: PopupButtonProps) => {
  return (
    <TouchableOpacity {...props}>
      <Box flexDirection="row" alignItems="center">
        <Icon
          color={iconColor || "mainForeground"}
          size={15}
          lineHeight={18.75}
          name={iconName}
        />
        <Text paddingLeft="xs" variant="popupButton">
          {text}
        </Text>
      </Box>
    </TouchableOpacity>
  );
};

export default PopupButton;
