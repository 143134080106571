import React, { createContext, useState, useContext, useEffect } from "react";
import {
	View,
	ActivityIndicator,
	StyleSheet,
	Modal,
	Platform,
} from "react-native";
import t from "../../../Texts/TextImport";
import { Box, Text } from "../Theme";

interface LoadingContextProps {
	isLoading: boolean;
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoadingContext = createContext<LoadingContextProps | undefined>(
	undefined
);

interface LoadingScreenProps {
	children: React.ReactNode;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false);

	return (
		<LoadingContext.Provider value={{ isLoading, setIsLoading }}>
			{children}
			<Modal
				transparent={true}
				animationType="none"
				visible={isLoading && Platform.OS !== "web"}
				onRequestClose={() => {}}
			>
				<View style={styles.modalBackground}>
					<View style={styles.activityIndicatorWrapper}>
						<Box width={200} justifyContent="center" alignItems="center">
							<ActivityIndicator size="large" color="#dddddd" />
							<Text variant="title2" fontSize={20} color="information">
								{t("RILoaded")}
							</Text>
						</Box>
					</View>
				</View>
			</Modal>
		</LoadingContext.Provider>
	);
};

const useLoading = (): LoadingContextProps => {
	const context = useContext(LoadingContext);
	if (context === undefined) {
		throw new Error("useLoading must be used within a LoadingScreen");
	}
	return context;
};

const styles = StyleSheet.create({
	activityIndicatorWrapper: {
		alignItems: "center",
		borderRadius: 10,
		display: "flex",
		height: 100,
		justifyContent: "space-around",
		width: 100,
	},
	modalBackground: {
		alignItems: "center",
		backgroundColor: "#ffffff80",
		flex: 1,
		flexDirection: "column",
		justifyContent: "space-around",
	},
});

export { LoadingScreen, useLoading };
