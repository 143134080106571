import React from "react";
import { StackActions } from "@react-navigation/native";
import { Box, LinkBox, Text, Theme } from "../../components";
import { GeneralRoutes } from "../../components/Navigation";

interface SettingsChapterButtonProps {
  color: keyof Theme["colors"];
  id: string;
  title: string;
  screen: keyof GeneralRoutes;
}
const SettingsChapterButton = ({
  color,
  id,
  title,
  screen
}: SettingsChapterButtonProps) => {
  const to = `/AppStack/General/${screen}?id=${id}`;
  return (
    <Box marginBottom="m">
      <LinkBox to={to}>
        <Box
          flex={1}
          flexDirection="row"
          paddingVertical="xs"
          paddingHorizontal="s"
          alignItems="center"
          borderLeftWidth={6}
          borderBottomWidth={1}
          borderLeftColor={color}
          borderBottomColor="smoke"
        >
          <Text variant="subtitle2" paddingLeft="s">
            {title}
          </Text>
        </Box>
      </LinkBox>
    </Box>
  );
};

export default SettingsChapterButton;
