import {
  MutationFunction,
  QueryFunctionContext,
  QueryObserverOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { MET, USER, URL, POST_REQUEST, SECTOR, LANGUAGE, QR } from "./config";
import { UserType } from "../../types";
import { Platform } from "react-native";
import moment from "moment";
import { useEffect } from "react";
import { LANGUAGE_SUFFIX } from "../useLanguage";
import { DEBUGISON, HOOKSDEBUGGING, USERDEBUG } from "../../config";
import { createURLWithParams } from "../../utils/helpers";
import { GuidelineActionTypes } from "../../redux/guidelines/types";
import { GuidelilineActivationType } from "../../types/user";
import { devLog } from "../../utils/logging";
import constructURL from "./utils";

const userAttributes = `ID,FAVORITE_GUIDELINE_UUID,MAIN_USER_ID,NOTICES.*,SUB_USER.NOTICES.*,SUB_USER.BOOKMARKS.*,SUB_USER.BOOKMARKS.CONTENT.TITLE${LANGUAGE_SUFFIX},SUB_USER.BOOKMARKS.CONTENT.TITLE,SUB_USER.BOOKMARKS.CONTENT.GUIDELINE_UUID,SUB_USER.BOOKMARKS.CONTENT.GUIDELINE.TITLE,SUB_USER.BOOKMARKS.CONTENT.GUIDELINE.TITLE${LANGUAGE_SUFFIX},BOOKMARKS.*,BOOKMARKS.CONTENT.TITLE,BOOKMARKS.CONTENT.TITLE${LANGUAGE_SUFFIX},BOOKMARKS.CONTENT.GUIDELINE_UUID,BOOKMARKS.CONTENT.GUIDELINE.TITLE,BOOKMARKS.CONTENT.GUIDELINE.TITLE${LANGUAGE_SUFFIX}`;
const activatedGuidelineAttrs = `GUIDELINE_UUID,UUID,USER_ID`;

const getActivatedGuidelines = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: QR,
    query: `USER_ID=` + context.queryKey[1],
    attributes: activatedGuidelineAttrs,
    brew: "CONTENT" + LANGUAGE_SUFFIX,
  };

  devLog(
    "useUser:getActivatedGuidelines:\n url" +
      createURLWithParams(params) +
      JSON.stringify(params),
    HOOKSDEBUGGING
  );
  //@ts-ignore-next-line
  const { data } = await axios.get(URL, { params });
  DEBUGISON && HOOKSDEBUGGING && console.log("getActivatedGuidelines " + data);

  return data;
};

const getUserById = async (context: QueryFunctionContext) => {
  DEBUGISON && HOOKSDEBUGGING && console.log("useUsers:getUserById: ");
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: USER,
    get: context.queryKey[1],
    attributes: userAttributes,
  };
  const { data } = await axios.get(URL, { params });

  devLog(
    "useUsers:getUserById:\n" +
      JSON.stringify(data) +
      "\n\nOn params: " +
      JSON.stringify(params),
    HOOKSDEBUGGING
  );
  return data;
};

const mutateUser = async (user: UserType) => {
  DEBUGISON && HOOKSDEBUGGING && console.log("useUsers:mutateUser: ");
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: USER,
    request: POST_REQUEST,
    get: user.ID,
    FAVORITE_GUIDELINE_UUID: user.FAVORITE_GUIDELINE_UUID,
    attributes: userAttributes,
  };
  const { data } = await axios.get(URL, { params });

  DEBUGISON &&
    HOOKSDEBUGGING &&
    console.log("useUsers:mutateUser:\n" + JSON.stringify(data));
  return data;
};

const mutateMergeUser = async (user: UserType) => {
  DEBUGISON && HOOKSDEBUGGING && console.log("useUsers:mutateMergeUser: ");
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: USER,
    request: POST_REQUEST,
    get: user.ID,
    MAIN_USER_ID: user.MAIN_USER_ID,
    attributes: "ID,MAIN_USER_ID",
  };
  const { data } = await axios.get(URL, { params });

  DEBUGISON &&
    HOOKSDEBUGGING &&
    console.log("useUsers:mutateMergeUser: \n" + JSON.stringify(data));
  return data;
};

const createUser = async (userId: string) => {
  devLog("useUsers:createUser: ", HOOKSDEBUGGING);
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: USER,
    request: POST_REQUEST,
    ID: userId,
    PLATFORM: Platform.OS,
    CREATION_DATE: moment().format("YYYY-MM-DD"),
    CREATION_TIME: moment().format("HH:mm:ss"),
  };
  devLog("url: " + constructURL(URL, params), HOOKSDEBUGGING);
  const { data } = await axios.get(URL, { params });
  devLog("created User: " + data, HOOKSDEBUGGING);
  return data;
};

export default function useUser(
  userId: string,
  options?: QueryObserverOptions<UserType>
) {
  return useQuery<UserType>({
    queryKey: ["user", userId],
    queryFn: getUserById,
    ...options,
  });
}

export function useActivatedGuidelines(
  userId: string,
  options?: QueryObserverOptions<GuidelilineActivationType[]>
) {
  DEBUGISON &&
    HOOKSDEBUGGING &&
    console.log("useActivatedGuidelines: ", userId);
  return useQuery<GuidelilineActivationType[]>({
    queryKey: ["activatedGuidelines", userId],
    queryFn: getActivatedGuidelines,
    ...options,
  });
}
export function useUserMutation(options?: QueryObserverOptions<UserType>) {
  DEBUGISON && HOOKSDEBUGGING && console.log("useUsers:useUserMutation: ");
  // @ts-expect-error mutateUser
  return useMutation<UserType>(mutateUser, options);
}

export function useUserMerge(options: QueryObserverOptions<UserType>) {
  DEBUGISON && HOOKSDEBUGGING && console.log("useUsers:useUserMerge: ");
  // @ts-expect-error mutateUser
  return useMutation<UserType>(mutateMergeUser, options);
}

export function useUserCreation(
  onSuccess: (msg: string) => void,
  onError: (msg: string) => void,
  options?: QueryObserverOptions<UserType>
) {
  devLog("useUserCreation", HOOKSDEBUGGING);

  return useMutation<UserType, unknown, string, unknown>(
    (userId: string) => {
      devLog("mutatingUser: " + userId);
      return createUser(userId);
    },
    {
      onSuccess: (data) => onSuccess(data && JSON.stringify(data)),
      onError: (err) => onError("error"),
    }
  );
}

export function ensureUser(userId: string) {
  DEBUGISON && HOOKSDEBUGGING && console.log("useUser:ensureUser: ");
  const queryClient = useQueryClient();
  return queryClient.ensureQueryData<UserType>({
    queryKey: ["user", userId],
    queryFn: getUserById,
  });
}

export function logUserAttributes(user: UserType | undefined): void {
  DEBUGISON && USERDEBUG && console.log("UserType attributes:");

  if (user) {
    Object.keys(user).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(user, key)) {
        const value = user[key];

        if (typeof value === "object" && value !== null) {
          DEBUGISON &&
            USERDEBUG &&
            console.log(`${key}: ${JSON.stringify(value)}`);
        } else {
          DEBUGISON && USERDEBUG && console.log(`${key}: ${value}`);
        }
      }
    });
  } else {
    DEBUGISON && USERDEBUG && console.log("User object is undefined");
  }
}
