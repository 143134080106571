import axios from "axios";
import { useMutation, QueryObserverOptions } from "@tanstack/react-query";
import {
	MET,
	NOTICE,
	POST_REQUEST,
	DELETE_REQUEST,
	URL,
	SECTOR,
	LANGUAGE,
} from "./config";
import { NoticeType } from "../../types";
import { LANGUAGE_SUFFIX, getContent } from "../useLanguage";
import { DEBUGISON, HOOKSDEBUGGING } from "../../config";

const createNotice = async (notice: NoticeType) => {
	DEBUGISON && HOOKSDEBUGGING && console.log("useNotice:createNotice: ");
	const params = {
		met: MET,
		SECTOR: SECTOR,
		LANGUAGE: LANGUAGE,
		entity: NOTICE,
		request: POST_REQUEST,
		ID: notice.ID,
		CREATION_DATE: notice.CREATION_DATE,
		CREATION_TIME: notice.CREATION_TIME,
		CONTENT: getContent(notice),
		USER_ID: notice.USER_ID,
	};
	const { data } = await axios.get(URL, { params });
	DEBUGISON &&
		HOOKSDEBUGGING &&
		console.log(
			"useNotice:createNotice:\n" +
				"URL: " +
				URL +
				"\nparams: " +
				params +
				"\ndata: " +
				data
		);
	return data;
};

const deleteNotice = async (notice: NoticeType) => {
	DEBUGISON && HOOKSDEBUGGING && console.log("useNotice:deleteNotice: ");
	const params = {
		met: MET,
		SECTOR: SECTOR,
		LANGUAGE: LANGUAGE,
		entity: NOTICE,
		request: DELETE_REQUEST,
		get: notice.ID,
	};
	const { data } = await axios.get(URL, { params });

	DEBUGISON &&
		HOOKSDEBUGGING &&
		console.log(
			"useNotice:deleteNotice:\n" +
				"URL: " +
				URL +
				"\nparams: " +
				params +
				"\ndata: " +
				data
		);
	return data;
};

export function useNoticeCreate(options?: QueryObserverOptions<NoticeType>) {
	DEBUGISON && HOOKSDEBUGGING && console.log("useNotice:useNoticeCreate: ");
	// @ts-expect-error
	return useMutation<NoticeType>(createNotice, options);
}

export function useNoticeDelete(options?: QueryObserverOptions<NoticeType>) {
	DEBUGISON && HOOKSDEBUGGING && console.log("useNotice:useNoticeDelete: ");
	// @ts-expect-error
	return useMutation<NoticeType>(deleteNotice, options);
}
