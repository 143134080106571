import { Platform } from "react-native";
import Constants from "expo-constants";
import * as Network from "expo-network";
import axios from "axios";
import { DEBUGISON, ROOT } from "../config";

const URL = ROOT + "?met=TRACKING";

const isConnected = async () => {
  const { isInternetReachable } = await Network.getNetworkStateAsync();
  return isInternetReachable;
};

export interface TrackingType {
  id?: string;
  screen?: string;
  term?: string;
  guidelineId?: string;
  username?: string;
}

const translateMode = (mode: string | undefined) => {
  switch (mode) {
    case "Search":
      return "search";
    case "FullText":
      return "fullText";
    case "Recommendations":
      return "recommendations";
    case "StepByStep":
      return "stepByStep";
    case "Information":
      return "information";
    case "guideline":
      return mode;
    case "reference":
      return mode;
    case "glossary":
      return mode;
    case "scores":
      return mode;
    default:
      return "";
  }
};

const tracking = async (object: TrackingType) => {
  if (!__DEV__) {
    isConnected().then((isInternetReachable) => {
      if (isInternetReachable) {
        const { id, screen, term, guidelineId, username } = object;
        axios
          .get(URL, {
            params: {
              TRACKING_UUID: id || "",
              TRACKING_LEILI_UUID: guidelineId || "",
              CONTEXT: translateMode(screen),
              SEARCH_ITEM: term || "",
              VERSION: Constants?.expoConfig?.version,
              DEVICE_ID: username || Constants.sessionId,
              PLATFORM: Platform.OS
            }
          })
          .then(() => {
            DEBUGISON && console.log("test passed");
          })
          .catch(() => {
            DEBUGISON && console.log("an error occured while tracking");
          });
      }
    });
  } else {
    DEBUGISON && console.log("not tracking");
  }
};

export { tracking };
