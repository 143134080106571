import React, { ReactNode, useRef } from "react";
import { ScrollView } from "react-native";
import { Box } from "./Theme";
import ProgressBar from "./ProgressBar";
import FloatingButtons from "./FloatingButton";
import { Footer } from "./Footer";
import Popup from "./Popup";
import { ModeType } from "../types";
import { useIsLargeScreen } from "../hooks";
import { ToastProvider, Toast } from "./Toast";

const buttonGroupWidth = 120;
const maxWidth = 800;
interface ContainerProps {
	children: ReactNode;
	tabBar?: ReactNode;
	backButton?: ReactNode;
	mode: ModeType;
	customMobileFooter?: ReactNode;
	breadCrumb?: ReactNode;
	scrollRef?: React.Ref<ScrollView>;
}

const Container = ({
	children,
	tabBar,
	backButton,
	mode,
	customMobileFooter,
	breadCrumb,
	scrollRef,
}: ContainerProps) => {
	const ref = useRef<ScrollView>(null);
	const localRef = scrollRef || ref;
	const isLargeScreen = useIsLargeScreen();
	return (
		<ToastProvider>
			<Box flex={1} backgroundColor="mainBackground">
				<Toast />
				<Popup />
				<Box
					maxWidth={maxWidth}
					paddingHorizontal={{ phone: "none", tablet: "xl" }}
				>
					{tabBar !== null && isLargeScreen ? tabBar : null}
					{breadCrumb !== null && !isLargeScreen ? breadCrumb : null}
					<ProgressBar barWidth={100} />
				</Box>
				<Box flex={1}>
					{isLargeScreen && (
						<Box maxWidth={maxWidth} zIndex={1}>
							<FloatingButtons width={buttonGroupWidth} mode={mode} />
						</Box>
					)}
					<ScrollView
						ref={localRef}
						style={{ flex: 1 }}
						contentContainerStyle={{
							flexGrow: 1,
						}}
						keyboardShouldPersistTaps="handled"
						//keyboardShouldPersistTaps="always" // scroll while keyboard is open
					>
						<Box flex={1} maxWidth={maxWidth}>
							{isLargeScreen && backButton !== null ? backButton : null}
							{children}
						</Box>
					</ScrollView>
					{isLargeScreen ? <Footer maxWidth={maxWidth} /> : null}
					{!isLargeScreen && customMobileFooter ? customMobileFooter : null}
				</Box>
			</Box>
		</ToastProvider>
	);
};

Container.defaultProps = {
	mode: "default",
};

export default React.memo(Container);
