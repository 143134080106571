import { useNavigation } from "@react-navigation/native";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../redux";
import { popHistory, resetHistory } from "../redux/global";
import { translateModetoScreen } from "../utils";

export const useGoBack = () => {
  const dispatch = useDispatch();
  const reduxHistory = useSelector((state) => state.global.history);
  const navigation = useNavigation();
  const onGoBack = useCallback(() => {
    if (reduxHistory?.length > 1) {
      const lastScreen = reduxHistory[reduxHistory.length - 2];
      dispatch(popHistory());
      const screen = translateModetoScreen(lastScreen.mode);
      if (screen === "Informations" || screen === "Settings") {
        navigation.navigate("General", {
          screen,
          params: {
            id: lastScreen.id,
            guidelineId: lastScreen.guidelineId,
          },
        });
      } else {
        navigation.navigate("Guideline", {
          screen,
          params: {
            id: lastScreen.id,
            guidelineId: lastScreen.guidelineId,
            term: lastScreen.term,
          },
        });
      }
    } else {
      dispatch(resetHistory());
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.navigate("Home");
      }
    }
  }, [reduxHistory, navigation]);

  return onGoBack;
};
