import React from "react";
import { QueryClient, MutationCache } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  PersistQueryClientProvider,
  persistQueryClientSave,
  persistQueryClientSubscribe,
} from "@tanstack/react-query-persist-client";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import { DEBUGISON, ROOT, isInTest, isWeb } from "../config";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";
import Constants from "expo-constants";

// console.log(Constants?.expoConfig?.version?.substring(0, 4));
const persister = createAsyncStoragePersister({
  storage:
    isWeb || isInTest
      ? AsyncStorage
      : require("redux-persist-expo-filesystem").default,
  throttleTime: 1000,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
    },
  },
});

persistQueryClientSubscribe({
  queryClient,
  persister,
  buster: URL + Constants?.expoConfig?.version + Constants?.expoConfig?.name,
});

interface QueryClientProps {
  children: React.ReactNode;
}

export default function QueryProvider({ children }: QueryClientProps) {
  const isWeb = Platform.OS == "web";
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      {children}
    </PersistQueryClientProvider>
  );
}
