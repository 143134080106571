import { useNavigationState } from "@react-navigation/native";
import React from "react";
import t from "../../../Texts/TextImport";
import { TabBarButtonProps, Box, TabBarButton } from "../../components";

const TabBar = () => {
  const tabs: TabBarButtonProps[] = [
    {
      name: t("TBAreal"),
      to: "/Areal",
      mode: "default",
      screen: "Areal",
    },
    {
      name: t("TBAlph"),
      to: "/List",
      mode: "default",
      screen: "List",
    },
  ];

  const routes = useNavigationState<any>((state) => state.routes);
  const currentRoute = routes[routes.length - 1];
  const currentRouteName = currentRoute.name;

  return (
    <Box flexDirection='row'>
      {tabs.map((tab, index) => {
        const isLast = tabs.length - 1 === index;
        const isActive = tab.screen == currentRouteName;
        return (
          <TabBarButton
            key={tab.to}
            {...tab}
            marginRight={!isLast ? "l" : "none"}
            isActive={isActive}
            //flexGrow={1}
          />
        );
      })}
    </Box>
  );
};

export default TabBar;
