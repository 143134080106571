import React, { useState, forwardRef } from "react";
import {
	TextInput as RNTextInput,
	TextInputProps as RNTextInputProps,
} from "react-native";
import { useTheme } from "../Theme";

export type TextInputProps = RNTextInputProps;
/* eslint-disable */
const TextInput = forwardRef<RNTextInput, TextInputProps>(
	({ style, onFocus, ...props }, ref) => {
		const [isFocused, setIsFocused] = useState(false);
		const theme = useTheme();
		//console.log(isFocused);
		return (
			<RNTextInput
				ref={ref}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setIsFocused(false)}
				{...props}
				style={[
					{
						flex: 1,
						padding: theme.spacing.s,
						borderRadius: theme.spacing.xs,
						borderWidth: 1,
						borderColor: isFocused
							? theme.colors.primary
							: theme.colors.information,
						backgroundColor: theme.colors.mainBackground,
						fontSize: 18,
						lineHeight: 24,
						fontFamily: "bliss-pro-light",
						color: theme.colors.mainForeground,
					},
					style,
				]}
			/>
		);
	}
);
/* eslint-enable */

export default TextInput;
