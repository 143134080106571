import React from "react";
import { RectButton } from "react-native-gesture-handler";
import t from "../../Texts/TextImport";
import { useGoBack } from "../hooks";
import Icon from "./Icon";
import { Box, Text } from "./Theme";

const BackButton = () => {
  const onGoBack = useGoBack();

  return (
    <Box flexDirection='row' paddingHorizontal='xl' paddingTop='xl'>
      <RectButton onPress={onGoBack}>
        <Box flexDirection='row' alignItems='center' padding='s'>
          <Icon name='circledLeftArrow' color='mainForeground' />
          <Text variant='subtitle2' paddingLeft='s' lineHeight={20}>
            {t("BBBack")}
          </Text>
        </Box>
      </RectButton>
    </Box>
  );
};

export default BackButton;
