import { useNavigation } from "@react-navigation/native";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Platform } from "react-native";
import { useHover } from "react-native-web-hooks";
import { Icons } from "../../styles";
import Icon from "../Icon";
import RoundedIconButton from "../RoundedIconButton";
import { Box } from "../Theme";
import { useFloatingButtonGroupContext } from "./FloatingButtonGroup";

const isWeb = Platform.OS === "web";
interface FloatingButtonProps {
  index: number;
  icon: keyof typeof Icons;
  onPress?: () => void;
  onHoverChildren?: ReactNode;
}

const FloatingButton = ({
  index,
  icon,
  onPress,
  onHoverChildren,
}: FloatingButtonProps) => {
  const {
    currentIndex,
    setCurrentIndex,
    mode,
  } = useFloatingButtonGroupContext();
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    setCurrentIndex(isHovered ? index : -1);
  }, [isHovered]);

  useEffect(() => {
    if (isPressed) {
      setTimeout(() => setIsPressed(false), 2000);
    }
  }, [isPressed]);

  const isAnyHovered = currentIndex !== -1;
  const navigation = useNavigation();
  return (
    <Box
      flexDirection="column"
      alignItems="flex-end"
      opacity={isHovered ? 1 : isAnyHovered ? 0.5 : 1}
      paddingVertical="xs"
    >
      <Box ref={ref} flexDirection="row">
        {isHovered || isPressed ? onHoverChildren : null}
        <RoundedIconButton
          onPress={() => {
            if ((onPress && !onHoverChildren) || (onPress && isWeb)) {
              onPress();
            } else if (!isWeb) {
              setIsPressed(true);
            }
          }}
          name={icon}
          size={40}
          color={mode}
          iconRatio={1}
        />
      </Box>
    </Box>
  );
};

export default FloatingButton;
