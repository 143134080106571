import {
  CommonActions,
  NavigationAction,
  StackActions,
} from "@react-navigation/native";
import React from "react";
import { Box, Icon, LinkBox, Text, Theme } from "../../components";
import { DEBUGISON, ORIGINDEBUG } from "../../config";
import DevText from "../../utils/DevText";

interface BreadCrumbProps {
  color: keyof Theme["colors"];
  title: string;
  to: string;
  action?: NavigationAction;
  opacity: number;
}
const BreadCrumb = ({ color, title, to, action, opacity }: BreadCrumbProps) => {
  return (
    <LinkBox to={to} action={action}>
      <DevText conditions={ORIGINDEBUG}>{color}</DevText>
      <Box
        flexDirection="row"
        backgroundColor={color}
        alignItems="center"
        paddingHorizontal="m"
        paddingVertical="s"
        opacity={opacity}
      >
        <Icon name="enter" color="mainBackground" size={15} />
        <Text
          variant="description"
          paddingHorizontal="xs"
          color="mainBackground"
          numberOfLines={1}
        >
          {title}
          {ORIGINDEBUG && DEBUGISON && "BreadCrumb"}
        </Text>
      </Box>
    </LinkBox>
  );
};

export default BreadCrumb;
