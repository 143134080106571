import React, { Fragment, useEffect, useRef, useState } from "react";
import { TouchableOpacity } from "react-native";
import t from "../../../../Texts/TextImport";
import { Box, ChapterCard, Text } from "../../../components";
import { ErrorAlert } from "../../../components/Alert";
import { useSearch } from "../../../hooks";
import { getContent } from "../../../hooks/useLanguage";
import { GuidelineType } from "../../../types/guideline";
import { DEBUGISON, SEARCHDEBUG } from "../../../config";
import theme from "../../../components/Theme";
import { sliceContentAtNearestSpace } from "../../../utils";

const threshold = 30;

interface ResultsProps {
  term: string;
  guidelines: GuidelineType[];
  search?: boolean;
}

const Results = ({ term, guidelines, search }: ResultsProps) => {
  const [length, setLength] = useState(threshold);
  const showRes = useRef(search);
  const reg = new RegExp(term, "ig");
  const htmlReg = new RegExp(/(<([^>]+)>)/, "ig");
  const { data } = useSearch(term, guidelines, length, {
    enabled: term !== "" && showRes.current,
    onSuccess: () => {
      DEBUGISON && SEARCHDEBUG && console.log(`length: ${length}`);
      return;
    },
    onError: () => {
      ErrorAlert();
    },
  });

  useEffect(() => {
    DEBUGISON &&
      SEARCHDEBUG &&
      console.log(
        `Results: based on ${JSON.stringify(term)} » results in: \n${data}`
      );
    showRes.current = search;
    setLength(threshold);
  }, [term, guidelines, search]);

  return (
    <Box>
      {data?.map((chapter) => {
        const content = getContent(chapter)
          .replace(htmlReg, "")
          .replace(/(\r\n|\n|\r)/gm, " ");
        const hits = content.match(reg) || [];
        const index = content.search(reg);
        const { CONTENT, CONTENT_EN } = sliceContentAtNearestSpace(
          content,
          term,
          index
        );
        chapter.CONTENT = CONTENT;
        chapter.CONTENT_EN = CONTENT_EN;

        return (
          <Fragment key={chapter.UUID}>
            {hits.length > 0 ? (
              <ChapterCard
                chapter={chapter}
                mode="fulltext"
                hits={hits.length}
                withGuidelineTitle={guidelines.length > 1}
                term={term}
                maxHeight={150}
              />
            ) : null}
          </Fragment>
        );
      })}
      {data?.length === 0 && (
        <Text marginTop={"m"} color={"danger"}>
          {t("GenNoSearchResult")}
        </Text>
      )}
      <Box flexDirection="row" flex={1} justifyContent="center">
        {data && data.length === length && (
          <TouchableOpacity
            onPress={() => {
              setLength((prev) => prev + threshold);
              showRes.current = true;
            }}
          >
            <Box flexDirection="row" alignItems="center">
              <Text padding="m" variant="popupButton">
                {t("RMore")}
              </Text>
            </Box>
          </TouchableOpacity>
        )}
      </Box>
    </Box>
  );
};

export default Results;
