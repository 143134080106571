import {
	QueryObserverOptions,
	useMutation,
	useQuery,
} from "@tanstack/react-query";

import axios from "axios";

import { FEEDBACK, MET, POST_REQUEST, URL, SECTOR, LANGUAGE } from "./config";

import { FeedbackType } from "../../types";
import { DEBUGISON, FEEDBACKDEBUG } from "../../config";

const createFeedback = async (feedback: FeedbackType) => {
	const params = {
		met: MET,
		// SECTOR: SECTOR,
		// LANGUAGE: LANGUAGE,
		entity: FEEDBACK,
		request: POST_REQUEST,
		UUID: feedback.UUID,
		CREATION_DATE: feedback.CREATION_DATE,
		CREATION_TIME: feedback.CREATION_TIME,
		PLATFORM: feedback.PLATFORM,
		MODE: feedback.MODE,
		CONTENT_UUID: feedback.CONTENT_UUID,
		GUIDELINE_UUID: feedback.GUIDELINE_UUID,
		MESSAGE: feedback.MESSAGE,
	};

	const { data } = await axios.get(URL, { params });
	DEBUGISON && FEEDBACKDEBUG && console.log("useFeedback:createFeedback: ");
	DEBUGISON && FEEDBACKDEBUG && console.log(data);

	return data;
};

export function useFeedbackCreate(
	options?: QueryObserverOptions<FeedbackType>
) {
	// @ts-expect-error

	return useMutation<FeedbackType>(createFeedback, options);
}
