import { ReactNode } from "react";
import { devError } from "../../utils/logging";

const checkProps = (
  children: string | ReactNode | undefined,
  markdown: string | undefined
) => {
  if (markdown && children) {
    devError(
      "markdown and children cannot not exist provide either markdown prop or children but not both."
    );
    return "";
  }
  if (!markdown && !children) {
    devError(
      "markdown or children needs to exist: Provide either markdown prop or children but not both."
    );
    return "";
  }
  if (typeof children !== "string") {
    devError(
      "if you want to render children you need to provide a string as children."
    );
    return JSON.stringify(children);
  }
  if (markdown) {
    return markdown;
  }
  if (children) {
    return children?.toString();
  }
  return "not expected scenario: there is something wrong with inputs.";
};

const convertToJsonObject = (input: string): { [key: string]: string } => {
  const keyValueRegex = /(\w+)\s*:\s*'([^']+)'/g;
  let match;
  let jsonObject: { [key: string]: string } = {};

  // Iterate over all matches
  // eslint-disable-next-line no-cond-assign
  while ((match = keyValueRegex.exec(input)) !== null) {
    jsonObject[match[1].trim()] = match[2].trim();
  }

  // Default case when no key-value pairs are found
  if (Object.keys(jsonObject).length === 0) {
    jsonObject = { standardParam: input.replace(/{|}/g, "") };
  }

  return jsonObject;
};

const checkLink = (text: string | undefined): boolean => {
  const urlRegex = /https?:\/\/[^\s]+/;
  return urlRegex.test(text || "");
};

export { convertToJsonObject, checkLink, checkProps };
