import { useDrawerStatus } from "@react-navigation/drawer";
import { DrawerActions, useIsFocused } from "@react-navigation/native";
import React, { useEffect, useRef } from "react";
import { Platform, ScrollView } from "react-native";
import { useDispatch } from "react-redux";
import { BackButton, Container, Box, Text, HTML } from "../../../components";
import { MobileFooter } from "../../../components/Footer";
import { GeneralNavigationProps } from "../../../components/Navigation";
import { useAbout, useAllAbout } from "../../../hooks";
import { getContent, getTitle } from "../../../hooks/useLanguage";
import { pushHistory } from "../../../redux/global";
import { compareValues } from "../../../utils";
import SettingsChapterButton from "../SettingsChapterButton";
import { DEBUGISON, NAVDEBUGGING, ORIGINDEBUG } from "../../../config";
import { AboutType } from "../../../types";
import { useQueryClient } from "@tanstack/react-query";
import DevText from "../../../utils/DevText";

function buildTreeString(data: AboutType[], level = 0): string {
  let treeString = Platform.OS + " allAboutData:\n";

  data?.forEach((node) => {
    const padding = " ".repeat(level * 2);
    treeString += `${padding} ${node.TITLE} ID:${node.UUID.substring(
      0,
      10
    )} PLATFORM:${node.PLATFORM} SECTOR: ${node.SECTOR}\n`;
    if (node.CHILDREN && node.CHILDREN.length > 0) {
      treeString += buildTreeString(node.CHILDREN, level + 1);
    }
  });

  return treeString;
}

const Informations = ({
  navigation,
  route,
}: GeneralNavigationProps<"Informations">) => {
  const scrollRef = useRef<ScrollView>(null);
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const isWeb = Platform.OS === "web";
  const id = route.params?.id;
  const { data: allAboutData } = useAllAbout();
  const { data: aboutData } = useAbout(id);

  const DrawerStatus = useDrawerStatus();
  useEffect(() => {
    if (DrawerStatus === "open") {
      navigation.dispatch(DrawerActions.closeDrawer());
    }
    dispatch(pushHistory({ id, guidelineId: undefined, mode: "informations" }));
    if (scrollRef?.current && scrollRef?.current?.scrollTo) {
      scrollRef?.current?.scrollTo({ y: 0, animated: false });
    }
  }, [id]);
  console.log(buildTreeString(allAboutData));
  const queryclient = useQueryClient();
  useEffect(() => {
    queryclient.invalidateQueries(["allAbout"]);
  }, []);

  return (
    <>
      {(isFocused || !isWeb) && (
        <Container
          scrollRef={scrollRef}
          backButton={<BackButton />}
          customMobileFooter={<MobileFooter />}
        >
          {aboutData && (
            <Box padding={{ phone: "m", tablet: "xl" }}>
              <Text variant="title3" paddingBottom="m">
                {DEBUGISON && ORIGINDEBUG && "Informations: Title"}
                {getTitle(aboutData)}
              </Text>
              <HTML html={getContent(aboutData)} mode="default" />
            </Box>
          )}
          <DevText conditions={NAVDEBUGGING}>{id ? id : "NoID"}</DevText>
          <DevText conditions={NAVDEBUGGING}>allAboutData:</DevText>
          <DevText conditions={NAVDEBUGGING}>
            {JSON.stringify(allAboutData)?.substring(0, 600)}
          </DevText>
          <DevText conditions={NAVDEBUGGING}>allAboutCondensed:</DevText>
          <DevText conditions={NAVDEBUGGING}>
            {buildTreeString(allAboutData)}
          </DevText>
          {allAboutData && !getContent(aboutData) && (
            <Box
              flex={1}
              justifyContent="center"
              paddingVertical={{ phone: "m", tablet: "xl" }}
              paddingHorizontal={{ phone: "none", tablet: "xl" }}
            >
              <DevText conditions={ORIGINDEBUG}>
                {allAboutData.map((val: AboutType) => {
                  return getTitle(val);
                })}
              </DevText>
              <DevText>
                {" "}
                {JSON.stringify(
                  allAboutData.map((el: AboutType) => {
                    return el.VISIBLE;
                  })
                )}{" "}
              </DevText>
              {allAboutData
                .filter((entry: AboutType) => {
                  return entry?.VISIBLE;
                })
                .map((chapter: AboutType) => {
                  return (
                    <SettingsChapterButton
                      key={chapter.UUID}
                      color="default"
                      title={getTitle(chapter)}
                      screen="Informations"
                      id={chapter.UUID}
                    />
                  );
                })}
            </Box>
          )}
        </Container>
      )}
    </>
  );
};

export default Informations;
