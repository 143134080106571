import React, { ReactNode, useRef, useState } from "react";
import { Platform } from "react-native";
import { NavigationAction, useLinkProps } from "@react-navigation/native";
import { Text, Box, useTheme } from "./Theme";
import { useHover } from "react-native-web-hooks";
import { RectButton } from "react-native-gesture-handler";
import { Modal, useWindowDimensions } from "react-native";
import { DEBUGISON, NAVDEBUGGING } from "../config";
import { useLoading } from "./LoadingProvider";

interface LinkBoxProps {
  to: string;
  action?: NavigationAction;
  children: ReactNode;
  withHover?: boolean;
  isDisabled?: boolean;
  showDisabled?: boolean;
  showLoading?: boolean;
}

const LinkBox = ({
  to,
  action,
  children,
  withHover,
  isDisabled,
  showDisabled = true,
  showLoading = false
}: LinkBoxProps) => {
  const theme = useTheme();
  const { onPress, ...props } = useLinkProps({ to, action });
  const loading = useLoading();

  const handlePress = () => {
    showLoading && loading.setIsLoading(true);
    DEBUGISON && NAVDEBUGGING && console.warn("pressed Linkbox");
    onPress();
  };
  if (Platform.OS === "web") {
    const ref = useRef(null);
    const isHovered = useHover(ref);
    return (
      <Box
        testID="LinkBox"
        ref={ref}
        // @ts-ignore
        onClick={onPress}
        backgroundColor={withHover && isHovered ? "hoverBackground" : undefined}
        pointerEvents={isDisabled ? "none" : "auto"}
        style={{ opacity: isDisabled && showDisabled ? 0.5 : 1 }}
        {...props}
      >
        {children}
      </Box>
    );
  }
  return (
    <>
      <RectButton
        // @ts-ignore
        testID="LinkBox"
        onPress={handlePress}
        enabled={!isDisabled}
        style={{ opacity: isDisabled && showDisabled ? 0.5 : 1 }}
        underlayColor={theme.colors.information}
        {...props}
      >
        {children}
      </RectButton>
    </>
  );
};

LinkBox.defaultProps = {
  withHover: true,
  isDisabled: false
};

export default LinkBox;

/*

LinkBox:props{"href":"/FullText?guidelineId=DCFCB9E2E5524490BBAE432E23C9C78F","accessibilityRole":"link"}
LinkBox:props{"href":"/%2FGuideline%2FFullText%3Fid%3DB4BA6A3F6F8243EF818407074D09F2C4%26guidelineId%3DDCFCB9E2E5524490BBAE432E23C9C78F","accessibilityRole":"link"}
LinkBox:props{"href":"/FullText?guidelineId=","accessibilityRole":"link"}



*/
