import React from "react";
import t from "../../../../Texts/TextImport";
import { Box, HTML, Icon, Text } from "../../../components";
import {
  APPBRANCH,
  BACKENDTRACING,
  DEBUGISON,
  ORIGINDEBUG
} from "../../../config";
import { RecommendationType } from "../../../types";
import { HTMLPreprocessor } from "../../../components/HTML/HTMLRendering/HTMLPreprocessing";

const Recommendation = ({
  UUID,
  NO,
  RECOMMENDATION_DEGREE,
  CONSENS,
  EVIDENCE,
  ...text
}: RecommendationType) => {
  return (
    <Box marginBottom="l">
      {DEBUGISON && ORIGINDEBUG && <Text>Recommendation</Text>}
      {DEBUGISON && BACKENDTRACING && (
        <>
          <Text>{UUID}</Text>
          <Text>{NO}</Text>
          <Text>text: {JSON.stringify(text)}</Text>
          <Text>{RECOMMENDATION_DEGREE}</Text>
        </>
      )}
      <Box flexDirection="row" flexWrap="wrap" alignItems="center">
        {RECOMMENDATION_DEGREE !== "" && (
          <Box paddingRight="m" paddingBottom="xs">
            <RecommendationDegree {...{ RECOMMENDATION_DEGREE }} />
          </Box>
        )}
        {EVIDENCE !== "" && (
          <Box paddingRight="m" paddingBottom="xs">
            <Evidence {...{ EVIDENCE }} />
          </Box>
        )}
        {CONSENS !== "" && (
          <Box flexDirection="row" alignItems="center" paddingBottom="xs">
            <Text
              variant="tiny"
              fontFamily="bliss-pro-medium"
              paddingRight="xs"
            >
              {t("RConsens")}
            </Text>
            <Text fontFamily="bliss-pro-medium" paddingBottom="xxs">
              {CONSENS}%
            </Text>
          </Box>
        )}
      </Box>
      <Text fontFamily="bliss-pro-medium" paddingBottom="xs">
        {NO}
      </Text>
      <HTML
        mode={APPBRANCH == "ERKNET" ? "search" : "recommendations"}
        html={HTMLPreprocessor.precompile(text)}
      />
    </Box>
  );
};

interface RecommendationDegreeProps {
  RECOMMENDATION_DEGREE: string;
}

const RecommendationDegree = ({
  RECOMMENDATION_DEGREE
}: RecommendationDegreeProps) => {
  const regPlus = new RegExp("(&#X2295;|⊕)", "g");
  const regMinus = new RegExp("(&#X2296;|⊖)", "g");
  const regNegativeCheck = new RegExp("&#xE067;", "g");
  const regNegativeCross = new RegExp("&#xE068;", "g");
  const regCircledCheck = new RegExp("&#x2714;", "g");
  const regCircledCross = new RegExp("&#x2BBE;", "g");
  const numberOfPlus = RECOMMENDATION_DEGREE.match(regPlus);
  const numberOfMinus = RECOMMENDATION_DEGREE.match(regMinus);
  const numberOfNegativeCheck = RECOMMENDATION_DEGREE.match(regNegativeCheck);
  const numberOfNegativeCross = RECOMMENDATION_DEGREE.match(regNegativeCross);
  const numberOfCircledCheck = RECOMMENDATION_DEGREE.match(regCircledCheck);
  const numberOfCircledCross = RECOMMENDATION_DEGREE.match(regCircledCross);
  const A = RECOMMENDATION_DEGREE.match(new RegExp("(&#X24B6;|Ⓐ)", "g"));
  const B = RECOMMENDATION_DEGREE.match(new RegExp("(&#X24B7;|Ⓑ)", "g"));
  const C = RECOMMENDATION_DEGREE.match(new RegExp("(&#X24B8;|Ⓒ)", "g"));
  const D = RECOMMENDATION_DEGREE.match(new RegExp("(&#X24B8;|Ⓒ)", "g"));
  return (
    <Box flexDirection="row" alignItems="center">
      <Text variant="tiny" fontFamily="bliss-pro-medium" paddingRight="xs">
        {t("RCriteria").replace("S", "")}
      </Text>
      {numberOfPlus?.map((_, idx) => (
        <Icon key={idx} name="negativePlus" color="green" />
      ))}
      {numberOfMinus?.map((_, idx) => (
        <Icon key={idx} name="negativeMinus" color="danger" />
      ))}
      {numberOfNegativeCheck && numberOfNegativeCheck.length > 0 && (
        <>
          <Icon name="negativeCheck" color="success" />
          <Text
            variant="tiny"
            fontFamily="bliss-pro-medium"
            textTransform="uppercase"
            paddingLeft="xxs"
          >
            {RECOMMENDATION_DEGREE.replace(regNegativeCheck, "")}
          </Text>
        </>
      )}
      {numberOfCircledCheck && numberOfCircledCheck.length > 0 && (
        <>
          <Icon name="circledMark" color="success" />
          <Text
            variant="tiny"
            fontFamily="bliss-pro-medium"
            textTransform="uppercase"
            paddingLeft="xxs"
          >
            {RECOMMENDATION_DEGREE.replace(regCircledCheck, "")}
          </Text>
        </>
      )}
      {numberOfNegativeCross && numberOfNegativeCross.length > 0 && (
        <>
          <Icon name="negativeCross" color="danger" />
          <Text
            variant="tiny"
            fontFamily="bliss-pro-medium"
            textTransform="uppercase"
            paddingLeft="xxs"
          >
            {RECOMMENDATION_DEGREE.replace(regNegativeCross, "")}
          </Text>
        </>
      )}
      {numberOfCircledCross && numberOfCircledCross.length > 0 && (
        <>
          <Icon name="circledCross" color="danger" />
          <Text
            variant="tiny"
            fontFamily="bliss-pro-medium"
            textTransform="uppercase"
            paddingLeft="xxs"
          >
            {RECOMMENDATION_DEGREE.replace(regCircledCross, "")}
          </Text>
        </>
      )}
      {A && A.length > 0 && <Icon name={"A"} color="mainForeground" />}
      {B && B.length > 0 && <Icon name={"B"} color="mainForeground" />}
      {C && C.length > 0 && <Icon name={"C"} color="mainForeground" />}
      {D && D.length > 0 && <Icon name={"D"} color="mainForeground" />}
    </Box>
  );
};

interface EvidenceProps {
  EVIDENCE: string;
}

const Evidence = ({ EVIDENCE }: EvidenceProps) => {
  const A = EVIDENCE.match(new RegExp("(&#X24B6;|Ⓐ)", "g"));
  const B = EVIDENCE.match(new RegExp("(&#X24B7;|Ⓑ)", "g"));
  const C = EVIDENCE.match(new RegExp("(&#X24B8;|Ⓒ)", "g"));
  const D = EVIDENCE.match(new RegExp("(&#X24B9;|Ⓒ)", "g"));

  const noIcon = !A && !B && !C && !D;

  return (
    <Box flexDirection="row" alignItems="center">
      <Text variant="tiny" fontFamily="bliss-pro-medium" paddingRight="xs">
        {t("REvidence")}
      </Text>
      {A && A.length > 0 && <Icon name={"A"} color="mainForeground" />}
      {B && B.length > 0 && <Icon name={"B"} color="mainForeground" />}
      {C && C.length > 0 && <Icon name={"C"} color="mainForeground" />}
      {D && D.length > 0 && <Icon name={"D"} color="mainForeground" />}
      {noIcon && <Text fontFamily="bliss-pro-medium">{EVIDENCE}</Text>}
    </Box>
  );
};

export default Recommendation;
