import React, { useState } from "react";
import { Platform } from "react-native";
import { useNavigationState } from "@react-navigation/native";
import { useAuth, useFeedbackCreate } from "../../hooks";
import TextArea from "../Form/TextArea";
import { Box } from "../Theme";
import { randomString, translateScreenToMode } from "../../utils";
import moment from "moment";
import PopupFooter from "./PopupFooter";
import { useToast } from "../Toast";
import { useDispatch } from "react-redux";
import { togglePopup } from "../../redux/global";
import t from "../../../Texts/TextImport";
import { DEBUGISON, FEEDBACKDEBUG } from "../../config";

const PopupFeedback = () => {
	DEBUGISON && FEEDBACKDEBUG && console.log("useFeedback:PopupFeedback: ");
	const [text, setText] = useState("");
	const dispatch = useDispatch();
	const { show } = useToast();
	const { username } = useAuth();
	const routes = useNavigationState((state) => state.routes);
	const { mutate } = useFeedbackCreate({
		onSuccess: () => {
			dispatch(togglePopup({ isVisible: false }));
			show(t("GenThanks"));
		},
		onError: () => {
			show(t("GenFail"));
		},
	});

	const currentRoute = routes[routes.length - 1];

	const handlePress = () => {
		DEBUGISON && FEEDBACKDEBUG && console.log("useFeedback:handlePress: ");
		if (text !== "") {
			mutate({
				ID: randomString(),
				USER_ID: username,
				MESSAGE: text,
				CREATION_DATE: moment().format(),
				CREATION_TIME: moment().format("HH:mm:ss"),
				PLATFORM: Platform.OS,
				MODE: translateScreenToMode(currentRoute.name),
				GUIDELINE_UUID: currentRoute.params?.guidelineId,
				CONTENT_UUID: currentRoute.params?.id,
				DEVICE_ID: username,
			});
		}
	};

	return (
		<>
			<Box margin="m" marginTop="s" minHeight={100}>
				<TextArea
					value={text}
					onChangeText={setText}
					placeholder="Feedback eingeben"
				/>
			</Box>
			<PopupFooter
				singleButton={false}
				successText={t("GenSend")}
				onSuccess={handlePress}
			/>
		</>
	);
};

export default PopupFeedback;
