import React from "react";
import { Box, RoundedIconButton, Hoverable } from "../../../components";

interface RecommendationIconProps {
  onPress: () => void;
}
const RecommendationIcon = ({ onPress }: RecommendationIconProps) => {
  return (
    <Box marginLeft="m" marginTop="xs">
      <Hoverable>
        <RoundedIconButton
          name="circledRecommendations"
          color="fulltext"
          size={24}
          iconRatio={1}
          onPress={onPress}
        />
      </Hoverable>
    </Box>
  );
};

export default RecommendationIcon;
