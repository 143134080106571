import { isWeb } from "../config";
import { URL } from "../hooks/queries/config";

export function compareValues(key: string, order = "asc") {
  return function innerSort(a: any, b: any) {
    // eslint-disable-next-line
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export function randomString(
  length = 32,
  chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
) {
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

//take care of how the style string has to be written
//ex. style="background-color: red; font-size: 30"
//do not forget the white space after : and ; (has to be consistent)
//SEARCH FOR CSS PARSER
export const transformCSS = (string: string) => {
  const splittedString = string.split("; ");
  const outputArray: any[] = [];
  splittedString.map((item) => {
    const index = item.indexOf(": ");
    const attribute = item.substr(0, index);
    const value = item.substr(index + 1);
    outputArray.push([attribute, value]);
  });
  return outputArray;
};

export const transformCSS2 = (string: string) => {
  const splittedString = string.split("; ");
  const outputArray: any[] = [];
  splittedString.map((item) => {
    const index = item.indexOf(": ");
    const attribute = item.substr(0, index);
    const value = item.substr(index + 1);
    outputArray.push([attribute, value.replace(";", "")]);
  });
  return outputArray;
};

export function arrayToJson(arrayOfJson: object[]): object {
  const result: object = {};

  arrayOfJson.forEach((jsonObj, index) => {
    const key = `item${index + 1}`;
    result[key] = jsonObj;
  });

  return result;
}

export function generateUniqueId(size = 32): string {
  let result = "";
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < size; i++) {
    result += Math.floor(Math.random() * 16).toString(16);
  }
  return result;
}

export function isUnusefulString(str: string | undefined | null): boolean {
  if (str === undefined || str === null) {
    return true;
  }
  const trimmedString = str.trim();

  return trimmedString === "";
}
export function hasUnusefulStrings(
  strings: (string | undefined | null | any)[]
): boolean {
  for (const str of strings) {
    if (isUnusefulString(str)) {
      return true;
    }
  }
  return false;
}

export function sliceContentAtNearestSpace(
  content: string,
  term: string,
  index: number
): { CONTENT: string; CONTENT_EN: string } {
  const maxDistance = 35;
  console.log("index" + index);
  let start = index - maxDistance < 0 ? 0 : index - maxDistance;
  let end = index + term.length + maxDistance;

  // Find the nearest space before the start index
  while (start > 0 && content[start] !== " ") {
    start--;
  }

  // Find the nearest space after the end index
  while (end < content.length && content[end] !== " ") {
    end++;
  }

  // Slice the content string using the nearest spaces
  const slicedContent = `<span>... ${content.slice(start, end)} ... </span>`;

  return {
    CONTENT: slicedContent,
    CONTENT_EN: slicedContent,
  };
}

export function createURLWithParams(params: any) {
  const baseUrl = URL;
  const queryParams = new URLSearchParams();

  for (const key in params) {
    if (params[key]) {
      queryParams.append(key.toLowerCase(), params[key]);
    }
  }

  return `${baseUrl}?${queryParams.toString()}`;
}

export const buildHomeLink = (): string => {
  let link = "/Home/Areal";
  if (isWeb) link = "/AppStack/" + link;
  return link;
};
