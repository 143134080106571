import tokenizeMarkdown from "./MarkdownTokenizer";
import { checkProps } from "./utils";
import RenderTokens from "./RenderTokens";
import { Text } from "../Theme";

import React, { createContext, useContext, ReactNode, Children } from "react";
import { devLog } from "../../utils/logging";
import { useIsLargeScreen } from "../../hooks";

// Define the types for the components
type BoldProps = { children: string };
type RedProps = { children: string };

// MarkdownTextContext erstellen
interface MarkdownTextContextProps {
  Bold: React.ReactNode;
  Red: React.ReactNode;
  Link: React.ReactNode;
  Standard: React.ReactNode;
  children: React.ReactNode;
  H1: React.ReactNode;
  H2: React.ReactNode;
  H3: React.ReactNode;
}

// Define Bold component
export const Bold: React.FC<BoldProps> = ({ children }) => (
  <Text variant="body" fontWeight="bold">
    {children}
  </Text>
);

// Define Red component
export const Red: React.FC<RedProps> = ({ children }) => (
  <Text variant="body" color="danger" fontWeight="bold">
    {children}
  </Text>
);

// Define Red component
export const H1: React.FC<RedProps> = ({ children }) => (
  <Text variant="body">{children}</Text>
);

export const H2: React.FC<RedProps> = ({ children }) => (
  <Text variant="body">{children}</Text>
);

export const H3: React.FC<RedProps> = ({ children }) => (
  <Text variant="body">{children}</Text>
);

export const Standard: React.FC<RedProps> = ({ children }) => (
  <Text variant="body">{children}</Text>
);

export const Link: React.FC<RedProps> = ({ children }) => (
  <Text variant="body">{children}</Text>
);
const MarkdownTextContext = createContext<MarkdownTextContextProps>({
  Red: Red,
  H1: H1,
  H2: H2,
  H3: H3,
  Link: Link,
  Bold: Bold,
  Red: Red,
  Standard: Standard,
});

// Provider-Komponente für MarkdownTextContext erstellen
export const MarkdownTextProvider: React.FC<MarkdownTextContextProps> = ({
  Bold,
  Red,
  Standard,
  H1,
  H2,
  H3,
  Link,
  children,
}) => {
  return (
    <MarkdownTextContext.Provider
      value={{
        Bold,
        Red,
        Standard,
        H1,
        H2,
        H3,
        Link,
      }}
    >
      {children}
    </MarkdownTextContext.Provider>
  );
};

// MarkdownText-Komponente erstellen, die den Context verwendet
const Markdown: React.FC<{ children: string }> = ({
  children,
  markdown,
}: {
  children: string;
  markdown: string;
}) => {
  const { Bold: ContextBold, Red: ContextRed } =
    useContext(MarkdownTextContext);
  const input = checkProps(children, markdown);
  const tokens = tokenizeMarkdown(input);
  devLog(tokens);

  return (
    <RenderTokens
      tokens={tokens}
      Bold={Bold}
      Red={Red}
      H1={H1}
      H2={H2}
      H3={H3}
      Link={Link}
      Standard={Standard}
    />
  );
};

export default Markdown;
