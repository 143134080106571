import React, { createContext, useContext, ReactNode, Children } from "react";
import { Text } from "./Theme"; // Assuming Text component and TextProps are defined in "Theme"

// Define the types for the components
type BoldTextProps = { children: string };
type RedTextProps = { children: string };

// MarkdownTextContext erstellen
interface MarkdownTextContextProps {
  BoldText: React.ComponentType<BoldTextProps>;
  RedText: React.ComponentType<RedTextProps>;
  children: React.ReactNode;
}

// Define BoldText component
export const BoldText: React.FC<BoldTextProps> = ({ children }) => (
  <Text variant="body" fontWeight="bold">
    {children}
  </Text>
);

// Define RedText component
export const RedText: React.FC<RedTextProps> = ({ children }) => (
  <Text variant="body" color="danger" fontWeight="bold">
    {children}
  </Text>
);

const MarkdownTextContext = createContext<MarkdownTextContextProps>({
  BoldText: BoldText,
  RedText: RedText,
});

// Provider-Komponente für MarkdownTextContext erstellen
export const MarkdownTextProvider: React.FC<MarkdownTextContextProps> = ({
  BoldText,
  RedText,
  children,
}) => {
  return (
    <MarkdownTextContext.Provider value={{ BoldText, RedText }}>
      {children}
    </MarkdownTextContext.Provider>
  );
};

// MarkdownText-Komponente erstellen, die den Context verwendet
export const MarkdownText: React.FC<{ children: string }> = ({
  children,
}: {
  children: string;
}) => {
  const { BoldText: ContextBoldText, RedText: ContextRedText } =
    useContext(MarkdownTextContext);

  // Beispiel-Markdown-Parser (kann je nach Ihren Anforderungen angepasst werden)
  const parseMarkdown = (markdownText: string): ReactNode => {
    // Detect bold text using **...**
    const boldRegex = /\*\*(.*?)\*\*/g;

    return <Text variant="tiny">{parsedText}</Text>;
  };
};
