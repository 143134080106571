import React from "react";
import QRWrapper from "./QRWrapper";
import t from "../../../Texts/TextImport";
import { Box, Button, LinkBox, Text } from "../../components";
import { generateUniqueId, buildHomeLink } from "../../utils/helpers";
import useGuideline, {
  useIsGuidelineUnlocked,
} from "../../hooks/queries/useGuideline";
import { GuidelineNavigationProps } from "../../components/Navigation";
import { APPBRANCH, DEBUGISON, HOOKSDEBUGGING, isWeb } from "../../config";
import { useAuth } from "../../hooks";
import Markdown from "../../components/Markdown";
import { getTitle } from "../../hooks/useLanguage";
import DevText from "../../utils/DevText";
import { QRAbortButton, QRButton } from "./QRButton";

function WantToUnlock({
  navigation,
  route,
}: GuidelineNavigationProps<"FullText">) {
  const isUnlocked = useIsGuidelineUnlocked(route.params?.guidelineId);
  const buildLink = (): string => {
    let link = "/";
    if (!isUnlocked) link = link + "Guideline/ScanQR";
    else if (isUnlocked && isWeb) link = link + "Guideline/Fulltext";
    else if (isUnlocked && !isWeb) link = link + "Guideline/Modeselection";
    if (isWeb) link = "/AppStack/" + link;
    return link + `?guidelineId=${route.params.guidelineId}`;
  };
  const { username } = useAuth();
  const { data: guideline } = useGuideline(route.params.guidelineId);

  return (
    <QRWrapper>
      <DevText>{route?.params?.guidelineId}</DevText>

      <Box
        width={"100%"}
        height={"100%"}
        justifyContent="space-between"
        marginVertical="s"
        flexDirection="column"
      >
        <DevText>{username}</DevText>
        {!isWeb && <Box></Box>}
        <Box width={"100%"} justifyContent="center" marginTop="m">
          <Markdown>{t("QRWantActivate", [getTitle(guideline)])}</Markdown>

          <QRButton
            onPress={() => {}}
            text={t("QRScanInstruction")}
            to={buildLink()}
          />
        </Box>

        <QRAbortButton />
      </Box>
    </QRWrapper>
  );
}

export default WantToUnlock;
