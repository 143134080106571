import { BookmarkType, NoticeType } from "../../types";
import {
	UserActionTypes,
	ADD_LOCAL_BOOKMARK,
	RESET_LOCAL_BOOKMARK,
	ADD_LOCAL_NOTICE,
	RESET_LOCAL_NOTICE,
	SET_LOCAL_FAVORITE_GUIDELINE,
	RESET_LOCAL_FAVORITE_GUIDELINE,
	REMOVE_LOCAL_BOOKMARK,
	ADD_LOCAL_BOOKMARK_DELETE,
	REMOVE_LOCAL_BOOKMARK_DELETE,
	REMOVE_LOCAL_NOTICE,
	ADD_LOCAL_NOTICE_DELETE,
	REMOVE_LOCAL_NOTICE_DELETE,
} from "./types";

export const addLocalNotice = (notice: NoticeType): UserActionTypes => ({
	type: ADD_LOCAL_NOTICE,
	payload: notice,
});

export const removeLocalNotice = (notice: NoticeType): UserActionTypes => ({
	type: REMOVE_LOCAL_NOTICE,
	payload: notice,
});

export const addLocalNoticeDelete = (notice: NoticeType): UserActionTypes => ({
	type: ADD_LOCAL_NOTICE_DELETE,
	payload: notice,
});

export const removeLocalNoticeDelete = (
	notice: NoticeType
): UserActionTypes => ({
	type: REMOVE_LOCAL_NOTICE_DELETE,
	payload: notice,
});

export const resetLocalNotice = (): UserActionTypes => ({
	type: RESET_LOCAL_NOTICE,
});

export const addLocalBookmark = (bookmark: BookmarkType): UserActionTypes => ({
	type: ADD_LOCAL_BOOKMARK,
	payload: bookmark,
});

export const removeLocalBookmark = (
	bookmark: BookmarkType
): UserActionTypes => ({
	type: REMOVE_LOCAL_BOOKMARK,
	payload: bookmark,
});

export const addLocalBookmarkDelete = (
	bookmark: BookmarkType
): UserActionTypes => ({
	type: ADD_LOCAL_BOOKMARK_DELETE,
	payload: bookmark,
});

export const removeLocalBookmarkDelete = (
	bookmark: BookmarkType
): UserActionTypes => ({
	type: REMOVE_LOCAL_BOOKMARK_DELETE,
	payload: bookmark,
});

export const resetLocalBookmark = (): UserActionTypes => ({
	type: RESET_LOCAL_BOOKMARK,
});

export const setLocalFavoriteGuideline = (uuid: string): UserActionTypes => ({
	type: SET_LOCAL_FAVORITE_GUIDELINE,
	payload: uuid,
});

export const resetLocalFavoriteGuideline = (): UserActionTypes => ({
	type: RESET_LOCAL_FAVORITE_GUIDELINE,
});
