import { useDrawerStatus } from "@react-navigation/drawer";
import { DrawerActions } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import React, { useEffect, useRef } from "react";
import { ScrollView } from "react-native";
import { useDispatch } from "react-redux";
import {
  BackButton,
  Container,
  Box,
  Text,
  BreadCrumbs,
} from "../../../components";
import { MobileFooter } from "../../../components/Footer";
import { GuidelineNavigationProps } from "../../../components/Navigation";
import {
  useAllChapters,
  useAllPathways,
  useAnalytics,
  useChapter,
  useGuideline,
} from "../../../hooks";
import { useSelector } from "../../../redux";
import { pushHistory, togglePopup } from "../../../redux/global";
import {
  addPathwayToChapter,
  createChapterTree,
  filterStepByStepTree,
  findChapterById,
} from "../../../utils";
import ChapterButton from "../ChapterButton";
import TabBar from "../TabBar";
import Pathway from "./Pathway";
import { getTitle } from "../../../hooks/useLanguage";
import {
  DEBUGISON,
  NAVDEBUGGING,
  isTextSelectable,
  isWeb,
} from "../../../config";
import { ChapterType } from "../../../types";
import { useForwardIfNotUnlocked } from "../../../hooks/queries/useGuideline";

const StepByStep = ({
  navigation,
  route,
}: GuidelineNavigationProps<"StepByStep">) => {
  isWeb && useForwardIfNotUnlocked(route?.params?.guidelineId, navigation);
  useAnalytics({});
  const id = route.params?.id;
  const guidelineId = route.params?.guidelineId;
  const reduxPopup = useSelector((state) => state.global.popup);
  const scrollRef = useRef<ScrollView>(null);
  const dispatch = useDispatch();
  const allChapter = useAllChapters(guidelineId);
  const guideline = useGuideline(guidelineId);
  const allPathways = useAllPathways(guidelineId);

  const drawerStatus = useDrawerStatus();
  useEffect(() => {
    if (drawerStatus == "open") {
      navigation.dispatch(DrawerActions.closeDrawer());
    }
    if (reduxPopup.isVisible) {
      dispatch(togglePopup({ isVisible: false }));
    }
    dispatch(pushHistory({ id, guidelineId, mode: "stepbystep" }));
    if (scrollRef?.current && scrollRef?.current?.scrollTo) {
      scrollRef?.current?.scrollTo({ y: 0, animated: false });
    }
  }, [id]);

  useEffect(() => {
    if (guideline.data) {
      navigation.setOptions({ title: getTitle(guideline.data) });
    }
  }, [guideline.data]);

  const tree =
    allChapter.data &&
    allPathways.data &&
    filterStepByStepTree(
      createChapterTree(addPathwayToChapter(allChapter.data, allPathways.data))
    );
  const chapterWithChildren = tree && id && findChapterById(tree, id);

  const isLastChild = allPathways.data?.find((i) => i.UUID === id);

  DEBUGISON &&
    NAVDEBUGGING &&
    console.log("Tree: " + JSON.stringify(tree).substring(0, 100));
  DEBUGISON &&
    NAVDEBUGGING &&
    console.log(
      "ChapterWithChildren: " +
        JSON.stringify(chapterWithChildren)?.substring(0, 100)
    );

  return (
    <>
      <StatusBar style="light" />
      <Container
        scrollRef={scrollRef}
        tabBar={<TabBar />}
        backButton={<BackButton />}
        customMobileFooter={<MobileFooter />}
        breadCrumb={
          tree ? (
            <BreadCrumbs tree={tree} chapterId={id} screen="StepByStep" />
          ) : null
        }
        mode="stepbystep"
      >
        {!isLastChild && chapterWithChildren?.data && (
          <Box padding={{ phone: "m", tablet: "xl" }}>
            <Text selectable={isTextSelectable} variant="title3">
              {chapterWithChildren?.data?.TITLE}
            </Text>
          </Box>
        )}
        <Box
          flex={1}
          justifyContent="center"
          paddingHorizontal={{ phone: "m", tablet: "xl" }}
          paddingVertical={{ phone: "m", tablet: "xl" }}
        >
          {id && isLastChild && (
            <Pathway
              id={id}
              scoreId={isLastChild.SCORE_UUID}
              guidelineId={guidelineId}
            />
          )}
          {tree &&
            !id &&
            tree.map((chapter: ChapterType) => (
              <ChapterButton
                key={chapter.UUID}
                chapter={chapter}
                color="stepbystep"
                screen="StepByStep"
              />
            ))}
          {chapterWithChildren &&
            chapterWithChildren?.CHILDREN?.map((chapter: ChapterType) => (
              <ChapterButton
                key={chapter.UUID}
                chapter={chapter}
                color="stepbystep"
                screen="StepByStep"
              />
            ))}
          {chapterWithChildren &&
            chapterWithChildren?.PATHWAYS?.map((pathway: ChapterType) => (
              <ChapterButton
                key={pathway.UUID}
                chapter={pathway}
                color="stepbystep"
                screen="StepByStep"
                pathway={true}
              />
            ))}
        </Box>
      </Container>
    </>
  );
};

export default StepByStep;
