import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import axios from "axios";
import { AREA, MET, URL, SECTOR, LANGUAGE } from "./config";
import { AreaType } from "../../types";
import { useEffect } from "react";
import { writeToStorage } from "../../utils";

export const getAreaById = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: AREA,
    get: context.queryKey[1]
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export const getAreas = async (_: any) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: AREA,
    attributes: "UUID,ICON,SHORT_NAME,NAME"
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export default function useArea(areaId: string) {
  return useQuery<AreaType>(["area", areaId], getAreaById, {
    onSuccess: (data) => {
      writeToStorage(["area", areaId], data);
    }
  });
}

export function useAreas(options: QueryObserverOptions<AreaType[]>) {
  return useQuery<AreaType[]>(["areas"], getAreas, {
    onSuccess: (data) => {
      writeToStorage(["areas"], data);
    }
  });
}
