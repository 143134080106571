import { StackHeaderProps } from "@react-navigation/stack";
import { TextProps } from "@shopify/restyle";
import React from "react";
import { Platform, View } from "react-native";
import { BorderlessButton } from "react-native-gesture-handler";
import { Text, Box, useTheme, Theme } from "../../components";
import { useIsLargeScreen } from "../../hooks";
import { DEBUGISON, LAYOUTDEBUG, ORIGINDEBUG } from "../../config";

interface HeaderTitleProps extends StackHeaderProps, TextProps<Theme> {
  onPress?: () => void;
}

const HeaderTitle = ({
  children,
  onLayout,
  allowFontScaling,
  tintColor,
  style,
  onPress,
  ...rest
}: HeaderTitleProps) => {
  const theme = useTheme();
  const isWeb = Platform.OS === "web";
  const isIOS = Platform.OS === "ios";
  const isLargeScreen = useIsLargeScreen();
  return (
    <>
      {!isLargeScreen ? (
        <Box style={{ marginRight: theme.spacing.m }} width="80%">
          <BorderlessButton
            enabled={onPress ? true : false}
            onPress={() => {
              onPress && onPress();
            }}
          >
            <Text
              numberOfLines={2}
              color="mainForeground"
              variant="navigationTitle"
              fontSize={isWeb ? 18 : 15}
              lineHeight={isWeb ? 22 : 18}
              style={style}
              {...rest}
            >
              {DEBUGISON && ORIGINDEBUG && "headertitle"}
              {children}
            </Text>
          </BorderlessButton>
        </Box>
      ) : null}
    </>
  );
};

export default HeaderTitle;
