import React, { useEffect, useRef, useState } from "react";
import { Modalize } from "react-native-modalize";
import { Box, Text, useTheme } from "../Theme";
import RoundedIconButton from "../RoundedIconButton";
import Icon from "../Icon";
import BorderlessTap from "../BorderlessTap";
import { useIsFocused } from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch } from "react-redux";
import { useSelector } from "../../redux";
import { togglePopup } from "../../redux/global";
import t from "../../../Texts/TextImport";

const Popup = () => {
	const theme = useTheme();
	const isFocused = useIsFocused();
	const modalizeRef = useRef<Modalize>(null);
	const dispatch = useDispatch();
	const reduxPopup = useSelector((state) => state.global.popup);
	const insets = useSafeAreaInsets();

	useEffect(() => {
		if (reduxPopup && reduxPopup.isVisible && isFocused)
			modalizeRef.current?.open();
		if (reduxPopup && !reduxPopup.isVisible) {
			onClose();
		}
	}, [reduxPopup?.isVisible]);

	const onClose = () => {
		modalizeRef.current?.close();
	};

	return (
		<Modalize
			ref={modalizeRef}
			modalTopOffset={150}
			adjustToContentHeight={true}
			//disableScrollIfPossible={true}
			tapGestureEnabled={false}
			rootStyle={rootStyle}
			overlayStyle={overlayStyle}
			handleStyle={{ backgroundColor: theme.colors.popupBackground }}
			onClosed={() =>
				dispatch(
					togglePopup({
						isVisible: false,
					})
				)
			}
			keyboardAvoidingOffset={70}
			scrollViewProps={{
				keyboardShouldPersistTaps: "handled",
				style: {
					backgroundColor: theme.colors.popupBackground,
				},
			}}
			HeaderComponent={
				<Box padding="m" paddingBottom="s" backgroundColor="popupBackground">
					<Box
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Box flexDirection="row" alignItems="center">
							<Text variant="popupTitle" paddingRight="s">
								{reduxPopup?.title}
							</Text>
							{reduxPopup?.header}
						</Box>
						<RoundedIconButton
							name="cross"
							size={28}
							onPress={onClose}
							color="mainForeground"
						/>
					</Box>
					{reduxPopup?.description ? (
						<Text>{reduxPopup.description}</Text>
					) : null}
				</Box>
			}
		>
			<Box backgroundColor="popupBackground">
				{reduxPopup?.context}
				{reduxPopup?.content}
			</Box>
			<Box height={insets.bottom} backgroundColor="mainForeground" />
		</Modalize>
	);
};

interface PopupFooterProps {
	singleButton?: boolean;
	successText?: string;
	onClose: () => void;
	onSuccess: () => void;
}
const PopupFooter = ({
	singleButton,
	successText,
	onClose,
	onSuccess,
}: PopupFooterProps) => {
	if (singleButton) {
		return (
			<Box alignItems="center" marginBottom="m">
				<BorderlessTap onPress={onClose}>
					<Box flexDirection="row" alignItems="center">
						<Icon color="mainForeground" size={16} name="cross" />
						<Text paddingLeft="xs">{t("PClose")}</Text>
					</Box>
				</BorderlessTap>
			</Box>
		);
	} else {
		return (
			<Box
				flexDirection="row"
				justifyContent="space-evenly"
				alignItems="center"
				marginBottom="m"
			>
				<BorderlessTap onPress={onClose}>
					<Box flexDirection="row" alignItems="center">
						<Icon color="danger" size={16} name="cross" />
						<Text paddingLeft="xs">{t("PCancel")}</Text>
					</Box>
				</BorderlessTap>
				<BorderlessTap onPress={onSuccess}>
					<Box flexDirection="row" alignItems="center">
						<Icon color="success" size={16} name="circledMark" />
						<Text paddingLeft="xs">{successText}</Text>
					</Box>
				</BorderlessTap>
			</Box>
		);
	}
};

PopupFooter.defaultProps = {
	singleButton: true,
};

const modalStyle = {
	borderTopLeftRadius: 0,
	borderTopRightRadius: 0,
	maxWidth: 800,
};

const rootStyle = {
	// @ts-expect-error
	position: "absolute",
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	zIndex: 9998,
	maxWidth: 800,
	//paddingLeft: 40,
	//paddingRight: 40,
};

const overlayStyle = {
	// @ts-expect-error
	position: "absolute",
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	backgroundColor: "rgba(255, 255, 255, 0.6)",
};

export default Popup;
