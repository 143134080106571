import React, { useEffect } from "react";
import t from "../../../Texts/TextImport";
import { useAnalytics } from "../../hooks";
import { getContent, getTitle } from "../../hooks/useLanguage";
import { ModeType, ScoreType } from "../../types";
import HTML from "../HTML";
import Icon from "../Icon";
import Score from "../Score";
import { Box, Text } from "../Theme";
import PopupFooter from "./PopupFooter";
import { DEBUGISON, SCOREDEBUG } from "../../config";

interface PopupScoreContentProps {
	mode: ModeType;
	data: ScoreType;
}
const PopupScoreContent = ({ mode, data }: PopupScoreContentProps) => {
	DEBUGISON && SCOREDEBUG && console.log("Data : " + JSON.stringify(data));

	// useEffect(() => {
	// 	data.UUID &&
	// useAnalytics({ term: getTitle(data), id: data.UUID, screen: "score" });
	// }, [data]);

	return (
		<>
			<Box
				margin="m"
				marginTop="s"
				padding="s"
				backgroundColor="mainBackground"
			>
				<Box flexDirection="row" alignItems="center" paddingBottom="s">
					<Icon name="circledHeart" size={18} color="mainForeground" />
					<Text paddingLeft="xs" lineHeight={15} variant="description">
						{t("PRCSupport")}
					</Text>
				</Box>

				<HTML mode={mode} html={getContent(data)} />
				<Box paddingTop="m">
					<Score data={data} />
				</Box>
			</Box>

			<PopupFooter />
		</>
	);
};

export default PopupScoreContent;
