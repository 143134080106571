import React from "react";
import Icon from "./Icon";

import { Theme, Box } from "./Theme";
import { Icons } from "../styles/icons";
import { TextProps } from "@shopify/restyle";

export interface RoundedIconProps extends TextProps<Theme> {
  name: keyof typeof Icons;
  size: number;
  color: keyof Theme["colors"];
  backgroundColor?: keyof Theme["colors"];
  iconRatio: number;
  align: "center" | "flex-start" | "flex-end";
}

const RoundedIcon = ({
  name,
  size,
  color,
  backgroundColor,
  iconRatio,
  align,
  ...props
}: RoundedIconProps) => {
  const iconSize = size * iconRatio;
  return (
    <Box
      height={size}
      width={size}
      justifyContent='center'
      alignItems={align}
      style={{ borderRadius: size / 2 }}
      backgroundColor={backgroundColor}
      {...props}
    >
      <Icon size={iconSize} {...{ name, color }} />
    </Box>
  );
};

RoundedIcon.defaultProps = {
  iconRatio: 0.7,
  align: "center",
  backgroundColor: undefined,
  color: "mainForeground",
};

export default RoundedIcon;
