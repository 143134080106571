import { DEBUGISON, NAVDEBUGGING, UTILDEBUGGING } from "../config";
import { ChapterType, PathwayType } from "../types";
import { compareValues } from "./helpers";

export const createChapterTree = (chapters: ChapterType[]) => {
  const tree: ChapterType[] = [];
  const childrenOf: any = {};
  chapters?.forEach((item) => {
    const { UUID: id, PARENT_CONTENT_UUID: parentId = "" } = item;
    childrenOf[id] = childrenOf[id] || [];
    item.CHILDREN = childrenOf[id];
    if (parentId) {
      (childrenOf[parentId] = childrenOf[parentId] || []).push(item);
      childrenOf[parentId] = childrenOf[parentId].sort(compareValues("ORDER")); // not the fastest method
    } else {
      tree.push(item);
    }
  });
  return tree;
};

export const filterInfoTree = (chapters: ChapterType[]) => {
  return chapters.filter((chapter) => {
    if (chapter.CHILDREN && chapter.CHILDREN.length > 0) {
      chapter.CHILDREN = filterInfoTree(chapter.CHILDREN);
      if (chapter.CHILDREN.length > 0) {
        return chapter;
      }
    } else {
      if (chapter.MAIN_DATA !== true) {
        return chapter;
      }
    }
  });
};

export const filterChapterTree = (chapters: ChapterType[]) => {
  return chapters.filter((chapter) => {
    if (chapter.CHILDREN && chapter.CHILDREN.length > 0) {
      chapter.CHILDREN = filterChapterTree(chapter.CHILDREN);
      if (chapter.CHILDREN.length > 0) {
        return chapter;
      }
    } else {
      if (chapter.MAIN_DATA === true) {
        return chapter;
      }
    }
  });
};

export const filterRecommendationsTree = (chapters: ChapterType[]) => {
  DEBUGISON &&
    NAVDEBUGGING &&
    console.log(
      "filter RecommendationsTree: " +
        JSON.stringify(chapters)?.substring(0, 200)
    );
  return chapters.filter((chapter) => {
    if (chapter.RECOMMENDATIONS.length > 0) {
      chapter.CHILDREN = [];
      return chapter;
    }
    if (
      chapter?.RECOMMENDATIONS?.length == 0 &&
      chapter.CHILDREN &&
      chapter.CHILDREN.length > 0
    ) {
      chapter.CHILDREN = filterRecommendationsTree(chapter.CHILDREN);
      if (
        chapter.CHILDREN.length > 0 &&
        chapter?.RECOMMENDATIONS?.length == 0
      ) {
        return chapter;
      }
    }
  });
};

// export const filterRecommendationsTree = (chapters: ChapterType[]) => {
//   return chapters.filter((chapter) => {
//     if (chapter.RECOMMENDATIONS.length > 0) {
//       chapter.CHILDREN = [];
//       return chapter;
//     }
//     if (chapter.CHILDREN && chapter.CHILDREN.length > 0) {
//       chapter.CHILDREN = filterRecommendationsTree(chapter.CHILDREN);
//       if (chapter.CHILDREN.length > 0) {
//         return chapter;
//       }
//     }
//   });
// };

export const addPathwayToChapter = (
  chapters: ChapterType[],
  pathways: PathwayType[]
) => {
  const newChapters = chapters.map((chapter) => {
    chapter.PATHWAYS = [];
    pathways.forEach((pathway) => {
      if (pathway.CONTENT_UUID === chapter.UUID) {
        chapter.PATHWAYS?.push(pathway);
      }
    });
    return chapter;
  });
  return newChapters;
};

export const filterStepByStepTree = (chapters: ChapterType[]) => {
  return chapters.filter((chapter) => {
    if (chapter.PATHWAYS && chapter.PATHWAYS?.length > 0) {
      chapter.CHILDREN = [];
      return chapter;
    }
    if (chapter.CHILDREN && chapter.CHILDREN.length > 0) {
      chapter.CHILDREN = filterStepByStepTree(chapter.CHILDREN);
      if (chapter.CHILDREN.length > 0) {
        return chapter;
      }
    }
  });
};

export const findFirstChapter = (
  chapters: ChapterType[]
): ChapterType | undefined => {
  if (chapters.length > 0) {
    if (chapters[0].CHILDREN && chapters[0].CHILDREN.length > 0) {
      return findFirstChapter(chapters[0].CHILDREN);
    } else {
      return chapters[0];
    }
  }
  return;
};

export const findChapterById = (
  tree: ChapterType[] | undefined,
  chapterId: string
): ChapterType | undefined => {
  const path = findPathById(chapterId, tree);
  let chapters = tree;
  let chapter = undefined;
  let i = 0;
  while (path && chapters && i < path.length) {
    chapter = chapters[path[i]];
    chapters = chapters[path[i]]?.CHILDREN;
    i += 2;
  }
  return chapter;
};

export const findChaptersById = (
  tree: ChapterType[] | undefined,
  chapterId: string
): ChapterType[] => {
  const path = findPathById(chapterId, tree);
  let chapters = tree;
  const chapterPath = [];
  let i = 0;
  while (path && chapters && i < path.length) {
    chapterPath.push(chapters[path[i]]);
    chapters = chapters[path[i]] && chapters[path[i]].CHILDREN;
    i += 2;
  }
  return chapterPath;
};

export const pathToChapter = (chapters: ChapterType[], chapterId: string) => {
  let breadCrump = "";
  let currentChapter = chapters.find((i) => i.UUID == chapterId);
  while (currentChapter) {
    currentChapter = chapters.find(
      (i) => i.UUID === currentChapter?.PARENT_CONTENT_UUID
    );
    breadCrump = currentChapter
      ? `${currentChapter.TITLE}/${breadCrump}`
      : breadCrump;
  }
  return breadCrump;
};

export const findPathById = (a: any, obj: any): [] | undefined => {
  DEBUGISON && UTILDEBUGGING && console.log(a, obj);
  function iter(o: any, p: any): any {
    return Object.keys(o).some(function (k) {
      result = p.concat(Array.isArray(o) ? +k : k);
      return (
        o[k] === a || (o[k] && typeof o[k] === "object" && iter(o[k], result))
      );
    });
  }
  let result;
  return (iter(obj, []) && result) || undefined;
};

export function visualizeRecommendations(tree: ChapterType[]): void {
  function generateNestedMessage(chapter: ChapterType, depth: number): string {
    const recommendationsCount = chapter.RECOMMENDATIONS.length;
    const childrenCount = chapter.CHILDREN ? chapter.CHILDREN.length : 0;
    let message = `${" ".repeat(depth * 2)}Title: ${
      chapter.TITLE
    }, Recommendations: ${recommendationsCount}, Children: ${childrenCount}\n`;

    if (chapter.CHILDREN) {
      for (const child of chapter.CHILDREN) {
        message += generateNestedMessage(child, depth + 1);
      }
    }

    return message;
  }

  const finalMessage = generateNestedMessage(
    { TITLE: "Root", RECOMMENDATIONS: [], CHILDREN: tree },
    0
  );
  DEBUGISON && NAVDEBUGGING && console.log(finalMessage);
}
