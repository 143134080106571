import {
	TOGGLE_POPUP,
	globalState,
	GlobalActionTypes,
	PUSH_HISTORY,
	POP_HISTORY,
	RESET_HISTORY,
	TOGGLE_LAUNCH,
	TOGGLE_AUTO_UPDATE,
} from "./types";

const initialState: globalState = {
	popup: {
		isVisible: false,
	},
	history: [],
	hasLaunched: false,
	autoUpdate: false,
	appId: "",
};

export default (state = initialState, action: GlobalActionTypes) => {
	switch (action.type) {
		case TOGGLE_POPUP: {
			return {
				...state,
				popup: {
					...action.payload,
				},
			};
		}
		case PUSH_HISTORY: {
			const index = state?.history?.findIndex((i) => {
				return JSON.stringify(i) === JSON.stringify(action.payload);
			});
			const history =
				index !== -1
					? state.history.slice(0, index + 1)
					: [...state?.history, action.payload]; // eslint-disable-line
			return {
				...state,
				history,
			};
		}
		case POP_HISTORY: {
			const history = state.history;
			history.pop();
			return {
				...state,
				history,
			};
		}
		case RESET_HISTORY: {
			return {
				...state,
				history: [],
			};
		}
		case TOGGLE_LAUNCH: {
			return {
				...state,
				hasLaunched: !state.hasLaunched,
			};
		}
		case TOGGLE_AUTO_UPDATE: {
			return {
				...state,
				autoUpdate: !state.autoUpdate,
			};
		}
		default: {
			return state;
		}
	}
};
