import { useDrawerStatus } from "@react-navigation/drawer";
import { useIsFocused, DrawerActions } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import { Platform, ScrollView } from "react-native";
import {
  BackButton,
  Box,
  Container,
  Text,
  BreadCrumbs,
} from "../../../components";
import HTML from "../../../components/HTML";
import { MobileFooter } from "../../../components/Footer";
import { GuidelineNavigationProps } from "../../../components/Navigation";
import { useAnalytics } from "../../../hooks";
import {
  createChapterTree,
  filterChapterTree,
  findChapterById,
} from "../../../utils";
import ChapterButton from "../ChapterButton";
import TabBar from "../TabBar";
import RecommendationIcon from "./RecommendationIcon";
import { useDispatch } from "react-redux";
import { pushHistory, togglePopup } from "../../../redux/global";
import { useSelector } from "../../../redux";
import { StatusBar } from "expo-status-bar";
import {
  BACKENDTRACING,
  DEBUGISON,
  HOOKSDEBUGGING,
  ORIGINDEBUG,
  isTextSelectable,
} from "../../../config";
import { getContent, getTitle } from "../../../hooks/useLanguage";
import { manipulateHTMLENV } from "../../../components/HTML/HTMLRendering/HTMLPreprocessing";
import useChapter, { useAllChapters } from "../../../hooks/queries/useChapter";
import useGuideline, {
  useForwardIfNotUnlocked,
} from "../../../hooks/queries/useGuideline";
import { useExecuteDownload } from "../../../hooks/queries/useInitialize";

const isWeb = Platform.OS === "web";
const Fulltext = ({
  navigation,
  route,
}: GuidelineNavigationProps<"FullText">) => {
  useAnalytics({});
  isWeb && useForwardIfNotUnlocked(route?.params?.guidelineId, navigation);
  const scrollRef = useRef<ScrollView>(null);
  const dispatch = useDispatch();
  const reduxPopup = useSelector((state) => state.global.popup);
  const id = route.params?.id;
  const guidelineId = route.params?.guidelineId;
  const term = route.params?.term;
  const AllChapters = useAllChapters(guidelineId);
  const Guideline = useGuideline(guidelineId);
  const Chapter = useChapter(id, guidelineId);
  useExecuteDownload(
    guidelineId,
    () => {
      console.log("download worked");
    },
    () => {}
  );

  const tree =
    AllChapters.data && filterChapterTree(createChapterTree(AllChapters.data));
  const chapterWithChildren = tree && id && findChapterById(tree, id);

  const content =
    term && Chapter.data
      ? getContent(Chapter.data).replace(regExp, `<term>${term}</term>`)
      : getContent(Chapter.data);

  DEBUGISON &&
    HOOKSDEBUGGING &&
    console.log(
      "Chapterdata in Fulltext" +
        JSON.stringify(Chapter.data)?.substring(0, 100)
    );

  navigation.setOptions({ title: getTitle(Guideline.data) });

  const regExp = new RegExp(term || "", "gi");
  const drawerStat = useDrawerStatus();

  useEffect(() => {
    manipulateHTMLENV(id, guidelineId);
    if (
      drawerStat == "open" &&
      navigation.dangerouslyGetState().routes.length > 1
    ) {
      navigation.dispatch(DrawerActions.closeDrawer());
    }
    dispatch(pushHistory({ id, guidelineId, mode: "fulltext", term }));
    if (reduxPopup.isVisible) {
      dispatch(togglePopup({ isVisible: false }));
    }
    if (scrollRef?.current && scrollRef?.current?.scrollTo) {
      scrollRef?.current?.scrollTo({ y: 0, animated: false });
    }
  }, [id, guidelineId]);

  return (
    <>
      <StatusBar style="light" />

      <Container
        scrollRef={scrollRef}
        tabBar={<TabBar />}
        backButton={<BackButton />}
        customMobileFooter={<MobileFooter />}
        breadCrumb={
          tree ? (
            <BreadCrumbs tree={tree} chapterId={id} screen="FullText" />
          ) : null
        }
        mode="fulltext"
      >
        <>
          <Box
            paddingHorizontal={{ phone: "m", tablet: "xl" }}
            nativeID={"DEBUGINFO chapter.data:" + JSON.stringify(Chapter.data)}
          >
            {DEBUGISON && HOOKSDEBUGGING && (
              <Text selectable={isTextSelectable}>
                {route.params?.guidelineId} and {route.params?.id}
              </Text>
            )}
            {DEBUGISON && BACKENDTRACING && (
              <Text selectable={isTextSelectable}>{"content:" + content}</Text>
            )}
            <Box flexDirection="row" paddingBottom="m">
              {getTitle(Chapter.data) && getTitle(Chapter.data) != "null" && (
                <Box flex={1}>
                  <Text selectable={isTextSelectable} variant="title1">
                    {getTitle(Chapter.data)}
                  </Text>
                </Box>
              )}
              {Chapter.data?.RECOMMENDATIONS &&
              Chapter.data?.RECOMMENDATIONS.length > 0 ? (
                <RecommendationIcon
                  onPress={() =>
                    navigation.push("Recommendations", {
                      id,
                      guidelineId,
                    })
                  }
                />
              ) : null}
            </Box>
            {DEBUGISON && ORIGINDEBUG && (
              <Text
                selectable={isTextSelectable}
                paddingBottom={"m"}
                variant={"subtitle2"}
              >
                HTML
              </Text>
            )}
            {content != "null" && (
              <HTML
                html={'<p style="user-select: none;">' + content + "</p>"}
                mode={"fulltext"}
              />
            )}
          </Box>
          <Box
            flex={1}
            justifyContent="center"
            paddingHorizontal={{ phone: "none", tablet: "xl" }}
          >
            {DEBUGISON && ORIGINDEBUG && (
              <Text
                selectable={isTextSelectable}
                paddingBottom={"m"}
                variant={"subtitle2"}
              >
                TREE:
                {JSON.stringify(tree)?.substring(0, 1000)}
              </Text>
            )}

            {DEBUGISON && ORIGINDEBUG && (
              <Text
                selectable={isTextSelectable}
                paddingBottom={"m"}
                variant={"subtitle2"}
              >
                CONTENT:
                {JSON.stringify(content)?.substring(0, 1000)}
              </Text>
            )}
            {tree &&
              !id &&
              tree.map((c) => (
                <ChapterButton
                  chapter={c}
                  key={c.UUID}
                  color="fulltext"
                  screen="FullText"
                />
              ))}
            {chapterWithChildren &&
              chapterWithChildren.CHILDREN?.map((c) => (
                <>
                  {DEBUGISON && ORIGINDEBUG && (
                    <Text
                      selectable={isTextSelectable}
                      paddingBottom={"m"}
                      variant={"subtitle2"}
                    >
                      Chapter with children
                    </Text>
                  )}
                  <ChapterButton
                    key={c.UUID}
                    chapter={c}
                    color="fulltext"
                    screen="FullText"
                  />
                </>
              ))}
          </Box>
        </>
      </Container>
    </>
  );
};

export default Fulltext;
