import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  StyleSheet,
  Platform,
  Pressable,
} from "react-native";
import { Box, Text, useTheme } from "./Theme";
import LinkBox from "./LinkBox";
import RoundedIconButton from "./RoundedIconButton";
import { GuidelineType } from "../types/guideline";
import {
  useUserMutation,
  useAuth,
  useIsLargeScreen,
  useInitialize,
  useUser,
} from "../hooks";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import Date from "./Date";
import Svg, { Defs, Line, Pattern, Rect } from "react-native-svg";
import { useDispatch } from "react-redux";
import { addLocalGuidelines } from "../redux/guidelines";
import { setLocalFavoriteGuideline } from "../redux/user/actions";
import { ErrorAlert } from "./Alert";
import { RectButton } from "react-native-gesture-handler";
import moment from "moment";
import {
  APPBRANCH,
  AutoDownload,
  DEBUGISON,
  HOOKSDEBUGGING,
  NAVDEBUGGING,
  ORIGINDEBUG,
  isInDevelopment,
} from "../config";
import { getTitle } from "../hooks/useLanguage";
import {
  getCacheSideHash,
  useExecuteDownload,
  useIsGuidelineDownloaded,
} from "../hooks/queries/useInitialize";
import { useIsGuidelineUnlocked } from "../hooks/queries/useGuideline";
import DevText from "../utils/DevText";
import ModeSelection from "../screens/Guideline/ModeSelection";
import { isNewWebImplementationEnabled } from "react-native-gesture-handler/lib/typescript/EnableNewWebImplementation";

const isWeb = Platform.OS === "web";
interface GuidelineCardProps extends GuidelineType {
  isFavorite: boolean;
}
const GuidelineCard = ({
  UUID,
  CLASSIFICATION,
  AUTHOR,
  FIRST_AUTHOR,
  SOCIETY: { TITLE: SOCIETY_TITLE },
  AREA: { ICON } = "ICONNOTFOUND",
  EXPIRATION_DATE,
  BASIC_ENTRY_POINTS,
  isFavorite,
  GUIDELINE_EXPORT,
  ...Guideline
}: GuidelineCardProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { username } = useAuth();
  const { data: user } = useUser(username);
  const [download, setDownload] = useState(false);
  const queryClient = useQueryClient();
  const guidelineHash = getCacheSideHash(UUID);
  const isDownloaded = isWeb ? true : useIsGuidelineDownloaded(UUID);
  if (
    isDownloaded &&
    guidelineHash &&
    guidelineHash !== GUIDELINE_EXPORT[0].HASH_KEY
  ) {
    queryClient.invalidateQueries(["guideline", UUID]);
    queryClient.invalidateQueries(["pathways", UUID]);
    queryClient.invalidateQueries(["export", UUID]);
    queryClient.invalidateQueries(["allChapter", UUID]);
    setDownload(true);
  }
  const isUnlocked = useIsGuidelineUnlocked(UUID);
  const { mutate } = useUserMutation({
    onSuccess: (newUser) => {
      queryClient.setQueryData(["user", username], () => newUser); // USER ID
    },
    onError: () => {
      dispatch(setLocalFavoriteGuideline(UUID));
    },
  });
  const isValid = moment(EXPIRATION_DATE) > moment();

  const buildLink = (): string => {
    let link = "/";
    if (!isUnlocked && !isWeb) link = link + "Guideline/WantToUnlock";
    if (!isUnlocked && isWeb) link = link + "Guideline/EnterQR";
    else if (isUnlocked && isWeb) link = link + "Guideline/FullText";
    else if (isUnlocked && !isWeb) link = link + "Guideline/ModeSelection";
    if (isWeb) link = "/AppStack/" + link;
    return link + `?guidelineId=${UUID}`;
  };

  const modeselection = (): string => {
    let link = "/";
    link = link + "Guideline/ModeSelection";
    if (isWeb) link = "/AppStack/" + link;
    return link + `?guidelineId=${UUID}`;
  };

  const link = buildLink();

  return (
    <Box marginBottom="m">
      <LinkBox showLoading={false} to={link}>
        <Box
          borderLeftWidth={6}
          borderBottomWidth={1}
          flexDirection="row"
          borderLeftColor="greyLight"
          borderBottomColor="smoke"
          paddingTop="xs"
        >
          <Box width={50} alignItems="center">
            <Text variant="symbols" fontSize={20}>
              {ICON
                ? String.fromCharCode(parseInt(ICON, 16))
                : String.fromCharCode(parseInt("E16", 16))}
            </Text>
          </Box>
          <Box flex={1} paddingTop="xs" paddingBottom="m" paddingRight="xl">
            <DevText conditions={NAVDEBUGGING}>
              {link}
              {isUnlocked}
            </DevText>

            {isInDevelopment && isWeb && (
              <LinkBox to={modeselection()}>
                <Text variant="title3">ModeSelection</Text>
              </LinkBox>
            )}

            <Text
              paddingBottom="xs"
              paddingRight="m"
              variant="tiny"
              style={{ textTransform: "uppercase" }}
            >
              {SOCIETY_TITLE}
            </Text>
            <Box>
              <Text paddingBottom="xs">{getTitle(Guideline)}</Text>
              <Text variant="description" color="information" lineHeight={20}>
                {FIRST_AUTHOR?.NAME
                  ? `| ${FIRST_AUTHOR?.NAME} ${FIRST_AUTHOR?.NAME_LAST} | ${CLASSIFICATION} | `
                  : `| ${AUTHOR}| ${CLASSIFICATION} |`}
                <Date
                  date={EXPIRATION_DATE}
                  variant="description"
                  lineHeight={20}
                  color="information"
                />
                {` | `}
              </Text>

              {!isValid && (
                <>
                  <Text
                    variant="description"
                    fontSize={14}
                    lineHeight={20}
                    color="danger"
                  >
                    {getTitle(Guideline).includes("Überarbeitung")
                      ? `IN ÜBERARBEITUNG`
                      : APPBRANCH === "ERKNET"
                      ? ""
                      : `ABGELAUFEN`}

                    {ORIGINDEBUG && DEBUGISON && "GuidelineCard"}
                  </Text>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </LinkBox>
      {!isDownloaded && (APPBRANCH !== "CARE" || isUnlocked) && (
        <RectButton
          style={StyleSheet.absoluteFill}
          onPress={() => setDownload(true)}
        />
      )}
      <Box
        position="absolute"
        right={10}
        top={theme.spacing.xs}
        height={30}
        width={30}
        backgroundColor={undefined}
      >
        {APPBRANCH === "CARE" && !isUnlocked && (
          <RoundedIconButton
            name={"circledCross"}
            color={!isDownloaded ? "danger" : "primary"}
            size={30}
            onPress={() => {}}
          />
        )}

        {APPBRANCH !== "CARE" || (APPBRANCH === "CARE" && isUnlocked) ? (
          !download ? (
            <RoundedIconButton
              name={
                !isDownloaded ? "download" : isFavorite ? "starFilled" : "star"
              }
              color={!isDownloaded ? "danger" : "primary"}
              size={30}
              onPress={() => {
                if (isDownloaded) {
                  mutate({
                    ID: username,
                    FAVORITE_GUIDELINE_UUID:
                      user?.FAVORITE_GUIDELINE_UUID !== UUID ? UUID : "",
                  });
                } else {
                  setDownload(true);
                }
              }}
            />
          ) : (
            <Downloader
              guidelineId={UUID}
              onEndDownload={() => setDownload(false)}
            />
          )
        ) : null}
      </Box>
    </Box>
  );
};

interface DownloaderProps {
  guidelineId: string;
  onEndDownload: () => void;
}
const Downloader = ({ guidelineId, onEndDownload }: DownloaderProps) => {
  useExecuteDownload(
    guidelineId,

    () => {
      setTimeout(() => {
        onEndDownload();
      }, 3000);
    },
    () => {
      ErrorAlert();
    },
    { enabled: true }
  );

  return <ActivityIndicator color={"#999999"} />;
};

export default GuidelineCard;
