import React from "react";
import { TabBarButtonProps, Box, TabBarButton } from "../../components";
import t from "../../../Texts/TextImport";

const TabBar = () => {
	const tabs: TabBarButtonProps[] = [
		{
			name: t("BookmarkThis"),
			to: "/Bookmarks",
			mode: "default",
		},
		{
			name: t("GenSmallNote"),
			to: "/Notices",
			mode: "default",
		},
	];

	return (
		<Box flexDirection="row">
			{tabs.map((tab, index) => {
				const isLast = tabs.length - 1 === index;

				return (
					<TabBarButton
						key={tab.to}
						{...tab}
						marginRight={!isLast ? "l" : "none"}
						//flexGrow={1}
					/>
				);
			})}
		</Box>
	);
};

export default TabBar;
