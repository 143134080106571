import React, { useRef } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { useHover } from "react-native-web-hooks";
import { Box, Text, useTheme, Theme } from "./Theme";
import Icon from "./Icon";
import LinkBox from "./LinkBox";
import { BoxProps } from "@shopify/restyle";
import { ModeType } from "../types";
import { DEBUGISON } from "../config";
export interface TabBarButtonProps extends BoxProps<Theme> {
  withIcon?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  name: string;
  mode: ModeType;
  to: string;
  screen?: string;
}

const TabBarButton = ({
  withIcon,
  isDisabled,
  name,
  mode,
  to,
  isActive,
  ...props
}: TabBarButtonProps) => {
  const theme = useTheme();
  const ref = useRef();
  const isHovered = useHover(ref);
  return (
    <Box {...props}>
      <LinkBox to={to} withHover={false} {...{ isDisabled }}>
        <Box
          ref={ref}
          paddingVertical='s'
          borderBottomWidth={theme.spacing.xs}
          borderBottomColor={mode}
          opacity={isActive ? 1 : isHovered ? 1 : 0.7}
          flexDirection={{ phone: "column", tablet: "row" }}
          alignItems='center'
          justifyContent='center'
        >
          {withIcon && <Icon name={mode} size={24} color={mode} />}
          <Text
            color={isActive || isHovered ? mode : "mainForeground"}
            variant='button'
            paddingLeft={withIcon ? "s" : "none"}
          >
            {DEBUGISON && " LinkBox"}
            {name}
          </Text>
        </Box>
      </LinkBox>
    </Box>
  );
};

TabBarButton.defaultProps = {
  withIcon: false,
  isDisabled: false,
};

export default TabBarButton;
