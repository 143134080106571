import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { ABOUT, MET, URL, SECTOR, LANGUAGE } from "./config";
import { LANGUAGE_SUFFIX } from "../useLanguage";

import { AboutType } from "../../types";
import { useEffect } from "react";
import { writeToStorage } from "../../utils";
import { Platform } from "react-native";
import { some } from "cypress/types/lodash";
import { createURLWithParams } from "../../utils/helpers";
import { HOOKSDEBUGGING, PLATFORM } from "../../config";
import { devLog } from "../../utils/logging";

const isWeb = Platform.OS === "web";

export const getAboutById = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    Sector: SECTOR,
    entity: ABOUT,
    get: context.queryKey[1],
    brew: `CONTENT`,
  };
  console.log(params);
  const { data } = await axios.get(URL, { params });
  return data;
};

export const getAllAbout = async (_: any) => {
  const params = {
    met: MET,
    Sector: SECTOR,
    brew: `CONTENT`,
    brewGuideline: `PLATZHALTER`,
    entity: ABOUT,
  };
  devLog(createURLWithParams(params), HOOKSDEBUGGING);
  const { data } = await axios.get(URL, { params });
  const filtered: AboutType[] = [];
  data.forEach((about: AboutType) => {
    console.log(Platform.OS);
    if (
      (about?.PLATFORM?.toLowerCase() === PLATFORM || about?.PLATFORM === "") &&
      about?.SECTOR.toLowerCase() === SECTOR.toLowerCase()
    ) {
      filtered.push(about);
    }
  });

  return filtered;
};

export default function useAbout(
  aboutId: string,
  options?: QueryObserverOptions<AboutType>
) {
  return useQuery<AboutType>(["about", aboutId], getAboutById, {
    ...options,
  });
}

export function useAllAbout(options?: QueryObserverOptions<AboutType[]>) {
  return useQuery<AboutType[]>(["allAbout"], getAllAbout, {
    keepPreviousData: false,
    ...options,
  });
}
