import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { MET, URL, GLOSSARY, CHAPTER, SECTOR, LANGUAGE } from "./config";
import { ChapterType } from "../../types";
import { GuidelineType } from "../../types/guideline";
import { LANGUAGE_SUFFIX } from "../useLanguage";
import { DEBUGISON, HOOKSDEBUGGING, SEARCHDEBUG } from "../../config";
import { devLog } from "../../utils/logging";
import constructURL from "./utils";

const getSuggestion = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: GLOSSARY,
    query: `(TITLE${LANGUAGE_SUFFIX}="${context.queryKey[1]}@" and (STATUS=FREIGABE or STATUS=VERÖFFENTLICHT))`,
    distinct: `TITLE${LANGUAGE_SUFFIX}`,
  };
  // devLog("getSuggestion " + params && constructURL(params));
  const { data } = await axios.get(URL, { params });
  return data;
};

const getSeachResults = async (context: QueryFunctionContext) => {
  //@ts-expect-error
  const guidelinesString = context.queryKey[2]
    //@ts-expect-error
    .map((i) => `GUIDELINE_UUID=${i.UUID}`)
    .join(" or ");
  //@ts-expect-error
  const guidelinesComma = context.queryKey[2].map((i) => i.UUID).join();
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: CHAPTER,
    query: `(CONTENT="@${context.queryKey[1]}@" and 
      STATUS=VERÖFFENTLICHT and 
      PUBLISHED_OLD=false and 
      MAIN_DATA=true and (${guidelinesString}))`,
    attributes: `*,GUIDELINE.TITLE${LANGUAGE_SUFFIX}`,
    slice: context.queryKey[3],
    brewGuideline: `${guidelinesComma}`,
  };
  devLog("getSearchResults: " + constructURL(URL, params));
  const { data } = await axios.get(URL, { params });

  return data;
};

const getSeachResultsById = async (
  // _: any,
  // term: string,
  // guidelineId: string
  context: QueryFunctionContext
) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: CHAPTER,
    query: `(CONTENT="@${context.queryKey[1]}@" and 
      STATUS=VERÖFFENTLICHT and 
      PUBLISHED_OLD=false and
      MAIN_DATA=true and  GUIDELINE_UUID=${context.queryKey[2]})`,
    attributes: `*,GUIDELINE.TITLE${LANGUAGE_SUFFIX}`,
  };

  devLog("getSearchResultsById " + constructURL(URL, params));
  const { data } = await axios.get(URL, { params });
  return data;
};

const wildcardContent = (terms: string[]) => {
  DEBUGISON && HOOKSDEBUGGING && console.log("useSearch:wildcardContent");
  let content = "";
  terms.forEach((term, index) => {
    content += `${
      index !== 0 ? " or " : ""
    }CONTENT="@ ${term} @" or CONTENT="@ ${term}.@" or CONTENT="@ ${term},@" or CONTENT="@(${term} @" or CONTENT="@ ${term})@" or CONTENT="@(${term})@"`;
  });
  return content;
};

const getSearchHorizontal = async (context: QueryFunctionContext) => {
  DEBUGISON && HOOKSDEBUGGING && console.log("useSearch:getSearchHorizontal");
  // @ts-ignore
  const content = wildcardContent(context.queryKey[1]);
  DEBUGISON && HOOKSDEBUGGING && console.log("wildcardContent: " + content);
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: CHAPTER,
    // @ts-ignore
    query: `(${content} and 
      STATUS=VERÖFFENTLICHT and 
      PUBLISHED_OLD=false and
      MAIN_DATA=true and  GUIDELINE_UUID=${context.queryKey[2]})`,
    attributes: `*,GUIDELINE.TITLE${LANGUAGE_SUFFIX}`,
  };
  const { data } = await axios.get(URL, { params });
  DEBUGISON && HOOKSDEBUGGING && console.log("getSearchHorizontal: " + data);
  return data;
};

export function useSearchById(
  term: string,
  guidelineId: string,
  options?: QueryObserverOptions<ChapterType[]>
) {
  DEBUGISON && HOOKSDEBUGGING && console.log("useSearchById");
  return useQuery<ChapterType[]>(
    ["search", term, guidelineId],
    getSeachResultsById,
    options
  );
}

export function useSuggestions(
  term: string,
  options?: QueryObserverOptions<string[]>
) {
  DEBUGISON && HOOKSDEBUGGING && console.log("useSuggestions");
  return useQuery<string[]>(["suggestion", term], getSuggestion, options);
}

export default function useSearch(
  term: string,
  guidelines?: GuidelineType[],
  slice?: number | string,
  options?: QueryObserverOptions<ChapterType[]>
) {
  DEBUGISON && HOOKSDEBUGGING && console.log("useSearch");
  return useQuery<ChapterType[]>(
    ["search", term, guidelines, slice],
    getSeachResults,
    options
  );
}

export function useSearchHorizontal(
  term: string[],
  guidelineId: string,
  options: QueryObserverOptions<ChapterType[]>
) {
  DEBUGISON && HOOKSDEBUGGING && console.log("useSearchHorizontal");
  return useQuery<ChapterType[]>(
    ["search", term, guidelineId],
    getSearchHorizontal,
    options
  );
}
