import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { CHAPTER, MET, RECOMMENDATION, URL, SECTOR, LANGUAGE } from "./config";
import { RecommendationType } from "../../types";
import { useEffect } from "react";
import { writeToStorage } from "../../utils";
import { LANGUAGE_SUFFIX } from "../useLanguage";
import { BACKENDTRACING, DEBUGISON, HOOKSDEBUGGING } from "../../config";
import { getDownloadedData } from "./useInitialize";
import { createURLWithParams } from "../../utils/helpers";
import { devLog } from "../../utils/logging";

export const getRecommendationById = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: RECOMMENDATION,
    get: context.queryKey[1],
  };
  devLog(createURLWithParams(params), HOOKSDEBUGGING);
  const { data } = await axios.get(URL, { params });
  return data;
};

export const getRecommendationByChapterId = async (
  context: QueryFunctionContext
) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: RECOMMENDATION,
    query: `CONTENT_UUID=${context.queryKey[1]}`,
    orderBy: "ORDER asc",
    brew: "CONTENT" + LANGUAGE_SUFFIX,
    brewGuideline: context.queryKey[2],
  };
  const { data } = await axios.get(URL, { params });

  console.log(createURLWithParams(params));
  return data;
};

export function useRecommendationByChapter(
  chapterId: string,
  guidelineId: string,
  options?: QueryObserverOptions<RecommendationType[]>
) {
  DEBUGISON &&
    BACKENDTRACING &&
    console.log(
      "useRecommendationByChapter\nchapter: " +
        chapterId +
        "\nguidelineId: " +
        guidelineId +
        "\n options" +
        JSON.stringify(options)
    );

  const downloaded = getDownloadedData(guidelineId, [
    "recommendations",
    chapterId,
    guidelineId,
  ]);
  const queried = useQuery<RecommendationType[]>(
    ["recommendations", chapterId, guidelineId],
    getRecommendationByChapterId,
    {
      ...options,
    }
  );
  if (downloaded) {
    return { data: downloaded };
  } else return queried;
}

export default function useRecommendation(
  recommendationId: string,
  options?: QueryObserverOptions<RecommendationType>
) {
  return useQuery<RecommendationType>(
    ["recommendation", recommendationId],
    getRecommendationById,
    options
  );
}
