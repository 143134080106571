import {
  QueryFunctionContext,
  QueryObserverOptions,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import axios from "axios";
import { TAG, MET, URL, SECTOR, LANGUAGE } from "./config";
import { TagType } from "../../types/tag";
import { useEffect } from "react";
import { writeToStorage } from "../../utils";
import { LANGUAGE_SUFFIX } from "../useLanguage";

export const getTagById = async (
  // _: any,
  // tagId: string,
  // guidelineId: string
  context: QueryFunctionContext
) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: TAG,
    get: context.queryKey[1],
    brew: `CONTENT${LANGUAGE_SUFFIX}`,
    // brew: `CONTENT${LANGUAGE_SUFFIX}`,
    brewGuideline: context.queryKey[2],
    attributes: "*,ROTE_HAND.*,GBA.*,IQWIG.*"
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export const getAllTags = async (_: any) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: TAG,
    query: "STATUS=VERÖFFENTLICHT",
    attributes: `UUID,TITLE${LANGUAGE_SUFFIX}, TITLE`
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export function useAllTags(options?: QueryObserverOptions<TagType[]>) {
  return useQuery<TagType[]>(["allTags"], getAllTags, options);
}

export default function useTag(
  tagId: string,
  guidelineId: string,
  onSuccess: (data: TagType) => void,
  options?: QueryObserverOptions<TagType>
) {
  const res = useQuery<TagType>(["tag", tagId, guidelineId], getTagById, {
    ...options
  });

  res.data && options?.enabled && onSuccess(res.data);
}
