import React from "react";
import { Switch, SwitchProps } from "react-native";
import { Box, Text } from "../Theme";

interface SwitchBarProps extends SwitchProps {
  title: string;
}
const SwitchBar = ({ title, ...props }: SwitchBarProps) => {
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      borderBottomWidth={1}
      borderColor="information"
      paddingBottom="s"
    >
      <Text>{title}</Text>
      <Switch {...props} />
    </Box>
  );
};

export default SwitchBar;
