import {
  QueryClient,
  QueryFunctionContext,
  QueryObserverOptions,
  useQueries,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import axios, { all } from "axios";
import { EXPORT, EXPORT_GENERAL, MET, URL, SECTOR, LANGUAGE } from "./config";
import { ExportType } from "../../types";
import { initializeToStorage, readFromStorage } from "../../utils";
import useImages from "./useImages";
import { GuidelineType } from "../../types/guideline";
import { getGuidelineById } from "./useGuideline";
import { getAllChaptersById, getChapterById } from "./useChapter";
import { getAllPathways } from "./usePathway";
import { useEffect, useMemo } from "react";
import { DEBUGISON, HOOKSDEBUGGING, isWeb } from "../../config";
import { set } from "cypress/types/lodash";
import { isLoading } from "expo-font";
import { arrayToJson } from "../../utils/helpers";
import useLocalStorage from "../useLocalStorage";

export const getInitialData = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: EXPORT,
    query: `GUIDELINE_UUID=${context.queryKey[1]}`,
    orderBy: "EXPORT_DATE desc EXPORT_TIME desc",
    slice: "1" // only get newest
  };
  //console.log(params);
  const { data } = await axios.get(URL, { params });
  return data;
};

export const getInitialGeneralData = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: EXPORT_GENERAL,
    orderBy: "EXPORT_DATE desc EXPORT_TIME desc",
    slice: "1"
  };
  const { data } = await axios.get(URL, { params });
  //console.log(data);
  return data;
};

export default function useInitialize(
  guidelineId: string,

  onSuccess: (data: ExportType[]) => void,
  options?: QueryObserverOptions<ExportType[]>
) {
  useImages(guidelineId, { enabled: options?.enabled });
  const queryClient = useQueryClient();
  const res = useQuery<ExportType[]>(["export", guidelineId], getInitialData, {
    ...options,
    onSuccess: (data) => {
      if (data && data.length > 0) {
        if (data[0].JSON) {
          initializeToStorage(data[0].JSON, guidelineId);
          readFromStorage(queryClient);
        }
      }
      options?.onSuccess && options.onSuccess(data);
    }
  });
  res.data && options?.enabled && onSuccess(res.data);
}

export function useInitializeGeneral(
  options?: QueryObserverOptions<ExportType[]>
) {
  const queryClient = useQueryClient();
  return useQuery<ExportType[]>(["export general"], getInitialGeneralData, {
    ...options,
    onSuccess: (data) => {
      if (data && data.length > 0) {
        if (data[0].JSON) {
          initializeToStorage(data[0].JSON);
          readFromStorage(queryClient);
        }
      }
      options?.onSuccess && options.onSuccess(data);
    }
  });
}

export function useIsGuidelineDownloaded(guidelineId: string) {
  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueryData<GuidelineType>([
    "export",
    guidelineId
  ]);
  return cacheData ? true : false;
}

export function getCacheSideHash(guidelineId: string) {
  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueryData<ExportType>([
    "export",
    guidelineId
  ]);
  return cacheData?.HASH_KEY;
}

export function useExecuteDownload(
  guidelineId: string,
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  options?: QueryObserverOptions<GuidelineType>
) {
  const results = useQueries({
    queries: [
      {
        queryKey: ["guideline", guidelineId],
        queryFn: getGuidelineById,
        cacheTime: Infinity,
        staleTime: Infinity
      },
      {
        queryKey: ["pathways", guidelineId],
        queryFn: getAllPathways,
        cacheTime: Infinity,
        staleTime: Infinity
      },
      {
        queryKey: ["export", guidelineId],
        queryFn: getInitialData,
        cacheTime: Infinity,
        staleTime: Infinity
      },
      {
        queryKey: ["allChapter", guidelineId],
        queryFn: getAllChaptersById,
        enabled: options?.enabled,
        cacheTime: Infinity,
        staleTime: Infinity
      }
    ]
  });

  const isLoading = results.some((result) => result.isLoading);

  !isLoading && onSuccess("");
}

export const getDownloadedData = (guidelineId: string, keys: string[]) => {
  const guidelineExport = useQuery({
    queryKey: ["export", guidelineId],
    queryFn: getInitialData,
    staleTime: Infinity,
    cacheTime: Infinity
  });
  if (!guidelineExport.isLoading && guidelineExport.data[0]) {
    const guidelineData = guidelineExport.data[0].JSON;
    const returnData = guidelineData;
    const accesskeys = JSON.stringify(keys);
    if (isWeb) {
      return;
    }
    if (returnData[accesskeys]) {
      DEBUGISON && HOOKSDEBUGGING && console.log(returnData[accesskeys]);
      return returnData[accesskeys];
    } else {
      DEBUGISON &&
        HOOKSDEBUGGING &&
        console.log(
          `Did not find downloaded data for exportId: ${guidelineId}, keys: ${JSON.stringify(
            accesskeys
          )}`
        );
      return undefined;
    }
  }
};
