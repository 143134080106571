import { ReactNode } from "react";
import { ModeType } from "../../types";

export type PopupType = {
  isVisible: boolean;
  title?: string;
  description?: string;
  context?: ReactNode;
  content?: ReactNode;
  header?: ReactNode;
};

export type HistoryType = {
  id: string | undefined;
  guidelineId: string | undefined;
  term?: string;
  mode: ModeType;
};

export interface globalState {
  popup: PopupType;
  history: HistoryType[];
  hasLaunched: boolean;
  autoUpdate: boolean;
  appId: string;
}

export const TOGGLE_POPUP = "TOGGLE_POPUP";
export interface togglePopup {
  type: typeof TOGGLE_POPUP;
  payload: PopupType;
}

export const PUSH_HISTORY = "PUSH_HISTORY";
export interface pushHistory {
  type: typeof PUSH_HISTORY;
  payload: HistoryType;
}

export const POP_HISTORY = "POP_HISTORY";
export interface popHistory {
  type: typeof POP_HISTORY;
}

export const RESET_HISTORY = "RESET_HISTORY";
export interface resetHistory {
  type: typeof RESET_HISTORY;
}

export const TOGGLE_LAUNCH = "TOGGLE_LAUNCH";
export interface toggleLaunch {
  type: typeof TOGGLE_LAUNCH;
}

export const TOGGLE_AUTO_UPDATE = "TOGGLE_AUTO_UPDATE";
export interface toggleAutoUpdate {
  type: typeof TOGGLE_AUTO_UPDATE;
}

export type GlobalActionTypes =
  | togglePopup
  | pushHistory
  | resetHistory
  | popHistory
  | toggleLaunch
  | toggleAutoUpdate;
