import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback, useState } from "react";
import { Platform } from "react-native";
import { RectButton } from "react-native-gesture-handler";
import { useDispatch } from "react-redux";
import {
  Box,
  Text,
  GuidelineCard,
  Container,
  RoundedIconButton
} from "../../../components";
import { MobileHomeFooter } from "../../../components/Footer";
import { useAuth, useGuideline, useGuidelines, useUser } from "../../../hooks";
import { resetHistory } from "../../../redux/global";
import { GuidelineType } from "../../../types/guideline";
import { compareValues } from "../../../utils";
import TabBar from "../TabBar";

const List = () => {
  const dispatch = useDispatch();
  const isWeb = Platform.OS === "web";
  const [activeChar, setActiveChar] = useState<string>("");
  const { username } = useAuth();
  const userData = useUser(username);
  const { data: guidelinesData } = useGuidelines();
  const alphabet = [...Array(26).keys()].map((i) =>
    String.fromCharCode(i + 65)
  );

  useFocusEffect(
    useCallback(() => {
      dispatch(resetHistory());
    }, [dispatch])
  );

  return (
    <Container tabBar={<TabBar />} customMobileFooter={<MobileHomeFooter />}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        padding={{ phone: "m", tablet: "xl" }}
      >
        {alphabet.map((char) => {
          const isActive = char === activeChar;
          const isRelevant = guidelinesData?.find(
            (i) => i.TITLE[0].toUpperCase() === char
          )
            ? true
            : false;
          return (
            <RectButton
              key={char}
              onPress={() => setActiveChar(char)}
              enabled={isRelevant}
            >
              <Text
                color={
                  isActive
                    ? "primary"
                    : !isRelevant
                    ? "information"
                    : "mainForeground"
                }
                fontFamily={
                  isActive || isRelevant
                    ? "bliss-pro-medium"
                    : "bliss-pro-light"
                }
              >
                {char}
              </Text>
            </RectButton>
          );
        })}
        <RoundedIconButton
          name="reset"
          size={18}
          iconRatio={1}
          onPress={() => setActiveChar("")}
          color={activeChar !== "" ? "mainForeground" : "information"}
        />
      </Box>
      <Box
        paddingHorizontal={{ phone: "m", tablet: "xl" }}
        paddingBottom={{ phone: "m", tablet: "xl" }}
      >
        {guidelinesData
          ?.filter((i) =>
            activeChar !== "" ? i.TITLE[0].toUpperCase() === activeChar : true
          )
          .sort(compareValues("TITLE", "asc"))
          .map((item: GuidelineType) => (
            <GuidelineCard
              key={item.UUID}
              isFavorite={userData?.FAVORITE_GUIDELINE_UUID === item.UUID}
              isDownloaded={isWeb}
              {...item}
            />
          ))}
      </Box>
    </Container>
  );
};

export default List;
