import {
  QueryFunctionContext,
  QueryObserverOptions,
  hashQueryKey,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { CHAPTER, MET, URL, SECTOR, LANGUAGE } from "./config";
import { ChapterType } from "../../types";
import { useEffect, useState } from "react";
import { writeToStorage } from "../../utils";
import { LANGUAGE_SUFFIX } from "../useLanguage";
import { HOOKSDEBUGGING, DEBUGISON } from "../../config";
import { getDownloadedData } from "./useInitialize";
import { createURLWithParams, hasUnusefulStrings } from "../../utils/helpers";
import { devLog } from "../../utils/logging";

export const getChapterById = async (context: QueryFunctionContext) => {
  if (hasUnusefulStrings([context.queryKey[0], context.queryKey[1]])) {
    return;
  }
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: CHAPTER,
    get: context.queryKey[1],
    brew: `CONTENT${LANGUAGE_SUFFIX}`,
    brewGuideline: context.queryKey[2],
    attributes: `*,RECOMMENDATIONS.UUID,GUIDELINE,GUIDELINE.TITLE${LANGUAGE_SUFFIX}, GUIDELINE.TITLE, CONTENT${LANGUAGE_SUFFIX}`,
  };
  devLog(createURLWithParams(params));
  const { data } = await axios.get(URL, { params });
  return data;
};

export const getAllChaptersById = async (context: QueryFunctionContext) => {
  const params = {
    met: MET,
    SECTOR: SECTOR,
    LANGUAGE: LANGUAGE,
    entity: CHAPTER,
    query: `(GUIDELINE_UUID=${context.queryKey[1]} and 
      STATUS=VERÖFFENTLICHT and 
      PUBLISHED_OLD=false)`,
    brew: `CONTENT${LANGUAGE_SUFFIX}`,
    brewGuideline: context.queryKey[2],
    attributes: `UUID,TITLE${LANGUAGE_SUFFIX},TITLE, MAIN_DATA,ORDER,GUIDELINE_UUID,CONTENT${LANGUAGE_SUFFIX},CONTENT, PARENT_CONTENT_UUID,RECOMMENDATIONS.UUID,GUIDELINE.TITLE${LANGUAGE_SUFFIX}, GUIDELINE.TITLE`, // RECOMMENDATIONS_UUID PATHWAY_UUID
    orderBy: "ORDER asc",
  };
  const { data } = await axios.get(URL, { params });
  return data;
};

export default function useChapter(
  chapterId: string,
  guidelineId: string,
  options?: QueryObserverOptions<ChapterType>
) {
  const downloaded = getDownloadedData(guidelineId, [
    "chapter",
    chapterId,
    guidelineId,
  ]);
  DEBUGISON &&
    HOOKSDEBUGGING &&
    console.log("downloaded" + JSON.stringify(downloaded)?.substring(0, 100));

  const queried = useQuery<ChapterType>(
    ["chapter", chapterId, guidelineId],
    getChapterById,
    {
      enabled: chapterId && guidelineId && !downloaded ? true : false,
      ...options,
    }
  );
  if (downloaded) {
    return { data: downloaded };
  } else return queried;
}

export function useAllChapters(
  guidelineId: string,
  options?: QueryObserverOptions<ChapterType[]>
) {
  const downloaded = getDownloadedData(guidelineId, ["chapter", guidelineId]);

  const queried = useQuery<ChapterType[]>(
    ["allChapter", guidelineId],
    getAllChaptersById,
    {
      ...options,
    }
  );
  if (downloaded) {
    return { data: downloaded };
  } else return queried;
}
