import { BinaryAlertProps, StdAlertProps } from "./index";

export const StdAlert = ({
  title,
  desc,
  onPress = () => {},
}: StdAlertProps) => {
  alert(`${title}\n${desc}`);
  if (onPress) onPress();
};

export const BinaryAlert = ({
  title,
  desc,
  onPositivePress = () => {},
  onNegativePress = () => {},
}: BinaryAlertProps) => {
  const res = window.confirm(`${title}\n${desc}`);
  if (res) onPositivePress();
  else onNegativePress();
};

export const ErrorAlert = () => {
  StdAlert({
    title: "Netzwerkfehler",
    desc: "Wir konnten keine Verbindung zum Leila Server herstellen",
    onPress: () => null,
  });
};
