import React from "react";
import { Box, LinkBox, Text, Theme, Icon } from "../../../components";
import { DEBUGISON, ORIGINDEBUG } from "../../../config";
import { Icons } from "../../../styles";

interface ModeButtonProps {
	color: keyof Theme["colors"];
	icon: keyof typeof Icons;
	title: string;
	to: string;
	isDisabled?: boolean;
}
const ModeButton = ({
	to,
	color,
	title,
	icon,
	isDisabled,
}: ModeButtonProps) => {
	return (
		<Box marginBottom="s">
			<LinkBox to={to} isDisabled={isDisabled} showLoading={true}>
				<Box
					flex={1}
					flexDirection="row"
					paddingHorizontal="m"
					alignItems="center"
					borderLeftWidth={6}
					borderBottomWidth={1}
					borderLeftColor={color}
					borderBottomColor="smoke"
				>
					<Icon name={icon} color="mainForeground" size={40} />
					<Text variant="title2" paddingLeft="s">
						{title}
						{ORIGINDEBUG && DEBUGISON && "FILE: ModeButton"}
					</Text>
				</Box>
			</LinkBox>
		</Box>
	);
};

export default ModeButton;
