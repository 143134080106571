import { CommonActions } from "@react-navigation/native";
import React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import t from "../../../Texts/TextImport";
import { DEBUGISON, ORIGINDEBUG } from "../../config";
import Icon from "../Icon";
import LinkBox from "../LinkBox";
import { Box, Text, useTheme } from "../Theme";

const MobileHomeFooter = () => {
	const insets = useSafeAreaInsets();
	const theme = useTheme();
	return (
		<>
			<Box
				flexDirection="row"
				paddingHorizontal="xs"
				paddingVertical="m"
				justifyContent="space-between"
				borderTopColor="smoke"
				borderTopWidth={1}
			>
				<LinkBox
					to="/General/Informations"
					action={CommonActions.navigate("General", { screen: "Informations" })}
				>
					<Box
						flexDirection="row"
						alignItems="center"
						height={40}
						paddingHorizontal="xs"
					>
						<Icon name="informations" color="mainForeground" />
						<Text
							paddingLeft="xs"
							paddingBottom="xxs"
							variant="navigationTitle"
						>
							{t("MHFLeila")}
							{ORIGINDEBUG && DEBUGISON && "MobileHomeFooter"}
						</Text>
					</Box>
				</LinkBox>
				<LinkBox
					to="/General/Settings"
					action={CommonActions.navigate("General", { screen: "Settings" })}
				>
					<Box
						flexDirection="row"
						alignItems="center"
						height={40}
						paddingHorizontal="xs"
					>
						<Icon name="settings" color="mainForeground" />
						<Text
							paddingLeft="xs"
							paddingBottom="xxs"
							variant="navigationTitle"
							marginRight="s"
						>
							{t("MHFSettings")}
							{ORIGINDEBUG && DEBUGISON && "MobileHomeFooter"}
						</Text>
					</Box>
				</LinkBox>
			</Box>
			<Box height={insets.bottom} backgroundColor="mainForeground" />
		</>
	);
};

export default MobileHomeFooter;
