import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import { Platform } from "react-native";
import { Asset } from "expo-asset";
import * as Font from "expo-font";
import {
  InitialState,
  NavigationContainer,
  NavigationContainerRef,
} from "@react-navigation/native";
import Constants from "expo-constants";
import * as Linking from "expo-linking";
import { isInTest, isWeb } from "../config";

const NAVIGATION_STATE_KEY = `NAVIGATION_STATE_KEY-${Constants?.expoConfig?.sdkVersion}`;

/* eslint-disable */
const AsyncStorage = isWeb
  ? require("redux-persist/lib/storage/session").default
  : !isInTest
  ? require("redux-persist-expo-filesystem").default
  : "";
/* eslint-enable */

const prefix = isInTest ? "" : Linking.createURL("/");

const linking = {
  prefixes: [prefix],
};

export type FontSource = Parameters<typeof Font.loadAsync>[0];

const usePromiseAll = (
  promises: Promise<void | void[] | Asset[]>[],
  cb: () => void
) =>
  useEffect(() => {
    (async () => {
      await Promise.all(promises);
      cb();
    })();
  });

const useLoadAssets = (assets: number[], fonts: FontSource): boolean => {
  const [ready, setReady] = useState(false);
  usePromiseAll(
    [Font.loadAsync(fonts), ...assets.map((asset) => Asset.loadAsync(asset))],
    () => setReady(true)
  );
  return ready;
};

interface LoadAssetsProps {
  fonts?: FontSource;
  assets?: number[];
  children: ReactElement | ReactElement[];
}

const LoadAssets = ({ assets, fonts, children }: LoadAssetsProps) => {
  const [isNavigationReady, setIsNavigationReady] = useState(!__DEV__);
  const [initialState, setInitialState] = useState<InitialState | undefined>();

  const routeNameRef = useRef("");
  const navigationRef = useRef<NavigationContainerRef>(null);

  const ready = useLoadAssets(assets || [], fonts || {});

  useEffect(() => {
    const restoreState = async () => {
      try {
        const savedStateString = await AsyncStorage.getItem(
          NAVIGATION_STATE_KEY
        );
        const state = savedStateString
          ? JSON.parse(savedStateString)
          : undefined;
        setInitialState(state);
      } finally {
        setIsNavigationReady(true);
      }
    };
    if (!isNavigationReady) {
      restoreState();
    }
  }, [isNavigationReady]);

  const onStateChange = useCallback((state) => {
    AsyncStorage.setItem(NAVIGATION_STATE_KEY, JSON.stringify(state));
    makeAnalytics();
  }, []);

  const onReady = () => {
    // @ts-ignore
    routeNameRef.current = navigationRef.current.getCurrentRoute().name;
  };

  const makeAnalytics = () => {
    const previousRouteName = routeNameRef.current;
    // @ts-ignore
    const currentRouteName = navigationRef.current.getCurrentRoute().name;
    // @ts-ignore
    const currentRouteParams = navigationRef.current.getCurrentRoute().params;
    if (previousRouteName !== currentRouteName) {
      //console.log(currentRouteName, currentRouteParams);
    }
    routeNameRef.current = currentRouteName;
  };

  if (!ready || !isNavigationReady) {
    return null;
  }
  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      documentTitle={{
        enabled: false,
      }}
      {...{
        onReady,
        onStateChange,
      }}
    >
      {children}
    </NavigationContainer>
  );
};

export default LoadAssets;
