import React from "react";
import { Box, Button, LinkBox } from "../../components";
import { buildHomeLink } from "../../utils/helpers";
import { generateUniqueId } from "../../utils/helpers";
import t from "../../../Texts/TextImport";
import DevText from "../../utils/DevText";

function QRAbortButton() {
  return (
    <Box maxWidth="200px">
      <LinkBox showLoading={false} to={buildHomeLink()}>
        <Button
          variant="border"
          color={"primary"}
          mainColor={"primary"}
          label={t("GenAbort")}
          key={generateUniqueId()}
          onPress={() => {}}
        />
      </LinkBox>
    </Box>
  );
}

interface QRButton {
  text: string;
  to?: string;
  onPress: () => void;
}

function QRButton({ text, to, onPress }: QRButton) {
  return (
    <Box maxWidth="200px">
      <DevText>{to}</DevText>
      <LinkBox showLoading={false} to={to ? to : ""}>
        <Button
          variant="default"
          color={"mainBackground"}
          mainColor={"primary"}
          label={text}
          key={generateUniqueId()}
          onPress={onPress}
        />
      </LinkBox>
    </Box>
  );
}

export { QRButton, QRAbortButton };
