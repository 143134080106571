import React, { useCallback, useEffect, useState } from "react";
import {
  Platform,
  View,
  Dimensions,
  useWindowDimensions,
  LogBox,
} from "react-native";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./src/redux";
import * as Font from "expo-font";
import { Asset } from "expo-asset";
import { enableScreens } from "react-native-screens";
import { AppContainer } from "./src/navigation";
import { ThemeProvider } from "./src/components";
import theme, { Text } from "./src/components/Theme";
import { AuthProvider, useDeviceDetect } from "./src/hooks";
import * as ScreenOrientation from "expo-screen-orientation";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { PortalHost, PortalProvider } from "@gorhom/portal";
import Theater from "./src/screens/Home/Area/Theater";
import { registerRootComponent } from "expo";
import * as SplashScreen from "expo-splash-screen";
import { Box } from "./src/components";
import { LoadingScreen } from "./src/components/";
import QueryProvider from "./src/hooks/QueryProvider";
import {
  BoldText,
  MarkdownTextProvider,
  RedText,
} from "./src/components/MarkdownText";
import { isInTest } from "./src/config";
!isInTest && enableScreens();

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();
export default function App() {
  const [isReady, setIsReady] = useState(false);
  const { isTablet } = useDeviceDetect();
  // const expoPushToken = usePushNotification();
  const width = Dimensions.get("window").width; //full width
  const height = Dimensions.get("window").height; //full height
  useEffect(() => {
    // Android Log Warning with @tanstack/react-query
    if (Platform.OS === "android") {
      //LogBox.ignoreLogs(["Setting a timer"]);
    }
    if (isTablet) {
      //ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);
      ScreenOrientation.unlockAsync();
    }
  }, [isTablet]);

  async function _cacheResourcesAsync() {
    await Font.loadAsync({
      "bliss-pro": require("./assets/fonts/BlissPro.ttf"),
      "bliss-pro-italic": require("./assets/fonts/BlissPro-Italic.ttf"),
      "bliss-pro-light": require("./assets/fonts/BlissPro-Light.ttf"),
      "bliss-pro-light-italic": require("./assets/fonts/BlissPro-LightItalic.ttf"),
      "bliss-pro-medium": require("./assets/fonts/BlissPro-Medium.ttf"),
      "bliss-pro-medium-italic": require("./assets/fonts/BlissPro-MediumItalic.ttf"),
      "leila-symbols": require("./assets/fonts/Leila-Regular.ttf"),
    });
    await Asset.loadAsync(require("./assets/network.png"));
    await Asset.loadAsync(require("./assets/logos/leila_logo_black.png"));
  }

  const onLayoutRootView = useCallback(async () => {
    if (isReady) {
      //   console.log("Loaded");
      await SplashScreen.hideAsync();
    } else {
      //   console.log("Not Loaded");
      return null;
    }
  }, [isReady]);

  useEffect(() => {
    async function prepare() {
      try {
        _cacheResourcesAsync();
      } catch (e) {
        console.warn("errors prerendering");
        console.warn(e);
      } finally {
        setIsReady(true);
      }
    }

    prepare();
  }, []);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <View
      style={{
        overflow: "hidden",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
      onLayout={onLayoutRootView}
    >
      <SafeAreaProvider>
        <QueryProvider>
          <PersistGate loading={null} persistor={persistor}>
            <Provider store={store}>
              <AuthProvider>
                <ThemeProvider>
                  <MarkdownTextProvider
                    BoldText={BoldText}
                    RedText={RedText}
                    Standard={Text}
                    Link={Text}
                    H1={Text}
                    H2={Text}
                    H3={Text}
                  >
                    <LoadingScreen>
                      <PortalProvider>
                        <Theater>
                          <AppContainer />
                          <Box
                            style={{
                              width: useWindowDimensions().width,
                              height: 0,
                            }}
                          />
                        </Theater>
                      </PortalProvider>
                    </LoadingScreen>
                  </MarkdownTextProvider>
                </ThemeProvider>
              </AuthProvider>
            </Provider>
          </PersistGate>
        </QueryProvider>
      </SafeAreaProvider>
    </View>
  );
}

!isInTest && LogBox.ignoreLogs(["Require cycle:"]);
