import React from "react";
import t from "../../Texts/TextImport";
import { APPBRANCH } from "../config";
import { Platform, Animated, Pressable } from "react-native";
import {
  createStackNavigator,
  StackCardInterpolationProps,
  StackHeaderInterpolationProps,
} from "@react-navigation/stack";
import { RoundedIconButton, useTheme, Box, Text } from "../components";
import { AppDrawerProps, AppRoutes } from "../components/Navigation";
import Home from "../screens/Home";
import Guideline from "../screens/Guideline";
import User from "../screens/User";
import General from "../screens/General";
import { useIsLargeScreen } from "../hooks";
import HeaderTitle from "./components/HeaderTitle";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import OnBoarding from "../screens/OnBoarding";
import { useSelector } from "../redux";
import { ClearCache } from "../screens/General/Settings/Content";

export const forFade = ({ current }: StackCardInterpolationProps) => ({
  cardStyle: {
    opacity: current.progress,
  },
});

export const forHeaderFade = ({
  current,
  next,
}: StackHeaderInterpolationProps) => {
  const opacity = Animated.add(
    current.progress,
    next ? next.progress : 0
  ).interpolate({
    inputRange: [0, 1, 2],
    outputRange: [0, 1, 0],
  });

  return {
    leftButtonStyle: { opacity },
    rightButtonStyle: { opacity },
    titleStyle: { opacity },
    backgroundStyle: { opacity },
  };
};

const Stack = createStackNavigator<AppRoutes>();

const AppStack = ({ navigation, route }: AppDrawerProps<"AppStack">) => {
  const isWeb = Platform.OS === "web";
  const hasLaunched = useSelector((state) => state.global.hasLaunched);
  const theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  const insets = useSafeAreaInsets();
  return (
    <Stack.Navigator
      screenOptions={{
        headerStyleInterpolator: forHeaderFade,
        gestureEnabled: false,
        headerStyle: {
          backgroundColor: theme.colors.mainBackground,
          borderBottomWidth: 0,
          shadowColor: "transparent",
        },
        headerTitleStyle: {
          marginTop: 40 + insets.top,
          height: 60 + insets.top,
          color: isLargeScreen
            ? theme.colors.mainBackground
            : theme.colors.mainForeground,
        },
        headerTitleContainerStyle: {
          flexGrow: 1,
        },

        headerLeft: () => null,
        headerRight: () => {
          const handleToggleDrawer = () => {
            navigation.openDrawer();
          };
          return !isLargeScreen ? (
            <Pressable onPress={() => handleToggleDrawer()}>
              <Box paddingHorizontal="s">
                <RoundedIconButton
                  size={36}
                  iconRatio={0.7}
                  name="hamburger"
                  color="mainForeground"
                />
              </Box>
            </Pressable>
          ) : null;
        },
        cardStyle: {
          height: isWeb ? "100vh" : "100%",
        },
      }}
      initialRouteName={isWeb || hasLaunched ? "Home" : "OnBoarding"}
    >
      <Stack.Screen
        component={OnBoarding}
        name="OnBoarding"
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        component={ClearCache}
        name="ClearCache"
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        component={Home}
        name="Home"
        options={{
          title: t("WhichGuide"),
          headerTitle: (props) => (
            <HeaderTitle
              {...props}
              style={{
                fontSize: 18,
                lineHeight: 20,
                fontFamily: "bliss-pro-light",
              }}
            />
          ),
        }}
      />
      <Stack.Screen
        component={Guideline}
        name="Guideline"
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen component={User} name="User" />
      <Stack.Screen
        component={General}
        name="General"
        options={{
          title: t("ASEI"),
        }}
      />
    </Stack.Navigator>
  );
};
export default AppStack;
