import { StatusBar } from "expo-status-bar";
import t from "../../../../Texts/TextImport";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  BackButton,
  Box,
  Text,
  SubmitInput,
} from "../../../components";
import { MobileFooter } from "../../../components/Footer";
import { GuidelineNavigationProps } from "../../../components/Navigation";
import { useGuideline, useGuidelines, useSuggestions } from "../../../hooks";
import { pushHistory } from "../../../redux/global";
import { GuidelineType } from "../../../types/guideline";
import TabBar from "../TabBar";
import FilterGuideline from "./FilterGuideline";
import Results from "./Results";
import SuggestionList from "./SuggestionList";
import { DEBUGISON, ORIGINDEBUG, SEARCHDEBUG } from "../../../config";

const Search = ({ navigation, route }: GuidelineNavigationProps<"Search">) => {
  const term = useRef<string>("");
  // useAnalytics({ term.current });
  const guidelineId = route.params?.guidelineId;
  const dispatch = useDispatch();
  const { data: thisGuideline } = useGuideline(guidelineId);
  const [guidelineFilter, setGuidelineFilter] = useState<string[]>([
    thisGuideline,
  ]);
  const [search, setSearch] = useState(false);
  const [suggestion, setSuggestion] = useState("");
  const { data } = useSuggestions(suggestion, { enabled: suggestion !== "" });
  const { data: guidelinesData } = useGuidelines();

  useEffect(() => {
    if (guidelinesData) {
      const currentGuideline = guidelinesData.find(
        (i) => i.UUID === guidelineId
      );
      if (currentGuideline) {
        setGuidelineFilter([currentGuideline]);
        navigation.setOptions({ title: currentGuideline.TITLE });
      }
    }
  }, [guidelinesData]);

  useEffect(() => {
    if (search) {
      setSearch(false);
    }
  }, [search]);

  useEffect(() => {
    dispatch(pushHistory({ id: undefined, guidelineId, mode: "search" }));
  }, []);

  const onChangeText = useCallback((text: string) => {
    DEBUGISON && SEARCHDEBUG && console.log("onChangeText: " + text);
    term.current = text;
    setSuggestion(text);
  }, []);

  const onSubmit = useCallback((text: string) => {
    DEBUGISON && SEARCHDEBUG && console.log("onSubmit with: " + text);
    setSuggestion("");
    term.current = text.replace(" ", "");
    setSearch(true);
  }, []);

  return (
    <>
      <StatusBar style="light" />
      <Container
        tabBar={<TabBar />}
        backButton={<BackButton />}
        customMobileFooter={<MobileFooter />}
      >
        <Box
          paddingHorizontal={{ phone: "m", tablet: "xl" }}
          paddingTop={{ phone: "m", tablet: "xl" }}
        >
          <Box zIndex={1} paddingBottom="m">
            {DEBUGISON && ORIGINDEBUG && <Text>Next is Searchinput</Text>}
            <SubmitInput
              label={t("GenSearch")}
              placeholder={t("GenSearchTerm")}
              onSubmit={onSubmit}
              onChangeText={onChangeText}
              returnKeySubmit={true}
              value={term.current}
            />
            {suggestion !== "" && data && data.length > 0 && (
              <SuggestionList suggestions={data} onSubmit={onSubmit} />
            )}
          </Box>
          <Box paddingBottom="m">
            <Text variant="description" color="search" paddingBottom="s">
              {t("GenSearchTerm")}:{" "}
              <Text
                variant="description"
                color="search"
                fontFamily="bliss-pro-medium"
              >
                {DEBUGISON && SEARCHDEBUG && "searchterm is next: "}
                {term.current}
              </Text>
            </Text>
            <Text variant="description" color="search">
              {t("GenGui") + ": "}
              <Text
                variant="description"
                color="search"
                fontFamily="bliss-pro-medium"
                numberOfLines={2}
              >
                {guidelineFilter.length > 3
                  ? guidelineFilter.length === guidelinesData?.length
                    ? "alle"
                    : `${guidelineFilter.length} ausgewählt`
                  : guidelineFilter.map((i) => i.TITLE_SHORT).join(", ")}
              </Text>
            </Text>
          </Box>
          <FilterGuideline
            onSelect={setGuidelineFilter}
            {...{
              guidelinesData,
              guidelineFilter,
              search,
              guidelineId,
            }}
          />
        </Box>
        <Box
          paddingHorizontal={{ phone: "m", tablet: "xl" }}
          paddingBottom={{ phone: "m", tablet: "xl" }}
          zIndex={-1}
        >
          {term !== "" && (
            <Results
              term={term.current}
              guidelines={guidelineFilter}
              search={search}
            />
          )}
        </Box>
      </Container>
    </>
  );
};

export default Search;
