import { includes } from "cypress/types/lodash";
import { DEBUGISON, LANGUAGEDEBUGGING } from "../config";
import { LANGUAGE } from "./queries/config";
export const LANGUAGE_SUFFIX = LANGUAGE == "EN" ? "_EN" : "";

const isUseful = (text: any) => {
  if (text == undefined) return false;
  if (text == null) return false;
  if (text == "") return false;
  return true;
};

const getAtIndex = (obj: any, index: string) => {
  try {
    return obj[index];
  } catch {
    return undefined;
  }
};

const possibleSuffices = ["_EN", ""];

function getField(obj: any, field: string) {
  const availableTexts: { [key: string]: string } = {};
  DEBUGISON && LANGUAGEDEBUGGING && console.log(obj);
  let fallbackText = "";

  const forRet = "No text found";
  possibleSuffices.map((el) => {
    const text = getAtIndex(obj, field + el);
    if (isUseful(text)) {
      availableTexts[el] = text;
      fallbackText = text;
    } else {
      availableTexts[
        el
      ] = `sLngg: No ${field}${LANGUAGE_SUFFIX} obj: ${JSON.stringify(
        obj?.UUID
      )}`;
    }
  });

  if (DEBUGISON && LANGUAGEDEBUGGING) {
    if (fallbackText == null) {
      if (!obj) return `wanted ${field}${LANGUAGE_SUFFIX} but did not find`;
      DEBUGISON && LANGUAGEDEBUGGING && console.warn(obj);
      return `sLngg:wanted ${field}${LANGUAGE_SUFFIX} but got deprecated Obj of UUID: ${obj?.UUID} `;
    }
  }

  if (availableTexts[LANGUAGE_SUFFIX] == null) return "";

  if (availableTexts[LANGUAGE_SUFFIX].includes("sLngg")) {
    return `${fallbackText}${
      DEBUGISON && LANGUAGEDEBUGGING ? availableTexts[LANGUAGE_SUFFIX] : ""
    }`;
  }
  return availableTexts[LANGUAGE_SUFFIX];
}

export const getContent = (obj: any) => {
  return getField(obj, "CONTENT");
};

export const getTitle = (obj: any) => {
  return getField(obj, "TITLE");
};
export const getTitleShort = (obj: any) => {
  return getField(obj, "TITLE_SHORT");
};

export const getPathwayName = (obj: any) => {
  return getField(obj, "PATHWAY_NAME");
};
