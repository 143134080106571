import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";
import axios from "axios";

const DummyApiComponent = () => {
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    const dummyAxiosCall = async () => {
      try {
        const response = await axios.get(
          "https://jsonplaceholder.typicode.com/todos/1"
        );
        setResponseData(response.data);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    dummyAxiosCall();
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  return (
    <View>
      <Text style={{ fontSize: 20, fontWeight: "bold", marginBottom: 10 }}>
        Dummy API Component
      </Text>
      {responseData ? (
        <View>
          <Text style={{ fontSize: 18, marginBottom: 5 }}>API Response:</Text>
          <Text>{JSON.stringify(responseData, null, 2)}</Text>
        </View>
      ) : (
        <Text>Loading...</Text>
      )}
    </View>
  );
};

export default DummyApiComponent;
