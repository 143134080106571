import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Container,
  GuidelineCard,
  Box,
  Text,
  Button as DefaultButton,
} from "../../../components";
import TabBar from "../TabBar";
import { useArea, useAuth, useGuidelines, useOnStart } from "../../../hooks";
import { GuidelineType } from "../../../types/guideline";
import { compareValues } from "../../../utils";
import { AreaType } from "../../../types";
import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import { Platform, Pressable } from "react-native";
import { MobileHomeFooter } from "../../../components/Footer";
import Filter from "./Filter";
import { useDispatch } from "react-redux";
import { resetHistory, togglePopup } from "../../../redux/global";
import { useSelector } from "../../../redux";
import { resetLoadedGuidelines } from "../../../redux/guidelines/actions";
import t from "../../../../Texts/TextImport";
import { LANGUAGE_SUFFIX, getTitle } from "../../../hooks/useLanguage";
import { useLoading } from "../../../components/LoadingProvider";
import useUser, {
  logUserAttributes,
  useActivatedGuidelines,
} from "../../../hooks/queries/useUser";
import {
  APPBRANCH,
  DEBUGISON,
  HOOKSDEBUGGING,
  USERDEBUG,
} from "../../../config";
import DummyApiComponent from "./DummyApiComponent";
import DummyGuidelines from "../DummyGuidelines";
import { useQueryClient } from "@tanstack/react-query";
import DevText from "../../../utils/DevText";

// Sentry.init({
//   dsn: "http://32c948dc42f84cbabd02634387c91ebe@prelive.leila.de:9001/2",
//   debug: true,
// });

const isWeb = Platform.OS === "web";

export type SortProps = {
  key: keyof GuidelineType;
  order: "asc" | "desc";
};

const Areal = () => {
  useOnStart();
  const loading = useLoading();
  const dispatch = useDispatch();
  const client = useQueryClient();
  const localFavoriteGuideline = useSelector(
    (state) => state.user.localFavoriteGuideline
  );
  const [selectedArea, setSelectedArea] = useState<AreaType[]>([]);
  const [sortBy, setSortBy] = useState<SortProps>({
    key: APPBRANCH === "ERKNET" ? "TITLE" + LANGUAGE_SUFFIX : "ORDER",
    order: "asc",
  });

  const { username } = useAuth();
  const { data: userData } = useUser(username);
  logUserAttributes(userData);
  const { data: areaData } = useArea();

  const { refetch, data: guidelinesData, isError } = useGuidelines();

  const onRetry = useCallback(() => refetch(), []);

  useEffect(() => {
    !isWeb && dispatch(resetLoadedGuidelines());
    loading.setIsLoading(false);
  }, []);

  useFocusEffect(
    useCallback(() => {
      dispatch(resetHistory());
      dispatch(togglePopup({ isVisible: false }));
    }, [])
  );

  logUserAttributes(userData);
  return (
    <Container
      tabBar={APPBRANCH !== "ERKNET" && <TabBar />}
      customMobileFooter={<MobileHomeFooter />}
    >
      {DEBUGISON && USERDEBUG && (
        <Box backgroundColor={userData ? "mainBackground" : "danger"}>
          {userData ? (
            <Text>{JSON.stringify(userData)}</Text>
          ) : (
            <Text>User was not loaded correctly.</Text>
          )}
        </Box>
      )}
      <Box
        paddingLeft={{ phone: "m", tablet: "xl" }}
        paddingTop={{ phone: "m", tablet: "xl" }}
      >
        <Filter
          {...{
            areaData,
            selectedArea,
            setSelectedArea,
            setSortBy,
            guidelinesData,
            sortBy,
          }}
        />
      </Box>
      <Box
        flex={1}
        paddingHorizontal={{ phone: "m", tablet: "xl" }}
        paddingVertical={{ phone: "m", tablet: "xl" }}
      >
        <Pressable
          onPress={() => {
            client.invalidateQueries({ queryKey: ["guideline"] });
            client.invalidateQueries({ queryKey: ["guidelines"] });
            client.invalidateQueries({ queryKey: ["newestGuideline"] });
            client.invalidateQueries({ queryKey: ["user"] });
            client.invalidateQueries({ queryKey: ["chapter"] });
            client.invalidateQueries({ queryKey: ["activatedGuidelines"] });
            client.removeQueries();
          }}
        >
          <DevText>Clearcache</DevText>
        </Pressable>
        {guidelinesData
          ?.filter((i) =>
            selectedArea.length === 0
              ? true
              : selectedArea.some((j) => j.UUID === i.AREA.UUID)
          )
          .sort(compareValues(sortBy.key, sortBy.order))
          .sort((a, b) => {
            if (
              a.UUID === userData?.FAVORITE_GUIDELINE_UUID ||
              localFavoriteGuideline === a.UUID
            ) {
              return -1;
            }
            return 0;
          })
          .map((item: GuidelineType) => (
            <GuidelineCard
              key={item.UUID}
              isFavorite={
                localFavoriteGuideline === item.UUID ||
                userData?.FAVORITE_GUIDELINE_UUID === item.UUID
              }
              {...item}
            />
          ))}
        {isError && (guidelinesData?.length === 0 || !guidelinesData) && (
          <Box flex={1} justifyContent="center" alignItems="center" padding="m">
            <Text paddingBottom="l">{t("AInternet")}</Text>
            {DEBUGISON && <DummyApiComponent />}
            <DefaultButton
              label={t("GenTryAgain")}
              onPress={onRetry}
              mainColor="primary"
              color="primary"
              variant="border"
            />

            {DEBUGISON && <DummyGuidelines />}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Areal;
