import { DEBUGISON, HTMLDEBUGGING } from "../../../config";
import { getContent } from "../../../hooks/useLanguage";
import DecodeHTML from "./DecodeHTML";
import { HTMLPrecompiler } from "./HTMLPrecompiler";

interface htmlNode {
	CONTENT: string;
	CONTENT_EN: string;
}

let MetaId = "";
let MetaGuidelineId = "";

export const manipulateHTMLENV = (id: string, guidelineId: string) => {
	MetaId = id;
	MetaGuidelineId = guidelineId;
};

function ReferenceCompensation(Con: htmlNode | undefined): string {
	if (typeof Con == "undefined") {
		if (DEBUGISON && HTMLDEBUGGING) {
			return "undefined input string arriving at preprocessor.";
		}
		return "";
	}
	const EN = Con.CONTENT_EN;
	const DE = Con.CONTENT;
	const target = getContent(Con);
	let withReferences = "";

	if (target.includes("<reference")) {
		return target;
	}
	if (EN.includes("<reference")) {
		withReferences = EN;
	}
	if (DE.includes("<reference")) {
		withReferences = DE;
	}

	function replace(target: string, withRefs: string): string {
		const badRefRegex = /\[\d+\]/g;
		const goodRefRegex = /<reference id='\d+'>\[\d+\]<\/reference>/g;

		const baddies = [];
		const goodies = [];
		let match;
		while ((match = badRefRegex.exec(target)) != null) {
			const [fullMatch, start, end] = match;
			baddies.push(fullMatch);
		}

		while ((match = goodRefRegex.exec(withReferences)) != null) {
			const [fullMatch, start, end] = match;
			goodies.push(fullMatch);
		}

		const m: { [key: string]: string } = {};
		for (let i = 0; i < goodies.length; i++) {
			m[baddies[i]] = goodies[i];
		}

		Object.keys(m).map((key) => {
			target = target.replaceAll(key, m[key]);
		});

		if (baddies.length == goodies.length) {
			return target;
		}
		if (DEBUGISON && HTMLDEBUGGING) {
			return (
				"different number of references: " +
				"baddies: " +
				baddies.toString() +
				"   goodies: " +
				goodies.toString() +
				"\n\nOutput:  " +
				target +
				"\n\nDE" +
				DE +
				"\n\nEN" +
				EN
			);
		}
		return target;
	}

	return replace(target, withReferences);
}

function ReconstructFulltext(html: string): string {
	const badRefRegex = /\(([A-F0-9]+);\s([A-Za-z0-9\s.]+)\)/;
	const baddies = [];
	let match;
	while ((match = badRefRegex.exec(html)) != null) {
		html = html.replace(
			badRefRegex,
			`<span id="$1">see: $2 </span> `
			// `<fulltext id="$1" guidelineid="${MetaGuidelineId}>see: $2 </fulltext> `

			// `<fulltext id="$1" className="fulltext" >see: $2 </fulltext> `
		);
	}
	return html;
}

function Sequence(html: string): string {
	html = ReconstructFulltext(html);
	html = DecodeHTML(html);
	return html;
}

export const HTMLPreprocessor = new HTMLPrecompiler(
	// ReferenceCompensation,
	ReferenceCompensation,
	Sequence
);
