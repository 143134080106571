import React, { ReactNode } from "react";
import { StyleSheet } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { Box, useTheme } from "../../components";
import { DrawerContentComponentProps } from "@react-navigation/drawer/lib/typescript/src/types";
import DrawerBody from "./DrawerBody";
import DrawerHeader from "./DrawerHeader";
import {
  GuidelineRoutes,
  HomeRoutes,
  UserRoutes,
} from "../../components/Navigation";
import { translateScreenToMode } from "../../utils/translation";
import { DEBUGISON, ORIGINDEBUG, isWeb } from "../../config";
import { Text } from "../../components";

// receives state, navigation, descriptors, progress

function CustomDrawerContent(props: DrawerContentComponentProps) {
  let actualRoute: any = props.state.routes[props.state.index];

  while (actualRoute?.state) {
    if (actualRoute.state.index) {
      actualRoute = actualRoute.state.routes[actualRoute.state.index];
    } else {
      actualRoute =
        actualRoute.state.routes[actualRoute.state.routes.length - 1];
    }
  }
  const screen = actualRoute?.params?.screen || actualRoute?.name || "Home";
  return (
    <>
      <Background screen={screen} />
      <TopBackground />
      <Box flex={1} alignItems="flex-end" width="100%">
        <Box flex={1} width="100%" maxWidth={isWeb ? 500 : 400}>
          <Box
            marginHorizontal="m"
            marginTop={{ phone: "m", tablet: "xl" }}
            marginBottom={{ phone: "m", tablet: "l" }}
          >
            <DrawerHeader {...props} />
          </Box>
          <Box flex={1} justifyContent="space-between">
            {DEBUGISON && ORIGINDEBUG && <Text> Drawerbody is next</Text>}
            <DrawerBody {...props} />
          </Box>
        </Box>
      </Box>
      <RightGradientBorder screen={screen} />
    </>
  );
}

interface RightGradientBorderProps {
  screen: keyof GuidelineRoutes | keyof HomeRoutes | keyof UserRoutes;
}
const RightGradientBorder = ({ screen }: RightGradientBorderProps) => {
  const theme = useTheme();
  const mode = translateScreenToMode(screen);
  return (
    <LinearGradient
      colors={["transparent", theme.colors[mode], "transparent"]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
      locations={[0, 0.5, 1]}
      style={{
        width: 1,
        height: "100%",
        position: "absolute",
        right: 0,
        top: 0,
      }}
    ></LinearGradient>
  );
};

interface BackgroundProps {
  children?: ReactNode;
  screen: keyof GuidelineRoutes | keyof HomeRoutes | keyof UserRoutes;
}

const Background = ({ children, screen }: BackgroundProps) => {
  //const mode = useSelector<RootState, Mode>((state) => state.guidelines.mode);
  const theme = useTheme();
  const mode = translateScreenToMode(screen);
  //console.log(mode);
  return (
    <LinearGradient
      colors={[theme.colors.mainBackground, theme.colors[mode]]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      locations={[0.1, 1]}
      style={{
        ...StyleSheet.absoluteFillObject,
        opacity: 0.1,
      }}
    >
      {children}
    </LinearGradient>
  );
};

const TopBackground = () => {
  const theme = useTheme();
  return (
    <LinearGradient
      colors={[
        theme.colors.mainBackground,
        theme.colors.mainBackground,
        "rgba(255, 255, 255, 0)",
      ]}
      start={{ x: 0, y: 0 }}
      end={{ x: 0, y: 1 }}
      locations={[0, 0.3, 1]}
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        height: 300,
      }}
    />
  );
};

export default CustomDrawerContent;
