import React, { ReactNode } from "react";
import { DEBUGISON, isInDevelopment } from "../config";
import { View } from "react-native"; // Assuming Text and View are imported from 'react-native'
import { Text } from "../components";

interface DevViewProps {
  conditions?: boolean;
  array?: any[];
  obj?: Map<any, any>;
  children: ReactNode;
}

function DevText({
  conditions,
  array,
  obj,
  children,
}: DevViewProps): JSX.Element | null {
  if (
    isInDevelopment &&
    ((DEBUGISON && conditions === undefined) || (DEBUGISON && conditions))
  ) {
    let content: ReactNode = children;

    if (array && Array.isArray(array)) {
      content = (
        <View style={{ marginTop: 10 }}>
          {children && <Text>{children}</Text>}
          {array.length === 0 && <Text variant="tiny"> array is empty</Text>}
          {array.map((item, index) => (
            <Text key={index} variant="tiny">
              {JSON.stringify(item)}
            </Text>
          ))}
        </View>
      );
    }

    if (obj !== undefined && typeof obj === "object" && obj !== null) {
      content = (
        <View style={{ marginTop: 10 }}>
          {children && <Text>{children}</Text>}
          {Object.entries(obj).map(([key, value], index) => (
            <View key={index} style={{ flexDirection: "row", marginBottom: 2 }}>
              <Text variant="tiny">{key}:</Text>
              <Text variant="tiny">
                {typeof value === "object" ? JSON.stringify(value) : value}
              </Text>
            </View>
          ))}
        </View>
      );
    }

    return <Text variant="tiny">{content}</Text>;
  }

  return null;
}

export default DevText;
