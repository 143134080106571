import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { HomeRoutes } from "../../components/Navigation";
import Areal from "./Area";
import List from "./List";
import { forFade } from "../../navigation";
import { useTheme } from "../../components";

const HomeStack = createStackNavigator<HomeRoutes>();

const HomeNavigator = () => {
	const theme = useTheme();
	return (
		<HomeStack.Navigator
			screenOptions={{
				headerShown: false,
				gestureEnabled: false,
				cardStyleInterpolator: forFade,
				cardStyle: {
					backgroundColor: theme.colors.mainBackground,
				},
			}}
		>
			<HomeStack.Screen name="Areal" component={Areal} />
			<HomeStack.Screen name="List" component={List} />
		</HomeStack.Navigator>
	);
};

export default HomeNavigator;
