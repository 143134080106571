import React, { useCallback, useEffect, useRef, useState } from "react";
import { Platform, TextInput } from "react-native";
import { useAuth, useNoticeCreate } from "../../hooks";
import BorderlessTap from "../BorderlessTap";
import TextArea from "../Form/TextArea";
import Icon from "../Icon";
import NoticeList from "../NoticeList";
import { Box, Text } from "../Theme";
import PopupFooter from "./PopupFooter";
import moment from "moment";
import { randomString } from "../../utils";
import { useQueryClient } from "@tanstack/react-query";
import Date from "../Date";
import { useDispatch } from "react-redux";
import { togglePopup } from "../../redux/global";
import { NoticeType } from "../../types";
import { addLocalNotice } from "../../redux/user/actions";
import { PopupButton } from ".";
import t from "../../../Texts/TextImport";

const isWeb = Platform.OS === "web";

const PopupNotice = () => {
	const ref = useRef<TextInput>(null);
	const [text, setText] = useState("");
	const dispatch = useDispatch();
	const { username } = useAuth();
	const queryClient = useQueryClient();

	useEffect(() => {
		!isWeb && ref?.current?.focus();
	}, []);

	const notice: NoticeType = {
		ID: randomString(),
		USER_ID: username,
		CONTENT: text,
		CREATION_DATE: moment().format(),
		CREATION_TIME: moment().format(),
		PLATFORM: Platform.OS,
	};

	const { mutate } = useNoticeCreate({
		onSuccess: () => {
			queryClient.refetchQueries(["user", username]);
		},
		onError: () => {
			dispatch(addLocalNotice(notice));
		},
	});
	return (
		<>
			<Box margin="m" marginTop="s" minHeight={100}>
				<Box padding="s" paddingBottom="none" backgroundColor="mainBackground">
					<Date variant="description" />
				</Box>
				<TextArea
					ref={ref}
					value={text}
					onChangeText={setText}
					placeholder="Meine Notiz"
					style={{ paddingTop: 0 }}
				/>
			</Box>
			<PopupFooter
				singleButton={false}
				onSuccess={() => {
					mutate(notice);
					dispatch(togglePopup({ isVisible: false }));
				}}
				successText={t("GenSave")}
			/>
		</>
	);
};

const PopupNoticeList = () => {
	const dispatch = useDispatch();
	const onCreateNotice = () => {
		dispatch(
			togglePopup({
				isVisible: true,
				title: t("GenNewNote"),
				content: <PopupNotice />,
			})
		);
	};

	return (
		<>
			<Box margin="m" marginTop="s" minHeight={100}>
				<Box paddingBottom="m" flexDirection="row">
					<PopupButton
						onPress={onCreateNotice}
						iconName="plus"
						text={t("GenNewNote")}
					/>
				</Box>
				<NoticeList />
			</Box>
			<PopupFooter />
		</>
	);
};

export default PopupNotice;
export { PopupNoticeList };
