import {
	QueryFunctionContext,
	QueryObserverOptions,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import * as FileSystem from "expo-file-system";
import { IMAGE, MET, URL, SECTOR, LANGUAGE } from "./config";
import { ImageType } from "../../types";
import { DEBUGISON, IMAGEDEBUG, ROOT } from "../../config";
import { LANGUAGE_SUFFIX } from "../useLanguage";

export const getImages = async (context: QueryFunctionContext) => {
	const params = {
		met: MET,
		SECTOR: SECTOR,
		LANGUAGE: LANGUAGE,
		entity: IMAGE,
		query: `CONTENT.GUIDELINE_UUID="${context.queryKey[1]}"`,
		attributes: "UUID,CONTENT_UUID",
	};
	const { data } = await axios.get(URL, { params });

	data.map((image: ImageType) => {
		// URL in production
		const uri = ROOT + "cms_photos/" + image.UUID + ".png";
		const fileUri = FileSystem.documentDirectory + image.UUID + ".png";
		FileSystem.downloadAsync(uri, fileUri).then(() => {
			console.log("downloadAsync Image: ", image.UUID);
		});
	});

	return data;
};

export const ensureImageExists = async (uuid: string) => {
	const path = FileSystem.documentDirectory + uuid;
	const info = await FileSystem.getInfoAsync(path);
	if (info.exists) {
		DEBUGISON && IMAGEDEBUG && console.error(info.exists + ": image exists");
		return info.uri;
	}
	console.error(uuid + " does not exits");
	return false;
};
export const ensurePathExists = async (path: string) => {
	const info = await FileSystem.getInfoAsync(path);
	if (info.exists) {
		console.error(info.exists + ": image exists");
		return info.uri;
	}
	DEBUGISON && IMAGEDEBUG && console.error(path + " does not exits");
	return false;
};

export const readImage = async (uuid: string) => {
	const fileUri = FileSystem.documentDirectory + uuid;
	const filestring = await FileSystem.readAsStringAsync(fileUri, {
		encoding: FileSystem.EncodingType.Base64,
	});
	DEBUGISON &&
		IMAGEDEBUG &&
		console.log("flestring: " + filestring.substring(0, 20));
	return filestring;
};

export const readImageAfterDownload = async (uuid: string) => {
	const uri = ROOT + "cms_photos/" + uuid;
	const fileUri = FileSystem.documentDirectory + uuid;
	try {
		const downloadResult = await FileSystem.downloadAsync(uri, fileUri);
		if (downloadResult.status === 200) {
			console.log("File downloaded successfully!");
			console.log("File URI:", downloadResult.uri);
			// You can now use the downloaded file URI
		} else {
			DEBUGISON &&
				IMAGEDEBUG &&
				console.error(
					"File download failed with status",
					downloadResult.status
				);
		}
	} catch (error) {
		DEBUGISON &&
			IMAGEDEBUG &&
			console.error("Error while downloading file:", error);
	}
	return readImage(uuid);
};
export async function clearPNGFilesFromDocumentDirectory() {
	try {
		const files = await FileSystem.readDirectoryAsync(
			FileSystem.documentDirectory
		);
		for (const file of files) {
			if (file.endsWith(".png")) {
				await FileSystem.deleteAsync(`${FileSystem.documentDirectory}${file}`);
			}
		}
		DEBUGISON &&
			IMAGEDEBUG &&
			console.error("Cleared all PNG files from document directory");
	} catch (error) {
		console.error(error);
	}
}
export async function deleteImage(uuid: string) {
	try {
		const files = await FileSystem.readDirectoryAsync(
			FileSystem.documentDirectory
		);
		for (const file of files) {
			if (file.includes(uuid)) {
				await FileSystem.deleteAsync(`${FileSystem.documentDirectory}${file}`);
			}
		}
		DEBUGISON && IMAGEDEBUG && console.error("deleted file with uuid: " + uuid);
	} catch (error) {
		console.error(error);
	}
}

export default function useImages(
	guidelineId: string,
	options?: QueryObserverOptions<ImageType[]>
) {
	return useQuery<ImageType[]>(["images", guidelineId], getImages, options);
}
