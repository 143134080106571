import { lt } from "cypress/types/lodash";
import React from "react";
import { HTMLMap } from "./HTMLMap";
import { UnitMap } from "./UnitMap";
import { DEBUGISON, HTMLDEBUGGING } from "../../../config";
import { devLog } from "../../../utils/logging";

function DecodeHTML(HTMLdata: string) {
  function decodeUnits() {
    DEBUGISON && HTMLDEBUGGING && console.log("DECODING UNITS");
    let data = HTMLdata;
    let shouldscan = true;

    while (shouldscan) {
      HTMLMap.forEach((el) => {
        data = data.replace(Object.keys(el)[0], Object.values(el)[0]);
        data.includes("\r\n") && devLog("unwanted chars");
      });
      shouldscan = false;
      HTMLMap.forEach((el) => {
        if (data.includes(Object.keys(el)[0])) {
          shouldscan = true;
        }
      });

      UnitMap.forEach((el) => {
        if (data === Object.keys(el)[0]) {
          data = Object.values(el)[0];
        }
      });
    }

    return data;
  }

  return decodeUnits();
}

export default DecodeHTML;
