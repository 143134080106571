import { useEffect } from "react";
import { useQueryClient, useQueries } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useSelector } from "../redux";
import { useBookmarkCreate, useBookmarkDelete } from "./queries/useBookmark";
import { useNoticeCreate, useNoticeDelete } from "./queries/useNotice";
import useUser, { logUserAttributes, useUserMutation } from "./queries/useUser";
import { useAuth } from "./useAuth";
import {
  removeLocalBookmark,
  removeLocalBookmarkDelete,
  removeLocalNotice,
  removeLocalNoticeDelete,
  resetLocalFavoriteGuideline,
} from "../redux/user/actions";
import { useGuidelines } from "./queries/useGuideline";
import {
  getGuidelineHash,
  getInitialData,
  useInitializeGeneral,
} from "./queries/useInitialize";
import { initializeToStorage } from "../utils";

const useOnStart = () => {
  const queryClient = useQueryClient();
  const { username } = useAuth();
  const { data: userFetch } = useUser(username);
  const dispatch = useDispatch();

  const { data: guidelinesData, isSuccess: guidelinesSuccess } =
    useGuidelines();
  const autoUpdate = useSelector((state) => state.global.autoUpdate);

  const localFavoriteGuideline = useSelector(
    (state) => state.user.localFavoriteGuideline
  );
  const localBookmarks = useSelector((state) => state.user.localBookmarks);
  const localBookmarksDelete = useSelector(
    (state) => state.user.localBookmarksDelete
  );
  const localNotices = useSelector((state) => state.user.localNotices);
  const localNoticesDelete = useSelector(
    (state) => state.user.localNoticesDelete
  );

  const { mutate: mutateUser } = useUserMutation();
  const { mutate: mutateCreateBookmark } = useBookmarkCreate();
  const { mutate: mutateDeleteBookmark } = useBookmarkDelete();
  const { mutate: mutateCreateNotice } = useNoticeCreate();
  const { mutate: mutateDeleteNotice } = useNoticeDelete();

  useEffect(() => {
    if (localFavoriteGuideline !== "" && guidelinesSuccess) {
      mutateUser(
        {
          ID: username,
          FAVORITE_GUIDELINE_UUID: localFavoriteGuideline,
        },
        {
          onSuccess: (newUser: any) => {
            queryClient.setQueryData(["user", username], newUser);
            dispatch(resetLocalFavoriteGuideline());
          },
        }
      );
    }

    localBookmarks.forEach((bookmark) => {
      mutateCreateBookmark(bookmark, {
        onSuccess: () => {
          dispatch(removeLocalBookmark(bookmark));
          queryClient.refetchQueries(["user", username]);
        },

        onError: (e) => {
          console.log(`e.code: ${e?.code} e.config.data ${e?.config}`);
          if (e?.code === "ERR_NETWORK" && e?.config && !e?.config?.data) {
            dispatch(removeLocalBookmark(bookmark));
          }
        },
      });
    });

    localBookmarksDelete.forEach((bookmark) => {
      mutateDeleteBookmark(bookmark, {
        onSuccess: () => {
          dispatch(removeLocalBookmarkDelete(bookmark));
          queryClient.refetchQueries(["user", username]);
        },

        onError: (e) => {
          console.log(`e.code: ${e?.code} e.config.data ${e?.config}`);
          if (e?.code === "ERR_NETWORK" && e?.config && !e?.config?.data) {
            dispatch(removeLocalBookmarkDelete(bookmark));
          }
        },
      });
    });

    localNotices.forEach((notice) => {
      mutateCreateNotice(notice, {
        onSuccess: () => {
          dispatch(removeLocalNotice(notice));
          queryClient.refetchQueries(["user", username]);
        },
        onError: (e) => {
          if (e?.code === "ERR_NETWORK" && e?.config && !e?.config?.data) {
            dispatch(removeLocalNotice(notice));
          }
        },
      });
    });

    localNoticesDelete.forEach((notice) => {
      mutateDeleteNotice(notice, {
        onSuccess: () => {
          dispatch(removeLocalNoticeDelete(notice));
          queryClient.refetchQueries(["user", username]);
        },
        onError: (e) => {
          console.log(`e.code: ${e?.code} e.config.data ${e?.config}`);
          if (e?.code === "ERR_NETWORK" && e?.config && !e?.config?.data) {
            dispatch(removeLocalNoticeDelete(notice));
          }
        },
      });
    });
  }, [
    dispatch,
    localBookmarks,
    localBookmarksDelete,
    localNotices,
    localNoticesDelete,
    localFavoriteGuideline,
    username,
    guidelinesSuccess,
    mutateCreateBookmark,
    mutateDeleteBookmark,
    mutateCreateNotice,
    mutateDeleteNotice,
    mutateUser,
    queryClient,
  ]);
};

export default useOnStart;
