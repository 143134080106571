import React from "react";
import t from "../../../Texts/TextImport";
import { useAnalytics } from "../../hooks";
import { LANGUAGE_SUFFIX, getContent } from "../../hooks/useLanguage";
import { ModeType, ReferenceType } from "../../types";
import HTML from "../HTML";
import Icon from "../Icon";
import { Box, Text } from "../Theme";
import PopupFooter from "./PopupFooter";

interface PopupRefContentProps {
	mode: ModeType;
	data: ReferenceType;
}
const PopupRefContent = ({ mode, data }: PopupRefContentProps) => {
	useAnalytics({
		term: `[${data.ORDER}]`,
		id: `${data.ORDER}`,
		screen: "reference",
	});
	return (
		<>
			<Box
				margin="m"
				marginTop="s"
				padding="s"
				backgroundColor="mainBackground"
			>
				<Box flexDirection="row" alignItems="center" paddingBottom="s">
					<Text variant="description">{t("PRCSource")}</Text>
				</Box>
				<Text>{data["CONTENT" + LANGUAGE_SUFFIX]}</Text>
			</Box>
			<PopupFooter />
		</>
	);
};

export default PopupRefContent;
