import React from "react";
import * as WebBrowser from "expo-web-browser";
import { Text } from "./Theme";
import { DEBUGISON, HTMLDEBUGGING } from "../config";

interface LinkPdfProps {
	url: string;
	title: string;
}
const LinkPdf = ({ url, title }: LinkPdfProps) => {
	const handlePress = async () => {
		await WebBrowser.openBrowserAsync(url);
	};
	DEBUGISON && HTMLDEBUGGING && console.log("PDF Link embedded");

	return (
		<Text
			textDecorationLine="underline"
			textDecorationStyle="dotted"
			onPress={handlePress}
		>
			{DEBUGISON && " LinkPdf: " + url}
			{title}
			{DEBUGISON && " ================\nLink: " + url + "================\n"}
		</Text>
	);
};

export default LinkPdf;
