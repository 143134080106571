import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Date, LinkPdf } from "..";
import t from "../../../Texts/TextImport";
import { useAnalytics, useArs } from "../../hooks";
import { LANGUAGE_SUFFIX, getContent, getTitle } from "../../hooks/useLanguage";
import { useSelector } from "../../redux";
import { togglePopup } from "../../redux/global";
import { ModeType, PDFType, TagType } from "../../types";
import ArsTable from "../ArsTable";
import HTML from "../HTML";
import Icon from "../Icon";
import { Box, Text } from "../Theme";
import PopupFooter from "./PopupFooter";
import { PopupTagHeader } from "./PopupHeader";
import {
  HTMLPreprocessor,
  manipulateHTMLENV,
} from "../HTML/HTMLRendering/HTMLPreprocessing";
import {
  APPBRANCH,
  BACKENDTRACING,
  DEBUGISON,
  ORIGINDEBUG,
  ROOT,
  isErknet,
} from "../../config";
import DevText from "../../utils/DevText";
import { generateUniqueId } from "../../utils/helpers";
interface PopupTagContentProps {
  mode: ModeType;
  data: TagType;
}
const PopupTagContent = ({ mode, data }: PopupTagContentProps) => {
  useAnalytics({ term: getTitle(data), id: data.UUID, screen: "glossary" });
  const ars = useArs(getTitle(data));
  const dispatch = useDispatch();
  const reduxPopup = useSelector((state) => state.global.popup);

  useEffect(() => {
    if (ars.data && ars.data.length > 0) {
      dispatch(
        togglePopup({
          ...reduxPopup,
          header: (
            <PopupTagHeader
              redHand={data.ROTE_HAND.length > 0}
              tag={true}
              ars={true}
            />
          ),
        })
      );
    }
  }, [ars.data]);
  return (
    <>
      <Box
        marginHorizontal="m"
        marginVertical="m"
        padding="s"
        backgroundColor="mainBackground"
      >
        <DevText obj={data}> data in PopupTagContent: </DevText>
        <Box flexDirection="row" alignItems="center" paddingBottom="s">
          <Icon name="informations" size={18} color="mainForeground" />
          <Text paddingLeft="xs" lineHeight={15} variant="description">
            {t("PTCGlossary")}
            {DEBUGISON && ORIGINDEBUG && "PopupTagContent"}
          </Text>
        </Box>
        <HTML mode={mode} html={HTMLPreprocessor.precompile(data)} />
        <Box paddingVertical="m">
          <Text>
            {t("PTCState")} <Date date={data.CHANGE_DATE} withDay={false} />
          </Text>
        </Box>
      </Box>
      {ars.data && ars.data.length > 0 && !isErknet && (
        <Box
          marginHorizontal="m"
          marginBottom="m"
          padding="s"
          backgroundColor="mainBackground"
        >
          <Box flexDirection="row" alignItems="center" paddingBottom="s">
            <Icon name="statistics" size={18} color="mainForeground" />
            <Text paddingLeft="xs" variant="description">
              {t("PTCData")}
            </Text>
          </Box>
          <ArsTable data={ars.data} />
        </Box>
      )}
      {data.ROTE_HAND.length && !isErknet && (
        <Box
          marginHorizontal="m"
          marginBottom="m"
          padding="s"
          backgroundColor="mainBackground"
        >
          {data.ROTE_HAND.map((el) => (
            <RedHand {...el} key={generateUniqueId()} />
          ))}
        </Box>
      )}
      {data.IQWIG.length > 0 && (
        <Box
          marginHorizontal="m"
          marginBottom="m"
          padding="s"
          backgroundColor="mainBackground"
        >
          <IqWig {...data.IQWIG[0]} />
        </Box>
      )}
      {data.GBA.length > 0 && (
        <Box
          marginHorizontal="m"
          marginBottom="m"
          padding="s"
          backgroundColor="mainBackground"
        >
          <Gba {...data.GBA[0]} />
        </Box>
      )}
      <PopupFooter />
    </>
  );
};

export default PopupTagContent;

const RedHand = (redHand: PDFType) => {
  const url = `${ROOT}cms_pdf/${redHand.UUID}.pdf`;
  DEBUGISON && BACKENDTRACING && console.log(url);
  return (
    <Box>
      <DevText obj={redHand}> Pdf Information: </DevText>
      <Box flexDirection="row" alignItems="center" paddingBottom="xs">
        <Icon name="hand" color="danger" />
        <Text variant="description" color="danger" paddingLeft="xs">
          {DEBUGISON && ORIGINDEBUG && "Redhand"}
          {t("PTCPdf")}
        </Text>
      </Box>
      <LinkPdf title={getTitle(redHand)} url={url} />
      <Text variant="tiny">
        {DEBUGISON && BACKENDTRACING && url}
        {t("PTCSource")} {redHand.SOURCE}
      </Text>
      <Text variant="tiny">
        {t("PTCPublished")}{" "}
        <Date date={redHand.PUBLISHED_DATE} variant="tiny" />
      </Text>
    </Box>
  );
};

const IqWig = (iqwig: PDFType) => {
  return (
    <Box>
      <Box flexDirection="row" alignItems="center" paddingBottom="xs">
        <Icon name="statistics" color="mainForeground" />
        <Text variant="description" paddingLeft="xs">
          {t("PTCIQ")}

          {DEBUGISON && ORIGINDEBUG && "IqWig"}
        </Text>
      </Box>
      <LinkPdf
        title={getTitle(iqwig)}
        url={`${URL}cms_pdf/${iqwig.UUID}.pdf`}
      />
      <Text variant="tiny">
        {t("PTCSource")}
        {iqwig.SOURCE}
      </Text>
      <Text variant="tiny">
        {t("PTCPublished")}
        <Date date={iqwig.PUBLISHED_DATE} variant="tiny" />
      </Text>
    </Box>
  );
};

const Gba = (gba: PDFType) => {
  return (
    <Box>
      <Box flexDirection="row" alignItems="center" paddingBottom="xs">
        <Icon name="statistics" color="mainForeground" />
        <Text variant="description" paddingLeft="xs">
          {DEBUGISON && ORIGINDEBUG && "Gba"}
          {t("PTCGBA")}
        </Text>
      </Box>
      <LinkPdf title={getTitle(gba)} url={`${URL}cms_pdf/${gba.UUID}.pdf`} />
      <Text variant="tiny">
        {t("PTCSource")}
        {gba.SOURCE}
      </Text>
      <Text variant="tiny">
        {t("PTCSource")}
        <Date date={gba.PUBLISHED_DATE} variant="tiny" />
      </Text>
    </Box>
  );
};
