import { APPBRANCH, backends, Language, Sector } from "../../config";

export const USER = "LEILA_USER";
export const SCORE = "LEILA_SCORE";
export const HORIZONTAL = "GENERATED_CONNECTED_GUIDELINE";
export const AREA = "AREA";
export const BOOKMARK = "LEILA_USER_BOOKMARK";
export const NOTICE = "LEILA_USER_NOTICE";
export const GLOSSARY = "LEILA_TAG";
export const RECOMMENDATION = "LEILA_RECOMMENDATION";
export const GUIDELINE = "LEILA_BASICS";
export const QR = "LEILA_QRCODE";
export const REFERENCE = "LEILA_SOURCE";
export const CHAPTER = "LEILA_CONTENT";
export const PATHWAY = "LEILA_PATHWAY";
export const ABOUT = "LEILA_INFOS";
export const TAG = "LEILA_TAG";
export const ARS = "ARS_DATA";
export const POST_REQUEST = "POST";
export const DELETE_REQUEST = "DELETE";
export const GET_REQUEST = "GET"; // optional
export const FEEDBACK = "LEILA_FEEDBACK";
export const EXPORT = "LEILA_EXPORT";
export const EXPORT_GENERAL = "LEILA_EXPORT_ALL";
export const IMAGE = "LEILA_CONTENT_IMAGE";
export const MET = "REST";
export const URL = backends[APPBRANCH];
export const SECTOR = Sector[APPBRANCH];
export const LANGUAGE = Language[APPBRANCH];
