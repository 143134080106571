import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  HTML,
  Icon,
  Text,
  TextInput,
  useTheme,
} from "../../../components";
import { usePathway, useScore } from "../../../hooks";
import Dot from "./Dot";
import HistoryList, { HistoryStepProps } from "./HistoryList";
import { LinearGradient } from "expo-linear-gradient";
import t from "../../../../Texts/TextImport";
import { getContent, getTitle } from "../../../hooks/useLanguage";
import {
  BACKENDTRACING,
  DEBUGISON,
  HTMLDEBUGGING,
  PATHWAYDEBUG,
  PATHWAYDEBUGGING,
} from "../../../config";
import DevText from "../../../utils/DevText";

const size = 10;
interface PathwayProps {
  id: string;
  scoreId: string;
  guidelineId: string;
}

const Pathway = ({ id: pathwayId, scoreId, guidelineId }: PathwayProps) => {
  const theme = useTheme();
  const [history, setHistory] = useState<HistoryStepProps[]>([]);
  const [id, setId] = useState<undefined | string>();
  const [scoreIndex, setScoreIndex] = useState(0);
  const [inputText, setInputText] = useState("");
  const isScore = scoreId !== "";
  const pathway = usePathway(pathwayId, guidelineId);
  const score = useScore(scoreId, () => {}, { enabled: scoreId !== "" });

  useEffect(() => {
    if (pathway.data && !isScore) {
      setId(pathway.data.find((i) => i.START_TREE)?.UUID);
    }
    setHistory([]);
  }, [pathwayId, scoreId, pathway.data]);

  const question = !isScore
    ? pathway.data?.find((i) => i.UUID === id)
    : score.data?.QUESTIONS[scoreIndex];
  const answers = !isScore
    ? pathway.data?.filter((i) => i.PARENT_UUID === id)
    : score.data?.QUESTIONS[scoreIndex].ANSWERS;

  const onAnswer = (answerId: string) => {
    // @ts-expect-error find function
    const answer = answers?.find((i) => i.UUID === answerId);
    if (isScore && question && question.TYPE === "INPUT") {
      const value = inputText !== "" ? parseInt(inputText) : 0;
      answer.VALUE = value;
      answer.TITLE = value;
    }
    history.push({
      question,
      answer,
    });
    if (!isScore) {
      const newQuestion = pathway.data?.find((i) => i.PARENT_UUID === answerId);
      setId(newQuestion?.UUID);
    } else {
      if (score.data) {
        setScoreIndex((index) =>
          index < score.data?.QUESTIONS.length - 1 ? index + 1 : index
        );
      }
    }
  };

  const onHistory = (index: number) => {
    if (!isScore) {
      setId(history[index].question?.UUID);
    } else {
      setScoreIndex(index);
    }
    history.splice(index, history.length);
    setHistory(history);
  };

  const isFinished =
    (answers && answers.length === 0 && history.length > 0) ||
    (score?.data && score.data?.QUESTIONS.length - 1 === scoreIndex)
      ? true
      : false;

  let sum = 0;
  if (isScore) {
    history.forEach((i) => {
      if (i.answer?.VALUE) {
        sum = sum + i.answer.VALUE;
      }
    });
  }

  const result =
    isFinished &&
    isScore &&
    score.data?.RESULTS.find((i) => i.VALUE_FROM <= sum && i.VALUE_TO >= sum);

  return (
    <>
      <DevText conditions={PATHWAYDEBUGGING}>isFinished: {isFinished}</DevText>

      <DevText conditions={PATHWAYDEBUGGING}>
        question defined: {question ? "Yes" : "No"}
      </DevText>

      <DevText conditions={PATHWAYDEBUGGING}>
        question content: {getContent(question).substring(0, 20)}
      </DevText>

      <DevText conditions={PATHWAYDEBUGGING}>
        questiontype: {question?.TYPE ? question.TYPE : "undefined"}
      </DevText>
      {pathway?.data || score?.data ? (
        <Box height="100%" flex={1} flexDirection="row" paddingRight="l">
          <LinearGradient
            style={{
              width: 1,
              marginHorizontal: theme.spacing.m,
              marginTop: theme.spacing.s,
            }}
            colors={[theme.colors.mainForeground, "transparent"]}
          />
          <Box flex={1}>
            <Box
              style={{
                transform: [{ translateX: -size / 2 - theme.spacing.m }],
              }}
            >
              <HistoryList history={history} onClick={onHistory} />
              <Box marginTop="s">
                {!isFinished ? (
                  <>
                    {DEBUGISON && PATHWAYDEBUG && (
                      <Text marginVertical={"m"}>not Finished</Text>
                    )}
                    <Dot size={size} idx={history.length === 0 ? 0 : 1} />
                  </>
                ) : (
                  <>
                    {DEBUGISON && PATHWAYDEBUG && (
                      <Text marginVertical={"m"}>not Finished</Text>
                    )}
                    <Box
                      height={4}
                      width={size}
                      backgroundColor="mainForeground"
                    />
                  </>
                )}
              </Box>
            </Box>
            <Box
              flex={1}
              marginRight="m"
              style={{ transform: [{ translateY: -size * 1.5 }] }}
            >
              {isFinished ? (
                <Box
                  flexDirection="row"
                  alignItems="center"
                  paddingVertical="s"
                >
                  {DEBUGISON && PATHWAYDEBUG && (
                    <Text marginVertical={"m"}>FlagIcon</Text>
                  )}
                  <Icon name="flag" size={30} color="mainForeground" />
                  {isScore && (
                    <Text
                      variant="subtitle1"
                      fontFamily="bliss-pro-medium"
                      paddingLeft="m"
                    >
                      {t("RResult")} {sum}
                    </Text>
                  )}
                </Box>
              ) : null}

              {(!isScore || !isFinished) && getTitle(question) ? (
                <>
                  <HTML
                    html={"<p>" + getTitle(question) + "</p>"}
                    mode={"stepbystep"}
                  />

                  <DevText conditions={HTMLDEBUGGING}>
                    {" "}
                    {getTitle(question)}
                  </DevText>
                </>
              ) : null}
              {(!isScore || !isFinished) && getContent(question) ? (
                <>
                  <HTML html={getContent(question)} mode={"stepbystep"} />

                  <DevText conditions={HTMLDEBUGGING}>
                    {" "}
                    {getContent(question)}
                  </DevText>
                </>
              ) : null}
              {isScore && isFinished && getContent(result) ? (
                <>
                  <HTML html={getContent(result)} mode={"stepbystep"} />
                  <DevText conditions={HTMLDEBUGGING}>
                    {" "}
                    {getContent(result)}
                  </DevText>
                </>
              ) : null}

              <Box flexDirection="row" flexWrap="wrap">
                {!isFinished && question && (
                  <>
                    {question.TYPE !== "INPUT" ? (
                      answers?.map((answer, idx) => {
                        return (
                          <Box key={idx} marginRight="s" marginVertical="s">
                            {DEBUGISON && PATHWAYDEBUG && (
                              <Text marginVertical={"m"}>Answers</Text>
                            )}
                            <Button
                              label={getTitle(answer)}
                              onPress={() => onAnswer(answer.UUID)}
                              mainColor="stepbystep"
                              color="mainForeground"
                              variant="border"
                            />
                          </Box>
                        );
                      })
                    ) : (
                      <Box flex={1} marginVertical="s" maxWidth={300}>
                        {DEBUGISON && PATHWAYDEBUG && (
                          <Text marginVertical={"m"}>Input</Text>
                        )}
                        <TextInput
                          keyboardType="numeric"
                          onChangeText={(text) => setInputText(text)}
                        />
                        <Box marginTop="s">
                          <Button
                            label={t("GenSave")}
                            onPress={() => {
                              if (answers && answers.length > 0) {
                                onAnswer(answers[0].UUID);
                              }
                            }}
                            mainColor="stepbystep"
                            color="mainForeground"
                            variant="border"
                          />
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default Pathway;
