import React, { useState, forwardRef } from "react";
import {
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
} from "react-native";
import { useTheme } from "./Theme";
import { invalid } from "moment";
import { Text } from "./Theme";
import t from "../../Texts/TextImport";

export type TextInputProps = RNTextInputProps;

function isValidNumber(newValue: string) {
  // Remove leading/trailing whitespace
  const trimmedValue = newValue.trim();

  // Regular expression to match integers or floats
  const validNumberPattern = /^-?\d*([,.]\d*)?$/;
  return validNumberPattern.test(trimmedValue);
}

/* eslint-disable */
const NumberInput = forwardRef<RNTextInput, TextInputProps>(
  ({ style, onFocus, onChangeText, ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState<number | string>();
    const [error, setError] = useState<string>("");
    const theme = useTheme();

    const validateNumber = (val: string) => {
      const before = value ? value : "";
      val = val.replace(",", ".");
      if (isValidNumber(val)) {
        console.log("isvalid");

        // Call the parent's onChangeText with the valid numeric value
        setValue(val);
        onChangeText?.(val);

        setError("");
      } else {
        setValue(before);

        onChangeText?.("");
        console.log("invalid: " + value);
        setError(t("ValidNumber"));
      }
    };

    return (
      <>
        <RNTextInput
          keyboardType="numeric"
          value={value?.toString()}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChangeText={validateNumber} // Use the validateNumber function
          {...props}
          style={[
            {
              flex: 1,
              padding: theme.spacing.s,
              borderRadius: theme.spacing.xs,
              borderWidth: 1,
              borderColor: isFocused
                ? theme.colors.primary
                : theme.colors.information,
              backgroundColor: theme.colors.mainBackground,
              fontSize: 18,
              lineHeight: 24,
              fontFamily: "bliss-pro-light",
              color: theme.colors.mainForeground,
            },
            style,
          ]}
        />
        <Text color="primary">{error}</Text>
      </>
    );
  }
);

export default NumberInput;

/* eslint-enable */
