import React, { useEffect, useRef, useState } from "react";
import { Box, Text, useTheme } from "../Theme";
import Icon from "../Icon";
import BorderlessTap from "../BorderlessTap";
import { useDispatch } from "react-redux";
import { togglePopup } from "../../redux/global";
import PopupButton from "./PopupButton";
import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "../Toast";
import { useAuth, useNoticeDelete } from "../../hooks";
import { addLocalNoticeDelete } from "../../redux/user/actions";
import { NoticeType } from "../../types";
import { Clipboard } from "react-native";
import { BinaryAlert } from "../Alert";
import t from "../../../Texts/TextImport";
import { getChapterById } from "../../hooks/queries/useChapter";
import { LANGUAGE_SUFFIX, getContent } from "../../hooks/useLanguage";

interface PopupFooterProps {
	singleButton?: boolean;
	successText?: string;
	onSuccess: () => void;
}
const PopupFooter = ({
	singleButton,
	successText,
	onSuccess,
}: PopupFooterProps) => {
	const dispatch = useDispatch();
	const onClose = () => dispatch(togglePopup({ isVisible: false }));

	if (singleButton) {
		return (
			<Box alignItems="center" marginBottom="m">
				<PopupButton
					iconName="buttonAbort"
					text={t("GenClose")}
					onPress={onClose}
				/>
			</Box>
		);
	} else {
		return (
			<Box
				flex={1}
				flexDirection="row"
				justifyContent="center"
				alignItems="center"
			>
				<Box
					flex={1}
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
					marginHorizontal="m"
					marginBottom="m"
					maxWidth={450}
				>
					<PopupButton
						iconName="buttonAbort"
						iconColor="danger"
						text={t("GenAbort")}
						onPress={onClose}
					/>
					<PopupButton
						iconName="buttonSave"
						iconColor="success"
						text={successText || ""}
						onPress={onSuccess}
					/>
				</Box>
			</Box>
		);
	}
};

const PopupFooterNotice = (notice: NoticeType) => {
	const queryClient = useQueryClient();
	const { show } = useToast();
	const { username } = useAuth();
	const dispatch = useDispatch();
	const { mutate } = useNoticeDelete({
		onSuccess: () => {
			queryClient.refetchQueries(["user", username]);
			dispatch(togglePopup({ isVisible: false }));
		},
		onError: () => {
			dispatch(addLocalNoticeDelete(notice));
			dispatch(togglePopup({ isVisible: false }));
		},
	});
	const onClipboard = () => {
		Clipboard.setString(getContent(notice));
		show("In Zwischenablage gespeichtert");
	};
	return (
		<Box
			flex={1}
			flexDirection="row"
			justifyContent="center"
			alignItems="center"
		>
			<Box
				flex={1}
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				marginHorizontal="m"
				marginBottom="m"
				maxWidth={450}
			>
				<PopupButton iconName="copy" text="Kopieren" onPress={onClipboard} />
				<PopupButton
					iconName="bin"
					text="Löschen"
					onPress={() => {
						BinaryAlert({
							title: "Wirklich löschen?",
							desc: "",
							onPositivePress: () =>
								mutate({
									ID: notice.ID,
								}),
							onNegativePress: () => null,
						});
					}}
				/>
			</Box>
		</Box>
	);
};

PopupFooter.defaultProps = {
	singleButton: true,
	onSuccess: () => {},
};

export default PopupFooter;
export { PopupFooterNotice };
