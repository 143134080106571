import React, { createContext, ReactNode, useContext, useState } from "react";
import { Animated } from "react-native";
import { Box, Text, useTheme } from "../Theme";

const DURATION = 500;
const DELAY = 800;
const AnimatedBox = Animated.createAnimatedComponent(Box);
const maxWidth = 800;

interface ToastState {
  isShow: boolean;
  text: string;
}

interface ToastContextProps {
  show: (text: string) => void;
  state: ToastState;
  opacity: Animated.Value | undefined;
}

const ToastContext = createContext<ToastContextProps>({
  show: () => {},
  state: { text: "", isShow: false },
  opacity: undefined,
});

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("Toast Context Component Error");
  }
  return context;
};

interface ToastProviderProps {
  children: ReactNode;
}

const ToastProvider = ({ children }: ToastProviderProps) => {
  const opacity = new Animated.Value(1);
  const [state, setState] = useState<{ isShow: boolean; text: string }>({
    isShow: false,
    text: "",
  });

  const show = (text: string) => {
    setState({
      isShow: true,
      text,
    });
    animation().start(() => {
      setState({ ...state, isShow: false });
    });
  };

  function animation() {
    return Animated.sequence([
      Animated.timing(opacity, {
        toValue: 1,
        duration: DURATION,
        useNativeDriver: true,
      }),
      Animated.timing(opacity, {
        toValue: 0,
        delay: DELAY,
        duration: DURATION,
        useNativeDriver: true,
      }),
    ]);
  }

  const value = { show, state, opacity };

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};

export const Toast = () => {
  const theme = useTheme();
  const { state, opacity } = useToast();
  return (
    <>
      {state.isShow ? (
        <Box
          position="absolute"
          left={0}
          right={0}
          bottom={100}
          alignItems="center"
          zIndex={9999}
          maxWidth={maxWidth}
        >
          <AnimatedBox
            backgroundColor="mainForeground"
            paddingVertical="s"
            paddingHorizontal="m"
            borderRadius={theme.spacing.xl}
            style={{ opacity }}
          >
            <Text color="mainBackground">{state.text}</Text>
          </AnimatedBox>
        </Box>
      ) : null}
    </>
  );
};

export default ToastProvider;
