import {
  userState,
  UserActionTypes,
  SET_LOCAL_FAVORITE_GUIDELINE,
  ADD_LOCAL_NOTICE,
  ADD_LOCAL_BOOKMARK,
  RESET_LOCAL_FAVORITE_GUIDELINE,
  REMOVE_LOCAL_BOOKMARK,
  ADD_LOCAL_BOOKMARK_DELETE,
  REMOVE_LOCAL_BOOKMARK_DELETE,
  REMOVE_LOCAL_NOTICE,
  ADD_LOCAL_NOTICE_DELETE,
  REMOVE_LOCAL_NOTICE_DELETE,
} from "./types";

const initialState: userState = {
  localBookmarks: [],
  localBookmarksDelete: [],
  localNotices: [],
  localNoticesDelete: [],
  localFavoriteGuideline: "",
};

export default (state = initialState, action: UserActionTypes) => {
  switch (action.type) {
    case SET_LOCAL_FAVORITE_GUIDELINE: {
      return {
        ...state,
        localFavoriteGuideline: action.payload,
      };
    }
    case RESET_LOCAL_FAVORITE_GUIDELINE: {
      return {
        ...state,
        localFavoriteGuideline: "",
      };
    }
    case ADD_LOCAL_NOTICE: {
      return {
        ...state,
        localNotices: [...state.localNotices, action.payload],
      };
    }
    case REMOVE_LOCAL_NOTICE: {
      return {
        ...state,
        localNotices: state.localNotices.filter(
          (notice) => notice.ID !== action.payload.ID
        ),
      };
    }
    case ADD_LOCAL_NOTICE_DELETE: {
      return {
        ...state,
        localNoticesDelete: [...state.localNoticesDelete, action.payload],
      };
    }
    case REMOVE_LOCAL_NOTICE_DELETE: {
      return {
        ...state,
        localNoticesDelete: state.localNoticesDelete.filter(
          (notice) => notice.ID !== action.payload.ID
        ),
      };
    }
    case ADD_LOCAL_BOOKMARK: {
      return {
        ...state,
        localBookmarks: [...state.localBookmarks, action.payload],
      };
    }
    case REMOVE_LOCAL_BOOKMARK: {
      return {
        ...state,
        localBookmarks: state.localBookmarks.filter(
          (bookmark) => bookmark.ID !== action.payload.ID
        ),
      };
    }
    case ADD_LOCAL_BOOKMARK_DELETE: {
      return {
        ...state,
        localBookmarksDelete: [...state.localBookmarksDelete, action.payload],
      };
    }
    case REMOVE_LOCAL_BOOKMARK_DELETE: {
      return {
        ...state,
        localBookmarksDelete: state.localBookmarksDelete.filter(
          (bookmark) => bookmark.ID !== action.payload.ID
        ),
      };
    }
    default: {
      return state;
    }
  }
};
