import React, { useState } from "react";
import t from "../../../Texts/TextImport";
import { URL } from "../../hooks/queries/config";
import { ModeType, ReferenceType, ScoreType, TagType } from "../../types";
import parse, { domToReact, attributesToProps } from "html-react-parser";
import { useTheme } from "../Theme";
import {
  PopupHorizontalContent,
  PopupRefContent,
  PopupScoreContent,
  PopupTagContent
} from "../Popup";
import { MethodeType } from "./index";
import {
  StackActions,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import { useHorizontal, useReference, useScore, useTag } from "../../hooks";
import Icon from "../Icon";
import { useDispatch } from "react-redux";
import { togglePopup } from "../../redux/global";
import { PopupTagHeader } from "../Popup/PopupHeader";
import {
  APPBRANCH,
  DEBUGISON,
  HTMLDEBUGGING,
  isTextSelectable,
  isWeb
} from "../../config";
import { getTitle } from "../../hooks/useLanguage";
import { giveLink } from "./checkLink";

let SelectableComponent;

if (isTextSelectable) {
  SelectableComponent = isWeb && require("./Select.web").default;
} else {
  SelectableComponent = isWeb && require("./NoSelect.web").default;
}

interface HTMLProps {
  html: string;
  mode: ModeType;
}

const defaultState = {
  methode: undefined,
  id: ""
};

function DebugOutput(node) {
  DEBUGISON && HTMLDEBUGGING && node?.name && console.log(node.name);
  DEBUGISON && HTMLDEBUGGING && node && console.log(node);
}

const HTML = ({ html, mode }: HTMLProps) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const [state, setState] = useState<{
    methode: MethodeType;
    id: string;
  }>(defaultState);
  const { methode, id } = state;
  const dispatch = useDispatch();
  const route = useRoute();
  // @ts-expect-error
  const guidelineId = route.params?.guidelineId;

  const onFetch = (methode: MethodeType, id: string) => {
    setState({
      methode,
      id
    });
  };

  useReference(
    id,
    guidelineId,
    (data: ReferenceType) => {
      DEBUGISON &&
        console.log(
          "Horizontal OnSuccess data:" + JSON.stringify(data)?.substring(0, 100)
        );
      dispatch(
        togglePopup({
          isVisible: true,
          title: `[${data.ORDER}]`,
          content: <PopupRefContent mode={mode} data={data} />
        })
      );
      setState(defaultState);
    },
    { enabled: methode === "reference" }
  );

  useTag(
    id,
    guidelineId,
    (data: TagType) => {
      DEBUGISON &&
        console.log(
          "Tag OnSuccess data:" + JSON.stringify(data)?.substring(0, 100)
        );
      dispatch(
        togglePopup({
          isVisible: true,
          title: getTitle(data),
          content: <PopupTagContent mode={mode} data={data} />,
          header: (
            <PopupTagHeader
              redHand={data.ROTE_HAND.length > 0}
              tag={true}
              ars={false}
            />
          )
        })
      );
      setState(defaultState);
    },
    { enabled: methode === "tag" || methode === "redhand" }
  );

  useScore(
    id,
    (data: ScoreType) => {
      DEBUGISON &&
        console.log(
          "Score OnSuccess data:" + JSON.stringify(data)?.substring(0, 100)
        );
      dispatch(
        togglePopup({
          isVisible: true,
          title: getTitle(data),
          content: <PopupScoreContent mode={mode} data={data} />
        })
      );
      setState(defaultState);
    },
    { enabled: methode === "score" }
  );

  useHorizontal(
    guidelineId,
    (data: string[]) => {
      DEBUGISON &&
        console.log(
          "horizontal OnSuccess data:" + JSON.stringify(data)?.substring(0, 100)
        );
      id &&
        dispatch(
          togglePopup({
            isVisible: true,
            title: t("GenConGui"),
            content: (
              <PopupHorizontalContent
                data={data}
                connectedGuideline={id}
                mode={mode}
              />
            )
          })
        );
      setState(defaultState);
    },
    { enabled: methode === "horizontal" }
  );

  const options = {
    replace: (node: any) => {
      DebugOutput(node);
      const props = attributesToProps(node.attribs);
      if (node.name === "span") {
        return (
          <span suppressContentEditableWarning={true} {...props}>
            {domToReact(node.children, options)}
          </span>
        );
      }
      if (node.name === "p") {
        return (
          <p
            style={{
              ...theme.textVariants.body,
              lineHeight: "24px",
              color: theme.colors.mainForeground,
              marginTop: 0,
              userSelect: "text"
            }}
          >
            {domToReact(node.children, options)}
          </p>
        );
      }
      if (node.name === "reference") {
        return (
          <span
            suppressContentEditableWarning={true}
            style={{ color: theme.colors[mode], cursor: "pointer" }}
            onClick={() => onFetch("reference", node.attribs.id)}
            // {...props}
          >
            {domToReact(node.children, options)}
          </span>
        );
      }
      if (node.name === "term") {
        //const props = attributesToProps(node.attribs);
        return (
          <span
            suppressContentEditableWarning={true}
            style={{
              backgroundColor: theme.colors[mode] + "1A",
              color: theme.colors.mainForeground
            }}
            //{...props}
          >
            {domToReact(node.children, options)}
          </span>
        );
      }

      if (node.name === "high") {
        const props = attributesToProps(node.attribs);
        console.log("high");
        return (
          <span
            className="high"
            suppressContentEditableWarning={true}
            //styles are in styles.web.css
            {...props}
          >
            {domToReact(node.children, options)}
          </span>
        );
      }
      if (node.name === "glossary") {
        //const props = attributesToProps(node.attribs);
        return (
          <span
            suppressContentEditableWarning={true}
            style={{ color: theme.colors[mode], cursor: "pointer" }}
            onClick={() => onFetch("tag", node.attribs.id)}
            //{...props}
          >
            {domToReact(node.children, options)}
            <Icon name="informations" size={18} color={mode} />
          </span>
        );
      }
      if (node.name === "redhand") {
        //const props = attributesToProps(node.attribs);
        if (APPBRANCH === "ERKNET") {
          return "";
        }
        return (
          <span
            suppressContentEditableWarning={true}
            style={{ color: theme.colors[mode], cursor: "pointer" }}
            onClick={() => onFetch("redhand", node.attribs.id)}
            //{...props}
          >
            {domToReact(node.children, options)}
            <Icon name="hand" size={18} color={mode} />
          </span>
        );
      }
      if (node.name === "horizontal") {
        //const props = attributesToProps(node.attribs);
        return (
          <span
            suppressContentEditableWarning={true}
            style={{ color: theme.colors[mode], cursor: "pointer" }}
            onClick={() => onFetch("horizontal", node.attribs.id)}
            //{...props}
          >
            {domToReact(node.children, options)}
            <Icon name="horizontalIntegration" size={18} color={mode} />
          </span>
        );
      }
      if (node.name === "score") {
        //const props = attributesToProps(node.attribs);
        return (
          <span
            suppressContentEditableWarning={true}
            style={{ color: theme.colors[mode], cursor: "pointer" }}
            onClick={() => onFetch("score", node.attribs.id)}
            //{...props}
          >
            {domToReact(node.children, options)}
            <Icon name="circledHeart" size={18} color={mode} />
          </span>
        );
      }
      if (node.name === "table") {
        return (
          <table
            suppressContentEditableWarning={true}
            style={{
              borderSpacing: 0,
              borderCollapse: "collapse",
              marginBottom: theme.spacing.s
            }}
          >
            {domToReact(node.children, options)}
          </table>
        );
      }
      if (node.name === "td") {
        const props = attributesToProps(node.attribs);
        return (
          <td
            suppressContentEditableWarning={true}
            //styles are in styles.web.css
            {...props}
          >
            {domToReact(node.children, options)}
          </td>
        );
      }
      if (node.name === "tr") {
        const props = attributesToProps(node.attribs);
        return (
          <tr
            //styles are in styles.web.css
            {...props}
          >
            {domToReact(node.children, options)}
          </tr>
        );
      }
      if (node.name === "ul") {
        //const props = attributesToProps(node.attribs);
        return (
          <ul
            suppressContentEditableWarning={true}
            style={{
              marginBottom: theme.spacing.m,
              marginTop: theme.spacing.none
            }}
            //{...props}
          >
            {domToReact(node.children, options)}
          </ul>
        );
      }
      if (node.name === "li") {
        //const props = attributesToProps(node.attribs);
        return (
          <li
            suppressContentEditableWarning={true}
            style={{
              ...theme.textVariants.body,
              lineHeight: "24px",
              color: theme.colors.mainForeground
            }}
            //{...props}
          >
            {domToReact(node.children, options)}
          </li>
        );
      }
      if (node.name === "em") {
        //const props = attributesToProps(node.attribs);
        return (
          <em
            suppressContentEditableWarning={true}
            style={{
              ...theme.textVariants.body,
              lineHeight: "24px",
              color: theme.colors.mainForeground
            }}
            //{...props}
          >
            {domToReact(node.children, options)}
          </em>
        );
      }
      if (node.name === "fulltext") {
        //const props = attributesToProps(node.attribs);
        return (
          <span
            suppressContentEditableWarning={true}
            style={{
              color: theme.colors[mode],
              cursor: "pointer",
              fontWeight: "bold"
            }}
            onClick={() =>
              navigation.dispatch(
                StackActions.push("FullText", {
                  id: node.attribs.id,
                  guidelineId: node.attribs.guidelineid
                })
              )
            }
            //{...props}
          >
            {domToReact(node.children, options)}
            <Icon name="rightArrow" size={18} color={mode} />
          </span>
        );
      }
      if (node.name === "stepbystep") {
        //const props = attributesToProps(node.attribs);
        return (
          <span
            suppressContentEditableWarning={true}
            style={{
              color: theme.colors[mode],
              cursor: "pointer",
              fontWeight: "bold"
            }}
            onClick={() =>
              navigation.dispatch(
                StackActions.push("StepByStep", {
                  id: node.attribs.id,
                  guidelineId: node.attribs.guidelineid
                })
              )
            }
            //{...props}
          >
            {domToReact(node.children, options)}
            <Icon name="rightArrow" size={18} color={mode} />
          </span>
        );
      }
      if (node.name === "recommendation") {
        //const props = attributesToProps(node.attribs);
        return (
          <span
            suppressContentEditableWarning={true}
            style={{
              color: theme.colors[mode],
              cursor: "pointer",
              fontWeight: "bold"
            }}
            onClick={() =>
              navigation.dispatch(
                StackActions.push("Recommendations", {
                  id: node.attribs.id,
                  guidelineId: node.attribs.guidelineid
                })
              )
            }
            //{...props}
          >
            {domToReact(node.children, options)}
            <Icon name="rightArrow" size={18} color={mode} />
          </span>
        );
      }
      if (node.name === "a") {
        const link = node.attribs.href.toString();
        node.attribs.href = giveLink(link);
        const props = attributesToProps(node.attribs);
        return (
          <a
            suppressContentEditableWarning={true}
            style={{
              color: theme.colors[mode],
              cursor: "pointer"
            }}
            {...props}
          >
            {domToReact(node.children, options)}
            <Icon name="href" size={18} color={mode} />
          </a>
        );
      }
      if (node.name === "img") {
        //const props = attributesToProps(node.attribs);
        return (
          <img
            suppressContentEditableWarning={true}
            //{...props}
            src={`${URL}${node.attribs.src}`}
            style={{ maxWidth: 500 }}
          />
        );
      }
      if (node.name === "icon") {
        if (node.children && node.children.length > 0) {
          return <Icon name={node.children[0].data} />;
        } else {
          return domToReact(node.children, options);
        }
      }
    }
  };
  return (
    <>
      <SelectableComponent />
      {parse(html, options)}
    </>
  );
};

export default HTML;
