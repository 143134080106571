import React from "react";
import { ScrollView, StyleSheet } from "react-native";
import { RectButton } from "react-native-gesture-handler";
import t from "../../../../Texts/TextImport";
import { Box, Hoverable, Icon, Text, useTheme } from "../../../components";
import { GlossaryType } from "../../../types";

interface SuggestionListProps {
  suggestions: string[];
  onSubmit: (text: string) => void;
}

const SuggestionList = ({ suggestions, onSubmit }: SuggestionListProps) => {
  const theme = useTheme();
  return (
    <Box zIndex={10}>
      <Box
        position='absolute'
        left={0}
        right={0}
        top={0}
        maxHeight={{ phone: 200, tablet: 400 }}
      >
        <ScrollView
          style={{
            borderTopWidth: 0,
            borderWidth: 1,
            borderColor: theme.colors.mainForeground,
            backgroundColor: theme.colors.mainBackground,
            borderBottomLeftRadius: theme.spacing.xs,
            borderBottomRightRadius: theme.spacing.xs,
          }}
          nestedScrollEnabled={true}
        >
          {suggestions.length > 0 ? (
            <Text
              paddingTop='m'
              paddingBottom='xs'
              marginHorizontal='s'
              variant='description'
            >
              {t("SLSuggestions")}
            </Text>
          ) : null}
          {suggestions.map((title, index) => {
            const isLast = index === suggestions.length - 1;
            const onPress = () => onSubmit(title);
            return (
              <RectButton key={index} onPress={onPress}>
                <Box
                  flexDirection='row'
                  justifyContent='space-between'
                  alignItems='center'
                  paddingVertical='s'
                  marginHorizontal='s'
                  borderBottomColor='smoke'
                  borderBottomWidth={!isLast ? 1 : 0}
                >
                  <Text fontFamily='bliss-pro-light-italic'>{title}</Text>
                  <Icon name='rightArrow' color='mainForeground' />
                </Box>
              </RectButton>
            );
          })}
        </ScrollView>
      </Box>
    </Box>
  );
};

export default SuggestionList;
