export interface LocalGuidelineType {
  GUIDELINE_UUID: string;
  HASH_KEY: string;
}
export interface guidelinesState {
  localGuidelines: LocalGuidelineType[];
  loadedGuidelines: LocalGuidelineType[];
  isRestoring: boolean;
}

export const ADD_LOCAL_GUIDELINES = "ADD_LOCAL_GUIDELINES";
export interface addLocalGuidelines {
  type: typeof ADD_LOCAL_GUIDELINES;
  payload: LocalGuidelineType;
}

export const RESTORE_LOCAL_GUIDELINE = "RESTORE_LOCAL_GUIDELINE";
export interface restoreLocalGuideline {
  type: typeof RESTORE_LOCAL_GUIDELINE;
  payload: boolean;
}

export const ADD_LOADED_GUIDELINES = "ADD_LOADED_GUIDELINES";
export interface addLoadedGuidelines {
  type: typeof ADD_LOADED_GUIDELINES;
  payload: LocalGuidelineType;
}

export const RESET_LOADED_GUIDELINES = "RESET_LOADED_GUIDELINES";
export interface resetLoadedGuidelines {
  type: typeof RESET_LOADED_GUIDELINES;
}

export type GuidelineActionTypes =
  | addLocalGuidelines
  | restoreLocalGuideline
  | addLoadedGuidelines
  | resetLoadedGuidelines;
