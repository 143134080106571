import React, { useState, useEffect } from "react";
import * as Device from "expo-device";

const useDeviceDetect = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  useEffect(() => {
    async function findDeviceType() {
      const deviceType = await Device.getDeviceTypeAsync();
      setIsMobile(deviceType === Device.DeviceType.PHONE);
      setIsTablet(deviceType === Device.DeviceType.TABLET);
    }
    findDeviceType();
  }, []);
  return { isMobile, isTablet };
};

export default useDeviceDetect;
