import React, { useEffect, useState } from "react";

import { Linking, ImageBackground, ScrollView, Image } from "react-native";
import {
  Box,
  Text,
  PulseButton,
  useTheme,
  TextInput,
  Checkbox,
  SubmitInput,
} from "../../components";
import { Step, Footer, StoreProps, StepProps } from "./components";
import { useDimensions } from "react-native-web-hooks";
import { useAuth } from "../../hooks";
import t from "../../../Texts/TextImport";
import { APPBRANCH } from "../../config";
import Constants from "expo-constants";

export const assets = [
  require("./assets/AppStore.png"),
  require("./assets/GooglePlay.png"),
  require("./assets/Backdrop.png"),
  require("./assets/step_1.png"),
  require("./assets/step_2.png"),
  require("./assets/step_3.png"),
  require("./assets/step_4.png"),
  require("../../../assets/logos/leila_logo_black.png"),
];

export const stores: StoreProps[] = [
  {
    picture: {
      src: assets[0],
      width: 120,
      height: 43,
    },
    onPress: () =>
      Linking.openURL("https://apps.apple.com/de/app/leila-pro/id1481959309"),
  },
  {
    picture: {
      src: assets[1],
      width: 120,
      height: 43,
    },
    onPress: () =>
      Linking.openURL(
        "https://play.google.com/store/apps/details?id=de.leila.leila"
      ),
  },
];

const steps: StepProps[] = [
  {
    id: 1,
    picture: {
      src: assets[3],
      height: 100,
      width: 100,
    },
    title: t("AuthInstall"),
    description: t("AuthDownload"),
    badges: true,
  },
  {
    id: 2,
    picture: {
      src: assets[4],
      height: 100,
      width: 100,
    },
    title: t("Authidentification"),
    description: t("WhereId"),
  },
  {
    id: 3,
    picture: {
      src: assets[5],
      height: 100,
      width: 100,
    },
    title: t("AuthUse"),
    description: t("SignIn"),
  },
];

const getGreeting = () => {
  const hours = new Date().getHours();
  if (4 < hours && hours < 11) {
    return t("AuthMorning");
  } else if (10 < hours && hours < 18) {
    return t("AuthNoon");
  } else if (17 < hours && hours < 23) {
    return t("AuthEvening");
  } else {
    return t("AuthNight");
  }
};

const NewAuthentification = () => {
  const theme = useTheme();
  const { signIn, error } = useAuth();
  const [rememberMe, setRememeberMe] = useState(false);
  const {
    window: { width, height },
  } = useDimensions();
  const onPress = (username: string) => {
    signIn(username);
  };
  const aspectRatio = height / width;
  return (
    <Box flex={1}>
      <Box flex={1} paddingHorizontal="xxl" paddingVertical="xl">
        <Text
          variant="title1"
          fontWeight="600"
          paddingBottom="xl"
          paddingTop="xxl"
        >
          Login
        </Text>
        <Box paddingBottom="xl">
          <Box maxWidth={540}>
            <SubmitInput
              onSubmit={onPress}
              placeholder={t("Authidentification")}
              label="Login"
              returnKeySubmit={true}
              buttonStyle={{ minWidth: 130 }}
            />
          </Box>
          <Box paddingTop="l">
            <Text color={error ? "danger" : "mainForeground"}>
              {error ? t("AuthSensitive") : t("AuthHere")}
            </Text>
            <Text paddingBottom="s">{t("AuthPro")}</Text>
            <Checkbox
              label={rememberMe ? t("AuthWell") : t("AuthIn")}
              checked={rememberMe}
              onPress={() => setRememeberMe((prev) => !prev)}
            />
          </Box>
        </Box>

        <Box flexDirection="row" flexWrap="wrap">
          <Box flex={1}>
            <Text variant="subtitle1" paddingBottom="m" fontWeight="600">
              {t("AuthNumber")}
            </Text>
            <Text paddingBottom="l">{t("AuthPercent")}</Text>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Text color="mainBackground" selectable={true}>
            {Constants?.expoConfig?.version}
          </Text>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default NewAuthentification;
