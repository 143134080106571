import {
  GuidelineActionTypes,
  ADD_LOCAL_GUIDELINES,
  LocalGuidelineType,
  RESTORE_LOCAL_GUIDELINE,
  ADD_LOADED_GUIDELINES,
  RESET_LOADED_GUIDELINES,
} from "./types";

export const addLocalGuidelines = (
  localGuideline: LocalGuidelineType
): GuidelineActionTypes => ({
  type: ADD_LOCAL_GUIDELINES,
  payload: localGuideline,
});

export const restoreLocalGuideline = (
  value: boolean
): GuidelineActionTypes => ({
  type: RESTORE_LOCAL_GUIDELINE,
  payload: value,
});

export const addLoadedGuidelines = (
  loadedGuideline: LocalGuidelineType
): GuidelineActionTypes => ({
  type: ADD_LOADED_GUIDELINES,
  payload: loadedGuideline,
});

export const resetLoadedGuidelines = (): GuidelineActionTypes => ({
  type: RESET_LOADED_GUIDELINES,
});
