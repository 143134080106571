import {
  GuidelineRoutes,
  HomeRoutes,
  GeneralRoutes,
  UserRoutes,
} from "../components/Navigation";
import { ModeType } from "../types";
import t from "../../Texts/TextImport";
import { devError } from "./logging";

export const translateScreen = (
  screen: keyof GuidelineRoutes | keyof HomeRoutes | keyof UserRoutes
) => {
  switch (screen) {
    case "FullText":
      return t("GenFu");
    case "Information":
      return t("GenNotes");
    case "Recommendations":
      return t("RCriteria");
    case "StepByStep":
      return t("GenSbs");
    case "Search":
      return t("GenSearch");
    default:
      return "missing tranlation";
  }
};

export const translateShortScreen = (
  screen:
    | keyof GuidelineRoutes
    | keyof HomeRoutes
    | keyof UserRoutes
    | keyof GeneralRoutes
) => {
  switch (screen) {
    case "FullText":
      return t("GenFu");
    case "Information":
      return t("Geninstructions");
    case "Recommendations":
      return t("RCriteria");
    case "StepByStep":
      return t("GenSbs");
    case "Search":
      return t("GenSearch");
    default:
      devError(
        screen + 'was not found and could not be translated to a "shortscreen"'
      );
      return "missing translation";
  }
};

export const translateModetoScreen = (
  mode: ModeType
): keyof GuidelineRoutes | keyof GeneralRoutes => {
  switch (mode) {
    case "fulltext":
      return "FullText";
    case "information":
      return "Information";
    case "stepbystep":
      return "StepByStep";
    case "recommendations":
      return "Recommendations";
    case "search":
      return "Search";
    case "informations":
      return "Informations";
    case "settings":
      return "Settings";
    default:
      return "ModeSelection";
  }
};

export const translateScreenToMode = (screen: string): ModeType => {
  switch (screen) {
    case "StepByStep":
      return "stepbystep";
    case "FullText":
      return "fulltext";
    case "Information":
      return "information";
    case "Recommendations":
      return "recommendations";
    case "Search":
      return "search";
    default:
      return "default";
  }
};
