import React, { ReactChildren, useRef } from "react";
import { DEBUGISON, HTMLDEBUGGING } from "../config";
import { View, Platform } from "react-native";
import { useLinkProps, NavigationAction } from "@react-navigation/native";
import { Text, Theme } from "./Theme";
import { useHover } from "react-native-web-hooks";
import { TouchableOpacity } from "react-native-gesture-handler";
import { TextProps } from "@shopify/restyle";

interface LinkProps extends TextProps<Theme> {
	to: string;
	action?: NavigationAction;
	children: string | React.ReactNode;
	color?: keyof Theme["colors"];
	//rest -> extends Text Props from restyle
}

const Link = ({ to, action, children, ...rest }: LinkProps) => {
	const { onPress, ...props } = useLinkProps({ to, action });
	if (Platform.OS === "web") {
		const ref = useRef(null);
		const isHovered = useHover(ref);
		DEBUGISON && HTMLDEBUGGING && console.log("weblink detected");
		return (
			<Text
				ref={ref}
				onPress={onPress}
				style={{ opacity: isHovered ? 0.5 : 1 }}
				{...rest}
				{...props}
			>
				{children}
			</Text>
		);
	}

	return (
		<TouchableOpacity onPress={onPress}>
			<Text {...props}>{children}</Text>
		</TouchableOpacity>
	);
};

export default Link;
