import React, { useCallback } from "react";
import { Box, Text, useTheme } from "../Theme";
import FloatingButtonGroup from "./FloatingButtonGroup";
import {
	useNavigation,
	CommonActions,
	useRoute,
} from "@react-navigation/native";
import { useAuth } from "../../hooks";
import { PopupBookmark, PopupFeedback, PopupNotice } from "../Popup";
import { ModeType } from "../../types";
import RoundedIconButton from "../RoundedIconButton";
import { useDispatch } from "react-redux";
import { togglePopup } from "../../redux/global";
import * as WebBrowser from "expo-web-browser";
import { Platform } from "react-native";
import t from "../../../Texts/TextImport";

const linkedinLink =
	"http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.leila.de%2Fde%2F&title=Leila";

const isWeb = Platform.OS === "web";

interface FloatingButtonsProps {
	width: number;
	mode: ModeType;
}
const FloatingButtons = ({ width, mode }: FloatingButtonsProps) => {
	const theme = useTheme();
	const { signOut } = useAuth();
	const navigation = useNavigation();
	const route = useRoute();
	const dispatch = useDispatch();

	const onFeedback = useCallback(() => {
		dispatch(
			togglePopup({
				isVisible: true,
				title: t("GenFBSend"),
				description: t("GenCrit"),
				content: <PopupFeedback />,
			})
		);
	}, []);

	const onNotice = useCallback(() => {
		dispatch(
			togglePopup({
				isVisible: true,
				title: t("Bookmarknew"),
				content: <PopupNotice />,
			})
		);
	}, []);

	const onBookmark = useCallback(() => {
		// @ts-expect-error
		const { id, guidelineId } = route.params;
		dispatch(
			togglePopup({
				isVisible: true,
				title: t("BookmarkCreate"),
				content: (
					<PopupBookmark chapterId={id} guidelineId={guidelineId} mode={mode} />
				),
			})
		);
	}, [route]);

	return (
		<Box
			position="absolute"
			top={{ phone: "m", tablet: "xl" }}
			right={{
				phone: -width + theme.spacing.m,
				tablet: -width + theme.spacing.xl,
			}}
			width={width}
		>
			<FloatingButtonGroup buttonGroupWidth={width} mode={mode}>
				<FloatingButtonGroup.Button
					index={0}
					icon="circledCluster"
					onPress={() => navigation.dispatch(CommonActions.navigate("Home"))}
				/>
				<FloatingButtonGroup.Button
					index={1}
					icon="circledNotice"
					onPress={() =>
						navigation.dispatch(
							CommonActions.navigate("User", { screen: "Notices" })
						)
					}
					onHoverChildren={
						<RoundedIconButton
							onPress={onNotice}
							name={"circledPlus"}
							size={40}
							color={mode}
							iconRatio={1}
						/>
					}
				/>
				<FloatingButtonGroup.Button
					index={2}
					icon="circledBookmark"
					onPress={() =>
						navigation.dispatch(
							CommonActions.navigate("User", { screen: "Bookmark" })
						)
					}
					onHoverChildren={
						["fulltext", "recommendations", "information", "stepbystep"].find(
							(i) => i === mode
						) && (
							<RoundedIconButton
								onPress={onBookmark}
								name={"circledPlus"}
								size={40}
								color={mode}
								iconRatio={1}
							/>
						)
					}
				/>
				<FloatingButtonGroup.Button
					index={3}
					icon="circledFeedback"
					onPress={onFeedback}
				/>
				<FloatingButtonGroup.Button
					index={3}
					icon="circledShare"
					onPress={() => null}
					onHoverChildren={
						<Box
							flex={1}
							flexDirection="row"
							alignItems="center"
							justifyContent="center"
						>
							<RoundedIconButton
								onPress={() => WebBrowser.openBrowserAsync(linkedinLink)}
								name={"circledLinkedIn"}
								size={40}
								color={mode}
								iconRatio={1}
							/>
						</Box>
					}
				/>
				{isWeb && (
					<FloatingButtonGroup.Button
						index={4}
						icon="circledUser"
						onPress={signOut}
						onHoverChildren={
							<Box flex={1} justifyContent="center">
								<Text color={mode} paddingRight="xs">
									{t("FBLogout")}
								</Text>
							</Box>
						}
					/>
				)}
			</FloatingButtonGroup>
		</Box>
	);
};

export default FloatingButtons;
