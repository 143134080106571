import React, { useEffect, useMemo, useState } from "react";
import { Box, Text } from "../Theme";
import { generateUniqueId } from "../../utils/helpers";
import Button, { ButtonTab } from "../Button";
import Equation, { EquationProps } from "./Equation";
import {
  TestCases,
  getAllFormulasSeparatedByPipe,
  getAllResultsSeparatedByPipe,
} from "./TestCases";
import { APPBRANCH, isTextSelectable, isWeb } from "../../config";
import HTML from "../HTML";
import DecodeHTML from "./DecodeHTML";

const TESTS = false;

function splitString(input: string): string[] {
  const parts = input.split("|");
  return parts;
}
const unitTitles: string[] = ["Metric", "SI"];

export function SelectFormula({
  formula,
  formulaResult,
  onComputed,
}: EquationProps) {
  const pureFormula = DecodeHTML(formula);
  const purFormulaResult = DecodeHTML(formulaResult);
  const Equations: string[] = !TESTS
    ? splitString(pureFormula)
    : splitString(getAllFormulasSeparatedByPipe());
  const results: string[] = !TESTS
    ? splitString(purFormulaResult)
    : splitString(getAllResultsSeparatedByPipe());
  const [activeEqIndex, setActiveEqIndex] = useState<number>(0);
  const [displayString, setDisplayString] = useState<string>("");
  return (
    <Box flexGrow={1}>
      {false && (
        <>
          <Text selectable={isTextSelectable}>{formula}</Text>
          <Text>{JSON.stringify(Equations)}</Text>
        </>
      )}
      <Box
        flexDirection="row"
        width={"120%"}
        marginTop={isWeb ? "none" : "none"}
      >
        <HTML
          mode="default"
          html={
            '<p style="display: flex; flex-direction: row;  "> ' +
            displayString +
            " </p>"
          }
        />
      </Box>
      <Box flex={1} flexGrow={1} flexDirection="row" marginBottom="m">
        {Equations.length > 1 &&
          Equations.map((el, index) => (
            <>
              <Button
                variant={index === activeEqIndex ? "default" : "border"}
                color={
                  index === activeEqIndex
                    ? "mainBackground"
                    : "generalinformation"
                }
                mainColor={
                  index === activeEqIndex ? "primary" : "mainForeground"
                }
                label={
                  unitTitles[index] ? unitTitles[index] : JSON.stringify(index)
                }
                key={generateUniqueId()}
                onPress={() => {
                  setActiveEqIndex(index);
                }}
              />
              <Box
                id={"spacer" + generateUniqueId()}
                marginVertical={"s"}
                width={10}
              />
            </>
          ))}
      </Box>
      <Equation
        formula={Equations[activeEqIndex]}
        formulaResult={results[activeEqIndex]}
        onComputed={onComputed}
        handBackDisplayString={setDisplayString}
      />
    </Box>
  );
}
