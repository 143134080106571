import { HTMLMap } from "./HTMLReplacements";

function DecodeHTML(HTMLdata: string) {
  function decodeUnits() {
    let data = HTMLdata;
    let shouldscan = true;

    while (shouldscan) {
      HTMLMap.forEach((el) => {
        data = data.replace(Object.keys(el)[0], Object.values(el)[0]);
      });
      shouldscan = false;
      HTMLMap.forEach((el) => {
        if (data.includes(Object.keys(el)[0])) {
          shouldscan = true;
        }
      });
    }
    return data;
  }

  return decodeUnits();
}

export default DecodeHTML;
