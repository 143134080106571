import React from "react";
import QRWrapper from "./QRWrapper";
import t from "../../../Texts/TextImport";

import { Button, LinkBox, Text } from "../../components";
import { buildHomeLink, generateUniqueId } from "../../utils/helpers";

import { GuidelineNavigationProps } from "../../components/Navigation";

function ForOtherGuideline({
  navigation,
  route,
}: GuidelineNavigationProps<"FullText">) {
  return (
    <QRWrapper>
      <Text variant="body">{t("QROtherGuidel")}</Text>

      <LinkBox showLoading={true} to={buildHomeLink()}>
        <Button
          variant="border"
          color={"primary"}
          mainColor={"primary"}
          label={t("GenClose")}
          key={generateUniqueId()}
          onPress={() => {}}
        />
      </LinkBox>
    </QRWrapper>
  );
}

export default ForOtherGuideline;
