import { CommonActions } from "@react-navigation/native";
import React from "react";
import { Box, Icon, LinkBox, Text } from "../../components";
import { GuidelineRoutes } from "../../components/Navigation";
import { DEBUGISON, NAVDEBUGGING, ORIGINDEBUG } from "../../config";
import { useGuideline } from "../../hooks";
import { ModeType } from "../../types";
import { translateModetoScreen, translateShortScreen } from "../../utils";

interface ModeTabsProps {
  guidelineId: string;
  screen: keyof GuidelineRoutes;
}
const ModeTabs = ({ guidelineId, screen: currentScreen }: ModeTabsProps) => {
  const { data: guidelineData } = useGuideline(guidelineId);

  const modes: ModeType[] = [
    "fulltext",
    "search",
    "recommendations",
    "stepbystep",
  ];
  return (
    <Box flexDirection="row" justifyContent="space-between" paddingBottom="m">
      {modes.map((mode, index) => {
        const screen = translateModetoScreen(mode);
        const text = translateShortScreen(screen);
        const color = screen === currentScreen ? mode : "mainForeground";
        const isDisabled =
          guidelineData?.BASIC_ENTRY_POINTS?.find((i) => i.MODE === mode)
            ?.CONTENT_UUID !== ""
            ? false
            : true;
        return (
          <Box
            key={index}
            flex={1}
            borderRightWidth={1}
            borderRightColor="smoke"
            borderLeftColor="smoke"
          >
            <LinkBox
              to={`/${screen}?guidelineId=${guidelineId}`}
              action={CommonActions.navigate(screen, { guidelineId })}
              isDisabled={isDisabled}
            >
              <Box
                justifyContent="center"
                alignItems="center"
                paddingHorizontal="xs"
              >
                <Icon name={mode} color={color} size={40} />
                <Text
                  textTransform="uppercase"
                  variant="tiny"
                  color={color}
                  textAlign="center"
                >
                  {DEBUGISON && NAVDEBUGGING && guidelineId}
                  {text}
                  {ORIGINDEBUG && DEBUGISON && "ModeTabs"}
                </Text>
              </Box>
            </LinkBox>
          </Box>
        );
      })}
    </Box>
  );
};

export default ModeTabs;
