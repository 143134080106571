import { DEBUGISON, HOOKSDEBUGGING } from "../config";
import { useSelector } from "../redux";
import useUser from "./queries/useUser";
import { useAuth } from "./useAuth";

const useBookmarks = () => {
  DEBUGISON && HOOKSDEBUGGING && console.log("useBookmarks:useBookmarks: ");
  const localBookmarks = useSelector((state) => state.user.localBookmarks);
  const localBookmarksDelete = useSelector(
    (state) => state.user.localBookmarksDelete
  );
  const { username } = useAuth();
  const { data: userData } = useUser(username);

  const subUsersBookmarks =
    userData?.SUB_USER.map((user) => user?.BOOKMARKS) || [];

  const allBookmarks = userData
    ? [...userData.BOOKMARKS, ...localBookmarks, ...subUsersBookmarks.flat()]
    : localBookmarks;

  const bookmarks = allBookmarks.filter(
    (bookmark) => !localBookmarksDelete.some((item) => item.ID === bookmark.ID)
  );

  DEBUGISON &&
    HOOKSDEBUGGING &&
    false &&
    console.log(
      "useBookmarks:useBookmarks:\n" +
        "\nlocalBookmarks: " +
        JSON.stringify(localBookmarks) +
        "'n\nlocalBookmarksDelete" +
        JSON.stringify(localBookmarksDelete) +
        "\n\nusername: " +
        JSON.stringify(username) +
        "\n\nlocalBookmarks: " +
        JSON.stringify(userData) +
        "\n\nbookmarks: " +
        JSON.stringify(bookmarks)
    );

  bookmarks.map((el, key) => {
    DEBUGISON && HOOKSDEBUGGING && console.log(el);
  });

  return bookmarks;
};

export { useBookmarks };
