import React, { useCallback, useState } from "react";
import {
	BackButton,
	Box,
	Container,
	Text,
	BorderlessTap,
	Icon,
	BookmarkList,
	BookmarkListSortProps,
	Button,
} from "../../../components";
import TabBar from "../TabBar";
import { MobileFooter } from "../../../components/Footer";
import t from "../../../../Texts/TextImport";

const Bookmarks = () => {
	const [sortBy, setSortBy] = useState<BookmarkListSortProps>({
		orderBy: "CREATION_DATE",
		order: "desc",
	});
	const isDate = sortBy.orderBy === "CREATION_DATE";
	const onPress = useCallback(
		() =>
			setSortBy({
				orderBy: isDate ? "GUIDELINE_UUID" : "CREATION_DATE",
				order: isDate ? "asc" : "desc",
			}),
		[isDate]
	);
	return (
		<Container
			tabBar={<TabBar />}
			backButton={<BackButton />}
			customMobileFooter={<MobileFooter />}
		>
			<Box padding={{ phone: "m", tablet: "xl" }}>
				<Box flexDirection="row" justifyContent="flex-end">
					<BorderlessTap onPress={onPress}>
						<Box flexDirection="row" alignItems="center" paddingBottom="m">
							<Icon name="shuffle" color="mainForeground" size={16} />
							<Text paddingLeft="xs">
								{sortBy.orderBy === "CREATION_DATE"
									? t("GenDate")
									: t("RCriteria")}
							</Text>
						</Box>
					</BorderlessTap>
				</Box>
				<BookmarkList orderBy={sortBy.orderBy} order={sortBy.order} />
			</Box>
		</Container>
	);
};

export default Bookmarks;
