import React, { ReactNode } from "react";
import {
  BorderlessButton,
  BorderlessButtonProperties,
} from "react-native-gesture-handler";

interface BorderlessTapProps extends BorderlessButtonProperties {
  children: ReactNode;
}

const BorderlessTap = ({ children, ...props }: BorderlessTapProps) => {
  return <BorderlessButton {...props}>{children}</BorderlessButton>;
};

export default BorderlessTap;
