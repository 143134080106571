import { TextProps } from "@shopify/restyle";
import React, { HTMLAttributes } from "react";
import { Icons } from "../../styles";
import { Theme, Text, useTheme } from "../Theme";

interface IconProps extends TextProps<Theme> {
  name: keyof typeof Icons;
  color: keyof Theme["colors"];
  size: number;
}

const Icon = ({ name, color, size, ...props }: IconProps) => {
  const theme = useTheme();
  return (
    <span
      style={{
        ...theme.textVariants.symbols,
        lineHeight: `${size}px`,
        color: theme.colors[color],
        fontSize: size,
      }}
    >
      {Icons[name]}
    </span>
  );
};

Icon.defaultProps = {
  size: 18,
};

export default Icon;
