import { DrawerContentComponentProps } from "@react-navigation/drawer";
import { DrawerActions, CommonActions } from "@react-navigation/native";
import React, { useCallback } from "react";
import { Image } from "react-native";
import { Box, LinkBox, RoundedIconButton, Text } from "../../components";
import { useIsLargeScreen } from "../../hooks";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { APPBRANCH, DEBUGISON, ORIGINDEBUG } from "../../config";

// eslint-disable-next-line
const logo = require("../../../assets/logos/leila_logo_black.png");

type DrawerHeaderProps = DrawerContentComponentProps;
const DrawerHeader = ({ ...props }: DrawerHeaderProps) => {
  const insets = useSafeAreaInsets();
  const { navigation } = props;
  const isLargeScreen = useIsLargeScreen();
  const onClose = useCallback(
    () => navigation.dispatch(DrawerActions.closeDrawer()),
    []
  );
  return (
    <>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ paddingTop: insets.top }}
      >
        <LinkBox
          to={"/Home"}
          action={CommonActions.navigate("Home")}
          withHover={false}
        >
          <Image
            source={logo}
            resizeMode={"contain"}
            style={{
              width: isLargeScreen ? 96 : APPBRANCH === "CARE" ? 125 : 85,
              height: isLargeScreen ? 44 : APPBRANCH === "CARE" ? 59 : 39,
            }}
          />
          {ORIGINDEBUG && DEBUGISON && <Text>DrawerHeader</Text>}
        </LinkBox>
        {!isLargeScreen ? (
          <>
            <RoundedIconButton
              name="buttonAbort"
              size={36}
              iconRatio={0.6}
              color="mainForeground"
              backgroundColor="mainBackground"
              onPress={onClose}
            />
            {ORIGINDEBUG && DEBUGISON && <Text>DrawerHeader</Text>}
          </>
        ) : null}
      </Box>
    </>
  );
};

export default DrawerHeader;
