import { useNavigationState, useRoute } from "@react-navigation/native";
import { useEffect } from "react";
import { useAuth } from "./useAuth";
import { tracking, TrackingType } from "../utils";

const useAnalytics = (props: TrackingType) => {
  const { username } = useAuth();
  const navigationState = useNavigationState((state) => state);
  const lastState = navigationState?.routes[navigationState.routes.length - 1];
  const route = useRoute();
  // @ts-ignore
  const id = route.params?.id;
  const guidelineId = route.params?.guidelineId;
  const term = route.params?.term;
  useEffect(() => {
    tracking({
      username,
      screen: props?.screen || route.name || undefined,
      id: props?.id || id || undefined,
      guidelineId: props.guidelineId || guidelineId || null,
      term: props?.term || term || null,
    });
  }, [id, guidelineId, term, props?.term]);
};

export { useAnalytics };

//
