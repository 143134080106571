import React from "react";
import { CommonActions } from "@react-navigation/native";
import { Box, Button, Text, useTheme } from "../../components";
import { AppNavigationProps } from "../../components/Navigation";
import { BinaryAlert } from "../../components/Alert";
import { useDispatch } from "react-redux";
import { toggleAutoUpdate } from "../../redux/global";
import { toggleLaunch } from "../../redux/global";
import { data } from "./constants";
import { ScrollView } from "react-native";
import { StatusBar } from "expo-status-bar";
import { SafeAreaView } from "react-native-safe-area-context";
import t from "../../../Texts/TextImport";

const OnBoarding = ({ navigation }: AppNavigationProps<"OnBoarding">) => {
	const dispatch = useDispatch();
	const theme = useTheme();

	const navigateToHome = () => {
		navigation.dispatch(
			CommonActions.reset({
				index: 0,
				routes: [{ name: "Home" }],
			})
		);
	};

	const onPress = () => {
		dispatch(toggleLaunch());
		navigateToHome();
		BinaryAlert({
			title: "Automatische Updates",
			desc: "Sie bleiben immer auf dem neusten Stand",
			onNegativePress: () => null,
			onPositivePress: () => dispatch(toggleAutoUpdate()),
		});
	};

	return (
		<SafeAreaView
			// eslint-disable-next-line
			style={{ backgroundColor: theme.colors.mainForeground, flex: 1 }}
		>
			<StatusBar style="light" />
			<Box flex={1}>
				<ScrollView
					style={{
						backgroundColor: theme.colors.mainBackground,
					}}
					contentContainerStyle={{
						padding: theme.spacing.m,
						paddingBottom: theme.spacing.m + theme.spacing.xl,
					}}
				>
					<Text
						fontFamily="bliss-pro"
						fontSize={30}
						lineHeight={36}
						paddingBottom="m"
					>
						{t("OBPro")}
					</Text>
					{data.map(({ title, text, component }, index) => (
						<Box key={index}>
							{title ? (
								<Text variant="title1" paddingBottom="m">
									{title}
								</Text>
							) : null}
							{text ? <Text paddingBottom="m">{text}</Text> : null}
							{component ? component : null}
						</Box>
					))}
					<Text paddingBottom="m">{t("OBFebruary")}</Text>
				</ScrollView>
				<Box
					position="absolute"
					bottom={theme.spacing.m}
					left={theme.spacing.m}
					right={theme.spacing.m}
				>
					<Button
						label={t("OBConsent")}
						onPress={onPress}
						color="mainBackground"
						mainColor="primary"
					/>
				</Box>
			</Box>
		</SafeAreaView>
	);
};

export default OnBoarding;
