import React, { useState, useEffect, useCallback } from "react";
import LoadAssets from "../components/LoadAssets";

import AuthStack from "./AuthStack";
import MainDrawer from "./MainDrawer";
import { useAuth } from "../hooks";
import { Platform, AppState, AppStateStatus, View } from "react-native";
import { readFromStorage } from "../utils";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "../redux";
import { Text } from "../components";
import TestNav from "./TestNavigator";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { DEBUGISON } from "../config";

const isWeb = Platform.OS === "web";
const assets = [
  require("../../assets/network.png"),
  require("../../assets/logos/leila_logo_black.png"),
];

function AppContainer() {
  const hasLaunched = useSelector((state) => state.global.hasLaunched);
  const { user, username } = useAuth();
  const queryClient = useQueryClient();

  const handleChange = useCallback(
    (newState: AppStateStatus) => {
      if (newState === "active") {
        console.log("state active");
        //readFromStorage(queryClient);
      }
    },
    [queryClient]
  );

  useEffect(() => {
    const state = AppState.addEventListener("change", handleChange);

    return () => {
      state.remove();
    };
  }, []);

  useEffect(() => {
    if (queryClient) {
      readFromStorage(queryClient);
    }
  }, [queryClient]);

  return (
    <LoadAssets assets={assets}>
      <MainDrawer />
      {isWeb && user?.ID === "1N8ajxqCUf" && (
        <ReactQueryDevtools initialIsOpen={true} />
      )}
    </LoadAssets>
  );
}

export default AppContainer;
