import React from "react";
import { BookmarkType } from "../../types";
import { useBookmarks } from "../../hooks";
import { compareValues } from "../../utils";
import BookmarkCard from "./BookmarkCard";
import { Box } from "../Theme";

export interface BookmarkListSortProps {
	orderBy: keyof BookmarkType;
	order: "asc" | "desc";
}

const BookmarkList = ({ orderBy, order }: BookmarkListSortProps) => {
	const bookmarks = useBookmarks();
	return (
		<>
			{bookmarks.sort(compareValues(orderBy, order)).map((bookmark, idx) => {
				const isLast = idx === bookmarks.length - 1;
				return (
					<Box key={bookmark.ID} marginBottom={!isLast ? "m" : "none"}>
						<BookmarkCard {...{ bookmark }} />
					</Box>
				);
			})}
		</>
	);
};

export default BookmarkList;
