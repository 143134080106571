export function giveLink(link: string): string {
	const l = [
		".net",
		".com",
		".org",
		".tech",
		".int",
		".edu",
		".goc",
		".mil",
		".de",
		".net",
		".com",
		".org",
		".edu",
		".gov",
		".mil",
		".int",
		".info",
		".biz",
		".mobi",
		".tv",
		".io",
		".me",
		".co",
		".us",
		".uk",
		".ca",
		".au",
		".de",
		".fr",
		".jp",
		".cn",
		".ru",
		".br",
		".it",
		".es",
		".nl",
		".se",
		".no",
	];
	if (l.some((item) => link.includes(item))) {
		// PDF or external link
		return link;
	} else {
		// SOMETHING out of the CMS
		return URL + link;
	}
}
