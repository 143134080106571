import React, { useRef } from "react";
import { ScrollView } from "react-native";
import { StatusBar } from "expo-status-bar";
import { useAnalytics } from "../../hooks";
import { BackButton, Box, Container } from "../../components";
import TabBar from "../Home/TabBar";
import { MobileFooter } from "../../components/Footer";

interface QRProps {
  children: React.ReactNode;
}

const QRWrapper = ({ children }: QRProps) => {
  useAnalytics({});
  const scrollRef = useRef<ScrollView>(null);

  return (
    <>
      <StatusBar style="light" />

      <Container
        scrollRef={scrollRef}
        tabBar={<TabBar />}
        backButton={<BackButton />}
        customMobileFooter={<MobileFooter />}
        mode="fulltext"
      >
        <Box
          paddingHorizontal={{ phone: "xl", tablet: "xl" }}
          paddingVertical={"l"}
          flex={1}
        >
          {children}
        </Box>
      </Container>
    </>
  );
};

export default QRWrapper;
