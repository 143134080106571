import React from "react";
import QRWrapper from "./QRWrapper";
import t from "../../../Texts/TextImport";

import { Button, Text, TextInput } from "../../components";
import { generateUniqueId } from "../../utils/helpers";

import { GuidelineNavigationProps } from "../../components/Navigation";

function CouldNotAccessCam({
  navigation,
  route,
}: GuidelineNavigationProps<"FullText">) {
  return (
    <QRWrapper>
      <Text variant="body">{t("QRFailCamera")}</Text>
      <TextInput />

      <Button
        variant="border"
        color={"primary"}
        mainColor={"primary"}
        label={t("GenConfirm")}
        key={generateUniqueId()}
        onPress={() => {}}
      />
    </QRWrapper>
  );
}

export default CouldNotAccessCam;
