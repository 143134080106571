import { QueryClient } from "@tanstack/react-query";
import { DEBUGISON, STORAGEDEBUG } from "../../config";
export const readFromStorage = async () => {
	DEBUGISON && STORAGEDEBUG && console.log("readFromStorage");
};
export const writeToStorage = async (
	queryKey: string[],
	data: any,
	guidelineId?: string
) => {
	DEBUGISON && STORAGEDEBUG && console.log(queryKey);
	DEBUGISON && STORAGEDEBUG && console.log(data);
	DEBUGISON && STORAGEDEBUG && console.log(guidelineId);
};
export const deleteFromStorage = async () => {
	DEBUGISON && STORAGEDEBUG && console.log("delete from Storage");
};
export const initializeToStorage = async (
	data: any,
	guidelineId: string,
	queryClient?: QueryClient
) => {
	DEBUGISON && STORAGEDEBUG && console.log(data);
	DEBUGISON && STORAGEDEBUG && console.log(guidelineId);
	DEBUGISON && STORAGEDEBUG && console.log(queryClient);
};
export const readGuidelineFromStorage = async (
	guidelineId: string,
	queryClient: QueryClient
) => {
	DEBUGISON && STORAGEDEBUG && console.log(guidelineId);
	DEBUGISON && STORAGEDEBUG && console.log(queryClient);
};
