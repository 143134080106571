import React from "react";
import { useNotices } from "../../hooks";
import { compareValues } from "../../utils";
import { Box } from "../Theme";
import NoticeCard from "../NoticeCard";

const NoticeList = () => {
	const notices = useNotices();
	return (
		<>
			{notices
				.sort(compareValues("CREATION_DATE", "desc"))
				.map((notice, idx) => {
					const isLast = idx === notices.length - 1;
					return (
						<Box key={notice.ID} marginBottom={!isLast ? "m" : "none"}>
							<NoticeCard notice={notice} thumbnail={false} />
						</Box>
					);
				})}
		</>
	);
};

export default NoticeList;
