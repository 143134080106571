import React, { useEffect } from "react";
import { Platform } from "react-native";
import { useQueryClient } from "@tanstack/react-query";
import { Box, Container, Text } from "../../../components";
import { MobileFooter } from "../../../components/Footer";
import {
  GuidelineNavigationProps,
  GuidelineRoutes
} from "../../../components/Navigation";
import { useDeviceDetect, useGuideline } from "../../../hooks";
import {
  readGuidelineFromStorage,
  translateScreenToMode
} from "../../../utils";
import { Icons } from "../../../styles";
import ModeButton from "./ModeButton";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../redux";
import { pushHistory, togglePopup } from "../../../redux/global";
import { restoreLocalGuideline } from "../../../redux/guidelines";
import RestoreIndicator from "../../../components/RestoreIndicator";
import { ModeType } from "../../../types";
import { addLoadedGuidelines } from "../../../redux/guidelines/actions";

import t from "../../../../Texts/TextImport";
import { useLoading } from "../../../components/LoadingProvider";
import { DEBUGISON, NAVDEBUGGING } from "../../../config";
const isWeb = Platform.OS === "web";
interface ModeButtonProps {
  mode: ModeType;
  title: string;
  screen: keyof GuidelineRoutes;
  icon: keyof typeof Icons;
}

const buttons: ModeButtonProps[] = [
  {
    mode: "fulltext",
    title: t("GenFu"),
    screen: "FullText",
    icon: "fulltext"
  },
  {
    mode: "recommendations",
    title: t("PHCGuideline"),
    screen: "Recommendations",
    icon: "recommendations"
  },
  {
    mode: "stepbystep",
    title: t("GenSbs"),
    screen: "StepByStep",
    icon: "stepbystep"
  },
  {
    mode: "search",
    title: t("GenSearch"),
    screen: "Search",
    icon: "search"
  }
];

const ModeSelection = ({
  navigation,
  route
}: GuidelineNavigationProps<"ModeSelection">) => {
  const { isTablet } = useDeviceDetect();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const history = useSelector((state) => state.global.history);
  const popup = useSelector((state) => state.global.popup);
  const guidelineId = route.params?.guidelineId;
  const { data: data } = useGuideline(guidelineId);
  const loading = useLoading();
  const localGuidelines = useSelector(
    (state) => state.guidelines.localGuidelines
  );
  const loadedGuidelines = useSelector(
    (state) => state.guidelines.loadedGuidelines
  );

  useEffect(() => {
    if (data) {
      navigation.setOptions({
        title: data.TITLE
      });
    }
  }, [data]);

  useEffect(() => {
    DEBUGISON &&
      NAVDEBUGGING &&
      console.log("Modeselection:guidelineId: " + guidelineId);

    DEBUGISON &&
      NAVDEBUGGING &&
      console.log("Modeselection:history: " + JSON.stringify(history));
    const func = async () => {
      if (history.length > 0) {
        dispatch(pushHistory({ id: undefined, guidelineId, mode: "default" }));
      }
      const localGuideline = localGuidelines.find(
        (g) => g.GUIDELINE_UUID === guidelineId
      );
      const loadedGuideline = loadedGuidelines.find(
        (g) => g.GUIDELINE_UUID === guidelineId
      );
      // if (!isWeb && localGuideline && !loadedGuideline) {
      // 	dispatch(restoreLocalGuideline(true));
      // 	await readGuidelineFromStorage(guidelineId, queryClient);
      // 	dispatch(addLoadedGuidelines(localGuideline));
      // 	dispatch(restoreLocalGuideline(false));
      // }
      if (popup.isVisible) {
        dispatch(togglePopup({ isVisible: false }));
      }
    };
    func();
  }, [guidelineId]);

  loading.setIsLoading(false);
  return (
    <>
      <Container customMobileFooter={<MobileFooter />}>
        <Box
          flex={1}
          justifyContent="center"
          paddingHorizontal={{ phone: "none", tablet: "xl" }}
          paddingVertical={{ phone: "m", tablet: "xl" }}
        >
          <Box padding="m">
            <Text variant="subtitle1">{t("MSMode")}</Text>
          </Box>
          {buttons.map(({ mode, title, screen, icon }) => {
            const modeEntry = data?.BASIC_ENTRY_POINTS?.find(
              (entry) => entry.MODE === mode
            );
            const isDisabled = modeEntry?.CONTENT_UUID === "" ? true : false;
            return (
              <ModeButton
                key={screen}
                color={mode}
                {...{ title, icon, isDisabled }}
                to={
                  isTablet || isWeb
                    ? `/${screen}?guidelineId=${guidelineId}&id=${
                        data?.BASIC_ENTRY_POINTS.find(
                          (i) => i.MODE === translateScreenToMode(screen)
                        )?.CONTENT_UUID
                      }`
                    : `/${screen}?guidelineId=${guidelineId}`
                }
              />
            );
          })}
        </Box>
      </Container>
      {!isWeb && <RestoreIndicator />}
    </>
  );
};

export default ModeSelection;
