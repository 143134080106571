export class HTMLPrecompiler<HTMLDataStructType> {
	HTMLStructToStringConverter: (HTMLDataStruct: HTMLDataStructType) => string;
	HTMLStringFilter: (HTMLString: string) => string;

	constructor(
		HTMLStructToStringConverter: (HTMLDataStruct: HTMLDataStructType) => string,
		HTMLStringFilter: (HTMLString: string) => string
	) {
		this.HTMLStringFilter = HTMLStringFilter;
		this.HTMLStructToStringConverter = HTMLStructToStringConverter;
	}

	precompile(HTMLDataStruct: HTMLDataStructType): string {
		return this.HTMLStringFilter(
			this.HTMLStructToStringConverter(HTMLDataStruct)
		);
	}
}
