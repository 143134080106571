export const Icons = {
  default: "\u{E019}", //?
  generalinformation: "\u{E019}", //?

  rightArrow: "\u{2192}",
  leftArrow: "\u{2193}",
  negativePlus: "\u{2295}",
  negativeMinus: "\u{2296}",
  hamburger: "\u{2630}",
  star: "\u{2606}",
  starFilled: "\u{2605}",
  A: "\u{24B6}",
  B: "\u{24B7}",
  C: "\u{24B8}",
  D: "\u{24B9}",
  settings: "\u{2699}",
  hand: "\u{270B}",
  cross: "\u{274C}",
  circledMark: "\u{2714}",
  circledCross: "\u{2BBE}",
  reset: "\u{21BA}",

  fulltext: "\u{1F5D0}",
  stepbystep: "\u{1F463}",
  search: "\u{1F50D}",
  circledUser: "\u{1F464}",
  triangleDown: "\u{1F893}",
  triangleUp: "\u{1F891}",
  information: "\u{1F6C8}",
  informations: "\u{1F6C8}", // cuz "informations" is a mode!
  flag: "\u{1F3C1}",
  statistics: "\u{1F5E0}",
  shuffle: "\u{1F500}",
  penEnvelope: "\u{1F586}",
  resizeLarge: "\u{2922}",
  enter: "\u{21B3}",
  download: "\u{2B73}",
  circle: "\u{2B55}",

  recommendations: "\u{E000}",
  circledSettings: "\u{E005}",
  circledHeart: "\u{E007}",
  circledRecommendations: "\u{E012}",
  circledLeftArrow: "\u{E016}",
  circledNotice: "\u{E017}",
  circledBookmark: "\u{E018}",
  circledQuestionMark: "\u{E019}",
  cluster: "\u{E020}",
  circledCluster: "\u{E045}",
  circledFeedback: "\u{E052}",
  buttonAbort: "\u{E054}",
  buttonSave: "\u{E055}",
  chevronLeft: "\u{E056}",
  plus: "\u{E057}",
  copy: "\u{E060}",
  bin: "\u{E061}",
  mail: "\u{E062}",
  swipe: "\u{E063}",
  zoom: "\u{E064}",
  tap: "\u{E065}",
  href: "\u{E066}",
  negativeCheck: "\u{E067}",
  negativeCross: "\u{E068}",
  circledShare: "\u{E071}",
  circledLinkedIn: "\u{E072}",
  circledTwitter: "\u{E073}",
  resizeSmall: "\u{E074}",
  horizontalIntegration: "\u{E075}",
  circledPlus: "\u{E076}",
};
