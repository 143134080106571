import { LayoutProps } from "@shopify/restyle";
import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from "react";
import {
	View,
	Animated,
	Easing,
	useWindowDimensions,
	LayoutChangeEvent,
} from "react-native";
import { useIsFetching } from "@tanstack/react-query";
import { Box, useTheme, Text } from "./Theme";
import { DEBUGISON, ORIGINDEBUG } from "../config";
import { useIsLargeScreen } from "../hooks";

const AnimatedBox = Animated.createAnimatedComponent(Box);

interface ProgressBarProps {
	barWidth: number;
}
const ProgressBar = ({ barWidth }: ProgressBarProps) => {
	const isFetching = useIsFetching();
	const animatedValue = useRef(new Animated.Value(0)).current;
	const [width, setWidth] = useState<number>(0);
	const [trigger, setTrigger] = useState<boolean>(true);

	useLayoutEffect(() => {}, []);

	useEffect(() => {
		if (isFetching > 0) {
			animation().start(() => {
				animatedValue.setValue(0);
				setTrigger(!trigger);
			});
		}
	}, [trigger, isFetching]);

	const animation = useCallback(
		() =>
			Animated.timing(animatedValue, {
				toValue: 1,
				duration: 1000,
				delay: 500,
				useNativeDriver: true,
				easing: Easing.inOut(Easing.ease),
			}),
		[animatedValue]
	);

	const translateX = animatedValue.interpolate({
		inputRange: [0, 1],
		outputRange: [-barWidth, width],
	});

	const onLayout = (event: LayoutChangeEvent) => {
		//console.log(event.nativeEvent);
		setWidth(event.nativeEvent.layout.width);
	};
	return (
		<Box height={4} overflow="hidden" onLayout={onLayout}>
			{/* {DEBUGISON && ORIGINDEBUG && <Text>Progressbar:</Text>} */}
			<AnimatedBox
				width={barWidth}
				flex={1}
				backgroundColor="primary"
				style={{ transform: [{ translateX }] }}
			/>
		</Box>
	);
};

export default ProgressBar;
