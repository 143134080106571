import React from "react";
import t from "../../../Texts/TextImport";
import { ChapterType } from "../../types";
import BreadCrumb from "./BreadCrumb";
import {
  findChaptersById,
  translateScreen,
  translateScreenToMode,
} from "../../utils";
import { CommonActions } from "@react-navigation/native";
import { GuidelineRoutes } from "../Navigation";
import { getTitle } from "../../hooks/useLanguage";
import { DEBUGISON, ORIGINDEBUG, isInDevelopment } from "../../config";
import { Text } from "../Theme";
import { devLog } from "../../utils/logging";
import DevText from "../../utils/DevText";
import { DeepLinkCopy } from "../DeepLinkCopy";

interface BreadCrumbsProps {
  tree: ChapterType[];
  chapterId: string;
  screen: keyof GuidelineRoutes;
}
const BreadCrumbs = ({ tree, chapterId, screen }: BreadCrumbsProps) => {
  devLog("chapterId" + chapterId, ORIGINDEBUG);
  devLog("tree" + tree, ORIGINDEBUG);
  if (!tree || !chapterId) {
    return <></>;
  }
  const allChapters = findChaptersById(tree, chapterId);
  const addon = allChapters.length === 1 || allChapters.length === 2;
  const guidelineId = addon && allChapters[0].GUIDELINE_UUID;
  if (allChapters.length > 0) {
    allChapters.pop();
    if (allChapters.length > 2) {
      allChapters.splice(0, 1);
    }
  }
  const ts = t("BCCs");
  return (
    <>
      <DevText>
        BreadCrumb :{JSON.stringify(addon)} : {screen}
        {translateScreenToMode(screen)}
      </DevText>

      {addon ? (
        <BreadCrumb
          color={translateScreenToMode(screen)}
          title={`${translateScreen(screen)}: ${ts} `}
          to={`/Guideline/${screen}?guidelineId=${guidelineId}&id=${undefined}`}
          action={CommonActions.navigate(screen, {
            guidelineId,
            id: undefined,
          })}
          opacity={0.9}
        />
      ) : null}
      {allChapters.map((chapter, index) => (
        <>
          <BreadCrumb
            color={translateScreenToMode(screen)}
            key={chapter.UUID}
            title={getTitle(chapter)}
            to={`/Guideline/${screen}?id=${chapter.UUID}&guidelineId=${chapter.GUIDELINE_UUID}`}
            opacity={addon ? 0.8 : 1 - (index + 1) * 0.1}
          />
        </>
      ))}
    </>
  );
};

export default BreadCrumbs;
