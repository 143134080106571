import React from "react";
import t from "../../../Texts/TextImport";
import { ORIGINDEBUG, DEBUGISON } from "../../config";
import { useAllAbout } from "../../hooks";
import Icon from "../Icon";
import LinkBox from "../LinkBox";
import { Box, Text } from "../Theme";

interface FooterProps {
  maxWidth: number;
}
const Footer = ({ maxWidth }: FooterProps) => {
  const { data: allAboutData } = useAllAbout();
  const chapterId =
    allAboutData && allAboutData.length > 0 ? allAboutData[0].UUID : "";

  return (
    <Box
      paddingVertical="s"
      paddingHorizontal={{ phone: "xs", tablet: "xl" }}
      backgroundColor="default"
    >
      <Box maxWidth={maxWidth} flexDirection="row">
        <LinkBox to="/General/Settings" withHover={false}>
          <Box flexDirection="row" alignItems="center">
            <Icon name="settings" color="mainBackground" size={16} />
            <Text color="mainBackground" paddingLeft="s" paddingRight="m">
              {t("FSettings")}
            </Text>
          </Box>
        </LinkBox>
        <LinkBox to={`/General/Informations?id=${chapterId}`} withHover={false}>
          <Box flexDirection="row" alignItems="center">
            <Icon name="informations" color="mainBackground" size={16} />
            <Text color="mainBackground" paddingLeft="s">
              {t("FLeila")}
              {ORIGINDEBUG && DEBUGISON && "Footer"}
            </Text>
          </Box>
        </LinkBox>
      </Box>
    </Box>
  );
};

export default Footer;
