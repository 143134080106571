import React, { useState, useRef, useEffect } from "react";
import {
  StyleProp,
  TextInput as RNTextInput,
  TextInputProps,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import { Box, Text, useTheme } from "../Theme";
import TextInput from "./TextInput";
import { DEBUGISON, SEARCHDEBUG } from "../../config";

interface SubmitInputProps extends TextInputProps {
  onSubmit: (value: string) => void;
  label: string;
  returnKeySubmit?: boolean;
  buttonStyle?: StyleProp<ViewStyle>;
}
const SubmitInput = ({
  onSubmit: defaultOnSubmit,
  label,
  onChangeText,
  returnKeySubmit,
  buttonStyle,
  ...props
}: SubmitInputProps) => {
  const ref = useRef<RNTextInput>(null);
  const [value, setValue] = useState("");
  const theme = useTheme();

  const onChange = (value: string) => {
    setValue(value);
    onChangeText && onChangeText(value);
  };

  const onSubmit = () => {
    defaultOnSubmit(value);
    ref.current?.blur();
  };

  useEffect(() => {
    DEBUGISON &&
      SEARCHDEBUG &&
      console.log("props.value: " + props.value + "\nvalue: " + value);
    setValue(props.value);
  }, [props.value]);

  return (
    <Box flexDirection="row" alignItems="center">
      <TextInput
        id="TextInput"
        ref={ref}
        value={value}
        onChangeText={onChange}
        onSubmitEditing={() => {
          returnKeySubmit && onSubmit();
        }}
        underlineColorAndroid={"transparent"}
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        {...props}
      />
      <TouchableOpacity onPress={() => onSubmit()} id="submitButton">
        <Box
          flex={1}
          borderTopRightRadius={theme.spacing.xs}
          borderBottomRightRadius={theme.spacing.xs}
          paddingVertical="s"
          paddingHorizontal="l"
          backgroundColor="primary"
          alignItems="center"
          style={buttonStyle}
        >
          <Text color="mainBackground" variant="button">
            {label}
          </Text>
        </Box>
      </TouchableOpacity>
    </Box>
  );
};

export default SubmitInput;
