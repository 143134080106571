import React, { useEffect, useState } from "react";
import QRWrapper from "./QRWrapper";
import t from "../../../Texts/TextImport";
import { Box, Button, LinkBox, Text, TextInput } from "../../components";
import { Camera } from "expo-camera";
import { generateUniqueId, buildHomeLink } from "../../utils/helpers";

import { GuidelineNavigationProps } from "../../components/Navigation";
import useGuideline, {
  useIsGuidelineUnlocked,
} from "../../hooks/queries/useGuideline";
import { DEBUGISON, isWeb } from "../../config";
import { getTitle } from "../../hooks/useLanguage";
import { useAuth } from "../../hooks";
import { ActivityIndicator, Dimensions } from "react-native";
import { QROutcometype, useMutateQRCodes } from "../../hooks/queries/useQRCode";
import Markdown from "../../components/Markdown";
import { QRAbortButton, QRButton } from "./QRButton";

interface QrInputProps {
  callback: (formattedValue: string) => void;
  withCam: boolean;
}

export const processHexCode = (input: string): string => {
  const capitalizedInput = input?.toUpperCase();
  const hexOnlyString = capitalizedInput.replace(/[^0-9A-F\s]/g, "");
  const truncatedString = hexOnlyString.substring(0, 32);
  return truncatedString;
};

const QrInput: React.FC<QrScannerComponentProps> = ({
  callback,
  withCam,
}: QrInputProps) => {
  const [inputValue, setInputValue] = useState("");

  const formatValue = (value: string) => {
    const cleaned = processHexCode(value?.replace(/\s/g, ""));
    return cleaned?.replace(/(.{1,4})/g, "$1 ").trim();
  };

  const handleChange = (event) => {
    const formattedValue = event?.target?.value
      ? formatValue(event.target.value)
      : formatValue(event);
    setInputValue(formattedValue);
    formattedValue?.length === 39 && callback(formattedValue); // Invoke callback with the formatted value
  };

  return (
    <Box
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      marginTop="m"
    >
      <TextInput
        style={!isWeb ? { height: 10, width: "100%" } : { width: "80%" }}
        value={inputValue}
        onChange={handleChange}
        placeholder={t("QRManual")}
      />
    </Box>
  );
};

function EnterQR({ navigation, route }: GuidelineNavigationProps<"FullText">) {
  const { data: guideline } = useGuideline(route.params.guidelineId);
  const { username } = useAuth();
  const [code, setCode] = useState<string>("");
  const QRMutation = useMutateQRCodes(
    route.params.guidelineId,
    username ? username : "",
    (msg) => {
      setValidationStatus(msg);
      console.log("Error", msg);
    },
    (msg) => {
      setValidationStatus(msg);
      console.log("Success", msg);
    }
  );

  const [validationStatus, setValidationStatus] = useState<
    QROutcometype | "validating" | "typing"
  >("typing");

  const handleScan = (code: string) => {
    setCode(code);
    validationStatus === "validating" && code;
    setValidationStatus("validating");
  };
  useEffect(() => {
    const cleanedCode = code.replace(/\s/g, "");
    cleanedCode.length !== 32 && setValidationStatus("typing");
    validationStatus === "validating" && QRMutation.mutateAsync(cleanedCode);

    validationStatus === "otherUser" &&
      navigation.navigate("Guideline", {
        screen: "ForOtherUser",
        params: { guidelineId: route.params.guidelineId },
      });
    validationStatus === "otherGuideline" &&
      navigation.navigate("Guideline", {
        screen: "ForOtherGuideline",
        params: { guidelineId: route.params.guidelineId },
      });
    validationStatus === "success" &&
      navigation.navigate("Guideline", {
        screen: "QRSuccess",
        params: { guidelineId: route.params.guidelineId },
      });
  }, [validationStatus, code]);

  return (
    <QRWrapper>
      {DEBUGISON && Text && <Text>userId: {username}</Text>}
      {validationStatus !== "error" && (
        <Markdown>{t("QRWantActivate", [getTitle(guideline)])}</Markdown>
      )}
      {validationStatus == "error" && <Markdown>{t("QRNotFound")}</Markdown>}
      <QrInput callback={handleScan} withCam={validationStatus !== "error"} />

      {validationStatus === "validating" && username && guideline?.UUID && (
        <ActivityIndicator color={"#999999"} />
      )}

      <Box
        width={"100%"}
        justifyContent="space-between"
        marginVertical="s"
        alignItems="center"
        backgroundColor={DEBUGISON ? "smoke" : "default"}
        flex={1}
      >
        <QRButton text="Aktivieren" onPress={() => handleScan("")} />
        <QRAbortButton />
      </Box>
    </QRWrapper>
  );
}

export default EnterQR;
