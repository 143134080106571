import React, {
  useState,
  useContext,
  createContext,
  ReactNode,
  useEffect,
} from "react";
import { UserType } from "../../types";
import useUser from "../queries/useUser";
import useLocalStorage from "../useLocalStorage";

interface AuthContextProps {
  error: boolean;
  user: UserType | null;
  username: string;
  signIn: (username: string) => void;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextProps>({
  error: false,
  user: null,
  username: "",
  signIn: () => null,
  signOut: () => null,
});

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
};

function useProvideAuth() {
  const [error, setError] = useState(false);
  const [username, setUsername] = useLocalStorage<string | null>(
    "username",
    null
  );
  const [user, setUser] = useLocalStorage<UserType | null>("user", null);

  const { refetch } = useUser(username, {
    enabled: false,
    retry: 0,
    onSuccess: (user) => {
      setUser(user);
      setError(false);
    },
    onError: () => setError(true),
  });

  const signIn = (username: string) => {
    setUsername(username);
  };

  const signOut = () => {
    setUser(null);
    setUsername(null);
  };

  useEffect(() => {
    if (username && username !== "") {
      refetch();
    }
  }, [username]);

  return {
    error,
    username,
    user,
    signIn,
    signOut,
  };
}
