import React from "react";
import { Text, Platform, useWindowDimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import AppStack from "./AppStack";
import DrawerContent from "./components/DrawerContent";
import { DrawerContentComponentProps } from "@react-navigation/drawer/lib/typescript/src/types";
import { useTheme } from "../components";
import { AppDrawerRoutes } from "../components/Navigation";
import { useIsLargeScreen, useAuth } from "../hooks";
import AuthStack from "./AuthStack";

const Drawer = createDrawerNavigator<AppDrawerRoutes>();

const MainDrawer = () => {
  const { height, width } = useWindowDimensions();
  const isWeb = Platform.OS === "web";
  const theme = useTheme();
  const isLargeScreen = useIsLargeScreen();
  const minWidth = width > 500 ? 100 : width - 20;
  const widthAtPerfectRatio = 1650;
  const { user, username } = useAuth();

  return (
    <Drawer.Navigator
      screenOptions={{
        drawerType: isLargeScreen ? "permanent" : undefined,
        headerShown: false,
        drawerPosition: "left",
        drawerStyle: [
          {
            backgroundColor: theme.colors.mainBackground,
            borderRightWidth: 0,
            height: isWeb ? undefined : "100%",
            width: isWeb ? undefined : "100%",
            flex:
              0.3 - ((widthAtPerfectRatio - width) / widthAtPerfectRatio) * 0.3,
            minWidth: minWidth,
          },
        ],
      }}
      drawerContent={(props: DrawerContentComponentProps) => (
        <DrawerContent {...props} />
      )}
    >
      {isWeb && (!user || !username) ? (
        <Drawer.Screen name="AuthStack" component={AuthStack} />
      ) : (
        <Drawer.Screen name="AppStack" component={AppStack} />
      )}
    </Drawer.Navigator>
  );
};

export default MainDrawer;
