import React, { ReactNode } from "react";
import EmailButton from "./EmailButton";

export const data: {
  title?: string;
  text?: string;
  component?: ReactNode;
}[] = [
  {
    text: "Nach Ihrer Zustimmung zum Datenschutz und den Nutzungsbedingungen steht Ihnen Leila PRO zur Verfügung.\n\nSollten Sie dazu Fragen haben, können Sie uns hier kontaktieren.",
    component: <EmailButton />,
  },
  {
    text: "Leila und Leila PRO der Lindgrün GmbH stellen medizinische Fachinformationen für Ärzte, Ärztinnen und Pflegende unverbindlich zur Verfügung. Als Voraussetzung für die Nutzung von Leila und Leila PRO bestätigen Sie, dass Sie den Fachkreisen im Sinne von §2 des HWG angehören. Dazu gehören die Angehörigen der Heilberufe oder Heilgewerbes, Einrichtungen die der Gesundheit von Menschen und Tieren dienen und sonstige Berufsgruppen die im Kontext Arzneimittel, Medizinprodukte, Verfahren und Behandlung tätig sind. Unser Redaktionsteam, bestehend aus medizinischen Redakteuren und Ärzten, arbeitet mit größter Sorgfalt. Dessen ungeachtet kann die Lindgrün GmbH keine Haftung für die Richtigkeit und Vollständigkeit der zur Verfügung gestellten Informationen übernehmen. Die Lindgrün GmbH übernimmt keine Haftung für darauf basierende Präventions-, Diagnose-, Therapie- oder Rehabilitationsentscheidungen.\n\nDurch die Zustimmung („Einverstanden“) am Ende der Nutzungsbedingungen und Datenschutzbestimmungen stimmen Sie der Verarbeitung der ANONYM ERFASSTEN DATEN im beschriebenen Umfang zu. Die ANONYM ERFASSTEN DATEN WERDEN ERST NACH IHRER ZUSTIMMUNG ERFASST. EINE NUTZUNG OHNE IHRE ZUSTIMMUNG („Einverstanden“) IST NICHT MÖGLICH.\n\nLeila PRO nutzt ausschließlich die vom Betriebssystem des Endgeräts bereitgestellten Möglichkeiten in der vom Betriebssystem vorgeschriebenen Art und Weise.\n\nLeila PRO benötigt für die Installation und das Update von Inhalten eine Internetverbindung. Nach einem Download der Inhalte ist Leila PRO auch offline zu nutzen. Um inhaltlich immer auf dem neuesten Stand der Leitlinien und der zusätzlichen Inhalte zu bleiben, empfehlen wir Ihnen das Einschalten des automatischen Updates.",
  },
  {
    title: "Kontaktdaten  ",
    text: "Lindgrün GmbH \nCuxhavener Strraße \n10555 Berlin (HRB: 82403 B)\nEmail: leila@leila.de",
  },
  {
    title: "Datenschutz",
    text: `Sie können diese Datenschutzerklärung jederzeit unter dem Menüeintrag „Datenschutz“ innerhalb der App aufrufen.\nRechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 Buchstabe f) DS-GVO. Wir verfolgen damit das berechtigte Interesse, unser Angebot, auf Basis der durchgeführten ANONYMEN Nutzungsanalysen, bedarfsgerecht zu gestalten und fortlaufend zu optimieren.`,
  },
  {
    title: "1. Informationen zur Verarbeitung und Nutzung Ihrer Daten",
    text: `Bei der Nutzung der App werden KEINE PERSONENBEZOGNEN DATEN erhoben und verarbeitet. Personenbezogene Daten sind definiert als sämtliche Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Beim ersten Start der geladenen App wird eine Identifikationsnummer (ID) erzeugt. Dies geschieht erst nachdem Sie sich mit den Nutzungsbedingungen und Datenschutzbestimmungen einverstanden erklärt haben. Diese ID stellt KEINE Verbindung zu Ihren persönlichen Daten oder Ihren spezifischen Smartphone-Daten her. Daher können wir KEINE Verbindung zwischen Ihnen und der Nutzung der App herstellen. \nIhre ID können Sie jederzeit in den Einstellungen in der installierten Leila PRO-App auf Ihrem Smartphone einsehen.`,
  },
  {
    title:
      "1.1 Informationen, die beim Download durch die App Stores erhoben werden",
    text: `Beim Download der App werden bestimmte erforderliche Informationen an den von Ihnen ausgewählten App Store (z.B. Google Play oder Apple App Store) übermittelt. Die Daten, die Sie an den jeweiligen App Store übermitteln, können Ihren Nutzernamen, die E-Mail-Adresse, die Kundennummer Ihres Accounts, den Zeitpunkt des Downloads, Zahlungsinformationen sowie die individuelle Gerätekennziffer umfassen. Die Verarbeitung dieser Daten erfolgt AUSSCHLIESSLICH durch den jeweiligen App Store und liegt außerhalb unseres Einflussbereiches.`,
  },
  {
    title: "1.2 Informationen, die durch die Nutzung ANONYM erhoben werden",
    text: `Im Rahmen Ihrer Nutzung der App erheben wir ANONYM folgende Daten automatisch, die für die Nutzung der App erforderlich sind. Hierzu gehören: erzeugte ID, Betriebssystem, Zeitpunkt des Zugriffs, Dauer des Zugriffs, Nutzungsdaten der App. Um Ihnen einen geräteunabhängigen Zugriff auf Ihre Notizen und Lesezeichen zu ermöglichen, werden diese ANONYM auf den Servern der Lindgrün GmbH gespeichert (Serverstandort Deutschland).\n\nDiese Daten werden ANONYM und automatisch an uns übermittelt (1) um Ihnen den Dienst und die damit verbundenen Funktionen zur Verfügung zu stellen; (2) die Funktionen und Leistungsmerkmale der App zu verbessern und (3) Missbrauch sowie Fehlfunktionen vorzubeugen und zu beseitigen. Diese Datenverarbeitung ist dadurch gerechtfertigt, dass (1) die Verarbeitung für die Erfüllung des Vertrags zwischen Ihnen als Betroffener und uns gemäß Art. 6 Abs. 1 lit. b) DS-GVO zur Nutzung der App erforderlich ist, oder (2) wir ein berechtigtes Interesse daran haben, die Funktionsfähigkeit und den fehlerfreien Betrieb der App zu gewährleisten und einen markt- und interessengerechten Dienst anbieten zu können, das hier Ihre Rechte und Interessen am Schutz Ihrer personenbezogenen Daten im Sinne von Art. 6 Abs. 1 lit. f) DS-GVO überwiegt.`,
  },
  {
    title: `Tools der Datenerfassung, Nutzeranalyse und Fehlerverfolgung \nSentry: Absturzberichte`,
    text: `Eingesetzt wird das Open-Source-Tool „Sentry“. Das eingesetzte Analysetool „Sentry“ ist auf den Servern der Lindgrün GmbH installiert. Sämtliche damit erfassten Daten werden auf den Servern der Lindgrün GmbH mit Standort Deutschland gespeichert und unterliegen der geltenden EU-Datenschutzverordnung. „Sentry“ bietet eine Echtzeit-Fehlerverfolgung für mobile Anwendungen, die uns den notwendigen Einblick in die Reproduktion und Behebung von Abstürzen gibt. Sentry hat eine SOC 2 Typ I-Zertifizierung.`,
  },
  {
    title: "Nutzung:",
    text: `Alle nutzungsbezogenen Daten, Zeitpunkt des Zugriffs, Dauer des Zugriffs und Nutzungsdaten der App werden durch das Datenbanksystem über die ID ANONYM von der Lindgrün GmbH erfasst.`,
  },
  {
    title: "2. Weitergabe und Übertragung von Daten an Empfänger",
    text: "Die Daten werden ausschließlich durch die Lindgrün GmbH und damit verbundenen Wissenschaftler*innen für wissenschaftliche Studien und Publikation genutzt. ",
  },
  {
    title: "3. Datenübermittlungen in Drittländer",
    text: `Wir verarbeiten Ihre Daten ausschließlich innerhalb Deutschlands.`,
  },
  {
    title: `4. Zweckänderungen`,
    text: `Verarbeitungen Ihrer ANONYMEN Daten zu anderen als den beschriebenen Zwecken erfolgen nur, soweit eine Rechtsvorschrift dies erlaubt oder Sie in den geänderten Zweck der Datenverarbeitung eingewilligt haben. `,
  },
  {
    title: "5. Speicherdauer",
    text: `Die Daten werden im Sinne der wissenschaftlichen Verwendung und der Pharmakovigilanz für 10 Jahre gespeichert und in der Folge gelöscht. `,
  },
  {
    title: "6. Ihre Rechte",
    text: "Spezifische Angaben in dieser Datenschutzerklärung oder rechtliche Vorgaben zur Aufbewahrung und Löschung der ID zugeordneten ANONYMEN Daten, insbesondere solcher, die wir aus steuerrechtlichen Gründen aufbewahren müssen, bleiben unberührt. Diese umfassen: Ihre Rechte als Betroffener, Auskunftsrecht, Recht zur Berichtigung unrichtiger Daten, Recht auf Löschung, Recht auf Einschränkung der Verarbeitung, Recht auf Datenübertragbarkeit, Recht auf Widerruf einer erteilten Einwilligung bezüglich einer Datenverarbeitung im Rahmen dieser App und das Widerspruchsrecht.\n\nSie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender, ID-bezogener Daten, die u.a. aufgrund von Art. 6 Abs. 1 lit. e) oder f) DS-GVO erfolgt, Widerspruch nach Art. 21 DS-GVO einzulegen. Wir werden die Verarbeitung der ID-Daten einstellen, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient. Sie müssen uns hierfür Ihre persönlich ID (die ID können Sie jederzeit in den Informationen zu Leila einsehen) zur Verfügung stellen. Nur so können wir Ihre Daten identifizieren.",
  },
  {
    title: "7. Beschwerderecht",
    text: "Sie haben ferner das Recht, sich bei Beschwerden an die zuständige Aufsichtsbehörde zu wenden. Die zuständige Aufsichtsbehörde ist:\n\nBerliner Beauftragte für Datenschutz und Informationsfreiheit\nMaja Smoltczyk\nFriedrichstr. 219\n10969 Berlin\nTel.: +49 (0)30 13889-0\nFax: +49 (0)30 2155050\nE-Mail: mailbox@datenschutz-berlin.de",
  },
  {
    title: "8. Sicherheit",
    text: `Die Lindgrün GmbH speichert und verarbeitet KEINE PERSÖNLICHEN DATEN. Sämtliche Daten, die durch die ANONYME ID erhoben werden, werden von Ihrem Endgerät verschlüsselt an die Lindgrün GmbH übertragen. `,
  },
  {
    title: "9. Kontakt",
    text: `Sollten Sie Fragen oder Anmerkungen zu unserem Umgang mit Ihren personenbezogenen Daten haben oder möchten Sie die unter Ziffer 6 und 7 genannten Rechte als betroffene Person ausüben, wenden Sie sich bitte an die oben genannte Kontaktadresse.`,
  },
  {
    title: "10. Änderungen dieser Datenschutzerklärung",
    text: `Wir halten diese Datenschutzerklärung immer auf dem neuesten Stand. Deshalb behalten wir uns vor, sie von Zeit zu Zeit zu ändern und Änderungen bei der Erhebung, Verarbeitung oder Nutzung Ihrer Daten nachzupflegen. Die aktuelle Fassung der Datenschutzerklärung ist stets unter „Datenschutz“ innerhalb der App abrufbar.\n\nIch habe die Nutzungsbedingungen und den Datenschutz verstanden und stimme zu.`,
  },
];
