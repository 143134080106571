import {
	QueryFunctionContext,
	QueryObserverOptions,
	useMutation,
	useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import {
	BOOKMARK,
	DELETE_REQUEST,
	MET,
	POST_REQUEST,
	URL,
	SECTOR,
	LANGUAGE,
} from "./config";
import { BookmarkType } from "../../types";
import { DEBUGISON, HOOKSDEBUGGING } from "../../config";

const getBookmarkById = async (context: QueryFunctionContext) => {
	DEBUGISON && HOOKSDEBUGGING && console.log("useBookmark:getBookmarkById: ");
	const params = {
		met: MET,
		SECTOR: SECTOR,
		LANGUAGE: LANGUAGE,
		entity: BOOKMARK,
		get: context.queryKey[1],
	};
	const { data } = await axios.get(URL, { params });

	DEBUGISON &&
		HOOKSDEBUGGING &&
		console.log(
			"useBookmark:getBookmarkById:\n" +
				"URL: " +
				URL +
				"\nparams: " +
				params +
				"\ndata: " +
				data
		);
	return data;
};

const createBookmark = async (bookmark: BookmarkType) => {
	DEBUGISON && HOOKSDEBUGGING && console.log("useBookmark:createBookmark: ");
	const params = {
		met: MET,
		SECTOR: SECTOR,
		LANGUAGE: LANGUAGE,
		entity: BOOKMARK,
		request: POST_REQUEST,
		ID: bookmark.ID,
		CREATION_DATE: bookmark.CREATION_DATE,
		CREATION_TIME: bookmark.CREATION_TIME,
		PLATFORM: bookmark.PLATFORM,
		MODE: bookmark.MODE,
		CONTENT_UUID: bookmark.CONTENT_UUID,
		GUIDELINE_UUID: bookmark.GUIDELINE_UUID,
		USER_ID: bookmark.USER_ID,
		BREADCRUMB: bookmark.BREADCRUMB,
	};
	const { data } = await axios.get(URL, { params });

	DEBUGISON &&
		HOOKSDEBUGGING &&
		console.log(
			"useBookmark:createBookmark:\n" +
				"URL: " +
				URL +
				"\nparams: " +
				params +
				"\ndata: " +
				data
		);
	return data;
};

const deleteBookmark = async (bookmark: BookmarkType) => {
	DEBUGISON && HOOKSDEBUGGING && console.log("useBookmark:deleteBookmark: ");
	const params = {
		met: MET,
		SECTOR: SECTOR,
		LANGUAGE: LANGUAGE,
		entity: BOOKMARK,
		request: DELETE_REQUEST,
		get: bookmark.ID,
	};
	const { data } = await axios.get(URL, { params });

	DEBUGISON &&
		HOOKSDEBUGGING &&
		console.log(
			"useBookmark:deleteBookmark:\n" +
				"URL: " +
				URL +
				"\nparams: " +
				params +
				"\ndata: " +
				data
		);
	return data;
};

export function useBookmarkCreate(
	options?: QueryObserverOptions<BookmarkType>
) {
	DEBUGISON && HOOKSDEBUGGING && console.log("useBookmark:useBookmarkCreate: ");
	// @ts-expect-error
	return useMutation<BookmarkType>(createBookmark, options);
}

export function useBookmarkDelete(
	options?: QueryObserverOptions<BookmarkType>
) {
	DEBUGISON && HOOKSDEBUGGING && console.log("useBookmark:useBookmarkDelete: ");
	// @ts-expect-error

	return useMutation<BookmarkType>(deleteBookmark, options);
}

export default function useBookmark(bookmarkId: string) {
	DEBUGISON && HOOKSDEBUGGING && console.log("useBookmark:useBookmark: ");
	return useQuery(["bookmark", bookmarkId], getBookmarkById);
}
