import React, { useCallback } from "react";
import t from "../../../Texts/TextImport";
import { Box } from "../Theme";
import Icon from "../Icon";
import BorderlessTap from "../BorderlessTap";
import { useNavigation } from "@react-navigation/native";
import { PopupBookmarkList, PopupFeedback, PopupNoticeList } from "../Popup";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch } from "react-redux";
import { togglePopup } from "../../redux/global";
import { useGoBack } from "../../hooks";

const MobileFooter = () => {
  const onGoBack = useGoBack();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const insets = useSafeAreaInsets();

  const onFeedback = useCallback(() => {
    dispatch(
      togglePopup({
        title: t("GenFBSend"),
        description: t("GenCrit"),
        content: <PopupFeedback />,
        isVisible: true,
      })
    );
  }, []);

  const onNotice = useCallback(() => {
    dispatch(
      togglePopup({
        title: t("GenNotes"),
        content: <PopupNoticeList />,
        isVisible: true,
      })
    );
  }, []);

  const onBookmark = useCallback(() => {
    dispatch(
      togglePopup({
        title: t("BookmarkThis"),
        content: <PopupBookmarkList />,
        isVisible: true,
      })
    );
  }, []);

  return (
    <>
      <Box
        flexDirection='row'
        paddingVertical='m'
        paddingHorizontal='m'
        justifyContent='space-between'
        borderTopColor='smoke'
        borderTopWidth={1}
      >
        <BorderlessTap onPress={onGoBack}>
          <Icon name='circledLeftArrow' color='mainForeground' size={40} />
        </BorderlessTap>
        <BorderlessTap onPress={onNotice}>
          <Icon name='circledNotice' color='mainForeground' size={40} />
        </BorderlessTap>
        <BorderlessTap onPress={onBookmark}>
          <Icon name='circledBookmark' color='mainForeground' size={40} />
        </BorderlessTap>
        <BorderlessTap onPress={onFeedback}>
          <Icon name='circledFeedback' color='mainForeground' size={40} />
        </BorderlessTap>
        <BorderlessTap onPress={() => navigation.navigate("Home")}>
          <Icon name='circledCluster' color='mainForeground' size={40} />
        </BorderlessTap>
      </Box>
      <Box height={insets.bottom} backgroundColor='mainForeground' />
    </>
  );
};

export default MobileFooter;
