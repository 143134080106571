import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import t from "../../../../Texts/TextImport";
import {
  Accordion,
  Text,
  Box,
  Icon,
  Checkbox2,
  ButtonSmall,
} from "../../../components";
import { GuidelineType } from "../../../types/guideline";
import { chapters, results } from "./config";

interface FilterProps {
  guidelinesData: GuidelineType[] | undefined;
  guidelineFilter: GuidelineType[];
  guidelineId: string;
  onSelect: Dispatch<SetStateAction<GuidelineType[]>>;
  search: boolean;
}
const FilterGuideline = ({
  guidelinesData,
  guidelineFilter,
  guidelineId,
  onSelect,
  search,
}: FilterProps) => {
  const [activeEventKey, setActiveEventKey] = useState(-1);
  const allEnabled = guidelinesData?.length === guidelineFilter.length;

  useEffect(() => {
    if (activeEventKey !== -1 && search) {
      setActiveEventKey(-1);
    }
  }, [search]);

  return (
    <>
      <Accordion activeEventKey={activeEventKey} onChange={setActiveEventKey}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          paddingBottom="xs"
        >
          <Accordion.Toggle eventKey={0}>
            <Box flexDirection="row" alignItems="center">
              <Text paddingRight="xs">{t("FGGuidelines")}</Text>
              <Icon
                name={activeEventKey === 0 ? "triangleUp" : "triangleDown"}
                color="default"
              />
            </Box>
          </Accordion.Toggle>
          {activeEventKey === 0 && (
            <ButtonSmall
              onPress={() => {
                if (guidelineFilter.length === guidelinesData?.length) {
                  const currentGuideline = guidelinesData?.find(
                    (i) => i.UUID === guidelineId
                  );
                  onSelect(currentGuideline ? [currentGuideline] : []);
                } else {
                  onSelect(guidelinesData || []);
                }
              }}
              label={t("All")}
              mainColor={allEnabled ? "search" : "information"}
              color="mainBackground"
            />
          )}
        </Box>
        <Accordion.Collapse eventKey={0}>
          {guidelinesData?.map((i) => {
            const isSelected = guidelineFilter.find((j) => j?.UUID === i?.UUID)
              ? true
              : false;
            const onPress = () =>
              onSelect(
                isSelected
                  ? guidelineFilter.filter((j) => j.UUID !== i.UUID)
                  : [...guidelineFilter, i]
              );

            return (
              <Box key={i.UUID} paddingBottom="s">
                <Checkbox2
                  color="search"
                  label={
                    <Box paddingLeft="s">
                      <Text variant="description">
                        Areal:
                        <Text variant="symbols" fontSize={15} paddingLeft="xs">
                          {i.AREA.ICON
                            ? String.fromCharCode(parseInt(i?.AREA.ICON, 16))
                            : `ICONNOTFOUND`}
                        </Text>
                        <Text
                          variant="description"
                          fontFamily="bliss-pro-medium"
                        >
                          {i.AREA.NAME}
                        </Text>
                      </Text>
                      <Text>{i.TITLE}</Text>
                    </Box>
                  }
                  checked={isSelected}
                  onPress={onPress}
                />
              </Box>
            );
          })}
        </Accordion.Collapse>
      </Accordion>
    </>
  );
};

export default FilterGuideline;
