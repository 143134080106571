import React, { useEffect, useMemo, useState } from "react";
import { Box, LinkBox, Text, Theme } from "../../components";
import { GuidelineRoutes } from "../../components/Navigation";
import { hashQueryKey, useQueryClient } from "@tanstack/react-query";
import { BACKENDTRACING, DEBUGISON, ORIGINDEBUG, isErknet } from "../../config";
import { getPathwayName, getTitle } from "../../hooks/useLanguage";
import { AboutType, ChapterType } from "../../types";
import { SettingsPageType } from "../General/Settings/config";
import Pathway from "./StepByStep/Pathway";
import { getRecommendationById } from "../../hooks/queries/useRecommendation";
import {
  getAllChaptersById,
  getChapterById,
} from "../../hooks/queries/useChapter";
import { getPathwayById } from "../../hooks/queries/usePathway";
import { CHAPTER } from "../../hooks/queries/config";

interface ChapterButtonProps {
  color: keyof Theme["colors"];
  screen: keyof GuidelineRoutes;
  chapter: AboutType | SettingsPageType | ChapterType;
  pathway?: boolean;
}
const ChapterButton = ({
  color,
  chapter,
  screen,
  pathway = false,
}: ChapterButtonProps) => {
  const [id, SetId] = useState("NoId");
  const [guidelineId, SetGuidelineId] = useState("NoGuidelineId");
  const [title, SetTitle] = useState("NoTitle");
  const [loaded, SetLoaded] = useState(false);
  const queryClient = useQueryClient();

  async function load() {
    SetId(chapter?.UUID);
    SetGuidelineId(chapter?.GUIDELINE_UUID);
    SetTitle(
      screen === "StepByStep"
        ? getPathwayName(chapter) || getTitle(chapter)
        : getTitle(chapter)
    );

    setTimeout(() => {
      SetLoaded(true);
    }, 100);
  }

  useEffect(() => {
    load();
  }, [chapter]);

  if (!loaded) return <></>;

  return (
    <Box marginBottom="m">
      <LinkBox to={`/${screen}?guidelineId=${guidelineId}&id=${id}`}>
        <Box
          flex={1}
          flexDirection="row"
          paddingVertical="xs"
          paddingHorizontal="s"
          alignItems="center"
          borderLeftWidth={6}
          borderBottomWidth={1}
          borderLeftColor={color}
          borderBottomColor="smoke"
          nativeID={
            "\nDEBUGINFO ChapterButton:\nguidelineId: " +
            guidelineId +
            "\nId: " +
            id +
            "\ntitle:" +
            title
          }
          backgroundColor={undefined}
        >
          <Text variant="subtitle2" paddingLeft="s" color={undefined}>
            {title}
            {ORIGINDEBUG && DEBUGISON && "ChapterButton"}
          </Text>
          {BACKENDTRACING && DEBUGISON && (
            <>
              <Text>
                {"\nguidelineId: " + guidelineId}
                {"Id: " + id}
              </Text>
            </>
          )}
        </Box>
      </LinkBox>
    </Box>
  );
};

const queryData = (
  screen: keyof GuidelineRoutes,
  id: string,
  guidelineId: string,
  pathway: boolean
) => {
  if (screen === "Recommendations") {
    return ["recommendations", id, guidelineId];
  } else if (screen === "FullText") {
    return ["chapter", id, guidelineId];
  } else if (screen === "StepByStep" && pathway) {
    return ["pathway", id, guidelineId];
  }
  return ["chapter", id, guidelineId];
};

export default ChapterButton;
