import {
  CommonActions,
  StackActions,
  useLinkBuilder
} from "@react-navigation/native";
import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import Date from "../Date";
import { useAllPathways, useAuth, useBookmarkDelete } from "../../hooks";
import { BookmarkType } from "../../types";
import { translateModetoScreen } from "../../utils";
import { BinaryAlert } from "../Alert";
import Icon from "../Icon";
import LinkBox from "../LinkBox";
import { Box, Text } from "../Theme";
import { useDispatch } from "react-redux";
import { addLocalBookmarkDelete } from "../../redux/user/actions";
import RoundedIconButton from "../RoundedIconButton";
import { DEBUGISON, ORIGINDEBUG, isWeb } from "../../config";
import { getTitle } from "../../hooks/useLanguage";
import { Pressable } from "react-native";

interface BookmarkCardProps {
  bookmark: BookmarkType;
  withButton?: boolean;
  isDisabled?: boolean;
}

const BookmarkCard = ({
  bookmark,
  withButton,
  isDisabled
}: BookmarkCardProps) => {
  const buildLink = useLinkBuilder();
  const { data: pathwaysData } = useAllPathways(bookmark.GUIDELINE_UUID);
  const { username } = useAuth();
  const screen = translateModetoScreen(bookmark.MODE);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate } = useBookmarkDelete({
    onSuccess: () => queryClient.refetchQueries(["user", username]),
    onError: () => {
      dispatch(addLocalBookmarkDelete(bookmark));
    }
  });

  // FOR PATHWAYS
  if (!bookmark.CONTENT && !bookmark.CONTENT_EN) {
    bookmark.CONTENT = pathwaysData?.find(
      (i) => i.UUID === bookmark.CONTENT_UUID
    );
  }
  // END

  return (
    <Box backgroundColor="mainBackground">
      <LinkBox
        to={
          buildLink(
            `/Guideline/${screen}?id=${bookmark.CONTENT_UUID}&guidelineId=${bookmark.GUIDELINE_UUID}`
          ) || "404"
        }
        action={CommonActions.navigate("Guideline", {
          screen,
          params: {
            id: bookmark.CONTENT_UUID,
            guidelineId: bookmark.GUIDELINE_UUID
          }
        })}
        isDisabled={isDisabled ? true : false}
        showDisabled={false}
      >
        <Box
          borderBottomWidth={1}
          borderBottomColor="smoke"
          borderLeftWidth={5}
          borderLeftColor={bookmark.MODE}
          paddingBottom="s"
          paddingHorizontal="m"
          paddingTop="xs"
        >
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Date variant="description" date={bookmark.CREATION_DATE} />
            {withButton ? (
              <LinkBox to="">
                <RoundedIconButton
                  size={26}
                  //iconRatio={0.7}
                  name="bin"
                  onPress={() => {
                    BinaryAlert({
                      title: "Wirklich löschen?",
                      desc: "",
                      onPositivePress: () => mutate({ ...bookmark }),
                      onNegativePress: () => null
                    });
                  }}
                />
              </LinkBox>
            ) : null}
          </Box>
          <Text
            fontSize={15}
            lineHeight={18}
            fontFamily="bliss-pro-medium-italic"
            color={bookmark.MODE}
            numberOfLines={2}
          >
            {getTitle(bookmark?.CONTENT?.GUIDELINE)}

            {ORIGINDEBUG && DEBUGISON && "BookmarkCard"}
          </Text>
          {bookmark.BREADCRUMB !== "" ? (
            <Text variant="description">{bookmark?.BREADCRUMB}</Text>
          ) : null}
          <Text
            numberOfLines={2}
            fontFamily="bliss-pro-medium"
            lineHeight={20}
            paddingTop="xs"
          >
            {getTitle(bookmark?.CONTENT) || getTitle(bookmark?.CONTENT)}
          </Text>
        </Box>
      </LinkBox>
    </Box>
  );
};

BookmarkCard.defaultProps = {
  withButton: true
};

interface BookmarkThumbnailProps {
  bookmark: BookmarkType;
}

const BookmarkThumbnail = ({ bookmark }: BookmarkThumbnailProps) => {
  const buildLink = useLinkBuilder();
  const screen = translateModetoScreen(bookmark.MODE);
  const { data: pathwaysData } = useAllPathways(bookmark.GUIDELINE_UUID);
  if (!bookmark?.CONTENT) {
    bookmark.CONTENT = pathwaysData?.find(
      (i) => i.UUID === bookmark.CONTENT_UUID
    );
  }
  return (
    <Box marginBottom="m">
      <LinkBox
        to={
          buildLink(
            `/Guideline/${screen}?id=${bookmark.CONTENT_UUID}&guidelineId=${bookmark.GUIDELINE_UUID}`
          ) || "404"
        }
        action={CommonActions.navigate("Guideline", {
          screen,
          params: {
            id: bookmark.CONTENT_UUID,
            guidelineId: bookmark.GUIDELINE_UUID
          }
        })}
      >
        <Box flexDirection="row">
          <Box style={{ paddingTop: 2 }}>
            <Icon name="circledBookmark" size={15} color={bookmark.MODE} />
          </Box>
          <Box flex={1} paddingLeft="xs">
            <Text
              variant="description"
              fontFamily="bliss-pro-medium-italic"
              numberOfLines={2}
              color={bookmark.MODE}
            >
              {getTitle(bookmark.CONTENT?.GUIDELINE)}
            </Text>
            <Text
              variant="description"
              fontFamily="bliss-pro-light-italic"
              numberOfLines={2}
            >
              {getTitle(bookmark.CONTENT) || getTitle(bookmark.CONTENT)}
              {ORIGINDEBUG && DEBUGISON && "BookmarkCard"}
            </Text>
          </Box>
        </Box>
      </LinkBox>
    </Box>
  );
};

export default BookmarkCard;
export { BookmarkThumbnail };
