import React, { Fragment, useEffect, useState } from "react";
import { Platform } from "react-native";
import t from "../../../Texts/TextImport";
import { DEBUGISON, HOOKSDEBUGGING, ORIGINDEBUG } from "../../config";
import { useAnalytics, useGuidelines, useSearchHorizontal } from "../../hooks";
import { getContent, getTitle } from "../../hooks/useLanguage";
import { ModeType } from "../../types";
import Accordion from "../Accordion";
import ChapterCard from "../ChapterCard";
import Icon from "../Icon";
import Link from "../Link";
import { Box, Text } from "../Theme";
import PopupFooter from "./PopupFooter";
import { sliceContentAtNearestSpace } from "../../utils";

const isWeb = Platform.OS === "web";

interface PopupHorizontalContentProps {
  data: string[];
  connectedGuideline: string;
  mode: ModeType;
}
const PopupHorizontalContent = ({
  data: terms,
  connectedGuideline,
  mode
}: PopupHorizontalContentProps) => {
  useAnalytics({ screen: "guideline", term: terms ? terms.join(",") : "" });
  const [resultsExist, setResultsExist] = useState(false);
  const guidelineResult = useGuidelines();
  const guideline = guidelineResult.data?.find((guideline) => {
    return guideline.UUID === connectedGuideline;
  });

  const { data: searchData } = useSearchHorizontal(terms, connectedGuideline, {
    enabled: terms !== []
  });

  useEffect(() => {
    if (searchData && searchData.length > 0) {
      setResultsExist(false);
      searchData.some((result) => {
        DEBUGISON && HOOKSDEBUGGING && console.log(result);
        return terms.some((term) => {
          const reg = new RegExp(term, "g");
          const hits = getContent(result).match(reg) || [];
          if (hits.length > 0) {
            setResultsExist(true);
            return true;
          } else {
            return false;
          }
        });
      });
    }
  }, [searchData]);

  return (
    <>
      <Box
        margin="m"
        marginTop="s"
        paddingBottom="s"
        backgroundColor="mainBackground"
      >
        <Box flexDirection="row" alignItems="center" padding="s">
          <Icon name="horizontalIntegration" size={18} color="mainForeground" />
          <Text paddingLeft="xs" lineHeight={15} variant="description">
            {t("PHCIntegration")}
          </Text>
        </Box>
        <Box padding="s" paddingTop="none">
          <Box flexDirection="row" alignItems="center">
            <Text variant="description" paddingRight="xs">
              {t("PHCAreal")}
            </Text>
            <Text variant="symbols" paddingRight="xs">
              {
                // @ts-expect-error
                String.fromCharCode(parseInt(guideline?.AREA?.ICON, 16))
              }
            </Text>
            <Text fontFamily="bliss-pro-medium">
              {guideline?.AREA.SHORT_NAME}
            </Text>
          </Box>
          <Text fontFamily="bliss-pro-medium">{getTitle(guideline)}</Text>
          {!resultsExist ? (
            <Link
              to={
                isWeb
                  ? `/Guideline/FullText?guidelineId=${guideline?.UUID}&id=${
                      guideline?.BASIC_ENTRY_POINTS.find(
                        (i) => i.MODE === "fulltext"
                      )?.CONTENT_UUID
                    }`
                  : `/Guideline/ModeSelection?guidelineId=${guideline?.UUID}&id=${undefined}`
              }
            >
              <>
                <Text color={mode} paddingRight="xs">
                  {`${isWeb ? "" : t("PHCThe")}${t("GenGui")} `}
                  {ORIGINDEBUG &&
                    DEBUGISON &&
                    "PopupHorizontalContent: !resultsExist"}
                </Text>
                <Icon name="rightArrow" color={mode} fontWeight="bold" />
              </>
            </Link>
          ) : null}
        </Box>
        {resultsExist ? (
          <Accordion>
            <Accordion.Toggle eventKey={0}>
              <Box
                flexDirection="row"
                justifyContent="space-between"
                paddingHorizontal="s"
              >
                <Text color={mode}>
                  {t("PHCResults")}

                  {ORIGINDEBUG &&
                    DEBUGISON &&
                    "PopupHorizontalContent: resultsExist"}
                </Text>
                <Icon name="triangleDown" color={mode} />
              </Box>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={0}>
              {searchData?.map((chapter, idx) => {
                const term = findTerm(getContent(chapter), terms);
                const reg = new RegExp(term, "g");
                const hits = getContent(chapter).match(reg) || [];
                const index = getContent(chapter).search(reg);

                const { CONTENT, CONTENT_EN } = sliceContentAtNearestSpace(
                  getContent(chapter),
                  term,
                  index
                );
                chapter.CONTENT = CONTENT;
                chapter.CONTENT_EN = CONTENT_EN;

                return (
                  <Fragment key={chapter.UUID}>
                    {hits.length > 0 && (
                      <ChapterCard
                        chapter={chapter}
                        mode="fulltext"
                        hits={hits.length}
                        term={term}
                      />
                    )}
                  </Fragment>
                );
              })}
            </Accordion.Collapse>
          </Accordion>
        ) : null}
      </Box>
      <PopupFooter />
    </>
  );
};

export default PopupHorizontalContent;

const findTerm = (string: string, terms: string[]): string => {
  let term = "dertermwurdenichtgefunden";
  terms.some((i) => {
    const reg = new RegExp(i, "g");
    const hits = string.match(reg) || [];
    if (hits.length > 0) {
      term = i;
      return true;
    } else {
      return false;
    }
  });
  return term;
};
