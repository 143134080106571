import { useSelector } from "../redux";
import useUser from "./queries/useUser";
import { useAuth } from "./useAuth";

const useNotices = () => {
  const { username } = useAuth();
  const { data: userData } = useUser(username);

  const localNotices = useSelector((state) => state.user.localNotices);
  const localNoticesDelete = useSelector(
    (state) => state.user.localNoticesDelete
  );

  const subUsersNotices = userData?.SUB_USER.map((user) => user?.NOTICES) || [];

  const allNotices = userData
    ? [...userData.NOTICES, ...localNotices, ...subUsersNotices.flat()]
    : localNotices;

  const notices = allNotices.filter(
    (notice) => !localNoticesDelete.some((item) => item.ID === notice.ID)
  );

  return notices;
};

export { useNotices };
