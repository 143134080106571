import React from "react";
import { BorderlessTap, Box, Text } from "../../../components";
import { PathwayType } from "../../../types";
import Dot from "./Dot";
import { getTitle } from "../../../hooks/useLanguage";

const size = 10;
export interface HistoryStepProps {
	question?: {
		TITLE: string;
		TITLE_EN: string;
		UUID: string;
		TYPE?: string;
	};
	answer?: {
		TITLE: string;
		TITLE_EN: string;
		UUID: string;
		VALUE?: number;
		TYPE?: string;
	};
}

interface HistoryListProps {
	history: HistoryStepProps[];
	onClick: (idx: number) => void;
}
const HistoryList = ({ history, onClick }: HistoryListProps) => {
	return (
		<>
			{history.map((item, idx) => {
				return (
					<Box
						key={idx}
						flexDirection="row"
						marginBottom="s"
						alignItems="center"
						//maxWidth="80%"
					>
						<Dot size={size} idx={idx} />
						<BorderlessTap onPress={() => onClick(idx)}>
							<Box paddingLeft="m" flexDirection="row" alignItems="center">
								<Text
									variant="description"
									color="stepbystep"
									numberOfLines={1}
								>
									{getTitle(item.question)}
								</Text>
								<Text
									variant="description"
									fontFamily="bliss-pro-medium"
									paddingLeft="xs"
									color="stepbystep"
									numberOfLines={1}
								>
									{getTitle(item.answer)}
								</Text>
							</Box>
						</BorderlessTap>
					</Box>
				);
			})}
		</>
	);
};

export default HistoryList;
