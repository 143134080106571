import React, { useEffect, useMemo, useRef, useState } from "react";
import { Text, Box, HTML } from "../../components";
import NumberInput from "../NumberInput";
import t from "../../../Texts/TextImport";
import {
  APPBRANCH,
  DEBUGISON,
  FEATUREDEBUGGING,
  TextSelectable,
  isTextSelectable,
  isWeb
} from "../../config";
import { syntaxCheckFormula } from "./TestCases";
const math = require("mathjs");

export interface EquationProps {
  formula: string;
  formulaResult: string;
  onComputed: (text: string) => void;
  handBackDisplayString?: (text: string) => void;
}

function parseInputs(inputString: string) {
  DEBUGISON && FEATUREDEBUGGING && console.log("InputString: " + inputString);

  const regex = /{([^}]+)}/g; // Regular expression to match content within curly braces
  const matches = inputString.match(regex); // Find all matches

  if (!matches) {
    return []; // Return an empty array if no matches found
  }

  // Extract and return the text content within curly braces
  return matches.map((match) => match.slice(1, -1).trim());
}

function parseUnits(inputs: string[]) {
  const units = inputs.map((input) => {
    const regex = /\[(.*?)\]/; // Regular expression to match content within square brackets
    const match = input.match(regex); // Find the first match

    if (match && match[1]) {
      return match[1].trim(); // Extract unit text and trim spaces
    } else {
      return ""; // If no brackets found, unit is empty
    }
  });

  return units;
}

function createComputeString(
  numbers: { [key: string]: string },
  template: string
): string {
  DEBUGISON &&
    FEATUREDEBUGGING &&
    console.log(
      "template: " + template + " numbers: " + JSON.stringify(numbers)
    );

  // Define a regular expression to match placeholders like {key}
  const regex = /\{([^{}]+)\}/g;

  // Use matchAll to find all matches in the template string and convert it to an array
  const match = Array.from(template.matchAll(regex));
  DEBUGISON &&
    FEATUREDEBUGGING &&
    console.log("matches: " + JSON.stringify(match));

  let output = template;

  // Map through the matches and replace each placeholder with the corresponding value from the 'numbers' object
  match.forEach((matchGroup) => {
    const placeholder = matchGroup[0];
    DEBUGISON && FEATUREDEBUGGING && console.log(placeholder);
    const key = matchGroup[1]; // Extract the key within curly braces
    // Check if the key exists in the 'numbers' object
    if (numbers.hasOwnProperty(key)) {
      output = output.replace(placeholder, numbers[key]);
    } else {
      // Handle cases where the key is not found in 'numbers'
      DEBUGISON &&
        FEATUREDEBUGGING &&
        console.warn(`Key '${key}' not found in the 'numbers' object.`);
    }
  });
  output = output.replace(/ X | x /g, "*");

  DEBUGISON && FEATUREDEBUGGING && console.log(output);
  return output;
}

// Example usage
const numbers = { sTfR: "12", ferritin: "2" };
const template = "{sTfR[mg/l]} / {ferritin[µg/l]}";
const result = createComputeString(numbers, template);

DEBUGISON && FEATUREDEBUGGING && console.log("Result:", result);

export function createDisplayString(
  symbols: string[],
  template: string
): string {
  let currentIndex = 0;
  const result = template.replace(/\{.*?}/g, () => {
    const currentNumber = symbols[currentIndex];
    currentIndex = (currentIndex + 1) % symbols.length;
    return currentNumber.toString(); // Convert the number to string
  });
  return result.replace("<p>", "").replace("</p>", "");
}
function calculate(ComputeString: string): number {
  return math.evaluate(ComputeString);
}

function createSymbols(inputs: string[]): string[] {
  const symbol: string[] = [];
  inputs.map((el, index) => {
    symbol.push(el.split("[")[0]);
  });
  return symbol;
}

function isValidNumber(newValue: string) {
  // Remove leading/trailing whitespace
  const trimmedValue = newValue.trim().replace(",", ".");

  // Regular expression to match integers or floats
  const validNumberPattern = /^(?:-?\d+|-?\d+\.\d+)$/;

  return validNumberPattern.test(trimmedValue);
}
function roundToFourSignificantDigits(num: number): number {
  if (isNaN(num) || !isFinite(num)) {
    return 0;
  }

  if (num === 0) {
    return 0;
  }

  const exponent = Math.floor(Math.log10(Math.abs(num)));
  const scale = Math.pow(10, 3 - exponent);
  return parseFloat((num * scale).toFixed(0)) / scale;
}
function Equation({
  formula,
  formulaResult,
  onComputed,
  handBackDisplayString
}: EquationProps) {
  const EntireEquation = formula + " =  " + formulaResult;
  const allSymbols = createSymbols(parseInputs(EntireEquation));
  const displayEquation = createDisplayString(allSymbols, EntireEquation);
  const resultUnit = parseUnits(parseInputs(formulaResult));
  const resultSymbol = createSymbols(parseInputs(formulaResult))[0];
  handBackDisplayString && handBackDisplayString(displayEquation);

  const inputs = Array.from(
    new Set(parseInputs(formula).map((el, index) => el))
  );

  const inputUnits = parseUnits(inputs);
  const inputSymbols = createSymbols(inputs);

  const [Res, setRes] = useState<number>(0);
  const [ValidationMessage, setValidationMessage] = useState<string>("");

  const [InputValues, SetInputValues] = useState<{ [key: string]: string }>(
    Object.fromEntries(inputs.map((input) => [input, ""]))
  );

  useEffect(() => {
    const inputs = Array.from(
      new Set(parseInputs(formula).map((el, index) => el))
    );
    SetInputValues(Object.fromEntries(inputs.map((input) => [input, ""])));
    onComputed("");
  }, [formula, formulaResult, onComputed]);

  useMemo(() => ValidateCompute(), [InputValues]);

  const replaceValueOfKey = (inputKey: string, newValue: any) => {
    const updatedInputValues = { ...InputValues };

    if (!isValidNumber(newValue)) {
      onComputed("");
      setValidationMessage(t("FormInvalidInput"));
    } else {
      setValidationMessage("");
    }
    updatedInputValues[inputKey] = newValue.replace(",", ".");
    SetInputValues(updatedInputValues);
  };

  function ValidateCompute() {
    const areAllInputsFilled = Object.values(InputValues).every(
      (value) => value !== ""
    );
    if (syntaxCheckFormula(formula)) {
      return;
    }

    DEBUGISON &&
      FEATUREDEBUGGING &&
      console.log("values: " + JSON.stringify(InputValues));
    if (!areAllInputsFilled) {
      return;
    }

    const before = Res;
    const ComputeString = createComputeString(InputValues, formula);
    DEBUGISON && FEATUREDEBUGGING && console.log(ComputeString);
    const result = calculate(ComputeString);
    setRes(result);
    before !== result &&
      onComputed(
        resultSymbol +
          " = " +
          roundToFourSignificantDigits(result).toString().replace(",", ".") +
          " " +
          resultUnit
      );
    before === result && onComputed("");
  }
  console.log(displayEquation);

  return (
    <Box width={"100%"}>
      {!handBackDisplayString && (
        <Box
          flexDirection="row"
          width={"120%"}
          marginTop={isWeb ? "none" : "none"}
        >
          <HTML
            mode="default"
            html={
              '<p style="display: flex; flex-direction: row;  "> ' +
              displayEquation +
              " </p>"
            }
          />
        </Box>
      )}
      {syntaxCheckFormula(formula) && (
        <Text color="danger"> Formula failed syntax check</Text>
      )}

      {inputs.map((el, index) => {
        return (
          <Box
            key={el}
            paddingVertical={isWeb ? "s" : "none"}
            marginHorizontal="xs"
            width={isWeb ? "300px" : "100%"}
          >
            <Text selectable={isTextSelectable}>
              <HTML
                mode="fulltext"
                html={
                  "<p> " +
                  inputSymbols[index] +
                  " " +
                  inputUnits[index] +
                  "</p>"
                }
              />
            </Text>
            <NumberInput
              key={"input" + index}
              keyboardType="numeric"
              onChangeText={(value) => replaceValueOfKey(el, value)}
            />
          </Box>
        );
      })}
      <Text variant="body" color="primary">
        {ValidationMessage}
      </Text>
    </Box>
  );
}

export default Equation;
