import { APPBRANCH, DEBUGISON, TEXTDEBUG } from "../src/config";
const PRO: { [key: string]: string } = require("./PROTexts.json");
const ERKNET: { [key: string]: string } = require("./ERKNETTexts.json");
const CARE: { [key: string]: string } = require("./CARETexts.json");
const Ids: { [key: string]: string } = require("./IdTexts.json");

const variations: { [key: string]: { [key: string]: string } } = {
  PRO: PRO,
  ERKNET: ERKNET,
  CARE: CARE,
};

const texts = variations[APPBRANCH];

function t(key: string, replacements?: string[]): string {
  const text = texts[key];

  if (text !== undefined) {
    const replacedText = replacements
      ? replaceInsert(text, replacements)
      : text;

    if (DEBUGISON && TEXTDEBUG) {
      return replacedText + Ids[key];
    } else {
      return replacedText;
    }
  } else {
    return `TEXT ERROR: ${key}`;
  }
}

export default t;

export function replaceInsert(text: string, replacements: string[]): string {
  return text.replace(/\|Insert\|/g, () => {
    // Get the next replacement from the array
    const replacement = replacements.shift();

    // If there are no more replacements, use an empty string
    return replacement !== undefined ? replacement : "";
  });
}
