import { Token } from "./types";

function categorize(line: string): Token {
  if (line.startsWith("#")) {
    return { type: "Header", content: line };
  }
  if (line.match(/^\s*[-*]\s+/)) {
    return { type: "List", content: line };
  }
  if (line.match(/^\[.*\]\(.*\)/)) {
    return { type: "Link", content: line };
  }
  if (line.startsWith("**")) {
    return { type: "Label", content: line };
  }
  if (line.startsWith("*")) {
    return { type: "Paragraph", content: line };
  }
  if (line.trim().length > 0) {
    return { type: "Paragraph", content: line };
  }
  return { type: "Text", content: line };
}

function tokenizeMarkdown(md: string): Token[] {
  const tokens: Token[] = [];
  const images = md.replace(/!\[/g, "[Image:");
  const lines = images.split(/\r?\n/);
  lines.map((line) => tokens.push(categorize(line)));
  return tokens;
}

export default tokenizeMarkdown;
