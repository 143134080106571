import React from "react";
import t from "../../../../Texts/TextImport";
import { Box, Text, Container, BackButton } from "../../../components";
import { MobileFooter } from "../../../components/Footer";
import { Identifier, LogOut, Synchronisation, Version } from "./Content";

const Settings = () => {
  return (
    <Container
      backButton={<BackButton />}
      customMobileFooter={<MobileFooter />}
    >
      <Box justifyContent='center' padding={{ phone: "m", tablet: "xl" }}>
        <Text variant='title3' marginBottom='m'>
          {t("SNumber")}
        </Text>
        <Identifier />
        <Divider />
        <Text variant='title3' marginBottom='m'>
          {t("SSynchronisation")}
        </Text>
        <Synchronisation />
        <Divider />
        <Text variant='title3' marginBottom='m'>
          {t("SRegistration")}
        </Text>
        <LogOut />
        <Divider />
        <Version />
      </Box>
    </Container>
  );
};

const Divider = () => (
  <Box backgroundColor='smoke' height={1} width={"100%"} marginVertical='xl' />
);

export default Settings;
