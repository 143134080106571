import { BookmarkType, NoticeType } from "../../types";

export interface userState {
  localNotices: NoticeType[];
  localNoticesDelete: NoticeType[];
  localBookmarks: BookmarkType[];
  localBookmarksDelete: BookmarkType[];
  localFavoriteGuideline: string;
}

export const ADD_LOCAL_NOTICE = "ADD_LOCAL_NOTICE";
export interface addLocalNotice {
  type: typeof ADD_LOCAL_NOTICE;
  payload: NoticeType;
}

export const REMOVE_LOCAL_NOTICE = "REMOVE_LOCAL_NOTICE";
export interface removeLocalNotice {
  type: typeof REMOVE_LOCAL_NOTICE;
  payload: NoticeType;
}

export const ADD_LOCAL_NOTICE_DELETE = "ADD_LOCAL_NOTICE_DELETE";
export interface addLocalNoticeDelete {
  type: typeof ADD_LOCAL_NOTICE_DELETE;
  payload: NoticeType;
}

export const REMOVE_LOCAL_NOTICE_DELETE = "REMOVE_LOCAL_NOTICE_DELETE";
export interface removeLocalNoticeDelete {
  type: typeof REMOVE_LOCAL_NOTICE_DELETE;
  payload: NoticeType;
}

export const RESET_LOCAL_NOTICE = "RESET_LOCAL_NOTICE";
export interface resetLocalNotice {
  type: typeof RESET_LOCAL_NOTICE;
}

export const ADD_LOCAL_BOOKMARK = "ADD_LOCAL_BOOKMARK";
export interface addLocalBookmark {
  type: typeof ADD_LOCAL_BOOKMARK;
  payload: BookmarkType;
}

export const ADD_LOCAL_BOOKMARK_DELETE = "ADD_LOCAL_BOOKMARK_DELETE";
export interface addLocalBookmarkDelete {
  type: typeof ADD_LOCAL_BOOKMARK_DELETE;
  payload: BookmarkType;
}

export const REMOVE_LOCAL_BOOKMARK_DELETE = "REMOVE_LOCAL_BOOK_DELETE";
export interface removeLocalBookmarkDelete {
  type: typeof REMOVE_LOCAL_BOOKMARK_DELETE;
  payload: BookmarkType;
}

export const REMOVE_LOCAL_BOOKMARK = "REMOVE_LOCAL_BOOKMARK";
export interface removeLocalBookmark {
  type: typeof REMOVE_LOCAL_BOOKMARK;
  payload: BookmarkType;
}

export const RESET_LOCAL_BOOKMARK = "RESET_LOCAL_BOOKMARK";
export interface resetLocalBookmark {
  type: typeof RESET_LOCAL_BOOKMARK;
}

export const SET_LOCAL_FAVORITE_GUIDELINE = "SET_LOCAL_FAVORITE_GUIDELINE";
export interface setLocalFavoriteGuideline {
  type: typeof SET_LOCAL_FAVORITE_GUIDELINE;
  payload: string;
}

export const RESET_LOCAL_FAVORITE_GUIDELINE = "RESET_LOCAL_FAVORITE_GUIDELINE";
export interface resetLocalFavoriteGuideline {
  type: typeof RESET_LOCAL_FAVORITE_GUIDELINE;
}

export type UserActionTypes =
  | addLocalNotice
  | removeLocalNotice
  | addLocalNoticeDelete
  | removeLocalNoticeDelete
  | resetLocalNotice
  | addLocalBookmark
  | removeLocalBookmark
  | addLocalBookmarkDelete
  | removeLocalBookmarkDelete
  | resetLocalBookmark
  | setLocalFavoriteGuideline
  | resetLocalFavoriteGuideline;
