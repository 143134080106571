import React from "react";
import { Clipboard } from "react-native";
import { NoticeType } from "../../types";
import { Box, Text, useTheme } from "../Theme";
import { useAuth, useNoticeDelete } from "../../hooks";
import { BinaryAlert } from "../Alert";
import { useQueryClient } from "@tanstack/react-query";
import Date from "../Date";
import { useToast } from "../Toast";
import { useDispatch } from "react-redux";
import { addLocalNoticeDelete } from "../../redux/user/actions";
import { PopupButton } from "../Popup";
import { LANGUAGE_SUFFIX, getContent } from "../../hooks/useLanguage";
import { BACKENDTRACING, DEBUGISON } from "../../config";

interface NoticeCardProps {
	notice: NoticeType;
	thumbnail?: boolean;
}

const NoticeCard = ({ notice, thumbnail }: NoticeCardProps) => {
	const theme = useTheme();
	const queryClient = useQueryClient();
	const { show } = useToast();
	const { username } = useAuth();
	const dispatch = useDispatch();
	const { mutate } = useNoticeDelete({
		onSuccess: () => queryClient.refetchQueries(["user", username]),
		onError: () => dispatch(addLocalNoticeDelete(notice)),
	});
	const onClipboard = () => {
		Clipboard.setString(getContent(notice));
		show("In Zwischenablage gespeichtert");
	};
	return (
		<Box
			padding="xs"
			backgroundColor="mainBackground"
			borderBottomWidth={1}
			borderBottomColor="smoke"
		>
			<Box
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<Date
					variant="description"
					paddingRight="m"
					date={notice.CREATION_DATE}
				/>
				<Box flexDirection="row" alignItems="center">
					<PopupButton iconName="copy" onPress={onClipboard} text="kopieren" />
					<PopupButton
						onPress={() => {
							BinaryAlert({
								title: "Wirklich löschen?",
								desc: "",
								onPositivePress: () =>
									mutate({
										ID: notice.ID,
									}),
								onNegativePress: () => null,
							});
						}}
						iconName="bin"
						text="Löschen"
						style={{ marginLeft: theme.spacing.m }}
					/>
				</Box>
			</Box>
			<Box paddingVertical="s">
				<Text>
					{DEBUGISON && BACKENDTRACING && "NoticeWeb:"}

					{getContent(notice)}
				</Text>
			</Box>
		</Box>
	);
};

export default NoticeCard;
