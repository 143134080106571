import React, { forwardRef, RefObject, useState } from "react";
import {
	TextInput as RNTextInput,
	TextInputProps as RNTextInputProps,
} from "react-native";
import { useTheme } from "../Theme";

type TextAreaProps = RNTextInputProps;
/* eslint-disable */
const TextArea = forwardRef<RNTextInput, TextAreaProps>(
	({ style, ...props }, ref) => {
		const theme = useTheme();
		return (
			<RNTextInput
				ref={ref}
				multiline={true}
				numberOfLines={5}
				style={[
					{
						flex: 1,
						padding: theme.spacing.s,
						backgroundColor: theme.colors.mainBackground,
						...theme.textVariants.body,
						color: theme.colors.mainForeground,
						textAlignVertical: "top",
					},
					style,
				]}
				{...props}
			/>
		);
	}
);

/* eslint-enable */

export default TextArea;
