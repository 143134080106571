import React, { isValidElement, ReactNode } from "react";
import { TouchableOpacity } from "react-native";
import { Box, useTheme, Text, Theme } from "../Theme";
import Icon from "../Icon";

interface Checkbox2Props {
  label: string | ReactNode;
  checked: boolean;
  onPress: () => void;
  color: keyof Theme["colors"];
}

const CheckBox2 = ({ label, checked, onPress, color }: Checkbox2Props) => {
  return (
    <TouchableOpacity {...{ onPress }}>
      <Box flexDirection="row">
        <Box paddingTop="xs">
          <Icon name={checked ? "circledMark" : "circle"} color={color} />
        </Box>
        <Box flex={1}>
          {isValidElement(label) ? label : <Text paddingLeft="s">{label}</Text>}
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

export default CheckBox2;
