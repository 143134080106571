import {
  GlobalActionTypes,
  TOGGLE_POPUP,
  PopupType,
  HistoryType,
  PUSH_HISTORY,
  POP_HISTORY,
  RESET_HISTORY,
  TOGGLE_LAUNCH,
  TOGGLE_AUTO_UPDATE,
} from "./types";

export const togglePopup = (popup: PopupType): GlobalActionTypes => ({
  type: TOGGLE_POPUP,
  payload: popup,
});

export const pushHistory = (history: HistoryType): GlobalActionTypes => ({
  type: PUSH_HISTORY,
  payload: history,
});

export const popHistory = (): GlobalActionTypes => ({
  type: POP_HISTORY,
});

export const resetHistory = (): GlobalActionTypes => ({
  type: RESET_HISTORY,
});

export const toggleLaunch = (): GlobalActionTypes => ({
  type: TOGGLE_LAUNCH,
});

export const toggleAutoUpdate = (): GlobalActionTypes => ({
  type: TOGGLE_AUTO_UPDATE,
});
