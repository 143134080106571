import React from "react";
import { BackButton, Box, Container, NoticeList } from "../../../components";
import TabBar from "../TabBar";
import { MobileFooter } from "../../../components/Footer";

const Notices = () => {
	return (
		<Container
			tabBar={<TabBar />}
			backButton={<BackButton />}
			customMobileFooter={<MobileFooter />}
		>
			<Box padding={{ phone: "m", tablet: "xl" }}>
				<NoticeList />
			</Box>
		</Container>
	);
};

export default Notices;
