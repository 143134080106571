import { useDrawerStatus } from "@react-navigation/drawer";
import { DrawerActions } from "@react-navigation/native";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ScrollView } from "react-native";
import {
  BackButton,
  Box,
  BreadCrumbs,
  Container,
  HTML,
  Text,
} from "../../../components";
import { MobileFooter } from "../../../components/Footer";
import { GuidelineNavigationProps } from "../../../components/Navigation";
import { useAnalytics } from "../../../hooks";
import {
  createChapterTree,
  filterChapterTree,
  filterInfoTree,
  findChapterById,
} from "../../../utils";
import ChapterButton from "../ChapterButton";
import TabBar from "../TabBar";
import { useDispatch } from "react-redux";
import { pushHistory, togglePopup } from "../../../redux/global";
import { StatusBar } from "expo-status-bar";
import { useSelector } from "../../../redux";
import {
  LANGUAGE_SUFFIX,
  getContent,
  getTitle,
} from "../../../hooks/useLanguage";
import {
  BACKENDTRACING,
  DEBUGISON,
  NAVDEBUGGING,
  ORIGINDEBUG,
  isTextSelectable,
  isWeb,
} from "../../../config";
import useChapter, { useAllChapters } from "../../../hooks/queries/useChapter";
import useGuideline, {
  useForwardIfNotUnlocked,
} from "../../../hooks/queries/useGuideline";
import useReference, {
  useReferences,
} from "../../../hooks/queries/useReference";

const Information = ({
  navigation,
  route,
}: GuidelineNavigationProps<"Information">) => {
  useAnalytics({});
  const dispatch = useDispatch();
  const scrollRef = useRef<ScrollView>(null);
  const id = route.params?.id;
  const guidelineId = route.params?.guidelineId;
  const term = route.params?.term;
  const reduxPopup = useSelector((state) => state.global.popup);
  const regExp = new RegExp(term || "", "gi");
  const Allchapter = useAllChapters(guidelineId);
  const guideline = useGuideline(guidelineId);
  const chapter = useChapter(id, guidelineId);
  const references = useReferences(guidelineId);
  isWeb && useForwardIfNotUnlocked(route?.params?.guidelineId, navigation);

  const tree = filterInfoTree(createChapterTree(Allchapter.data));

  const drawerStatus = useDrawerStatus();
  useEffect(() => {
    if (drawerStatus == "open") {
      navigation.dispatch(DrawerActions.closeDrawer());
    }
    if (reduxPopup.isVisible) {
      dispatch(togglePopup({ isVisible: false }));
    }
    dispatch(pushHistory({ id, guidelineId, mode: "information" }));
    if (scrollRef?.current && scrollRef?.current?.scrollTo) {
      scrollRef?.current?.scrollTo({ y: 0, animated: false });
    }
  }, [id]);

  return (
    <>
      <StatusBar style="light" />
      <Container
        scrollRef={scrollRef}
        tabBar={<TabBar />}
        backButton={<BackButton />}
        customMobileFooter={<MobileFooter />}
        breadCrumb={
          tree ? (
            <BreadCrumbs
              tree={tree[0]?.CHILDREN || []}
              chapterId={id}
              screen="Information"
            />
          ) : null
        }
      >
        <>
          <Box padding={{ phone: "m", tablet: "xl" }}>
            {DEBUGISON && BACKENDTRACING && (
              <>
                <Text selectable={isTextSelectable}>
                  chapterdata: {JSON.stringify(chapter.data)}
                </Text>
                <Text selectable={isTextSelectable}>
                  chapterdata: {tree?.length}
                </Text>
              </>
            )}

            <Text
              selectable={isTextSelectable}
              variant="title3"
              paddingBottom="m"
            >
              {DEBUGISON && "getTitle: "}
              {getTitle(chapter.data)}
            </Text>
            {chapter.data && (
              <HTML html={getContent(chapter.data)} mode={"information"} />
            )}
            {(getTitle(chapter.data).includes("Quellen") ||
              getTitle(chapter.data).includes("ources")) && (
              <>
                {DEBUGISON && BACKENDTRACING && (
                  <>
                    <Text selectable={isTextSelectable}>
                      {" "}
                      We should have refs:
                    </Text>

                    <Text selectable={isTextSelectable}>
                      {JSON.stringify(references.data)}
                    </Text>
                  </>
                )}

                {references?.data?.map((ref, index) => (
                  <Box key={index} flexDirection="row" paddingBottom="s">
                    <Text
                      selectable={isTextSelectable}
                      paddingRight="xs"
                      fontFamily="bliss-pro-medium"
                    >
                      [{ref.ORDER}]
                    </Text>
                    <Box flex={1}>
                      {DEBUGISON && ORIGINDEBUG && (
                        <Text selectable={isTextSelectable}>
                          Quellen Content
                        </Text>
                      )}
                      <Text selectable={isTextSelectable}>
                        {getContent(ref)}
                      </Text>
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
          {!id && tree && tree.length > 0 && (
            <Box
              flex={1}
              justifyContent="center"
              paddingHorizontal={{ phone: "m", tablet: "xl" }}
            >
              {DEBUGISON && NAVDEBUGGING && (
                <Text selectable={isTextSelectable}>chapterButtons </Text>
              )}
              {tree[0]?.CHILDREN?.map((chapter) => (
                <ChapterButton
                  chapter={chapter}
                  key={chapter.UUID}
                  color={"information"}
                  screen="Information"
                />
              ))}
            </Box>
          )}
        </>
      </Container>
    </>
  );
};

export default Information;
