import { useDrawerStatus } from "@react-navigation/drawer";
import { DrawerActions } from "@react-navigation/native";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ScrollView } from "react-native";
import {
  BackButton,
  Box,
  BreadCrumbs,
  Container,
  Text,
} from "../../../components";
import { MobileFooter } from "../../../components/Footer";
import { GuidelineNavigationProps } from "../../../components/Navigation";
import { useAnalytics, useRecommendationByChapter } from "../../../hooks";
import { ChapterType, RecommendationType } from "../../../types";
import {
  createChapterTree,
  filterChapterTree,
  filterRecommendationsTree,
  findChapterById,
} from "../../../utils";
import TabBar from "../TabBar";
import Recommendation from "./Recommendation";
import ChapterButton from "../ChapterButton";
import { useDispatch } from "react-redux";
import { pushHistory, togglePopup } from "../../../redux/global";
import { StatusBar } from "expo-status-bar";
import { useSelector } from "../../../redux";
import {
  BACKENDTRACING,
  DEBUGISON,
  ORIGINDEBUG,
  isTextSelectable,
  isWeb,
} from "../../../config";
import useChapter, {
  getAllChaptersById,
  getChapterById,
  useAllChapters,
} from "../../../hooks/queries/useChapter";
import { GuidelineType } from "../../../types/guideline";
import useGuideline, {
  getGuidelineById,
  useForwardIfNotUnlocked,
} from "../../../hooks/queries/useGuideline";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { getTitle } from "../../../hooks/useLanguage";

const Recommendations = ({
  navigation,
  route,
}: GuidelineNavigationProps<"Recommendations">) => {
  useAnalytics({});

  isWeb && useForwardIfNotUnlocked(route?.params?.guidelineId, navigation);
  const scrollRef = useRef<ScrollView>(null);
  const dispatch = useDispatch();
  const drawerStat = useDrawerStatus();
  const id = route.params?.id;
  const guidelineId = route.params?.guidelineId;
  const reduxPopup = useSelector((state) => state.global.popup);

  const recommendation = useRecommendationByChapter(id, guidelineId);
  const allChapter = useAllChapters(guidelineId);
  const guideline = useGuideline(guidelineId);
  const chapter = useChapter(id, guidelineId);

  const tree =
    allChapter.data &&
    filterRecommendationsTree(createChapterTree(allChapter.data));

  const chapterWithChildren = tree && id && findChapterById(tree, id);

  DEBUGISON &&
    BACKENDTRACING &&
    console.warn("route Params: " + JSON.stringify(route.params));

  navigation.setOptions({ title: getTitle(guideline.data) });

  useEffect(() => {
    if (drawerStat == "open") {
      navigation.dispatch(DrawerActions.closeDrawer());
    }
    if (reduxPopup.isVisible) {
      dispatch(togglePopup({ isVisible: false }));
    }
    dispatch(pushHistory({ id, guidelineId, mode: "recommendations" }));
    if (scrollRef?.current && scrollRef?.current?.scrollTo) {
      scrollRef?.current?.scrollTo({ y: 0, animated: false });
    }
  }, [id]);

  return (
    <>
      <StatusBar style="light" />
      <Container
        scrollRef={scrollRef}
        tabBar={<TabBar />}
        backButton={<BackButton />}
        mode="recommendations"
        customMobileFooter={<MobileFooter />}
        breadCrumb={
          tree ? (
            <BreadCrumbs tree={tree} chapterId={id} screen="Recommendations" />
          ) : null
        }
      >
        <>
          <Box padding={{ phone: "m", tablet: "xl" }}>
            {DEBUGISON && BACKENDTRACING && (
              <>
                {/* <Text selectable={isTextSelectable}>tree: {JSON.stringify(chapter.data).substring(0, 20)}</Text> */}
                <Text selectable={isTextSelectable}>
                  allChapter.data:{" "}
                  {allChapter.data &&
                    JSON.stringify(allChapter.data).substring(0, 20)}
                </Text>
                <Text selectable={isTextSelectable}>
                  recommendation: {JSON.stringify(recommendation.data)}
                </Text>
              </>
            )}
            {/* {recommendation && allChapter.data && ( */}
            {chapter.data && recommendation && allChapter.data && (
              <>
                <Text
                  selectable={isTextSelectable}
                  variant="title3"
                  paddingBottom="m"
                >
                  {getTitle(chapter.data)}
                </Text>
                {recommendation.data?.map((item: RecommendationType) => (
                  <Recommendation key={item.UUID} {...item} />
                ))}
              </>
            )}
          </Box>
          <Box
            flex={1}
            justifyContent="center"
            paddingHorizontal={{ phone: "m", tablet: "xl" }}
          >
            {DEBUGISON && BACKENDTRACING && (
              <>
                <Text selectable={isTextSelectable}>
                  tree:{" "}
                  {allChapter.data && JSON.stringify(tree).substring(0, 20)}
                </Text>
                <Text selectable={isTextSelectable}>
                  id: {JSON.stringify(id)}
                </Text>
                <Text selectable={isTextSelectable}>
                  chapterWithChildren:
                  {chapterWithChildren &&
                    JSON.stringify(chapterWithChildren).substring(0, 20)}
                </Text>
                <Text selectable={isTextSelectable}>
                  recommendation: {JSON.stringify(recommendation.data)}
                </Text>
              </>
            )}

            {tree && !id && (
              <>
                {DEBUGISON && ORIGINDEBUG && (
                  <Text selectable={isTextSelectable}>
                    Recommendations: tree
                  </Text>
                )}

                {tree.map((chapter) => (
                  <ChapterButton
                    key={chapter.UUID}
                    chapter={chapter}
                    color="recommendations"
                    screen="Recommendations"
                  />
                ))}
              </>
            )}

            {chapterWithChildren && recommendation.data?.length === 0 && (
              <>
                {DEBUGISON && ORIGINDEBUG && (
                  <Text selectable={isTextSelectable}>
                    Recommendations: Chapter with children
                  </Text>
                )}
                {chapterWithChildren.CHILDREN?.map((chapter) => (
                  <ChapterButton
                    key={chapter.UUID}
                    chapter={chapter}
                    color="recommendations"
                    screen="Recommendations"
                  />
                ))}
              </>
            )}
          </Box>
        </>
      </Container>
    </>
  );
};

export default Recommendations;
