export const TestCases = {
  FractionalExcretionOfSodium: {
    formula:
      "100*  ({U<sub>Na</sub>[BeispielEinheit]} * {S<sub>Cr</sub>} )/ ({S<sub>Na</sub>} * {U<sub>Cr</sub>} )",
    result: "{FE<sub>Na</sub>[%]}",
  },
  Hypomagnesemia: {
    formula:
      "100*  ({U<sub>Mg</sub>} * {S<sub>Cr</sub>} )/ ({S<sub>Mg</sub>} * {U<sub>Cr</sub>} )",
    result: "{FE<sub>Mg</sub>[%]}",
  },
  TTKG: {
    formula:
      "100*  ({U<sub>K+</sub>} / {S<sub>K+</sub>} )/ ({U<sub>OSM</sub>} / {S<sub>OSM</sub>} )",
    result: "TTKG",
  },
  TTKG_Units: {
    formula:
      "100*  ({U<sub>K+</sub>[mmol/L]} / {S<sub>K+</sub>[mmol/L]} )/ ({U<sub>H2O</sub>[mOsm/kg]} / {S<sub>H2O</sub>[mOsm/kg]} )",
    result: "TTKG[%]",
  },
  Hypophosphatemia: {
    formula:
      "1 -  ({U<sub>p</sub>} / {S<sub>p</sub>} )* ({S<sub>Cr</sub>} / {U<sub>Cr</sub>} )",
    result: "TRP",
  },
  BloodAnionGap: {
    formula:
      "({Na<high>+</high>} +{K<high>+</high>} )- ({CI} / {HCO<sub>3</sub> <high>-</high>} )",
    result: "AG<high>hello</high><sub>long</sub>",
  },
  HTMLStuff: {
    formula: "Sp &ndash; (Up / Ucr) * Scr ",
    result: " TmP/GFR ",
  },
  HTML2BloodAnionGap: {
    formula:
      "(Na<sup>+</sup> + K<sup>+</sup>) &ndash; (Cl<sup>-</sup>+ HCO<sub>3</sub><sup>-</sup>)",
    result: "AG",
  },
  InvalidFormula1: {
    formula: "{Invalid<sub>Formula</sub>}",
    result: "Invalid Formula",
  },
  InvalidFormula2: {
    formula: "Invalid Formula {MissingClosingTag",
    result: "Invalid Formula",
  },
  InvalidFormula3: {
    formula: "{MissingOpeningTag} Invalid Formula",
    result: "Invalid Formula",
  },
};

export function getAllFormulasSeparatedByPipe() {
  const formulas = Object.values(TestCases).map((testCase) => testCase.formula);
  return formulas.join(" | ");
}

export function getAllResultsSeparatedByPipe() {
  const results = Object.values(TestCases).map((testCase) => testCase.result);
  return results.join(" | ");
}

export function syntaxCheckFormula(formula: string): boolean {
  // Regular expression pattern to match valid variable names inside curly braces
  const variablePattern = /{[A-Za-z0-9_<>]+}/g;

  // Regular expression pattern to match valid arithmetic operators and parentheses
  const operatorPattern = /[-+*/()]/g;

  // Find all matches in the formula
  const matches = formula.match(variablePattern) || [];
  const operators = formula.match(operatorPattern) || [];

  // Stack to keep track of opening and closing parentheses
  const stack: string[] = [];

  for (const match of matches.concat(operators)) {
    if (match === "(") {
      stack.push("(");
    } else if (match === ")") {
      if (!stack.length) {
        return false; // Unmatched closing parenthesis
      }
      stack.pop();
    } else if (!match.startsWith("{") || !match.endsWith("}")) {
      return false; // Invalid variable name
    }
  }

  // Check if there are unmatched opening parentheses
  if (stack.length > 0) {
    return false;
  }

  return true;
}
