import React, { useState, useEffect, useCallback } from "react";
import t from "../../Texts/TextImport";
import { getContent, getTitle } from "../hooks/useLanguage";
import {
  ModeType,
  ScoreAnswerType,
  ScoreResultType,
  ScoreType
} from "../types";
import { compareValues } from "../utils";
import ButtonGroup from "./ButtonGroup";
import TextInput from "./Form/TextInput";
import HTML from "./HTML";
import Icon from "./Icon";
import { Box, Text } from "./Theme";
import { SelectFormula } from "./Formula";
import { APPBRANCH, TextSelectable, isTextSelectable } from "../config";

interface ScoreProps {
  data: ScoreType;
}
const Score = ({ data }: ScoreProps) => {
  const [selectedAnswers, setSelectedAnswers] = useState<ScoreAnswerType[]>([]);
  const [result, setResult] = useState<ScoreResultType>();
  const [formulaResult, setFormulaResult] = useState<string>("");
  const isCompleted = selectedAnswers.length === data.QUESTIONS.length;
  useEffect(() => {
    if (isCompleted) {
      const value = getResultValue();
      setResult(
        data.RESULTS.find(
          (result) => result.VALUE_FROM <= value && value <= result.VALUE_TO
        )
      );
    }
  }, [selectedAnswers, formulaResult]);

  const getResultValue = useCallback(() => {
    let sum = 0;
    selectedAnswers.forEach((answer) => {
      sum += answer.VALUE;
    });
    return sum;
  }, [selectedAnswers]);

  return (
    <Box>
      {data.QUESTIONS.sort(compareValues("ORDER")).map((question, index) => {
        const isAnswered = selectedAnswers.find(
          (i) => i.QUESTION_UUID === question.UUID
        );
        const toggleAnswer = (list: number[]) => {
          if (list.length > 0) {
            setSelectedAnswers([
              ...selectedAnswers.filter(
                (i) => i.QUESTION_UUID !== question.UUID
              ),
              question.ANSWERS[list[0]]
            ]);
          } else {
            setSelectedAnswers([
              ...selectedAnswers.filter(
                (i) => i.QUESTION_UUID !== question.UUID
              )
            ]);
          }
        };
        const onChangeText = (text: string) => {
          if (text.length > 0 && parseInt(text) > 0) {
            const inputAnswer = question.ANSWERS[0];
            inputAnswer.VALUE = parseInt(text);
            setSelectedAnswers([
              ...selectedAnswers.filter(
                (i) => i.QUESTION_UUID !== question.UUID
              ),
              inputAnswer
            ]);
          } else {
            setSelectedAnswers([
              ...selectedAnswers.filter(
                (i) => i.QUESTION_UUID !== question.UUID
              )
            ]);
          }
        };
        return (
          <Box key={index}>
            {data.QUESTIONS.length !== 1 &&
              data.QUESTIONS[0].TYPE !== "FORMULA" && (
                <Text
                  selectable={isTextSelectable}
                  fontFamily="bliss-pro-medium"
                >
                  {getTitle(question)}
                </Text>
              )}
            {!isAnswered && question.TYPE !== "FORMULA" ? (
              <HTML mode="default" html={getContent(question)} />
            ) : null}
            {question.TYPE === "SELECT" ? (
              <ButtonGroup onStateChange={toggleAnswer}>
                {question.ANSWERS.map((answer, index) => (
                  <ButtonGroup.Button key={index} index={index} minWidth={150}>
                    {getTitle(answer)}
                  </ButtonGroup.Button>
                ))}
              </ButtonGroup>
            ) : null}
            {question.TYPE === "INPUT" ? (
              <Box maxWidth={200} paddingVertical="m">
                <TextInput keyboardType="numeric" onChangeText={onChangeText} />
              </Box>
            ) : null}
            {question.TYPE === "FORMULA" ? (
              <Box flex={1} paddingVertical="m">
                <SelectFormula
                  formula={getContent(question)}
                  formulaResult={getContent(data.RESULTS[index])}
                  onComputed={setFormulaResult}
                />
              </Box>
            ) : null}
          </Box>
        );
      })}
      {formulaResult !== "" && (
        <Box paddingBottom="s">
          <Box>
            <Text
              selectable={isTextSelectable}
              color="primary"
              paddingLeft="xs"
            >
              {t("SResult")}
            </Text>

            <Text
              selectable={isTextSelectable}
              color="informations"
              paddingLeft="xs"
            >
              <HTML mode="default" html={"<p>" + formulaResult + "</p>"} />
            </Text>
          </Box>
          <Text>{getTitle(result)}</Text>
        </Box>
      )}
      {isCompleted && (
        <Box paddingBottom="s">
          <Box flexDirection="row" alignItems="center">
            <Icon name="circledHeart" color="primary" />
            <Text
              selectable={isTextSelectable}
              color="primary"
              paddingLeft="xs"
            >
              {t("SResult")}
              {getResultValue()}
            </Text>
          </Box>
          <Text>{getTitle(result)}</Text>
        </Box>
      )}
    </Box>
  );
};

export default Score;
