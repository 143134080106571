import React, { ReactNode } from "react";
import { APPBRANCH, isInTest, setupMode } from "../config";
import {
  createTheme,
  createBox,
  createText,
  useTheme as useReTheme,
  ThemeProvider as ReThemeProvider,
} from "@shopify/restyle";
import { StatusBar } from "expo-status-bar";

const AllColors: { [key: string]: { [key: string]: string } } = {
  Fulltext: {
    PRO: "#E27200",
    CARE: "#5C91AE",
    ERKNET: "#177BAB",
  },
  StepByStep: {
    PRO: "#95000B",
    CARE: "#D1C892",
    ERKNET: "#A51B1A",
  },
  Search: {
    PRO: "#000B48",
    CARE: "#007A71",
    ERKNET: "#A6A5A5",
  },
  Recommendations: {
    PRO: "#681A83",
    CARE: "#9A4837",
    ERKNET: "#4B4444",
  },
  Standard: {
    PRO: "#63A231",
    CARE: "#847E62",
    ERKNET: "#DAB841",
  },
};

export const palette = {
  Fulltext: AllColors.Fulltext[APPBRANCH],
  orangeLight: "#E9953F",
  black: "#3E3E3E",
  blackLight: "#6E6E6E",
  teal: "#00625A",
  StepByStep: AllColors.StepByStep[APPBRANCH],
  crimsonLight: "#AF3F48",
  greyPrimary: "#888888",
  greyLight: "#CECFD3",
  Search: AllColors.Search[APPBRANCH],
  navyLight: "#3F4875",
  Recommendations: AllColors.Recommendations[APPBRANCH],
  purpleLight: "#8D53A2",
  Standard: AllColors.Standard[APPBRANCH],
  redPrimary: "#D52B38",
  tableBackground: "#e9eaec",
  tableBorder: "#b6c0d5",
  white: "#FFFFFF",
  snow: "#FCFCFC",
  ghost: "#F2F2F2",
  smoke: "#E5E5E5",
};

const theme = createTheme({
  colors: {
    mainBackground: palette.white,
    mainForeground: palette.black,
    hoverBackground: palette.smoke,
    primary: palette.Standard,
    secondary: palette.Search,
    secondaryLight: palette.navyLight,
    fullText: palette.Fulltext, // for migration
    fulltext: palette.Fulltext, // for migration
    recommendations: palette.Recommendations,
    search: palette.Search,
    stepByStep: palette.StepByStep, // for migration
    stepbystep: palette.StepByStep, // for migration
    default: palette.black,
    information: palette.greyPrimary,
    generalinformation: palette.black,
    informations: palette.black,
    settings: palette.black,
    danger: palette.redPrimary,
    success: palette.Standard,
    popupBackground: palette.smoke,
    tableBackground: palette.tableBackground,
    tableBorder: palette.tableBorder,
    greyLight: palette.greyLight,
    smoke: palette.smoke,
    green: "#63A231",
  },
  spacing: {
    none: 0,
    xxs: 2,
    xs: 4,
    s: 8,
    m: 16,
    l: 24,
    xl: 40,
    xxl: 60,
    xxxl: 80,
    DrawerHeader: 110,
    Footer: 105,
  },
  textVariants: {
    hero: {
      fontSize: 60,
      fontFamily: "bliss-pro-medium",
      lineHeight: 90,
      color: "mainForeground",
    },
    title1: {
      fontSize: 24,
      fontFamily: "bliss-pro-medium",
      lineHeight: 26,
      color: "mainForeground",
    },
    title2: {
      fontSize: 24,
      fontFamily: "bliss-pro-light-italic",
      lineHeight: 26,
      color: "mainForeground",
    },
    title3: {
      fontSize: 24,
      fontFamily: "bliss-pro-medium",
      lineHeight: 26,
      color: "mainForeground",
    },
    subtitle1: {
      fontSize: 20,
      fontFamily: "bliss-pro-light",
      lineHeight: 26,
      color: "mainForeground",
    },
    subtitle2: {
      fontSize: 20,
      fontFamily: "bliss-pro-light-italic",
      lineHeight: 26,
      color: "mainForeground",
    },
    body: {
      fontSize: 18,
      fontFamily: "bliss-pro-light",
      lineHeight: 24,
      color: "mainForeground",
    },
    description: {
      fontSize: 15,
      fontFamily: "bliss-pro-light",
      lineHeight: 18,
      color: "mainForeground",
    },
    tiny: {
      fontSize: 12,
      fontFamily: "bliss-pro-light",
      lineHeight: 16,
      color: "mainForeground",
    },
    button: {
      fontSize: 20,
      fontFamily: "bliss-pro-light-italic",
      lineHeight: 26,
      color: "mainForeground",
    },

    boldButton: {
      fontSize: 20,
      fontFamily: "bliss-pro-medium-italic",
      lineHeight: 26,
      color: "mainForeground",
    },
    symbols: {
      fontFamily: "leila-symbols",
      //textTransform: "none",
      color: "mainForeground",
    },
    navigationTitle: {
      fontFamily: "bliss-pro-medium",
      fontSize: 15,
      lineHeight: 18,
      color: "mainForeground",
    },
    popupTitle: {
      fontFamily: "bliss-pro-medium",
      fontSize: 18,
      lineHeight: 26,
      color: "mainForeground",
    },
    popupButton: {
      fontSize: 15,
      letterSpacing: 0.225,
      fontFamily: "bliss-pro-medium",
      lineHeight: 18.75,
      color: "mainForeground",
      textTransform: "uppercase",
    },
  },
  breakpoints: {
    phone: 0,
    tablet: 800,
    largeDesktop: 1400,
  },
});

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <ReThemeProvider theme={theme}>
      {!isInTest && <StatusBar style="dark" />}
      {children}
    </ReThemeProvider>
  );
};

export type Theme = typeof theme;
export const Box = createBox<Theme>();
export const Text = createText<Theme>();
export const useTheme = () => useReTheme<Theme>();

Text.defaultProps = {
  variant: "body",
  allowFontScaling: false,
};

export default theme;
