import React from "react";
import { Text, Theme } from "./Theme";
import moment from "moment";
import { TextProps } from "@shopify/restyle";
moment.locale("de", {
  months: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
});

interface DateProps extends TextProps<Theme> {
  date?: string;
  withDay: boolean;
}
const TextDate = ({ date, withDay, ...props }: DateProps) => {
  const formatedDate =
    date && withDay
      ? moment(date).format("DD.MM.YYYY")
      : date && !withDay
      ? moment(date, "YYYY-MM-DD", "de").format("MMMM YYYY")
      : moment(new Date()).format("DD.MM.YYYY");

  return <Text {...props}>{formatedDate}</Text>;
};

TextDate.defaultProps = {
  withDay: true,
};

export default TextDate;
