import React from "react";
import { View } from "react-native";
import Icon from "../Icon";
import { Text, Box } from "../Theme";
import { APPBRANCH } from "../../config";

interface PopupTagHeaderProps {
  tag: boolean;
  ars: boolean;
  redHand: boolean;
}

const PopupTagHeader = ({ tag, ars, redHand }: PopupTagHeaderProps) => {
  return (
    <Box flexDirection="row">
      {tag && (ars || redHand) && (
        <Box paddingRight="xs">
          <Icon name="informations" color="mainForeground" />
        </Box>
      )}
      {ars && APPBRANCH !== "ERKNET" && (
        <Box paddingRight="xs">
          <Icon name="statistics" color="mainForeground" />
        </Box>
      )}
      {redHand && APPBRANCH !== "ERKNET" && (
        <Box paddingRight="xs">
          <Icon name="hand" color="mainForeground" />
        </Box>
      )}
    </Box>
  );
};

interface PopupBookmarkHeaderProps {
  onPress: () => void;
}

const PopupBookmarkHeader = ({ onPress }: PopupBookmarkHeaderProps) => {
  return <Box></Box>;
};

export { PopupTagHeader };
